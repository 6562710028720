import axios from "axios"
import react, { useEffect, useState } from "react"
import { resources } from "../Resourses/Resources"
import { EditRounded } from "@mui/icons-material"
import "./facultyScreens.css";
import ViewNotifications from "./ViewNotifictions";
import ViewEvents from "./ViewEvents";
import ViewPressNotes from "./ViewPressNotes";
const FacultyView = () => {
    const [uploadType, setUploadType] = useState("FacultyViewNotifications")
    useEffect(() => {
        const activeStyle = {
            backgroundColor: "orange",
            color: "white",
        };
    
        const defaultStyle = {
            backgroundColor: "",
            color: "",
        };
    
        document.getElementById("notificationsView").style.backgroundColor = uploadType === "FacultyViewNotifications" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("notificationsView").style.color = uploadType === "FacultyViewNotifications" ? activeStyle.color : defaultStyle.color;
    
        document.getElementById("pressNotesView").style.backgroundColor = uploadType === "FacultyViewPressNotes" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("pressNotesView").style.color = uploadType === "FacultyViewPressNotes" ? activeStyle.color : defaultStyle.color;
    
        document.getElementById("eventsView").style.backgroundColor = uploadType === "FcultyViewEvents" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("eventsView").style.color = uploadType === "FcultyViewEvents" ? activeStyle.color : defaultStyle.color;
    }, [uploadType]);
    
    return (
        <div className="uploadContainer">
            <div className="facultyuploadButtons">
                <button onClick={(e) => setUploadType("FacultyViewNotifications")} id="notificationsView">Notifications</button>
                <button onClick={(e) => setUploadType("FacultyViewPressNotes")} id="pressNotesView" >Press Notes</button>
                <button onClick={(e) => setUploadType("FcultyViewEvents")} id="eventsView">Events</button>
            </div>
            <div>
                {uploadType === "FacultyViewNotifications" && <ViewNotifications />}
                {uploadType === "FacultyViewPressNotes" && <ViewPressNotes />}
                {uploadType === "FcultyViewEvents" && <ViewEvents />}
            </div>
        </div>
    )
}
export default FacultyView;
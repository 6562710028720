import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import csefaculty from "../../../assets/HODs/ECE HOD.JPG";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import Cookies from "js-cookie"
import Labs from "./Labs";
import Faculty from "./Faculty";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import axios from "axios";
import { resources } from "../../Resourses/Resources";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import {
  FluentShifts30Minutes24Filled,
  StreamlineBugAntivirusDebugging,
} from "../../../assets/icons/svgs";
import { UitCircuit } from "../../../assets/icons/svgs";
import { StreamlineBugAntivirusDebugging2 } from "../../../assets/icons/svgs";
import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
// import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
import { MdiPresentation } from "../../../assets/icons/svgs";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import QuizIcon from "@mui/icons-material/Quiz";
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourseStructure = () => {
  const userDetailsCookie = Cookies.get("userDetails");
  let userDetails;
  if(userDetailsCookie){
    userDetails = JSON.parse(userDetailsCookie);
  }
  const userName = userDetails?.username
  const departMent = userDetails?.department
  const userEmail = userDetails?.email
  const date = new Date()
  let academicYear1;
  const currentYear = date.getFullYear()
  const month = date.getMonth()
  if (month < 5) {
    academicYear1 = currentYear - 1 + "-" + currentYear
  } else {
    academicYear1 = currentYear + "-" + currentYear + 1

  }
  const [regulationDetails, setRegulationDetails] = useState([])
  const [fileView, setFileView] = useState(false)
  const [filepath, setFilepath] = useState("")
  const handleClickOpen = () => {
    setFileView(true);
  };

  const handleClose = () => {
    setFileView(false);
  };
  useEffect(() => {
    const fetchRegulationDetails = async () => {
      try {
        const response = await axios.get(resources.APPLICATION_URL + `getRegulationDetails?academicYear=${academicYear1}&branch=ECE`)
        console.log("response===>", response.data)
        setRegulationDetails(response.data)
      } catch (error) {
        console.log("error===>", error)
      }
    }
    fetchRegulationDetails()
  }, [])
  return (
    <div className="containe">
      <PageHeader name="ECE - COURSE STRUCTURE" />
      <div className="course_structure_cnt animate__animated animate__zoomIn">
        <div className="course text-center">
          <h4>Course Structure for all Years</h4>
          <p>
            Download the Course Structure and Syllabus of four years of ECE
            course
          </p>
        </div>

        <div className="w-100 text-center d-flex justify-content-center gap-5 ">
        <table className="studentDataTable" >
          <thead>
            <tr>
              <th>S.No</th>
              <th>Regulation</th>
              <th>Level</th>
              <th>Degree</th>
              <th>Batch</th>
              <th>Branch</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {regulationDetails && regulationDetails.length > 0 && regulationDetails.map((items, index) => {
              console.log("items===>", items)
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{items.regulation}</td>
                  <td>{items.level}</td>
                  <td>{items.degree}</td>
                  <td>{items.batch}</td>
                  <td>{items.branch}</td>
                  <td><RemoveRedEyeOutlinedIcon onClick={() => { setFileView(true); setFilepath(items.filePath) }}></RemoveRedEyeOutlinedIcon></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        </div>
        <React.Fragment>
        <Dialog
          fullScreen
          open={fileView}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
              maxHeight: '100vh',
            },
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

            </Toolbar>
          </AppBar>
          {filepath && (
            <iframe
              src={`data:application/pdf;base64,${filepath}`}
              width="100%"
              height="100%"
            />
          )}
        </Dialog>
      </React.Fragment>
      </div>
    </div>
  );
};
const Events = () => {
  const getIcon1 = () => {
    return <FluentShifts30Minutes24Filled />;
  };

  const getIcon2 = () => {
    return <UitCircuit />;
  };

  const getIcon3 = () => {
    return <StreamlineBugAntivirusDebuggingSolid />;
  };

  const getIcon4 = () => {
    return <CoPresentIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const getIcon5 = () => {
    return <MdiPresentation />;
  };

  const getIcon6 = () => {
    return <QuizIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const title1 = "Just-A-Minute";
  const desc1 =
    "is an all round-fun event that is all about the control of the mind over the mouth.";

  const title2 = "Instant Circuit Designing";
  const desc2 =
    "is a Competative event that all about the designing of Circuits Instantly.";

  const title3 = "Circuit Debugging";
  const desc3 = "Circuit Debugging is a Competative Event";

  const title4 = "Seminars";
  const desc4 =
    "Students give the demonistration on Various Latest Trends in Industry.";

  const title5 = "Poster Presentation";
  const desc5 =
    "is a way to share your knowledge of topic in a short format. It usually includes two elements - a poster and a brief explanation.";

  const title6 = "Quiz";
  const desc6 =
    "A quiz is a game or competition in which someone tests your knowledge by asking you questions";

  const IconCard = ({ icon, title, desc }) => {
    return (
      <>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            width: 320,
            // height: 205,
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
            },
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 60 }}>
            {icon()}
          </AspectRatio>
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {title}
            </Typography>
            <p className="desc">{desc}</p>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <div className="container">
      <PageHeader name="ACTIVITIES & EVENTS" />

      <div className="course text-center animate__animated animate__fadeInRight">
        <h4>Student Development Activities</h4>
        <br />
        <p style={{ textAlign: "justify" }}>
          In order to face the competitive world, a student must have good
          communication skills. In order to encourage the students to improve
          their inter personal and communication skills, to develop leadership
          qualities among students, Thrushna by Access have been formed.ECE
          association comprises the members from the faculty and the students
          from various years.
        </p>
        <p style={{ textAlign: "justify" }}>
          The Head of the Department will be the President for this association.
          This Association Students Forum conducts Student Meets, Guest Lectures
          and other Co-curricular and Extracurricular activities like Quizzes,
          Debates, Group Discussions, Just a minute, Elocution, Technical Talks,
          painting, tongue twister etc. These types of events greatly help the
          student community to come up with their ultimate potentialities.
        </p>
      </div>
      <div className="row mb-5">
        <div className="col-lg-4">
          <IconCard icon={getIcon1} title={title1} desc={desc1} />
        </div>
        <div className="col-lg-4">
          <IconCard icon={getIcon2} title={title2} desc={desc2} />
        </div>
        <div className="col-lg-4">
          <IconCard icon={getIcon3} title={title3} desc={desc3} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <IconCard icon={getIcon4} title={title4} desc={desc4} />
        </div>
        <div className="col-lg-4">
          <IconCard icon={getIcon5} title={title5} desc={desc5} />
        </div>
        <div className="col-lg-4">
          <IconCard icon={getIcon6} title={title6} desc={desc6} />
        </div>
      </div>
    </div>
  );
};

const Obe = () => {
  return (
    <div className="container">
      <PageHeader name="PEOs/POs/PSOs/COs" />
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM EDUCATIONAL OBJECTIVES(PEO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PEO 1:</h2>
              <p>
                Engineering Foundation: To produce graduates with firm
                foundation in Electronics and Communication Engineering.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 2:</h2>
              <p>
                Core Competence: To motivate graduates to Analyze, Design,
                Develop, Optimize and implement electronic systems with
                competent spirit.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 3:</h2>
              <p>
                Soft Skills: To make graduates with a professional outlook who
                can communicate effectively and interact responsibly with
                colleagues, Clients, Employers and society.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 4:</h2>
              <p>
                Knowledge Enhancement: To prepare graduates who pursue lifelong
                learning and professional development including higher
                education.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM OUTCOMES(PO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PO 1:</h2>
              <p>
                Engineering Knowledge: Apply the knowledge of mathematics,
                science, engineering fundamentals, and an engineering
                specialization to the solution of complex engineering problems.
              </p>
            </div>
            <div class="peo">
              <h2>PO 2:</h2>
              <p>
                Problem Analysis: Identify, formulate, review research
                literature, and analyze complex engineering problems reaching
                substantiated conclusions using first principles of mathematics,
                natural sciences, and engineering sciences.
              </p>
            </div>
            <div class="peo">
              <h2>PO 3:</h2>
              <p>
                Design/Development of solutions: Design solutions for complex
                engineering problems and design system components or processes
                that meet the specified needs with appropriate consideration for
                the public health and safety, and the cultural, societal, and
                environmental considerations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 4:</h2>
              <p>
                Conduct Investigations of Complex problems: Use research-based
                knowledge and research methods including design of experiments,
                analysis and interpretation of data, and synthesis of the
                information to provide valid conclusions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 5:</h2>
              <p>
                Modern Tool Usage: Create, select, and apply appropriate
                techniques, resources, and modern engineering and IT tools
                including prediction and modeling to complex engineering
                activities with an understanding of the limitations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 6:</h2>
              <p>
                The Engineer and Society: Apply reasoning informed by the
                contextual knowledge to assess societal, health, safety, legal
                and cultural issues and the consequent responsibilities relevant
                to the professional engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 7:</h2>
              <p>
                Environment and Sustainability: Understand the impact of the
                professional engineering solutions in societal and environmental
                contexts, and demonstrate the knowledge of, and need for
                sustainable development.
              </p>
            </div>
            <div class="peo">
              <h2>PO 8:</h2>
              <p>
                Ethics: Apply ethical principles and commit to professional
                ethics and responsibilities and norms of the engineering
                practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 9:</h2>
              <p>
                Individual and Team Work: Function effectively as an individual,
                and as a member or leader in diverse teams, and in
                multidisciplinary settings.
              </p>
            </div>
            <div class="peo">
              <h2>PO 10:</h2>
              <p>
                Communication: Communicate effectively on complex engineering
                activities with the engineering community and with society at
                large, such as, being able to comprehend and write effective
                reports and design documentation, make effective presentations,
                and give and receive clear instructions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 11:</h2>
              <p>
                Project Management and Finance: Demonstrate knowledge and
                understanding of the engineering and management principles and
                apply these to one’s own work, as a member and leader in a team,
                to manage projects and in multidisciplinary environments.
              </p>
            </div>
            <div class="peo">
              <h2>PO 12:</h2>
              <p>
                Life-Long Learning: Recognize the need for, and have the
                preparation and ability to engage in independent and life-long
                learning in the broadest context of technological change.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM SPECIFIC OUTCOMES (PSO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="pso-container">
            <div class="pso">
              <h2>PSO 1:</h2>
              <p>
                The ECE Graduates will be equipped with knowledge of complete
                design flow from specification to silicon in areas of both
                digital and analog VLSI Design and will be able to work in IC
                design companies.
              </p>
            </div>
            <div class="pso">
              <h2>PSO 2:</h2>
              <p>
                The ECE Graduates will be trained with microprocessor and
                microcontroller based system design skills and can work as
                design and verification engineers in the area of Embedded
                Systems and IOT.
              </p>
            </div>
            <div class="pso">
              <h2>PSO 3:</h2>
              <p>
                The ECE Graduates will be able to apply engineering knowledge
                for design and implementation of projects pertaining to signal
                processing and communications.
              </p>
            </div>
            <div class="pso">
              <h2>PSO 4:</h2>
              <p>
                The ECE Graduates will be incorporated with necessary soft
                skills, Aptitude and technical skills to work in it and public
                sector.
              </p>
            </div>
            <div class="pso">
              <h2>PSO 5:</h2>
              <p>
                The ECE Graduates will be Implemented functional blocks of
                hardware / software designs for signal processing and
                communication applications.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          COURSE STRUCTURE (CO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <ul class="course_outcomes_cnt">
              <li>
                <a
                  href="https://drive.google.com/file/d/1n3Qu55EsPjbyJDVHad3_JLPDCCPq67tR/view"
                  target="_blank"
                >
                  R18
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1P2RLsSGtXP-DegiXSAc44UC9cERVTJPh/view"
                  target="_blank"
                >
                  R22
                </a>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const AboutDepartment = () => {
  return (
    <div>
      <div className="container">
        <PageHeader name="ELECTRONICS & COMMUNICATION ENGINEERING" />
        <div className="container-xxl">
          <div className="container-xxl py-5">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{ minHeight: "100px", height: "auto" }}
                >
                  <div className="animate__animated animate__zoomIn">
                  <h6 style={{textAlign:"center",backgroundColor:"lightgreen"}}>HEAD OF THE DEPARTMENT -
ELECTRONICS & COMMUNICATION ENGINEERING</h6>
                    <img
                      className="img-fluid rounded w-100 h-100"
                      src={csefaculty}
                      alt="csefaculty-img"
                      style={{ objectFit: "contain" }}
                    />
                     <div className="img_content">
                      <p style={{textAlign: "center"}}>Dr. P. SEKHARBABU<br/>
HOD & Associate Professor<br/>
suma.sekhar3@gmail.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                  <h6 className="section-title bg-white text-start text-primary pe-3">
                    HOD
                  </h6>
                  <h6 className="mb-4">ELECTRONICS & COMMUNICATION ENGINEERING</h6>

                  <div className="row gy-2 gx-4 mb-4">
                    <p style={{textAlign:"justify"}}>
                    <p>
                      Electronics and Communication Engineering Department was
                      Established in 2001, the Department offers a 4-Year B.Tech
                      Degree programme, Electronics and Communications
                      Engineering, with an intake of 120 students.
                    </p>

                    <p>
                      The department of Electronics and Communication
                      Engineering (ECE) was established during the inception of
                      the institute in 2001. The department has a faculty
                      student ratio as per AICTE norms. The average teaching
                      experience is more than 5 years. So far around 1200
                      students have graduated. Every year our students secure
                      placements in reputed companies like INFOSYS, TCS, TECH
                      MAHINDRA, Accenture, Cognizant and VEDA IIT etc.
                    </p>

                    <p>
                      The Department is committed to establish, maintain and
                      advance a broad understanding of Electronics &
                      Communication Engineering to fulfill academic and
                      professional objectives.
                    </p>

                    <p>
                      The department has created a student association called
                      “Thrushna by Access” under the aegis of which the students
                      are conducting the events like Technical Quizes, Mini
                      Project exhibition, Guest lectures, Technical fest,
                      Technical Symposium to quote a few.
                    </p>

                    <p>
                      The department has well-equipped state-of-the-art
                      laboratories for Electronic Devices & Circuits, IC
                      Applications, Digital System Design, Basic Simulation,
                      Analog & Digital Communications, Electronic Circuit
                      Analysis, Analog Electronics, Analog & Digital
                      Electronics, Digital Signal Processing, Microprocessors
                      and Microcontrollers, ECAD& VLSI Microwave Engineering.
                      Software available includes latest versions of MATLAB,
                      Xilinx and Multisim.
                    </p>

                    <p>
                      The strengths of the department are the specialized
                      laboratories like Antenna Measurement System & PCB
                      fabrication setup, senior faculty, dynamic & young
                      associate and assistant professors, staff and meritorious
                      students.
                    </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card_cntr1">
            <div
              className="boxes col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="boxes1 service-item text-center pt-3">
                <div className="p-4">
                  <h5 className="mb-1">Vision</h5><br/>
                  <p style={{textAlign:"justify"}}>
                    Establish Industry ready high academic standards in Computer
                    Science Engineering education and research and accomplish
                    this goal.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="boxes col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="boxes1 service-item text-center pt-3">
                <div className="p-4">
                  <h5 className="mb-3">Mission</h5>
                  <p style={{textAlign:"justify"}}>
                    Establish high quality interactive programmes in partnership
                    with other eminent institutes of national importance and
                    train the students to become leaders and masters of
                    technology with academic excellence.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="boxes col-lg-3 text-center col-sm-6 wow "
              data-wow-delay="0.5s"
            >
              <div
                className="boxes text-center pt-3 pb-5"
                style={{ backgroundColor: "#f6f6f6", height: "fit-content" }}
              >
                <div className="p-4 ">
                  <h5 className="mb-3">Our Goals</h5>
                  <div>
                    <Accordion
                      sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ backgroundColor: "#fff" }}
                      >
                        Research & Development
                      </AccordionSummary>
                      <AccordionDetails
                        align="center"
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                          textAlign: "justify",
                        }}
                      >
                        To create Research & Development Cell to accelerate
                        Research & Development in the Department.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        Training Programs
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                          textAlign: "justify",
                        }}
                      >
                        To conduct Industry relevant Training Programmes for
                        students so that they are ready for Industry.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        Training & Placements
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                          textAlign: "justify",
                        }}
                      >
                        To enhance Training and Placement activities so that the
                        Department achieves 90% of employment to all eligible
                        candidates.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        Industrial Developement
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                          textAlign: "justify",
                        }}
                      >
                        To work closely with Industry in developing curriculum
                        commensurate with Industry needs.{" "}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ECE = () => {
  const [value, setValue] = React.useState(0);
  const [departmentData , setDepartmentData] = useState([])

  const handleGeteceDepartment = async () => {
    try {
      const res = await axios.get(
        resources.APPLICATION_URL + "getstudentdata"+"ECE"
      );
      if (res.data) {
       setDepartmentData(res.data)
       
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(()=>{
    handleGeteceDepartment()
  },[]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "35px" }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
             <Tab label="About Department" {...a11yProps(0)} />
            
            <Tab label="Laboratories" {...a11yProps(1)} />
            <Tab label="Course Structure" {...a11yProps(2)} />
            <Tab label="Activities & Events" {...a11yProps(3)} />
            <Tab label="PEOs/POs/PSOs/COs" {...a11yProps(4)} />
            <Tab label="Faculty" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AboutDepartment />
        </CustomTabPanel>
       
        <CustomTabPanel value={value} index={1}>
          <Labs />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CourseStructure />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Events />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Obe />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Faculty />
        </CustomTabPanel>
       
      </Box>
    </div>
  );
};

export default ECE;

import React, { useEffect } from "react";
import "../GrevienceCommittee/Uploadphotos.css";
import { useState } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { CircularProgress } from "@mui/material";

const UploadphotosCommitee = () => {
  const [committee, setCommittee] = useState();

  const [selectedFiles, setSelectedFiles] = useState();
  console.log("selected files", selectedFiles);

  const [uploading, setuploading] = useState(false);
  const [commities, setCommities] = useState([]);
  console.log("commities data", commities);
  useEffect(() => {
    console.log("resDataCommities");
    const fetchCommitteeDetails = async () => {
      try {
        const details = await axios.get(resources.APPLICATION_URL + "get/all/committees")
        setCommities(details.data);
        console.log("committee details===>", details.data)
      } catch (error) {
        console.log("error===>", error)
      }
    }
    fetchCommitteeDetails()
  }, []);
  const handlingDropdown = (e) => {
    setCommittee(e.target.value);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const file = files[0];
    console.log("file", file);
    // setSelectedFiles(file);
    const maxSize = 1024 * 1024 * 1024; // 1GB in bytes
    const fileSize = file.size;
    if (fileSize > maxSize) {
      alert(`File size exceeds the limit of 50MB`);
      return;
    } else {
      setSelectedFiles(file);
    }
  };


  const handleSubmit = async () => {
    if (!committee) {
      alert("committee Data cannot be Blank");
    } else if (selectedFiles.length !== 0) {
      setuploading(true);
      const formData = new FormData();
      formData.append("uploadImage", selectedFiles);
     await axios.post(resources.APPLICATION_URL + `save/imagepath?cName=${committee}`, formData)
        .then((response) => {
          console.log(response);
          setCommittee("");
          setSelectedFiles("");
          alert("file uploaded successfully");
          setuploading(false);
          document.getElementById("photo_filesCommitteePhoto").value = ""
        })

        .catch((error) => {
          alert("uploaded failed");
          setCommittee("");
          setSelectedFiles("");
          console.error(error);
          setuploading(false);
          document.getElementById("photo_filesCommitteePhoto").value = ""
        });
    } else {
      setuploading(false);
      alert("uploaded failed");
      alert("uploaded failed");
      setCommittee("");
      document.getElementById("photo_filesCommitteePhoto").value = ""
    }
  };
  return (
    <div className="main_container_ph">
      <h1 className="main_heading_ph">Committies Upload</h1>
      <div className="dropdown_container_ph">
        <div className="dropdown_ph">
          <label>Types commities</label>
          <select
            value={committee}
            onChange={(e) => {
              handlingDropdown(e);
            }}
          >
            <option value="">Please Select</option>
            {commities && commities.length !== 0 &&
              commities.map((item, index) => {
                return <option key={index}>{item.committees}</option>;
              })}
          </select>
        </div>
        <div>
          <label htmlFor="files" className="uploadLobel">
            Select Files
          </label>
          <div className="dropdown_ph_fileUpload">
            <input
              type="file"
              // ref={fileInputRef}
              id="photo_filesCommitteePhoto"
              accept="image/jpeg"
              onChange={handleFileChange}
            // multiple
            />
            <button
              type="button"
              className="photo_uploadbtn"
              onClick={handleSubmit}
              disabled={committee === ""}
            >
              {uploading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Upload"
              )}
            </button>
            {uploading && (
              <span style={{ fontSize: "bold", color: "red" }}>
                ...uploading
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadphotosCommitee;

import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import 'animate.css'

// Lab Images

import lab3 from "../../../assets/lab/cselab3-3.jpg";
import lab2 from "../../../assets/lab/cselab2-2.jpg";
import lab4 from "../../../assets/lab/cselab1-2.jpg";
import lab1 from "../../../assets/lab/cselab1-1.jpg";
import lab5 from "../../../assets/lab/cselab1-3.jpg";
import lab6 from "../../../assets/lab/cselab2-1.jpg";
import lab7 from "../../../assets/lab/cselab2-3.jpg";
import lab8 from "../../../assets/lab/cselab3-1.jpg";
import lab9 from "../../../assets/lab/cselab3-2.jpg";
import lab10 from "../../../assets/lab/cselab4-1.jpg";
import lab11 from "../../../assets/lab/cselab4-2.jpg";
import lab12 from "../../../assets/lab/cselab4-3.jpg";
import ViewLabImages from "../ViewLabPhotos/ViewLabPhotos";

const Labs = () => {
  const [key, setKey] = useState("All");
  const currentPath = window.location.pathname;
    const path = currentPath.split("/").pop();
    const labType=path.toUpperCase()
    console.log("path===>",path.toUpperCase())
  return (
    <div className="container">
      <PageHeader name="Laboratories" />
      <Card style={{ width: "100%", margin: "20px 0px" }}>
        <Card.Header>MBA Laboratories</Card.Header>
        <Card.Body>
          <Card.Title>We have Fully Equipped Laboratories</Card.Title>
          <Card.Text>
            we have the several Computer Labs equipped with latest software's
            and workstations. It is connected to internet through a lease line
            to facilitate high speed internet access. All the computers are
            connected through a structured network. Printer and copier
            facilities are also available in the lab. Our dedicated faculty and
            staff are proud of their impressive credentials, which are
            comparable to those of the best career instructors.
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="content">
      <ViewLabImages labType={labType}/>

        {/* <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="All" title="All">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Computer Lab1<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Computer Lab1<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Computer Lab1<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab4}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Computer Lab2<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab5}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab2<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab6}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab2<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab7}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab3<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab8}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab3<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab9}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab3<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                  <br />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab10}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Comon Internet Center<br/>
This labs consists of 32 systems used to surf the internet for various learning material. this lab configure with Core2Duo Processor, 2gb ram and 500gb Hard disk. And also having latest softwares for documentaion.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab11}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Common Internet Center<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab12}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Common Internet Center<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMMON INTERNET CENTER" title="COMMON INTERNET CENTER">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab10}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Common Internet Center<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab11}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Common Internet Center<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab12}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Common Internet Center<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB1" title="COMPUTER LAB1">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab7}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab1<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab8}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab1<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab9}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab1<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB2" title="COMPUTER LAB2">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab1}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab2<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab2}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab2<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab3}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab2<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB3" title="COMPUTER LAB3">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab7}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab3<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab8}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab3<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={lab9}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>Computer Lab3<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                  <br />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs> */}
      </div>
    </div>
  );
};

export default Labs;

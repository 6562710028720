import React from "react";
import { Link } from "react-router-dom";
import trustee from "../../assets/HSP1.JPG";
import anvida from "../../assets/ANVIDA MADAM.jpg";

const Society = () => {
  return (
    <div>
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 className="section-title bg-white text-center text-primary px-3">
          TRUST
        </h6>
      </div>
      <br />

      {/* <div className="text-area  text-center">
        <h4 bg-yellow>Vipassana Educational Trust</h4>
        <br />
        <div className="text text-center px-8"></div>
        <h6>
          Vipassana Educational Trust headed by Sri. D. Prabhakar Reddy has been
          promoting education to poor and meritorious students to enhance <br />
          the standards and impart high quality education. trust was also
          contemplating to enter technical education. With this idea ,to take
          technical education to rural areas of Telangana.
          <br /> The Trust has joined hands with Dr.B.Partha Saradhi Reddy, one
          of the eminent industrialists in Hyderabad hailing from rural area to
          establish SSIT in 2001 at Sathupally in Khammam District.
          <br /> The Trust has very high ideas of imparting quality in
          education, awarding merit cum scholarship to needy students and
          encouraging students in sports and games. The Trust is providing
          excellent infrastructural facilities such as a good campus, staff, and
          hostels for both boys and girls so that Engineering education can be
          given in a peaceful environment.
        </h6>
      </div> */}

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded position-absolute w-40 h-40"
                  src={trustee}
                  alt="trustee-img"
                  //   style={{ objectFit: "cover" }}
                />
                <br />
               
              </div>
              {/* <div className="position-relative h-100">
              <img
                  className="img-fluid rounded position-absolute w-40 h-40"
                  src={anvida}
                  alt="anvida-img"
                  //   style={{ objectFit: "cover" }}
                />
              </div> */}
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="position-relative h-100">
                <div className="position-relative h-100">
                  <p className="mb-0" >
                    <h5>Vipassana Educational Trust</h5>
                    <p style={{textAlign:"justify"}}>
                    <p>
                      Vipassana Educational Trust, under the leadership of Sri.
                      D. Prabhakar Reddy, has been dedicatedly committed to
                      advancing education opportunities for underprivileged yet
                      deserving students with a vision to uplift educational
                      standards and foster excellence in learning. Recognizing
                      the importance of technical education and its role in
                      empowering rural communities, the Trust embarked on a
                      mission to extend its educational outreach by venturing
                      into the realm of technical education.
                    </p>
                    <p>
                      Central to its mission is the provision of
                      merit-cum-scholarships to economically disadvantaged
                      students, thereby ensuring that financial constraints do
                      not impede deserving individuals from accessing quality
                      education. Furthermore, the Trust places a strong emphasis
                      on holistic development by actively encouraging student
                      participation in sports and extracurricular activities,
                      recognizing their integral role in fostering well-rounded
                      individuals.
                    </p>
                    <p>
                      The Vipassana Educational Trust's collaboration with Dr.
                      B. Partha Saradhi Reddy and the establishment of SSIT
                      underscore its steadfast commitment to advancing
                      education, empowering rural communities, and fostering
                      academic excellence. Through its tireless efforts, the
                      Trust continues to serve as a beacon of hope and
                      opportunity for countless students seeking to realize
                      their educational aspirations and fulfill their potential.
                    </p>
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Society;

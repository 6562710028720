import React from "react";
// import "./AcadamicCalender.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(MemberName, Position) {
  return { MemberName, Position };
}

const rows = [
  createData("	2020-2021", "View Document"),
  createData("2019-2020", "View Document"),
  createData("	2018-2019", "View Document"),
  createData("	2017-2018", "View Document"),
  createData("	2016-2017", "View Document"),
];

const AdministrationMinutes = () => {
  return (
    <div >
            <h2 className="adminstration_heading" style={{color:"orange", marginTop:"20px"}}>ACADEMIC BODY MINUTES</h2>

      <div>
        {/* <h1 className="adminstration_heading">ACADEMIC BODY MINUTES</h1> */}

        {/* <p className="adminstration_description" style={{ padding: "20px" }}>
        COLLEGE ACADEMIC COMMITTEE MEMBERS
        </p> */}
      </div>
      <TableContainer
        className="adminstration_table"
        component={Paper}
      >
        <Table sx={{ maxWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow className="adminstration_table_row">
              <StyledTableCell className="adminstration_table_cell">
                S No
              </StyledTableCell>
              <StyledTableCell className="adminstration_table_cell">
              Meetinng Date / Acadeeemic Year
              </StyledTableCell>
              <StyledTableCell className="adminstration_table_cell">
              Document Link
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow
                className="adminstration_table_row"
                key={row.name}
              >
                <StyledTableCell
                  className="adminstration_table_cell"
                  scope="row"
                >
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell className="adminstration_table_cell">
                  {row.MemberName}
                </StyledTableCell>
                <StyledTableCell className="adminstration_table_cell">
                  {row.Position}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
    </div>
  );
};

export default AdministrationMinutes;

import React from "react";
import { Link } from "react-router-dom";
import principal from "../../assets/sai spurthi principal.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

const Principal = () => {
  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded position-absolute w-90 h-40"
                  src={principal}
                  alt="principal-img"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Principal
              </h6>
              {/* <h5 className="mb-4">About</h5> */}

              <div className="row gy-2 gx-4 mb-4">
                <p style={{textAlign:"justify"}}>
                <p className="mb-0">
                  It is an extreme honour to be a part of achieving academic
                  excellence with the institute and I am much obliged to
                  felicitate the infrastructure of global technical and industry
                  orientation while safeguarding the values and esteem of the
                  institute and taking them to the next level by an integrating
                  my research, academic and technical exposure and also in
                  collaboration with good many value nurturing institutions.
                  with process
                </p>

                <p className="mb-0">
                  At SSIT my vision is to develop technology orientation and
                  analytical capabilities of every student. SSIT is committed to
                  provide students with a strong, broad based fundamental
                  engineering education and prepare the students for careers in
                  industry, teaching and other research organizations. We also
                  plan to develop entrepreneurial skills in students so that
                  they would drive the spirit of growth of our economy and would
                  be able to generate employment opportunities for other
                  qualified and skilled people.
                </p>

                <p className="mb-0">
                  We are committed to providing leadership and support for
                  students and help them in their professional development.
                </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card_cntr1">
        <div
          className="boxes col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-4">
              <h5 className="mb-1">Vision</h5>
              <p style={{textAlign:"justify"}}>
                To make the students achieve highest standards of quality
                technical education adaptable to suit the present day
                requirements in the fields of science and technology and to
                mould them morally into persons of character and integrity.
              </p>
            </div>
          </div>
        </div>
        <div
          className="boxes col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-4">
              <h5 className="mb-3">Mission</h5>
              <p style={{textAlign:"justify"}}>
                To awaken the students to the reality of identifying their
                hidden potentials and talents through meticulous and systematic
                grooming to gain the spirit and inventiveness to build a career
                full of glorious prospects and eventful future.
              </p>
            </div>
          </div>
        </div>
        <div
          className="boxes col-lg-3 text-center col-sm-6"
          data-wow-delay="0.5s"
        >
          <div className="boxes service text-center pt-3">
            <div className="p-4 ">
              <h5 className="mb-3">STRENGTHS</h5>
              <div>
                <Accordion sx={{ backgroundColor: "#fff" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Infrastructure & Faculty
                  </AccordionSummary>
                  <AccordionDetails sx={{textAlign:"justify"}}>
                    Ultra Modern Labs with more than 430 Systems having multiple
                    platforms like Linux, Windows and Ubuntu. 24/7
                    InternetFacility with 300 MBPS throughtwo different ISPs.
                    Well Experienced and University Ratified Faculty.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Training & Placements
                  </AccordionSummary>
                  <AccordionDetails sx={{textAlign:"justify"}}>
                    Conducting Industry relevant Training Programmes and
                    providing Placements in various MNCs through
                    On-Campus/Off-Campus.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Research & Development
                  </AccordionSummary>
                  <AccordionDetails sx={{textAlign:"justify"}}>
                    More than 50% Faculty Pursuing Ph.Ds from Reputed
                    Univeristies and publishing Their Research Papers in Various
                    Good Impact Journals.
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Principal;

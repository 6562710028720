import React from "react";
import faculty from "../../assets/examfaculty2.jpg";
import faculty1 from "../../assets/examfaculty.jpg";

const Examinationbranch = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            EXAMINIATION BRANCH
          </a>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-3 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded position-absolute w-60 "
                  src={faculty}
                  alt="faculty-img"
                />
              </div>
            </div>
            <div
              className="col-lg-3 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "50px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded position-absolute w-60 "
                  src={faculty1}
                  alt="faculty1-img"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="row gy-2 gx-4 mb-4">
                <p style={{textAlign:"justify"}}>
                <p className="mb-0">
                  The Exam Branch was started functioning from the inception of
                  the college i.e., in 2001.College Examination branch is being
                  headed by P.Sekhar Babu as Officer incharge examinations.
                </p>
                <p className="mb-0">
                  The main focus of the branch is to run the academics smoothly
                  by conducting internal examanitions in-time and keeping a
                  great effort in conducting the university examintaions
                  effectively.
                </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Examinationbranch;

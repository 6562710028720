import axios from "axios";
import react, { useState } from "react";
import { resources } from "../Resourses/Resources";
import { EditRounded } from "@mui/icons-material";
import "./facultyScreens.css";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import RotatingSpinner from "../Resourses/RotatingSpinner";
const FacultyUploadNotifications = () => {
  const userDetailsCookie = Cookies.get("userDetails");

  const userDetails = JSON.parse(userDetailsCookie);
  const userName = userDetails.username;
  const departMent = userDetails.department;
  const userEmail = userDetails.email;
  const [notificationDetails, setNotificationDetails] = useState({
    issuedBy: "",
    startDate: "",
    endDate: "",
    description: "",
    department: departMent,
    facultyName: userName,
    facultyEmail: userEmail,
  });
  const [fileUpload, setFileUpload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const errorMessage = (message) => {
    toast.error(message, {
      //  position: toast.POSITION.TOP_CENTER,
      toastId: "error1",
      className: "error",
    });
  };
  const successMessage = (message) => {
    toast.success(message, {
      // position: toast.POSITION.TOP_CENTER,
      toastId: "success1",
      className: "success",
    });
  };

  console.log("userDetails ===>", userDetails);
  console.log("departMent ===>", departMent);
  const handleUploadFile = async (e) => {
    const selectedFile = e.target.files;
    const fileToSend = selectedFile[0];
    setFileUpload(fileToSend);
    console.log("file to send===>", fileToSend);
  };
    const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append(
        "notificationDetails",
        new Blob([JSON.stringify(notificationDetails)], {
          type: "application/json",
        })
      );
      formData.append("facultyNotificationFile", fileUpload);

      console.log("formData ==> ", formData);

      const response = await axios.post(
        resources.APPLICATION_URL + "saveNotificationsDetails",
        formData
      );
      console.log("Response from server123:", response);
      if (response.status === 200) {
        document.getElementById("notificationFile").value = "";
        setNotificationDetails({
          issuedBy: "",
          startDate: "",
          endDate: "",
          description: "",
          department: departMent,
          facultyName: userName,
          facultyEmail: userEmail,
        });
        successMessage("uploaded Successfully");
      } else {
        errorMessage("Something went wrong");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      errorMessage("Data not send");
    }finally{
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <RotatingSpinner />
      ) : (
        <div className="uploadContainer">
          <p className="headerTagStyle">Upload Notifications</p>
          <div className="uploadDivs">
            <div className="uploadLabelAndInput">
              <label>Issued By</label>
              <input
                type="text"
                value={notificationDetails.issuedBy}
                onChange={(e) =>
                  setNotificationDetails({
                    ...notificationDetails,
                    issuedBy: e.target.value,
                  })
                }
              />
            </div>
            <div className="uploadLabelAndInput">
              <label>Notification Description</label>
              <textarea
                type="text"
                value={notificationDetails.description}
                onChange={(e) =>
                  setNotificationDetails({
                    ...notificationDetails,
                    description: e.target.value,
                  })
                }
              />
            </div>
            <div className="uploadLabelAndInput">
              <label>Circular</label>
              <input
                type="file"
                id="notificationFile"
                onChange={(e) => handleUploadFile(e)}
              />
            </div>
            <div className="uploadLabelAndInput">
              <label>Start Date</label>
              <input
                type="date"
                value={notificationDetails.startDate}
                onChange={(e) =>
                  setNotificationDetails({
                    ...notificationDetails,
                    startDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="uploadLabelAndInput">
              <label>End Date</label>
              <input
                type="date"
                value={notificationDetails.endDate}
                onChange={(e) =>
                  setNotificationDetails({
                    ...notificationDetails,
                    endDate: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="uploadDivButton">
            <button onClick={() => handleSubmit()}>Submit</button>
          </div>
        </div>
      )}
    </>
  );
};
export default FacultyUploadNotifications;

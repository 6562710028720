import React, { useState, useEffect } from "react";
import "./Campousphoto.css";
import Carousel from 'react-bootstrap/Carousel';
import photo from "../../../assets/02.jpg"
import temple from "../../../assets/Temple.jpg";
import { resources } from "../../Resourses/Resources";
import axios from "axios";
import Loader from "../../../Loader/Loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";

const Capmousphotos = () => {
  const [event, setEvent] = useState([]);
  const [img, setImg] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading indicator

  const PhotoData = async () => {
    try {
      const res = await axios.get(resources.APPLICATION_URL + "listofevents");
      console.log("data==========>", res.data);
      if (res.data) {
        setEvent(res.data);
        setLoading(false); // Data loaded, set loading to false
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    PhotoData();
  }, []);

  const getPhotosList = (event) => {
    console.log("listofevents/", event);
    setLoading(true);
    axios
      .get(resources.APPLICATION_URL + "listofevents/" + event)
      .then((res) => {
        console.log("2ndcall====>", res.data);
        setImg(res.data);
        setLoading(false); // Data loaded, set loading to false
      })
      .catch((e) => {
        console.log(e);
      });
  }

  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="gallery_main">
      <div className="gallery_header">
        <h2> Campus Photos View</h2>
      </div>
      <div className="gallery_btn">
        {event.map((event) => <button key={event} onClick={() => getPhotosList(event)}>{event}</button>)}
      </div>
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="gallery_curousel">
          {img.length > 0 ? (
            <Slider {...settings}>
              {img?.map((img, id) => {
                return (
                  <div key={id}>
                    <div className="card-body">
                      <img
                        className="campous_imgs"
                        src={`data:image/png;base64,${img}`}
                        alt={`Image ${id + 1}`}
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div style={{ textAlign: "center", fontSize: "25px", color: "white", fontWeight: "bold" }}>Please Select Event Type......</div>
          )}
        </div>
      )}


    </div>
  );
};

export default Capmousphotos;

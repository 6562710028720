import React from "react";
import { useState } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { RotatingLines } from "react-loader-spinner";

const StudentFeedback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [StudentFeedback, setStudentFeedback] = useState({
    regno: "",
    nameofstudent: "",
    department: "",
    academicyear: "",
    semester: "",
    regulation: "",
    relevancedevelopment: "",
    competitivnessstudent: "",
    ratelearning: "",
    practicaltools: "",
    potenticallyexams: "",
    timelygrievances: "",
    scopeplacements: "",
    transparencyassement: "",
    classroommaintained: "",
    elearninglibrary: "",
    assessoffered: "",
    evaluateacadamicpurpose: ""
  });
  
  const dept = ["ECE", "CSE", "EEE", "MECH", "CIVIL", "CSE"];
  const reg = ["R16", "R17", "R18", "R19", "R20"];
  const semesters = [
    "I SEMESTER",
    "II SEMESTER",
    "III SEMESTER",
    "IV SEMESTER",
    "V SEMESTER",
    "VI SEMESTER",
    "VII SEMESTER",
    "VIII SEMESTER",
  ];
  const academicYear = [];
  const year = new Date().getFullYear();
  for (let i = year - 5; i <= year; i++) {
    academicYear.push(i);
  }
  for (let i = year + 1; i <= year + 5; i++) {
    academicYear.push(i);
  }

  const handleStudentFeedbackDetails = (e) =>{
   const {value,name} = e.target
   setStudentFeedback({...StudentFeedback,[name]:value})
  }
  const handleSubmitDataToBackend = async () => {
    console.log(JSON.stringify(StudentFeedback));
    try {
      setIsLoading(true);
      const res = await axios.post(
        resources.APPLICATION_URL + "getstudentfeedback",
        StudentFeedback
      )
      console.log("response==========>",res)
      if(res.data){
        alert("Data Submitted Successfully");
        setStudentFeedback({
          regno: "",
          nameofstudent: "",
          department: "",
          academicyear: "",
          semester: "",
          regulation: "",
          relevancedevelopment: "",
          competitivnessstudent: "",
          ratelearning: "",
          practicaltools: "",
          potenticallyexams: "",
          timelygrievances: "",
          scopeplacements: "",
          transparencyassement: "",
          classroommaintained: "",
          elearninglibrary: "",
          assessoffered: "",
          evaluateacadamicpurpose: ""
        });
      }else{
        alert("Try again after some time")
      }
    } catch (e) {
      console.log(e);
   
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
    {isLoading ? <RotatingLines/>:(<div style={{ padding: "50px" }}>
      <div className="teacher_feedback_heading_container">
        <h1 className="teacher_form_heading">
          Students Feedback on Academic Performance and Institution Ambience
        </h1>
      </div>
      <form className="teacher_feedback_form_main_container">
        <div className="teacher_feedback_form_container">
          <label htmlFor="name">1.Regd.No</label>
          <input

            onChange={(e) => handleStudentFeedbackDetails(e)}
            type="text"
            id="Regd. No"
            name="regno"
            required
            placeholder="Enter Regd. No"
          />
        </div>
        <div className="teacher_feedback_form_container">
          <label htmlFor="name">2.Name of the student</label>
          <input
            onChange={(e) => handleStudentFeedbackDetails(e)}


            type="text"
            id="name"
            name="nameofstudent"
            required
            placeholder="Enter Name"
          />
        </div>
        <div className="teacher_feedback_form_container">
          <label htmlFor="name">3.Department</label>
          <select onChange={(e) => handleStudentFeedbackDetails(e)}
            name="department">

            <option>Please select...</option>
            {dept
              .filter((each) => each !== "")
              .map((each) => (
                <option>{each}</option>
              ))}
          </select>
        </div>

        <div className="teacher_feedback_form_container">
          <label>4.Academic Year</label>
          <select onChange={(e) => handleStudentFeedbackDetails(e)}
            name="academicyear">
            <option>Please select...</option>
            {academicYear
              .filter((each) => each !== "")
              .map((each) => (
                <option key={each}>{each}</option>
              ))}
          </select>
        </div>
        <div className="teacher_feedback_form_container">
          <label >5.Semester</label>
          <select onChange={(e) => handleStudentFeedbackDetails(e)}
            name="semester">
            <option>Please select...</option>
            {semesters
              .filter((each) => each !== "")
              .map((each) => (
                <option key={each}>{each}</option>
              ))}
          </select>
        </div>

        <div className="teacher_feedback_form_container">
          <label >6.Regulation</label>
          <select onChange={(e) => handleStudentFeedbackDetails(e)}
            name="regulation">
            <option>Please select...</option>
            {reg
              .filter((each) => each !== "")
              .map((each) => (
                <option key={each}>{each}</option>
              ))}
          </select>
        </div>
        <div className="teacher_feedback_form_container">
          <label>
            7.Relevance of syllabus of each course to the recent trends and
            developments
          </label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="relevancedevelopment"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="relevancedevelopment"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="relevancedevelopment"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="relevancedevelopment"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="relevancedevelopment"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>
            8.Competitiveness of the curriculum in aiding the academic growth of
            the student
          </label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="competitivnessstudent"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="competitivnessstudent"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="competitivnessstudent"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="competitivnessstudent"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="competitivnessstudent"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>
            9.Rate the activities offered by the institution related to
            Experiential and Participative learning
          </label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="ratelearning"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="ratelearning"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="ratelearning"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="ratelearning"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="ratelearning"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>10.Practical exposure to ICT tools</label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="practicaltools"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="practicaltools"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="practicaltools"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="practicaltools"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="practicaltools"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>
            11.Potentiality of the college in training students for the
            Competitive exams
          </label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="potenticallyexams"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="potenticallyexams"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="potenticallyexams"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="potenticallyexams"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="potenticallyexams"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>12.Timely redressal of grievances</label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="timelygrievances"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="timelygrievances"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="timelygrievances"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="timelygrievances"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="timelygrievances"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>13.Scope for career counseling and placements</label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="scopeplacements"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="scopeplacements"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="scopeplacements"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="scopeplacements"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="scopeplacements"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>14.Transparency of internal assessment</label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="transparencyassement"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="transparencyassement"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="transparencyassement"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="transparencyassement"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="transparencyassement"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>
            15.Are the classrooms and laboratories adequately equipped and
            properly maintained
          </label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="classroommaintained"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="classroommaintained"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="classroommaintained"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="classroommaintained"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="classroommaintained"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>
            16.E-Learning facilities and quantity of books available in the
            Library
          </label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="elearninglibrary"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="elearninglibrary"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="elearninglibrary"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="elearninglibrary"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="elearninglibrary"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>17.Assess the Scholarships and the freeships offered</label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="assessoffered"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="assessoffered"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="assessoffered"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="assessoffered"
              id="courseContent"
              value="Average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="assessoffered"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>

        <div className="teacher_feedback_form_container">
          <label>
            18.Evaluate the availability of computers for academic purpose
          </label>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="evaluateacadamicpurpose"
              id="courseContent"
              value="Excellent"
            />
            <span>Excellent</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="evaluateacadamicpurpose"
              id="courseContent"
              value="Very Good"
            />
            <span>Very Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="evaluateacadamicpurpose"
              id="courseContent"
              value="Good"
            />
            <span>Good</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="evaluateacadamicpurpose"
              id="courseContent"
              value="average"
            />
            <span>Average</span>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={(e) => handleStudentFeedbackDetails(e)}

              style={{ width: "15px" }}
              type="radio"
              name="evaluateacadamicpurpose"
              id="courseContent"
              value="Bellow Average"
            />
            <span>Bellow Average</span>
          </div>
        </div>
        <button onClick={()=>handleSubmitDataToBackend()} className="submit_btn" type="button">
          Submit
        </button>
      </form>
    </div>)}
    </>
  );
};

export default StudentFeedback;

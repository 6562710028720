import axios from "axios";
import react, { useEffect, useState } from "react";
import { resources } from "../Resourses/Resources";
import { EditRounded } from "@mui/icons-material";
import Cookies from "js-cookie";
import DeleteIcon from "@mui/icons-material/Delete";
const LabImagesUpload = () => {
    const labTypesEEE = ["Simulation Lab", "ElectricMachines Lab", "ElectricSimulation Lab", "PowerSystemLab Lab","PE AND EA LAB"]
    const labTypesCSE=["Common Internet Center","Computer Lab1","Computer Lab2","Computer Lab3"]
    const labTypesECE=["Communication Lab","Simulation Lab","MP&Mc Lab","IC/DSD Lab,","EDC Lab"]
   const labAiDs=["Common Internet Center","Computer Lab1","Computer Lab2","Computer Lab3"]
   const labAiandMl=["Common Internet Center","Computer Lab1","Computer Lab2","Computer Lab3"]
  const labMech=["MachineTools Lab","CAD/CAM Lab","Heat Transfer Lab","Thermal Engineer Lab"]
  const labSH=["AECS Lab","Engineering Chemistry Lab","English","Engineering Physics"]
  const labMba=["Common Internet Center","Computer Lab1","Computer Lab2","Computer Lab3"]
  const polytechnic=["Common Internet Center","Computer Lab1","Computer Lab2","Computer Lab3"]
  const general=["Carousel"]
   const [labTypes,setLabTypes]=useState([])
    const userDetailsCookie = Cookies.get("userDetails");
    const userDetails = JSON.parse(userDetailsCookie);
    const userName = userDetails.username;
    const departMent = userDetails.department;
    console.log("department====>",departMent)
    const userEmail = userDetails.email;
    let currentYear;
    const date = new Date();
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    if (month < 5) {
      currentYear = Number(year - 1 )+ "-" + "" + year
    }
    else {
      currentYear = year + "-" + "" + Number(year + 1)
    }
useEffect(()=>{
if(departMent==="CSE"){
    setLabTypes(labTypesCSE)
}
else if(departMent==="ECE"){
    setLabTypes(labTypesECE) 
}
else if(departMent==="EEE"){
    setLabTypes(labTypesEEE) 
}
else if(departMent==="AI&DS"){
    setLabTypes(labAiDs) 
}
else if(departMent==="AI&ML"){
    setLabTypes(labAiandMl) 
}
else if(departMent==="MECH"){
    setLabTypes(labMech) 
}
else if(departMent==="SH"){
    setLabTypes(labSH) 
}
else if(departMent==="MBA"){
    setLabTypes(labMba) 
}
else if(departMent==="POLYTECHNIC"){
    setLabTypes(polytechnic) 
}
else if(departMent==="Management"){
    setLabTypes(general)
}
},[])
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [studentData, setStudentData] = useState([
        {
            participantName: "",
            participantEmail: "",
            mobileNo: "",
        },
    ]);
    const [details, setDetails] = useState({
        labName: "",
        academicYear: "",
        department:"",
        uploadedBy: "",

    });
    useEffect(() => {
        setDetails({ ...details, academicYear: currentYear, uploadedBy: userName,department:departMent})
    }, [currentYear ,userName ])
    console.log("details===>", details)
    const handleUploadImages = async (e) => {
        const files = e.target.files;
        setSelectedFiles(files);
    };
    const handleSubmit = async () => {

        const formData = new FormData();
        formData.append(
            "labObject",
            new Blob([JSON.stringify(details)], { type: "application/json" })
        );
        for (let i = 0; i <= selectedFiles.length - 1; i++) {
            formData.append("labFiles", selectedFiles[i]);
        }
        try {
            const detailsToSend = await axios.post(
                resources.APPLICATION_URL + "save/lab/images",
                formData
            );
            console.log("events to send ===>", detailsToSend);
            if (detailsToSend.status === 200) {
                setDetails({
                    ...details,
                    labName: "",
                    uploadedBy: userName,
                });
                document.getElementById("labImages").value = "";
                alert("Successfully uploaded data");
            } else {
                alert("Something went wrong");
            }
        } catch (error) {
            alert("Data not uploaded");
        }
    };

    return (
        <div className="uploadContainer">
            <p className="headerTagStyle">Upload Lab Photos</p>
            <div className="overallDivContainer">
                <div className="uploadDivsCon">
                <div className="uploadLabelAndInput">
                        <label>Uploaded By</label>
                   <input type="text" value={details.uploadedBy} onChange={(e) => setDetails({ ...details, uploadedBy: e.target.value })}/>
                    </div>
                    <div className="uploadLabelAndInput">
                        <label>Lab Type</label>
                        <select value={details.labName} onChange={(e) => setDetails({ ...details, labName: e.target.value })}>
                            <option>Please Select</option>
                            {labTypes.map((items, index) => {
                                return (
                                    <option key={index}>{items}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="uploadLabelAndInput">
                        <label>Selecte Photos</label>
                        <input
                            type="file"
                            multiple
                            id="labImages"
                            onChange={(e) => handleUploadImages(e)}
                        />
                    </div>
                    <div className="uploadDivButton">
                        <button onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LabImagesUpload;

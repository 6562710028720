import react, { useState, useEffect } from "react"
import "./BranchMenu.css"
import axios from "axios";
import ExamGrievanceInfo from "./ExamGrievanceInfo";
const ExaminationGrievanceMenu=()=>{
    const [viewType,setViewType]=useState("info")
    useEffect(()=>{
        const defaultStyle={
            backgroundColor: "",
            color: "",
        }
        const activeStyle={
            backgroundColor: "orange",
            color: "white",
        }
        document.getElementById("info").style.backgroundColor=viewType==="info"?activeStyle.backgroundColor:defaultStyle.backgroundColor;
        document.getElementById("info").style.color=viewType==="info"?activeStyle.color:defaultStyle.color;

        document.getElementById("notificationMenu").style.backgroundColor=viewType==="notificationMenu"?activeStyle.backgroundColor:defaultStyle.backgroundColor;
        document.getElementById("notificationMenu").style.color=viewType==="notificationMenu"?activeStyle.color:defaultStyle.color;

        
        document.getElementById("results").style.backgroundColor=viewType==="results"?activeStyle.backgroundColor:defaultStyle.backgroundColor;
        document.getElementById("results").style.color=viewType==="results"?activeStyle.color:defaultStyle.color;
    },[viewType])
    console.log("view type===>",viewType)
    return(
        <div>
           <div className="facultyuploadButtons">
            <button onClick={()=>setViewType("info")} id="info">Info</button>
            <button onClick={()=>setViewType("notificationMenu")} id="notificationMenu">Notifications</button>
            <button onClick={()=>setViewType("results")} id="results">Results</button>
           </div>
           <div className="branchDivSecond">
            {viewType==="info" && <ExamGrievanceInfo/>}
            {viewType==="notificationMenu" && <ExamGrievanceInfo/>}
            {viewType==="results" && <ExamGrievanceInfo/>}
           </div>
        </div>
    )
}
export default ExaminationGrievanceMenu;
import { useEffect, useState } from "react"
import "./facultyScreens.css";
import FacultyUploadHome from "./FacutyHome";
import FacultyUploadPressNotes from "./FacultyUploadPressNotes";
import FacultyUploadNotifications from "./FacultyUploadNotifications";
import FcultyEvents from "./FacultyEvents";
import FacultyView from "./FacultyView";
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import LabImagesUpload from "./LabImagesUpload";
import axios from "axios";
import { resources } from "../Resourses/Resources";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const FacultyUpload = () => {
    const [uploadType, setUploadType] = useState("FacultyUploadNotifications")
    const [lastRecord, setLastRecord] = useState({})
    const [viewFile, setViewFile] = useState(false)
    const handleClickOpen = () => {
        setViewFile(true);
    };

    const handleClose = () => {
        setViewFile(false);
    };
    const date = new Date;
    let currentMonth;
    let currentDate;
    const toDaysDate = date.getDate();
    const month = date.getMonth() + 1
    const currentYear = date.getFullYear()

    if (month < 10) {
        currentMonth = "0" + month
    } else {
        currentMonth = month
    }
    if (toDaysDate < 10) {
        currentDate = "0" + toDaysDate
    } else {
        currentDate = toDaysDate
    }
    const newDate = currentYear + "-" + currentMonth + "-" + currentDate
    const navigate = useNavigate();
    const fetchLasstRecord = async () => {
        try {
            const response = await axios.get(resources.APPLICATION_URL + `getLastInsertedRecord?typeOfUpload=ExamBranch`)
            const details = response.data
            console.log("newDate===>", newDate)
            console.log("details.endDate===>", details.endDate)
            if (details.endDate >= newDate) {
                setLastRecord(response.data)
            } else {
                console.log("triggered")
            }
        } catch (error) {
            console.log("error===>", error)
        }
    }
    useEffect(() => {

        fetchLasstRecord();
    }, [newDate])
    // const handleLogOut=async()=>{
    //     Cookies.remove("userDetails","")
    //     navigate("/")
    // }
    const handleNavigate = () => {
        navigate("/")
    }
    useEffect(() => {
        const activeStyle = {
            backgroundColor: "orange",
            color: "white",
        };

        const defaultStyle = {
            backgroundColor: "",
            color: "",
        };

        document.getElementById("homeNew").style.backgroundColor = uploadType === "home" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("homeNew").style.color = uploadType === "home" ? activeStyle.color : defaultStyle.color;

        document.getElementById("notificationsNew").style.backgroundColor = uploadType === "FacultyUploadNotifications" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("notificationsNew").style.color = uploadType === "FacultyUploadNotifications" ? activeStyle.color : defaultStyle.color;

        document.getElementById("pressnotesNew").style.backgroundColor = uploadType === "FacultyUploadPressNotes" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("pressnotesNew").style.color = uploadType === "FacultyUploadPressNotes" ? activeStyle.color : defaultStyle.color;


        document.getElementById("eventsNew").style.backgroundColor = uploadType === "FcultyEvents" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("eventsNew").style.color = uploadType === "FcultyEvents" ? activeStyle.color : defaultStyle.color;


        document.getElementById("facultyNew").style.backgroundColor = uploadType === "FacultyView" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("facultyNew").style.color = uploadType === "FacultyView" ? activeStyle.color : defaultStyle.color;


        document.getElementById("lab").style.backgroundColor = uploadType === "lab" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("lab").style.color = uploadType === "lab" ? activeStyle.color : defaultStyle.color;
    }, [uploadType]);
    return (
        <div className="facultyUploadContainer">
            <marquee behavior="scroll" direction="left" scrollamount="10" className="marqueeScroll">
                {lastRecord.description ? <p onClick={() => handleClickOpen()} style={{ cursor: "pointer" }}>{lastRecord.description}</p> : <>No Notifications Available</>}
            </marquee>
            <div className="facultyuploadButtons">
                <button onClick={(e) => { setUploadType("home"); handleNavigate() }} id="homeNew">Home</button>
                <button onClick={(e) => setUploadType("FacultyUploadNotifications")} id="notificationsNew">Notifications</button>
                <button onClick={(e) => setUploadType("FacultyUploadPressNotes")} id="pressnotesNew">Press Notes</button>
                <button onClick={(e) => setUploadType("FcultyEvents")} id="eventsNew">Events</button>
                <button onClick={(e) => setUploadType("FacultyView")} id="facultyNew" >Faculty View</button>
                <button onClick={(e) => setUploadType("lab")} id="lab" >Lab Images</button>
            </div>
            <div>
                {uploadType === "home" && <FacultyUploadHome />}
                {uploadType === "FacultyUploadNotifications" && <FacultyUploadNotifications />}
                {uploadType === "FacultyUploadPressNotes" && <FacultyUploadPressNotes />}
                {uploadType === "FcultyEvents" && <FcultyEvents />}
                {uploadType === "FacultyView" && <FacultyView />}
                {uploadType === "lab" && <LabImagesUpload/>}
            </div>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={viewFile}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            width: '100%',
                            height: '100%',
                            margin: 0,
                            padding: 0,
                            maxHeight: '100vh',
                        },
                    }}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>

                        </Toolbar>
                    </AppBar>
                    {lastRecord && (
                        <iframe
                            src={`data:application/pdf;base64,${lastRecord.filePath}`}
                            width="100%"
                            height="100%"
                        />
                    )}
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default FacultyUpload;
import React from 'react'
import { useState, useEffect } from 'react';
import "./Login.css";
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { resources } from '../Resourses/Resources';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreatableSelect from 'react-select/creatable';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const PhotosUpload = () => {
    const [imgList, setImgList] = useState([]);
    const [fileName, setFileNames] = useState([]);
    const [fileList, setFileList] = useState([])
    const [evenType, setEventType] = useState("");
    const [event, setEvent] = React.useState([]);


    const eventOptions = event.map((event) => ({ value: event, label: event }));



    const PhotoData = async () => {
        try {
            const res = await axios.get(resources.APPLICATION_URL + "listofevents");
            console.log("data==========>", res.data);
            if (res.data) {
                // setEvent(res.data);
                let list = res.data;
                list.push("Others");
                setEvent(list);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        PhotoData();
    }, []
    );

    const handleFileUpload = (e) => {
        const file = e.target.files[0]
        if (file) {
            setFileNames([...fileName, file.name])
            setFileList([...fileList, file])
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImgList([...imgList, reader.result]);
                console.log(reader.result);

            }
        }
    }


    function jsonBlob(obj) {
        return new Blob([JSON.stringify(obj)], {
            type: "application/json"
        })
    }
    console.log("datafilre", jsonBlob(imgList))

    const handleSubmitUploadedFiles = async () => {
        const formData = new FormData();
        fileList.map((each, id) => {
            formData.append("collegephotouploads", each);
        })

        try {
            const res = await axios.post(
                resources.APPLICATION_URL + `collegeGalleryuploads?type=${evenType}`,
                formData
            );
            if (res.status === 200) {
                alert(res.data);
            } else {
                alert("Error: " + res.status);
            }
            console.log("res====>", res);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    return (
        <>
            <div className='photo_upload_main_container'>
                <div className='file_upload_container'>
                    <div>
                        <p className='upload_heading'>Event Name</p>
                        <CreatableSelect
                            isClearable
                            options={eventOptions}
                            className='upload_input'
                            placeholder='Enter Event Name'
                            onChange={(selectedOption) => setEventType(selectedOption ? selectedOption.value : '')}
                        /><br />
                    </div>
                    <div>
                        <p className='upload_heading'>Photos Upload</p>
                        <input multiple type="file" onChange={(e) => handleFileUpload(e)} />
                        {imgList.length > 0 && <button style={{ borderWidth: '0px', backgroundColor: '#1a1f36', color: 'white' }} onClick={() => handleSubmitUploadedFiles()}><CloudUploadIcon /></button>}
                    </div>

                </div>

            </div>


            <TableContainer className="adminstration_table" component={Paper} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Table sx={{ maxWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className="adminstration_table_cell">
                                S No
                            </StyledTableCell>
                            <StyledTableCell className="adminstration_table_cell">
                                File Name
                            </StyledTableCell>
                            <StyledTableCell className="adminstration_table_cell">
                                File
                            </StyledTableCell>
                            <StyledTableCell className="adminstration_table_cell">
                                Action
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {imgList.map((row, index) => (
                            <StyledTableRow
                                className="adminstration_table_row"
                                key={row.name}
                            >
                                <StyledTableCell
                                    className="adminstration_table_cell"
                                    component="th"
                                    scope="row"
                                >
                                    {index + 1}
                                </StyledTableCell>
                                <StyledTableCell className="adminstration_table_cell">
                                    {fileName[index]}
                                </StyledTableCell>
                                <StyledTableCell className="adminstration_table_cell">
                                    <img src={row} width="100px" height="100px" />
                                </StyledTableCell>

                                <StyledTableCell className="adminstration_table_cell">
                                    <button onClick={() => setImgList(imgList.filter((_, i) => i !== index))}><DeleteIcon /></button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    )
}

export default PhotosUpload

import axios from "axios"
import react, { useEffect, useState } from "react"
import { resources } from "../Resourses/Resources"
import EditOutlined from "@mui/icons-material/EditOutlined"
import { DeleteOutlineSharp } from "@mui/icons-material"
import PaginationReUsable from "../ReuseableComponent/ReusePagination"
const FeeDetailsView = () => {
    const [feeDetails, setFeeDetails] = useState([])
    const [allData, setAllData] = useState([])
    const [paginationData, setPaginationData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState("")
    const [serialNo, setSerialNo] = useState("")
    useEffect(() => {
        const fetchStudentDetails = async () => {
                try {
                    const response = await axios.get(resources.APPLICATION_URL + "getAllPendingFeeDetails")
                    if (response.data.length > 0) {
                        setFeeDetails(response.data)
                        setPaginationData(response.data)
                        setAllData(response.data)
                        setFilteredData(response.data)
                    }
                } catch (error) {
                    console.log("error===>", error)
                }
            }
            fetchStudentDetails()
        },[])

    const handleSearch = (e) => {
        let { value } = e.target;
        console.log("value", value);

        let list = allData.filter(
            (each) =>
                (each.feeType && each.feeType.toLowerCase().includes(value.toLowerCase())) ||
                (each.rollNumber && each.rollNumber.toLowerCase().includes(value.toLowerCase()))
        );
        console.log("list==>", list);
        setFilteredData(list);
        setSearch(value);
    };
    const handleGetSpliceData = (receivedList, startSNo) => {
        setPaginationData(receivedList);
        setSerialNo(startSNo);
    };
    console.log("paginationData===>",paginationData)
    return (
        <div>
            <div className="searchDiv">
                    <input type="text" placeholder="Roll Number or FeeType..." value={search} onChange={(e) => handleSearch(e)} />
                </div>
            <div>
                <table className="tableStylings">
                    <thead>
                        <tr>
                            <th>S.No</th>
                    
                            <th>Branch</th>
                            <th>Semester</th>
                            <th>Section</th>
                            <th>RollNumber</th>
                            <th>Fee Type</th>
                            <th>Pending Amount</th>
{/*                             <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {paginationData && paginationData.length > 0 && paginationData.map((items,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{serialNo+index}</td>
                                   
                                    <td>{items.branch}</td>
                                    <td>{items.semester}</td>
                                    <td>{items.section}</td>
                                    <td>{items.studentRollNo}</td>
                                    <td>{items.feeType}</td>
                                    <td>{items.pendingAmount}</td>
{/*                                     <td>
                                    <EditOutlined />
                                    <DeleteOutlineSharp />
                                </td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <PaginationReUsable
                paginationData={filteredData}
                handleGetSpliceData={handleGetSpliceData}
            />
        </div>
    )
}
export default FeeDetailsView;

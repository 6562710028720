import react, { useEffect, useState } from "react"
import axios from "axios"
import { resources } from "../Resourses/Resources"
import Cookies from "js-cookie"
import { json } from "react-router-dom"
import "./StudentDashboard.css"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
const SupplyFeepayment = () => {
    let currentYear1;
    let currentDate;
    let currentMonth;
    const date = new Date();
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const todaysDate=date.getDate()
    if(todaysDate < 10){
        currentDate="0"+todaysDate
    }else{
        currentDate=todaysDate
    }
    if(month < 10){
        currentMonth="0"+month
    }else{
        currentMonth=month
    }
    currentDate=todaysDate+"/"+currentMonth+"/"+year;
 
    if (month < 5) {
        currentYear1 = year - 1 + "-" + "" + year
    }
    else {
        currentYear1 = year + "-" + "" + year + 1
    }
    const useData = Cookies.get("userDetails")
    const userDetails = JSON.parse(useData)
    const [departMent, setDepartment] = useState("")
    const [rollNumber, setRollNumber] = useState("")
    const [edit,setEdit]=useState("")
    const [subjectDetails, setSubjectDetails] = useState({
        studentRollNo: "",
        section: "",
        subjectName: "",
        semester: "",
        branch:"",
        feeType:"Supply",
        paymentStatus:"Pending",
        academicYear:currentYear1,
        currentYear:currentYear1,
        date:currentDate

    })
    const [subjectList, setSubjectList] = useState([])
    useEffect(() => {
        const roll = userDetails.username
        const branchdata=userDetails.program
        setRollNumber(userDetails.username)
        setDepartment(userDetails.program)
        setSubjectDetails({ ...subjectDetails, studentRollNo: roll,branch: branchdata})
    }, [])
    console.log("user details===>", userDetails)
    const handleAdd = async () => {
        if(edit ===""){
            setSubjectList([...subjectList, subjectDetails])
            setSubjectDetails({
                ...subjectDetails,
    
                subjectName: "",
    
            })
        }else{
            const updatedList = [...subjectList]; 
            updatedList[edit] = subjectDetails; 
            setSubjectList(updatedList);
            setEdit("");
            setSubjectDetails({
                ...subjectDetails,
                subjectName: "",
            })

        }
   
    }
    const handleDelete=async(items)=>{
        const newData=subjectList.filter((each)=>each !==items)
        setSubjectList(newData)
    }
    console.log("subjectList===>",subjectList)
 const handleSubmit=async()=>{
   try {
    const response=await axios.post(resources.APPLICATION_URL+"saveSupplyFeeDetails",subjectList)
    if(response.data==="Successfully stored"){
        alert("Successfully added supply details")
        setSubjectDetails({
         ...subjectDetails,
            section: "",
            subjectName: "",
            semester: "",
            feeType:"Supply",
            paymentStatus:"Pending",
            academicYear:currentYear1,
            currentYear:currentYear1,
        })
        setSubjectList([])
    }
   } catch (error) {
    alert("Something went wrong")
    console.log("error===>",error)
    
   } 
 }
    return (
        <div className="supplyFeeConatiner">
            <div className="supplyFeeDiv">
                <div className="inputDivinSupply">
                    <label>Roll Number</label>
                    <input type="text" value={subjectDetails.studentRollNo} onChange={(e) => setSubjectDetails({ ...subjectDetails, rollNumber: e.target.value })} disabled="true" />
                </div>
                <div className="inputDivinSupply">
                    <label>Semester</label>
                    <input type="text" value={subjectDetails.semester} onChange={(e) => setSubjectDetails({ ...subjectDetails, semester: e.target.value })} />
                </div>
                <div className="inputDivinSupply">
                    <label>Section</label>
                    <input type="text" value={subjectDetails.section} onChange={(e) => setSubjectDetails({ ...subjectDetails, section: e.target.value })} />
                </div>
                <div className="inputDivinSupply">
                    <label>Subject Name</label>
                    <input type="text" value={subjectDetails.subjectName} onChange={(e) => setSubjectDetails({ ...subjectDetails, subjectName: e.target.value })} />
                </div>
                <div className="inputDivinSupply">
                  <button onClick={() => handleAdd()}>Add</button>
                </div>
            </div>
            <div className="tableDiv">
                <table className="tableStylings">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Subjects</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subjectList && subjectList.length > 0 && subjectList.map((items, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{items.subjectName}</td>
                                    <td><EditOutlinedIcon onClick={()=>{setSubjectDetails(items);setEdit(index)}}></EditOutlinedIcon ><DeleteOutlinedIcon onClick={()=>handleDelete(items)}></DeleteOutlinedIcon></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="generalButtonDiv"><button onClick={()=>handleSubmit()}>Submit</button></div>
        </div>
    )
}
export default SupplyFeepayment;
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Contactus from "./Components/Contactus/Contactus";
// import Courses from "./Components/Courses/Courses";
import Error from "./Components/Errorpage/Errorpage";
// import Team from "./Components/Team/Team";
// import Testimonial from "./Components/Testimonial/Testimonial";
import Socialservice from "./Components/Socialservice/Socialservice";
import Admissionprocedure from "./Components/Admissionprocedure/Admissionprocedure";
import AcadamicCalender from "./Components/Admissionprocedure/AcadamicCalender";
// import Feestructure from "./Components/FeeStructure/Feestructure";
// import Departments from "./Components/Departments/CSE";
import Management from "./Components/Management/Management";
import VisionMision from "./Components/VisionMision/VisionMision";
import Society from "./Components/Society/Society";
import Organogram from "./Components/Organogram/Organogram";
import Principal from "./Components/Principal/Principal";
import Strength from "./Components/Strengths/Strength";
import Incentive from "./Components/Incentives/Incentive";
import Companies from "./Components/Companies/Companies";
import Naac from "./Components/Naac/Naac";
import Dvv from "./Components/Naac/Naac-dvv";
import Placement from "./Components/Placements/Placement";
import Registration from "./Components/Alumini/Registration";
import StudentRegistraion from "./Components/StudentRegistraionForm/StudentRegistration";
import AdmissionForm from "./Components/Admissionprocedure/AdmissionForm";

// Departments
import CSEandAIML from "./Components/Departments/CSE(AI&ML)/CSE(AI&ML)";
import EEE from "./Components/Departments/EEE/EEE";
import AIandDS from "./Components/Departments/AI&DS/AI&DS";
import AIML from "./Components/Departments/AI&ML/AI&ML";
import MECH from "./Components/Departments/MECH/MECH";
import SandH from "./Components/Departments/S&H/S&H";
import MBA from "./Components/Departments/MBA/MBA";
import POLYTECHNIC from "./Components/Departments/POLYTECHNIC/POLYTECHNIC";
import CSE from "./Components/Departments/CSE/CSE";
import ECE from "./Components/Departments/ECE/ECE";

import Acadamicregulation from "./Components/AcadamicRegulation/Acadamicregulation";
import Counseling from "./Components/Counseling/Counseling";
import Examinationbranch from "./Components/ExaminationBranch/Examinationbranch";
import Administrationofc from "./Components/Administrationofc/Administrationofc";
import Policies from "./Components/Policies/Policies";
import Login from "./Components/Login/Login";
import Temple from "./Components/Temple/Temple";
import Quarters from "./Components/Amenities/Quarters";
import Eclassroom from "./Components/Amenities/Eclassroom";
import Transportion from "./Components/Amenities/Transportion";
import Hostels from "./Components/Amenities/Hostels";
import Cafeteria from "./Components/Amenities/Cafeteria";
import Sportsgames from "./Components/Amenities/Sportsgames";
import Seminorhall from "./Components/Amenities/Seminorhall";
import Englishlab from "./Components/Amenities/Englishlab";
import Internet from "./Components/Amenities/Internet";
import GrevienceCommittee from "./Components/GrevienceCommittee/GrevienceCommittee";
import DigitalLibrary from "./Components/Amenities/DigitalLibrary";
import Capmousphotos from "./Components/Departments/Galary/Campousphotos";
import AdminstrationGovernigBody from "./Components/Admissionprocedure/AdminstrationGovernigBody";
import MandatoryDiscloser from "./Components/MandatoryDiscloser/Mandatorydiscloser";
import Academic from "./Components/AcadamicCouncil/Academic";
import Library from "./Components/Library/Library";
import Iqac from "./Components/Naac/Iqac";
import Iccommittee from "./Components/IC COMMITTEE/Iccommittee";
import Aictefeedback from "./Components/Amenities/Aictefeedback";
import GrevienceEnquiryForm from "./Components/GrevienceCommittee/GrevienceEnquiryForm";
import GrevienceForm from "./Components/GrevienceCommittee/GrevienceForm";
import Notification from "./Components/Notification/Notification";
import PressNote from "./Components/Notification/PressNote";
import Events from "./Components/Notification/Events";
import Ict from "./Components/Amenities/IctFecilities/Ict";
import IctLabs from "./Components/Amenities/IctFecilities/IctLab";
import TeacherFeedback from "./Components/GrevienceCommittee/TeacherFeedback";
import StudentFeedback from "./Components/GrevienceCommittee/StudentFeedback";
import EmployeeFeedback from "./Components/GrevienceCommittee/EmployeeFeedback";
import AlluminiFeedback from "./Components/GrevienceCommittee/AlluminiFeedback";
// import Login from "./Components/Login/Login";
import ViewStudents from "./Components/ViewStudents/ViewStudents";
import PhotosUpload from "./Components/Login/PhotosUpload";
import NotificationUpload from "./Components/Notification/NotificationUpload";
import UploadphotosCommitee from "./Components/GrevienceCommittee/UploadphotosCommitee";
import Register from "./Components/Register/Register";
import FacultyUploadHome from "./Components/FacultyScreens/FacutyHome";
import FacultyUpload from "./Components/FacultyScreens/FacultyUpload";
import CommitteeMembersAdd from "./Components/IC COMMITTEE/CommitteeMembersAdd";
import BranchMenu from "./Components/BranchMenus/BranchMenu";
import GeneralNotifications from "./Components/GeneralNotifications/GeneralNotifications";
import ViewGeneralNotifications from "./Components/GeneralNotifications/ViewGeneralNotifications";
import AicteFeedbackView from "./Components/Amenities/AicteFeedbackView";
import AdminDashBoard from "./Components/AdminDashbOard/AdminDashboard";
import CourseUpload from "./Components/GeneralNotifications/CourseUpload/CourseUpload";
import ExcelUploads from "./Components/ExcelUploads/ExcelUploads";
import GalleryView from "./Components/Login/GalleryView";
import StudentDashBoard from "./Components/StudentDashboard/StudentDashboard";
import ViewEnquiryForm from "./Components/FacultyScreens/ViewEnquiryForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React from "react";
import StudentEnquiryForm from "./Components/StudentDashboard/StudentEnquiryForm";
import ViewStudentEnquiryForm from "./Components/StudentDashboard/ViewEnquiryForms";
import LabImagesUpload from "./Components/FacultyScreens/LabImagesUpload";
import AdminFeeStructure from "./Components/AdminfeeStructure/AdminFeeStructure";
function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contactus" element={<Contactus />} />

        {/* Administration */}
        <Route path="/Administration/management" element={<Management />} />
        <Route path="/Administration/society" element={<Society />} />
        <Route path="/Administration/vision" element={<VisionMision />} />
        <Route path="/Administration/quality" element={<VisionMision />} />
        <Route path="/Administration/organogram" element={<Organogram />} />
        <Route
          path="/Administration/governing"
          element={<AdminstrationGovernigBody />}
        />
        <Route path="/Administration/principal" element={<Principal />} />
        <Route path="/Administration/council" element={<Academic />} />
        <Route
          path="/Administration/mandatory"
          element={<MandatoryDiscloser />}
        />
        <Route path="/Administration/strength" element={<Strength />} />

        {/* Acadamics */}

        <Route
          path="/acadamics/acadamicRegulations"
          element={<Acadamicregulation />}
        />
        <Route
          path="/acadamics/acadamicCalender"
          element={<AcadamicCalender />}
        />
        <Route path="/acadamics/incentives" element={<Incentive />} />
        <Route path="/acadamics/socialService" element={<Socialservice />} />

        {/* Departments */}
        <Route path="/departments/cse" element={<CSE />} />
        <Route path="/departments/cse(al&ml)" element={<CSEandAIML />} />
        <Route path="/departments/ece" element={<ECE />} />
        <Route path="/departments/eee" element={<EEE />} />
        <Route path="/departments/ai&ds" element={<AIandDS />} />
        <Route path="/departments/aiml" element={<AIML />} />
        <Route path="/departments/mech" element={<MECH />} />
        <Route path="/departments/s&h" element={<SandH />} />
        <Route path="/departments/mba" element={<MBA />} />
        <Route path="/departments/polytechnic" element={<POLYTECHNIC />} />

        {/* Admissions */}

        <Route path="/admissions/procedure" element={<Admissionprocedure />} />
        <Route path="/admissions/fee" element={<Acadamicregulation />} />
        <Route path="/admissions/form" element={<AdmissionForm />} />

        {/* Governance */}
        <Route path="/governance/counselling" element={<Counseling />} />
        {/* <Route path="/governance/examination" element={<Examinationbranch />} /> */}
        <Route path="/governance/examination" element={<BranchMenu />} />
        <Route path="/governance/office" element={<Administrationofc />} />
        <Route path="/governance/policy" element={<Policies />} />
        <Route
          path="/governance/uploadPhotosCommittee"
          element={<UploadphotosCommitee />}
        />
        <Route path="/governance/committee" element={<GrevienceCommittee />} />
        <Route
          path="/gallery/General/notifications"
          element={<GeneralNotifications />}
        />
        <Route
          path="/gallery/View/General/notifications"
          element={<ViewGeneralNotifications />}
        />

        {/* Amenities */}

        <Route path="/amenities/library" element={<Library />} />
        <Route path="/amenities/digitalLibrary" element={<DigitalLibrary />} />
        <Route path="/amenities/internet" element={<Internet />} />
        <Route path="/amenities/englishLabs" element={<Englishlab />} />
        <Route path="/amenities/ictFecilities" element={<Ict />} />
        <Route path="/amenities/ictFecilities/lab" element={<IctLabs />} />
        <Route path="/amenities/seminarHalls" element={<Seminorhall />} />
        <Route path="/amenities/sports" element={<Sportsgames />} />
        <Route path="/amenities/cafeteria" element={<Cafeteria />} />
        <Route path="/amenities/hostels" element={<Hostels />} />
        <Route path="/amenities/transport" element={<Transportion />} />
        <Route path="/amenities/e-classRooms" element={<Eclassroom />} />
        <Route path="/amenities/staffQuarters" element={<Quarters />} />
        <Route path="/amenities/temple" element={<Temple />} />

        {/* placements */}

        <Route path="/placements/placementRecords" element={<Placement />} />
        <Route path="/placements/companies" element={<Companies />} />

        {/* NAAC */}

        <Route path="/naac/naacSsr" element={<Naac />} />
        <Route path="/naac/naacDvv" element={<Dvv />} />
        <Route path="/naac/iqac" element={<Iqac />} />
        <Route path="/naac/selfDeclaration" element={<Home />} />
        <Route path="/naac/undertaking" element={<Home />} />
        <Route path="/naac/iqaReport" element={<Home />} />

        {/* NIRF */}

        <Route path="/nirf/nirfOverall" element={<Home />} />
        <Route path="/nirf/nirfEngineering" element={<Home />} />

        {/* Alumni */}

        <Route path="/alumini/invitation" element={<Home />} />
        <Route path="/alumini/registration" element={<Registration />} />
        <Route path="/alumini/view" element={<ViewStudents />} />

        {/* Student Corner */}

        <Route path="/student/scholarshipNotification" element={<Home />} />
        <Route
          path="/student/scholarshipRegistration"
          element={<StudentRegistraion />}
        />
        <Route path="/student/socialService" element={<Socialservice />} />

        {/* Greivences */}

        <Route path="/feedback/greivences" element={<GrevienceForm />} />
        <Route path="/feedback/ic-committee" element={<Iccommittee />} />
        <Route path="/feedback/aicteFeedback" element={<Aictefeedback />} />
        <Route
          path="/feedback/aicteFeedbackView"
          element={<AicteFeedbackView />}
        />
        <Route path="/feedback/enqiryform" element={<GrevienceEnquiryForm />} />
        <Route path="/feedback/teacherFeedback" element={<TeacherFeedback />} />
        <Route path="/feedback/studentFeedback" element={<StudentFeedback />} />
        <Route
          path="/feedback/employerFeedback"
          element={<EmployeeFeedback />}
        />
        <Route
          path="/feedback/aluminiFeedback"
          element={<AlluminiFeedback />}
        />

        {/* Gallery */}

        <Route path="/gallery/campusPhotos" element={<Capmousphotos />} />
        <Route path="/gallery/notifications" element={<Notification />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/pressnote" element={<PressNote />} />
        <Route path="/events" element={<Events />} />
        <Route path="/gallery/login" element={<Login />} />
        <Route path="/gallery/Register" element={<Register />} />
        <Route path="/gallery/photosUpload" element={<PhotosUpload />} />
        <Route path="/gallery/galleryView" element={<GalleryView />} />
        <Route path="/notificationupload" element={<NotificationUpload />} />
        <Route path="/facultyUploads" element={<FacultyUpload />} />
        <Route
          path="/governance/add/addcommittee"
          element={<CommitteeMembersAdd />}
        />
        <Route path="/admin/DashboardView" element={<AdminDashBoard />} />
        <Route
          path="/gallery/View/General/courseUpload"
          element={<CourseUpload />}
        />
        <Route
          path="/gallery/View/General/uploadExcelFiles"
          element={<ExcelUploads />}
        />
        <Route
          path="/gallery/studentDashboard/view"
          element={<StudentDashBoard />}
        />
        <Route path="/gallery/enquiryForm/view" element={<ViewEnquiryForm />} />
        <Route path="/gallery/View/General/EnquiryForm" element={<StudentEnquiryForm />} />
        <Route path="/gallery/View/General/view/enquiryForm" element={<ViewStudentEnquiryForm />} />
        <Route path="/gallery/View/General/carousel/photosUpload" element={<LabImagesUpload/>} />

        <Route path="*" element={Error} />
        <Route path="/admin/feeStructure" element={<AdminFeeStructure />} />
      </Routes>
      <ToastContainer style={{ width: "500px" }} />
    </React.Fragment>
  );
}
export default App;

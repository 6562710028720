import React from 'react'
import Slider from "react-slick";
import "./Ict.css"
import Ict1 from "../../../assets/Ictfecilities/1.jpg";
import Ict2 from "../../../assets/Ictfecilities/2.jpg";
import Ict3 from "../../../assets/Ictfecilities/3.jpg";
import Ict4 from "../../../assets/Ictfecilities/4.jpg";
import Ict5 from "../../../assets/Ictfecilities/5.jpg";
import Ict6 from "../../../assets/Ictfecilities/6.jpg";
import Ict7 from "../../../assets/Ictfecilities/7.jpg";
import Ict8 from "../../../assets/Ictfecilities/8.jpg";
import Ict9 from "../../../assets/Ictfecilities/9.jpg";
import Ict10 from "../../../assets/Ictfecilities/10.jpg";
import { Link } from 'react-router-dom';

function Ict() {
    var settings = {
        dots: true,
        infinite: true,
        // slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        pauseOnHover: false,
        slidesToShow: 2,
        slidesToScroll: 1,
    };
    const carousal_images = [
        {
            image: Ict1
        },
        {
            image: Ict2
        },
        {
            image: Ict3
        },
        {
            image: Ict4
        },
        {
            image: Ict5
        },
        {
            image: Ict6
        },
        {
            image: Ict7
        },
        {
            image: Ict8
        },
        {
            image: Ict9
        },
        {
            image: Ict10
        }
        
    ]
    const fecilities = [
        {
            title:"SSIT-SAIS College",
            text:"Situated 3,500 feet above sea level and surrounded by lush green tea plantations, SSIT-SAIS College lies in lap of nature, providing the best environment for our students."
        },
        {
            title:"SSIT-SAIS Campus",
            text:"To achieve higher levels of learning we require a bit of inspiration. SSIT-SAIS’s breath-taking salubrious campus is a place where all our intellectual and spiritual needs can be met."
        },
        {
            title:"Departments",
            text:"Smart classrooms, state-of-the-art infrastructure, able management, dedicated faculty, vibrant students…SSIT-SAIS is the ideal place for you to jumpstart your career."
        },
        {
            title:"Facilities @ SSIT-SAIS",
            text:"SSIT-SAIS is a campus that envisions the comprehensive transformation of its students. It is a 24 hour campus committed to make students competent to face the dynamic world."
        },
        {
            title:"SSIT-SAIS Hostels",
            text:"A home away from home, SSIT-SAIS hostel provides a positive environment for all its residents. Managed by Reverend fathers and sisters, SSIT-SAIS makes no compromise in the facilities available.",
        },
        {
            title:"Co-curricular Activities",
            text:"Recreation revitalizes the mind’s inborn abilities. A balanced scheme of work and recreation is the proper way to keep the mind energetic. That is why we at SSIT-SAIS stress on extracurricular activities by emphasizing on making students active even outside the classroom."
        }
    ]
    const ictBtn = [
        {
            title:"ICT FACILITIES",
            
        },
        {
            title:"COMPUTER LABORATORIES",
            link:"/amenities/ictFecilities/lab"
        },
        {
            title:"RESOURCES"
        }
    ]
    return (
        <div>
            <h3 className='Ict_h1'>ICT FACILITIES</h3>
            <div className='ict_btn_container'>
            {ictBtn.map((each)=><Link to={each.link}><button className='Ict_btn'>{each.title}</button></Link>)}
            </div>
            
            {fecilities.map((each, index) => <div className='Ict_container'>
                <div className='Ict_carousal'>
                    <Slider  {...settings}>
                        {carousal_images.map((item, index) => {
                            return (
                                <div key={index} style={{ width: "100%", border: "1px solid lightgray" }}>
                                    
                                        <img 
                                            className="img-fluid Ict_img"
                                            src={item.image}
                                            alt="carousel1-img"
                                        />
                                    
                                </div>
                            );
                        })}
                    </Slider>
                    </div>
                <div className='Ict_content'>
                    <h3>{each.title}</h3><br/>
                    <p>
                    {each.text}
                    </p>
                </div>
            </div>
            
            )}
        </div>
    )
}

export default Ict

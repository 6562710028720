import react from "react"
import Cookies from "js-cookie"
import { useState, useEffect } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
const ViewEnquiryForm = () => {
    const [departMent, setDepartment] = useState("")
    const useData = Cookies.get("userDetails")
    const userDetails = JSON.parse(useData)
    const [enquiryFormDetails, setEnquiryFormDetails] = useState([])

    useEffect(() => {
        const branchdata = userDetails.program
        setDepartment(userDetails.program)
    }, [])
    const fetchEnquiryForm = async () => {
        try {
            const response = await axios.get(resources.APPLICATION_URL + `getAllEnquiryFormsByDepartment?department=${departMent}`)
            if (response.data) {
                setEnquiryFormDetails(response.data)
            }
        } catch (error) {
            console.log("error===>", error)
        }
    }
    useEffect(() => {
        console.log("rollNumber===>", departMent)
        if (departMent !== null && departMent !== "") {

            fetchEnquiryForm()
        }
    }, [departMent])
    const handleChange = async (items, index, e) => {
        const updatedList = [...enquiryFormDetails]
        const { name, value } = e.target
        updatedList[index] = { ...updatedList[index], feedBack: value,status:"Solved" };
        setEnquiryFormDetails(updatedList)

    }
  
    const handleSaveDataEnquiryData = async () => {
        try {
            const res = await axios.post(resources.APPLICATION_URL + "saveAafterGivingFeedBack",enquiryFormDetails);
           console.log("response after saving==>",res)
            if (res.data) {
                alert("FeedBack Added Successfully");
                fetchEnquiryForm()
     
            }
        } catch (e) {
     
            console.log(e);
        }
    };
    console.log("enquiryFormDetails===>", enquiryFormDetails)
    return (
        <div>
            <div className="tableDiv">
                <table className="tableStylings">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Roll Number</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>FeedBack</th>
                        </tr>
                    </thead>
                    <tbody>
                        {enquiryFormDetails && enquiryFormDetails.length > 0 && enquiryFormDetails.map((items, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{items.rollNumber}</td>
                                    <td>{items.subject}</td>
                                    <td>{items.message}</td>
                                    <td><input type="text" name="feedBack" value={items.feedBack} onChange={(e) => handleChange(items, index, e)} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="searchDiv">
                <button onClick={() => handleSaveDataEnquiryData()}>Submit</button>
            </div>
        </div>
    )
}
export default ViewEnquiryForm;
import React from "react";
import seminor1 from "../../assets/sh11.jpg";
import seminor2 from "../../assets/sh12.jpg";
import seminor3 from "../../assets/sh21.jpg";
import seminor4 from "../../assets/sh22.jpg";
import seminor5 from "../../assets/sh23.jpg";

const Seminorhall = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            SEMINAR HALLS
          </a>
        </div>
      </div>
      <br />

      <div className="row g-4">
        <div className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.1s">
          <div className="team-item bg-light img_cnt">
            <div className="overflow-hidden">
              <img
                className="img-fluid rounded img"
                src={seminor1}
                alt="seminor1-img"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item bg-light img_cnt">
            <div className="overflow-hidden">
              <img
                className="img-fluid rounded img"
                src={seminor2}
                alt="seminor2-img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.7s">
          <div className="team-item bg-light img_cnt">
            <div className="overflow-hidden">
              <img
                className="img-fluid  rounded img"
                src={seminor3}
                alt="seminor3-img"
              />
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="row g-4">
        <div className="col-lg-6 col-md-4 wow fadeInUp" data-wow-delay="0.1s">
          <div className="team-item bg-light img_cnt">
            <div className="overflow-hidden">
              <img
                className="img-fluid rounded img"
                src={seminor4}
                alt="seminor4-img"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-4 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item bg-light img_cnt">
            <div className="overflow-hidden">
              <img
                className="img-fluid rounded img"
                src={seminor5}
                alt="seminor5-img"
              />
            </div>
          </div>
        </div>
      </div><br/>

      <div className="container text-left">
        <h4 className="text text-center">Seminar Halls</h4>
        <br />
        <p style={{textAlign:"justify"}}>
        <p>
          SSIT has excellent seminar hall facility in the 2nd floor with a
          capacity to accommodate nearly 500 students at a stretch. The hall is
          equipped with LCD projector (Sony make, model: VPL-EX7) and excellent
          audio system comprising Amplifier (AHUJA make, model: SSA-250M),
          Eleven Column Speakers (AHUJA make, model: ASC310T) apart from marker
          board.
        </p>
        <p>
          Further the golden environmental photographs of eminent personalities
          of national and international for growth of science and technology
          have been wall mounted along with their brief bio-data covering the
          entire hall.
        </p>
        </p>
      </div>
    </div>
  );
};

export default Seminorhall;

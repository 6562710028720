import  { useEffect } from "react"
import { useState } from "react";
import FacultyDashBoardView from "./FacultyView";
import StudentDashboardView from "./StudentView";
import "./AdminDashboard.css"
import FeeDetailsView from "./FeeDetails";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminDashBoard = () => {
    const [viewType, setViewType] = useState("faculty")
    const [lastRecord, setLastRecord] = useState({})
    const [viewFile,setViewFile]=useState(false)
    const handleClickOpen = () => {
        setViewFile(true);
      };
    
      const handleClose = () => {
        setViewFile(false);
      };
    const date = new Date;
    let currentMonth;
    let currentDate;
    const toDaysDate = date.getDate();
    const month = date.getMonth() + 1
    const currentYear = date.getFullYear()

    if (month < 10) {
        currentMonth = "0" + month
    } else {
        currentMonth = month
    }
    if (toDaysDate < 10) {
        currentDate = "0" + toDaysDate
    } else {
        currentDate = toDaysDate
    }
    const newDate = currentYear + "-" + currentMonth + "-" + currentDate
    useEffect(() => {
        const activeStyles = ({
            backgroundColor: "Orange",
            color: "White"
        })
        const deActiveStyles = ({
            backgroundColor: "",
            color: ""
        })
 
        document.getElementById("student").style.backgroundColor = viewType === "student" ? activeStyles.backgroundColor : deActiveStyles.backgroundColor;
        document.getElementById("student").style.color = viewType === "student" ? activeStyles.color : deActiveStyles.color;
        document.getElementById("faculty").style.backgroundColor = viewType === "faculty" ? activeStyles.backgroundColor : deActiveStyles.backgroundColor;
        document.getElementById("faculty").style.color = viewType === "faculty" ? activeStyles.color : deActiveStyles.color;

        document.getElementById("feeDetails").style.backgroundColor = viewType === "feeDetails" ? activeStyles.backgroundColor : deActiveStyles.backgroundColor;
        document.getElementById("feeDetails").style.color = viewType === "feeDetails" ? activeStyles.color : deActiveStyles.color;
    }, [viewType])
    const fetchLasstRecord = async () => {
        try {
            const response = await axios.get(resources.APPLICATION_URL + `getLastInsertedRecord?typeOfUpload=ExamBranch`)
            const details = response.data
            console.log("newDate===>", newDate)
            console.log("details.endDate===>", details.endDate)
            if (details.endDate >= newDate) {
                setLastRecord(response.data)
            } else {
                console.log("triggered")
            }
        } catch (error) {
            console.log("error===>", error)
        }
    }
    useEffect(() => {

        fetchLasstRecord();
    }, [newDate])
    console.log("last record===>",lastRecord)
    return (
        <div className="dashboardMainContainer">
            <marquee behavior="scroll" direction="left" scrollamount="10">
            {lastRecord.description ? <p onClick={()=>handleClickOpen()} style={{cursor:"pointer"}}>Exam Branch : {lastRecord.description}</p> : <>No Notifications Available</>}
            </marquee>
            <div className="dahboardContainer">
                <div className="firstContainer">
                    <button onClick={() => setViewType("faculty")} id="faculty">Faculty</button>
                    <button onClick={() => setViewType("student")} id="student">Student</button>
                    <button onClick={() => setViewType("feeDetails")} id="feeDetails">Fee Details</button>
                </div>
                <div className="secondContainer">
                    {viewType === "faculty" && <FacultyDashBoardView />}
                    {viewType === "student" && <StudentDashboardView />}
                    {viewType === "feeDetails" && <FeeDetailsView />}
                </div>
            </div>
            <React.Fragment>
        <Dialog
          fullScreen
          open={viewFile}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
              maxHeight: '100vh',
            },
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

            </Toolbar>
          </AppBar>
          {lastRecord && (
            <iframe
              src={`data:application/pdf;base64,${lastRecord.filePath}`}
              width="100%"
              height="100%"
            />
          )}
        </Dialog>
      </React.Fragment>
        </div>
    )
}
export default AdminDashBoard;
import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo2r.png";

const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-3">Quick Link</h4>
            <Link className="btn btn-link" to="#">
              Results
            </Link>
            <Link className="btn btn-link" to="#">
              How to Reach
            </Link>
            <Link className="btn btn-link" to="#">
              Alumini
            </Link>
            <Link className="btn btn-link" to="#">
              SSIT News Letters
            </Link>
            <Link className="btn btn-link" to="#">
              Downloads
            </Link>
            <Link className="btn btn-link" to="https://sg2plcpnl0082.prod.sin2.secureserver.net:2096/">
              Webmail
            </Link>
            <Link className="btn btn-link" to="/gallery/login">
              Login
            </Link>
            {/* <Link className="btn btn-link" to="/gallery/register">
              Register
            </Link> */}
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-3">Contact</h4>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>Sai Spurthi Institute
              Of Technology B.Gangaram (V), Sathupalli (Mandal), Khammam
              District,Telangana-507303,
              <br />
              08761-288544,
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>Principal - +91
              9963084558,
              <br /> Director - +91 9491109564,
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>sssit.principal@gmail.com
            </p>
            <div className="d-flex pt-2">
              <Link
                className="btn btn-outline-light btn-social"
                to="https://twitter.com/i/flow/login?redirect_after_login=%2FSaiSpurthiMedia"
              >
                <i className="fab fa-twitter"></i>
              </Link>
              <Link
                className="btn btn-outline-light btn-social"
                to="https://www.facebook.com/people/Sai-Spurthi-Media/61550019525949/"
              >
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link
                className="btn btn-outline-light btn-social"
                to="https://www.youtube.com/@saispurthimedia"
              >
                <i className="fab fa-youtube"></i>
              </Link>
              <Link className="btn btn-outline-light btn-social" to="#">
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link
                className="btn btn-outline-light btn-social"
                to="https://www.instagram.com/saispurthimedia/"
              >
                <i className="fab fa-instagram"></i>
              </Link>
              <Link
                className="btn btn-outline-light btn-social"
                to="https://in.pinterest.com/saispurthimedia/"
              >
                <i className="fab fa-pinterest"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-3"></h4>
            <img className="img-fluid p-1" src={logo} alt="logo-img" />
            {/* <div className="row g-2 pt-2">
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course1}
                  alt="course1-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course2}
                  alt="course2-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course3}
                  alt="course3-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course2}
                  alt="course2-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={course3}
                  alt="course3-img"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid bg-light p-1"
                  src={about}
                  alt="about-img"
                />
              </div>
            </div> */}
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-3">Newsletter</h4>
            {/* <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p> */}
            <iframe
              className="position-relative rounded w-100 h-100"
              src="https://cache.careers360.mobi/media/colleges/staticmap/2023/10/28/3299.png"
              frameborder="0"
              style={{ minHeight: "200px", border: "0" }}
              allowfullscreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
            <div
              className="position-relative mx-auto"
              style={{ maxWidth: "400px" }}
            >
              <input
                className="form-control border-0 w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Your email"
              />
              <button
                type="button"
                className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                SignUp
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <Link className="border-bottom" to="#">
                http://www.saispurthi.ac.in
              </Link>
              , All Right Reserved.
              {/* This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. */}
              Designed By{" "}
              <Link className="border-bottom" to="https://htmlcodex.com">
                Adiverse Technologies
              </Link>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <Link to="#">Home</Link>
                <Link to="#">Cookies</Link>
                <Link to="#">Help</Link>
                <Link to="#">FQAs</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

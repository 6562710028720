import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import axios from "axios";
import { resources } from "../../Resourses/Resources";
import 'animate.css'

// Lab Images

import lab3 from "../../../assets/lab/cselab3-3.jpg";
import lab2 from "../../../assets/lab/cselab2-2.jpg";
import lab4 from "../../../assets/lab/cselab1-2.jpg";
import lab1 from "../../../assets/lab/cselab1-1.jpg";
import lab5 from "../../../assets/lab/cselab1-3.jpg";
import lab6 from "../../../assets/lab/cselab2-1.jpg";
import lab7 from "../../../assets/lab/cselab2-3.jpg";
import lab8 from "../../../assets/lab/cselab3-1.jpg";
import lab9 from "../../../assets/lab/cselab3-2.jpg";
import lab10 from "../../../assets/lab/cselab4-1.jpg";
import lab11 from "../../../assets/lab/cselab4-2.jpg";
import lab12 from "../../../assets/lab/cselab4-3.jpg";
import ViewLabImages from "../ViewLabPhotos/ViewLabPhotos";

const Labs = () => {
  const [key, setKey] = useState("All");
  const [commonData, setCommonInterData] = useState([])
  const [computerData1, setComputer1Data] = useState([])
  const [compueter2Data, setComputer2Data] = useState([])
  const [computer3Data, setComputer3Data] = useState([])
  const date = new Date();
  const currentYear = date.getFullYear()
  const currentPath = window.location.pathname;
    const path = currentPath.split("/").pop();
    const labType=path.toUpperCase()
    console.log("path===>",path.toUpperCase())
  useEffect(() => {

    const fetchAllImages = async () => {
      // try {
      //   const details = await axios.get(resources.APPLICATION_URL + `get/lab/imagesByYear?academicYear=${currentYear}&department=CSE`)
      //   // setAllImages(details.data)
      //   const allPhotos = details.data

      //   const labTypesCSE = ["Common Internet Center", "Computer Lab1", "Computer Lab2", "Computer Lab3"]

      //   const commonData = allPhotos.filter((items) => items.labName === "Common Internet Center")
      //   const electricMachines = allPhotos.filter((items) => items.labName === "Computer Lab1")
      //   const electricSimulation = allPhotos.filter((items) => items.labName === "Computer Lab2")
      //   const powerSystemLab = allPhotos.filter((items) => items.labName === "Computer Lab3")


      //   const commonDetails = commonData[0].filePath.split(",")
      //   const computer1 = electricMachines[0].filePath.split(",")
      //   const computer2 = electricSimulation[0].filePath.split(",")
      //   const computer3 = powerSystemLab[0].filePath.split(",")
      //   setCommonInterData(commonDetails)
      //   setComputer1Data(computer1)

      //   setComputer2Data(computer2)
      //   setComputer3Data(computer3)

      // } catch (error) {
      //   console.log("error in fetch images===>", error)
      // }
    }
    fetchAllImages()
  }, [currentYear])
  return (
    <div className="container">
      <PageHeader name="Laboratories" />
      <Card style={{ width: "100%", margin: "20px 0px" }}>
        <Card.Header>CSE Laboratories</Card.Header>
        <Card.Body>
          <Card.Title>We have Fully Equipped Laboratories</Card.Title>
          <Card.Text>
            we have the several Computer Labs equipped with latest software's
            and workstations. It is connected to internet through a lease line
            to facilitate high speed internet access. All the computers are
            connected through a structured network. Printer and copier
            facilities are also available in the lab. Our dedicated faculty and
            staff are proud of their impressive credentials, which are
            comparable to those of the best career instructors.
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="content">
      <ViewLabImages labType={labType}/>
        {/* <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
           
          <Tab eventKey="All" title="All">
         
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                {commonData && commonData.length > 0 && commonData.map((items, index) => {
                  return (

                    <div className="col-4 " key={index}>
                      <img
                        className="img-fluid bg-light p-10"
                        src={`data:application/pdf;base64,${items}`}
                      />
                      <p style={{ textAlign: "center" }}>Common Internet Center</p>
                    </div>
                  )

                })}


              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                {computerData1 && computerData1.length > 0 && computerData1.map((items, index) => {
                  return (

                    <div className="col-4 " key={index}>
                      <img
                        className="img-fluid bg-light p-10"
                        src={`data:application/pdf;base64,${items}`}
                      />
                      <p style={{ textAlign: "center" }}>Computer Lab 1</p>
                    </div>
                  )

                })}

              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                {compueter2Data && compueter2Data.length > 0 && compueter2Data.map((items, index) => {
                  return (

                    <div className="col-4 " key={index}>
                      <img
                        className="img-fluid bg-light p-10"
                        src={`data:application/pdf;base64,${items}`}
                      />
                      <p style={{ textAlign: "center" }}>Computer Lab 2</p>
                    </div>
                  )

                })}
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                {computer3Data && computer3Data.length > 0 && computer3Data.map((items, index) => {
                  return (

                    <div className="col-4 " key={index}>
                      <img
                        className="img-fluid bg-light p-10"
                        src={`data:application/pdf;base64,${items}`}
                      />
                      <p style={{ textAlign: "center" }}>Computer Lab 3</p>
                    </div>
                  )

                })}
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMMON INTERNET CENTER" title="COMMON INTERNET CENTER">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                {commonData && commonData.length > 0 && commonData.map((items, index) => {
                  return (
                    <div className="col-4" key={index}>
                      <img
                        className="img-fluid bg-light p-10"
                        src={`data:application/pdf;base64,${items}`}
                        alt="lab-img"
                      />
                      <p style={{ textAlign: "center" }}>Common Internet Center</p>
                    </div>
                  )
                })}


              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB1" title="COMPUTER LAB1">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
              {computerData1 && computerData1.length > 0 && computerData1.map((items, index) => {
                  return (
                    <div className="col-4" key={index}>
                      <img
                        className="img-fluid bg-light p-10"
                        src={`data:application/pdf;base64,${items}`}
                        alt="lab-img"
                      />
                      <p style={{ textAlign: "center" }}>Computer Lab1</p>
                    </div>
                  )
                })}
         
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB2" title="COMPUTER LAB2">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
              {compueter2Data && compueter2Data.length > 0 && compueter2Data.map((items, index) => {
                  return (
                    <div className="col-4" key={index}>
                      <img
                        className="img-fluid bg-light p-10"
                        src={`data:application/pdf;base64,${items}`}
                        alt="lab-img"
                      />
                      <p style={{ textAlign: "center" }}>Computer Lab2</p>
                    </div>
                  )
                })}
         
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMPUTER LAB3" title="COMPUTER LAB3">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
              {computer3Data && computer3Data.length > 0 && computer3Data.map((items, index) => {
                  return (
                    <div className="col-4" key={index}>
                      <img
                        className="img-fluid bg-light p-10"
                        src={`data:application/pdf;base64,${items}`}
                        alt="lab-img"
                      />
                      <p style={{ textAlign: "center" }}>Computer Lab3</p>
                    </div>
                  )
                })}
           
              </div>
            </div>
          </Tab>
        </Tabs> */}
      </div>
    </div>
  );
};

export default Labs;

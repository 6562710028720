import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import csefaculty from "../../../assets/HODs/EEE HOD.JPG";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import Labs from "./Labs";
import Faculty from "./Faculty";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";

import axios from "axios";
import { resources } from "../../Resourses/Resources";
import {
  FluentShifts30Minutes24Filled,
  StreamlineBugAntivirusDebugging,
} from "../../../assets/icons/svgs";
import { UitCircuit } from "../../../assets/icons/svgs";
import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
// import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
import { MdiPresentation } from "../../../assets/icons/svgs";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import QuizIcon from "@mui/icons-material/Quiz";
import Cookies from "js-cookie"
import event1 from "../../../assets/Events/EEE/fdp_1.jpg";
import event2 from "../../../assets/Events/EEE/fdp_2.jpg";
import event3 from "../../../assets/Events/EEE/fdp_3.jpg";
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourseStructure = () => {
  const userDetailsCookie = Cookies.get("userDetails");
  let userDetails;
  if(userDetailsCookie){
    userDetails = JSON.parse(userDetailsCookie);
  }
  const userName = userDetails?.username
  const departMent = userDetails?.department
  const userEmail = userDetails?.email
  const date = new Date()
  let academicYear1;
  const currentYear = date.getFullYear()
  const month = date.getMonth()
  if (month < 5) {
    academicYear1 = currentYear - 1 + "-" + currentYear
  } else {
    academicYear1 = currentYear + "-" + currentYear + 1

  }
  const [regulationDetails, setRegulationDetails] = useState([])
  const [fileView, setFileView] = useState(false)
  const [filepath, setFilepath] = useState("")

  const handleClickOpen = () => {
    setFileView(true);
  };

  const handleClose = () => {
    setFileView(false);
  };
  useEffect(() => {
    const fetchRegulationDetails = async () => {
      try {
        const response = await axios.get(resources.APPLICATION_URL + `getRegulationDetails?academicYear=${academicYear1}&branch=EEE`)
        console.log("response===>", response.data)
        setRegulationDetails(response.data)
      } catch (error) {
        console.log("error===>", error)
      }
    }
    fetchRegulationDetails()
  }, [])
    const [simulationLab,setSimulationLab]=useState([])
  const [electricMachieneLab,setElectricMachine]=useState([])
  const [electricSimulation,setElectricSimulation]=useState([])
  const [powerStationLab,setPowerStationLab]=useState([])
  const labTypesEEE = ["Simulation Lab", "ElectricMachines Lab", "ElectricSimulation Lab", "PowerSystemLab Lab",]

  return (
    <div className="containe">
      <PageHeader name="EEE - COURSE STRUCTURE" />
      <div className="course_structure_cnt animate__animated animate__zoomIn">
        <div className="course text-center">
          <h4>Course Structure for all Years</h4>
          <p>
            Download the Course Structure and Syllabus of four years of CSE
            course
          </p>
        </div>

        <div className="w-100 text-center d-flex justify-content-center gap-5 ">
        <table className="studentDataTable" >
          <thead>
            <tr>
              <th>S.No</th>
              <th>Regulation</th>
              <th>Level</th>
              <th>Degree</th>
              <th>Batch</th>
              <th>Branch</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {regulationDetails && regulationDetails.length > 0 && regulationDetails.map((items, index) => {
              console.log("items===>", items)
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{items.regulation}</td>
                  <td>{items.level}</td>
                  <td>{items.degree}</td>
                  <td>{items.batch}</td>
                  <td>{items.branch}</td>
                  <td><RemoveRedEyeOutlinedIcon onClick={() => { setFileView(true); setFilepath(items.filePath) }}></RemoveRedEyeOutlinedIcon></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        </div>
        <React.Fragment>
        <Dialog
          fullScreen
          open={fileView}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
              maxHeight: '100vh',
            },
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

            </Toolbar>
          </AppBar>
          {filepath && (
            <iframe
              src={`data:application/pdf;base64,${filepath}`}
              width="100%"
              height="100%"
            />
          )}
        </Dialog>
      </React.Fragment>
      </div>
    </div>
  );
};
const Events = () => {
  const getIcon1 = () => {
    return <FluentShifts30Minutes24Filled />;
  };

  const getIcon2 = () => {
    return <UitCircuit />;
  };

  const getIcon3 = () => {
    return <StreamlineBugAntivirusDebuggingSolid />;
  };

  const getIcon4 = () => {
    return <CoPresentIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const getIcon5 = () => {
    return <MdiPresentation />;
  };

  const getIcon6 = () => {
    return <QuizIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const title1 = "Just-A-Minute";
  const desc1 =
    "is an all round-fun event that is all about the control of the mind over the mouth.";

  const title2 = "Instant Circuit Designing";
  const desc2 =
    "is a Competative event that all about the designing of Circuits Instantly.";

  const title3 = "Circuit Debugging";
  const desc3 = "Circuit Debugging is a Competative Event";

  const title4 = "Seminars";
  const desc4 =
    "Students give the demonistration on Various Latest Trends in Industry.";

  const title5 = "Poster Presentation";
  const desc5 =
    "is a way to share your knowledge of topic in a short format. It usually includes two elements - a poster and a brief explanation.";

  const title6 = "Quiz";
  const desc6 =
    "A quiz is a game or competition in which someone tests your knowledge by asking you questions";

  const IconCard = ({ icon, title, desc }) => {
    return (
      <>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            width: 320,
            // height: 205,
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
            },
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 60 }}>
            {icon()}
          </AspectRatio>
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {title}
            </Typography>
            <p className="desc">{desc}</p>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <div className="container">
      <PageHeader name="ACTIVITIES & EVENTS" />
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="course text-center animate__animated animate__fadeInRight">
          <h4>Student Development Activities</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            VYDHUSHYA is a NATIONAL LEVEL TECHNICAL SYMPOSIUM ,Entitled and
            organized by the department of EEE Most of us are aware of the
            common Paper Presentation conferences and competitions where in
            students present their research or review papers. but with a twist,
            i.e. the students have to present their research work in the form of
            a power point presentation consisting of just 5 slides! And the
            slides should contain the basic concept of the technology,
            methodology, calculations/assessment, conclusions and references.
          </p>
        </div>
        <div className="row mb-5">
          <div className="col-lg-4">
            <IconCard icon={getIcon1} title={title1} desc={desc1} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon2} title={title2} desc={desc2} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon3} title={title3} desc={desc3} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <IconCard icon={getIcon4} title={title4} desc={desc4} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon5} title={title5} desc={desc5} />
          </div>
          <div className="col-lg-4">
            <IconCard icon={getIcon6} title={title6} desc={desc6} />
          </div>
        </div>
      </div>

      <h4 className="text-center m-5">Events Photos</h4>
      <div className="d-flex justify-content-center gap-5">
        <div>
          <img src={event1} className="img-fluid rounded" alt="eeeEvents" />
        </div>
        <div>
          <img src={event2} className="img-fluid rounded" alt="eeeEvents" />
        </div>
        <div>
          <img src={event3} className="img-fluid rounded" alt="eeeEvents" />
        </div>
      </div>
    </div>
  );
};

const Obe = () => {
  return (
    <div className="container">
      <PageHeader name="PEOs/POs/PSOs/COs" />
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM EDUCATIONAL OBJECTIVES(PEO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PEO 1:</h2>
              <p>
                To acquire the essential knowledge of basic sciences and
                fundamentals in engineering and analyze technical solutions to
                computational problems by developing the algorithms.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 2:</h2>
              <p>
                To apply knowledge in the identification, design, development,
                production, configuration and maintenance of computing systems
                for real life problems.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 3:</h2>
              <p>
                To develop managerial skills, meeting societal needs,
                leadership, entrepreneurial skills, sustainable competitive edge
                in R&D and adhering professional and ethical skills.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM OUTCOMES(PO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PO 1:</h2>
              <p>
                Engineering Knowledge: Apply the knowledge of mathematics,
                science, engineering fundamentals, and an engineering
                specialization to the solution of complex engineering problems.
              </p>
            </div>
            <div class="peo">
              <h2>PO 2:</h2>
              <p>
                Problem Analysis: Identify, formulate, review research
                literature, and analyze complex engineering problems reaching
                substantiated conclusions using first principles of mathematics,
                natural sciences, and engineering sciences.
              </p>
            </div>
            <div class="peo">
              <h2>PO 3:</h2>
              <p>
                Design/Development of solutions: Design solutions for complex
                engineering problems and design system components or processes
                that meet the specified needs with appropriate consideration for
                the public health and safety, and the cultural, societal, and
                environmental considerations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 4:</h2>
              <p>
                Conduct Investigations of Complex problems: Use research-based
                knowledge and research methods including design of experiments,
                analysis and interpretation of data, and synthesis of the
                information to provide valid conclusions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 5:</h2>
              <p>
                Modern Tool Usage: Create, select, and apply appropriate
                techniques, resources, and modern engineering and IT tools
                including prediction and modeling to complex engineering
                activities with an understanding of the limitations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 6:</h2>
              <p>
                The Engineer and Society: Apply reasoning informed by the
                contextual knowledge to assess societal, health, safety, legal
                and cultural issues and the consequent responsibilities relevant
                to the professional engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 7:</h2>
              <p>
                Environment and Sustainability: Understand the impact of the
                professional engineering solutions in societal and environmental
                contexts, and demonstrate the knowledge of, and need for
                sustainable development.
              </p>
            </div>
            <div class="peo">
              <h2>PO 8:</h2>
              <p>
                Ethics: Apply ethical principles and commit to professional
                ethics and responsibilities and norms of the engineering
                practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 9:</h2>
              <p>
                Individual and Team Work: Function effectively as an individual,
                and as a member or leader in diverse teams, and in
                multidisciplinary settings.
              </p>
            </div>
            <div class="peo">
              <h2>PO 10:</h2>
              <p>
                Communication: Communicate effectively on complex engineering
                activities with the engineering community and with society at
                large, such as, being able to comprehend and write effective
                reports and design documentation, make effective presentations,
                and give and receive clear instructions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 11:</h2>
              <p>
                Project Management and Finance: Demonstrate knowledge and
                understanding of the engineering and management principles and
                apply these to one’s own work, as a member and leader in a team,
                to manage projects and in multidisciplinary environments.
              </p>
            </div>
            <div class="peo">
              <h2>PO 12:</h2>
              <p>
                Life-Long Learning: Recognize the need for, and have the
                preparation and ability to engage in independent and life-long
                learning in the broadest context of technological change.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM SPECIFIC OUTCOMES (PSO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PSO1:</h2>
              <p>
                Foundation of mathematical concepts: To use mathematical
                methodologies to crack problem using suitable mathematical
                analysis, data structure and suitable algorithm.
              </p>
            </div>
            <div class="peo">
              <h2>PSO2:</h2>
              <p>
                Foundation of Computer System: The ability to interpret the
                fundamental concepts and methodology of computer systems.
                Students can understand the functionality of hardware and
                software aspects of computer systems.
              </p>
            </div>
            <div class="peo">
              <h2>PSO3:</h2>
              <p>
                Foundations of Software development: The ability to grasp the
                software development lifecycle and methodologies of software
                systems. Possess competent skills and knowledge of software
                design process. Familiarity and practical proficiency with a
                broad area of programming concepts and provide new ideas and
                innovations towards research.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          COURSE STRUCTURE (CO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <ul class="course_outcomes_cnt">
              <li>
                <a
                  href="https://drive.google.com/file/d/1V6Gm7_dgEDg5pLsNIcC2qJ3D9dEStktW/view"
                  target="_blank"
                >
                  R18
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1OUBgkOW_5Ditxj1kSNp65zELEWp-qwpA/view"
                  target="_blank"
                >
                  R22
                </a>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const AboutDepartment = () => {
  return (
    <div>
      <div className="container">
        <PageHeader name="ELECTRICAL AND ELECTRONICS ENGINEERING" />
        <div className="container-xxl">
          <div className="container-xxl py-5">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{ minHeight: "100px" }}
                >
                  <div className="position-relative h-100 animate__animated animate__zoomIn">
                  <h6 style={{textAlign:"center",backgroundColor:"lightgreen"}}>HEAD OF THE DEPARTMENT -
ELECTRICAL AND ELECTRONICS ENGINEERING</h6>
                    <img
                      className="img-fluid rounded w-100 h-80"
                      src={csefaculty}
                      alt="csefaculty-img"
                      style={{ objectFit: "cover" }}
                    />
                     <div className="img_content">
                      <p style={{textAlign: "center"}}>Mr. K RAMA KRISHNA PRASAD<br/>
HOD & Associate Professor<br/>
ramakrishna908@gmail.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                  <h6 className="section-title bg-white text-start text-primary pe-3">
                    HOD
                  </h6>
                  <h6 className="mb-4">ELECTRICAL AND ELECTRONICS ENGINEERING</h6>

                  <div className="row gy-2 gx-4 mb-4">
                    <p style={{textAlign: "justify"}}>
                      Electrical and Electronics Engineering degree is a
                      stepping stone to a wide range of careers ranging from
                      computer science to business. SSIT prepares sound ground
                      to equip the students to this creative engineering streak.
                      <p>
                        The EEE department technical association provides
                        opportunities to the students to give weekly seminars on
                        current topics of interest, Guest lectures are also
                        arranged in the Association activities. The Association
                        conducts competitions in technical paper presentations,
                        working models exhibition, technical Quiz etc., and
                        gives awards & prizes.
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card_cntr1">
            <div
              className="boxes col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="boxes1 service-item text-center pt-3">
                <div className="p-4">
                  <h5 className="mb-1">Vision</h5>
                  <p style={{textAlign: "justify"}}>
                    To be a pioneering department in preparing students to
                    compete globally in their profession, to reach the highest
                    levels of intellectual attainment, making significant
                    contributions to society.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="boxes col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="boxes1 service-item text-center pt-3">
                <div className="p-4">
                  <h5 className="mb-3">Mission</h5>
                  <p style={{ fontSize: "14px", textAlign: "justify"}}>
                    Department of EEE will strive continuously to be the center
                    of excellence with focus on advanced technology, to prepare
                    the students for successful career based on a strong moral
                    and ethical foundations, to advance the state – of – art in
                    electrical and electronics Engineering, to train and educate
                    students as Global Citizens to become entrepreneurs in their
                    chosen field.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="boxes col-lg-3 text-center col-sm-6 wow "
              data-wow-delay="0.5s"
            >
              <div
                className="boxes text-center pt-3 pb-5"
                style={{ backgroundColor: "#f6f6f6", height: "fit-content" }}
              >
                <div className="p-4 ">
                  <h5 className="mb-3">Goals</h5>
                  <div>
                    <Accordion
                      sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ backgroundColor: "#fff" }}
                      >
                        Industrial Developement
                      </AccordionSummary>
                      <AccordionDetails
                        align="center"
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                          textAlign: "justify"
                        }}
                      >
                        it is held that all societies undergo changes from
                        traditional, transitional and modern stages of
                        development. It stands for rapid industrialization,
                        technological advancement, modernization, full
                        employment and continuous process of liberalization of
                        society, economy and polity.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        Training Programs
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                          textAlign: "justify",
                        }}
                      >
                        The program is an amalgam of skill training education
                        that aims at making our students interview ready in
                        every way by development of applied knowledge well
                        thought out integration of technical skills and academic
                        knowledge put into practical use in projects.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        Training & Placements
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                          textAlign: "justify",
                        }}
                      >
                        Training & placement was created as a way to provide
                        best in class training to our students. The aim was to
                        develop specific skills in people so they can achieve
                        their goals and meet their objectives.
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        Research & Development
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: "#fff5dc",
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Arial",
                          marginBottom: "10px",
                          letterSpacing: "1px",
                          textAlign: "justify",
                        }}
                      >
                        R&D is focused on applied research for the benefit of
                        power sector along with product/instrumentation
                        development in our department.
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EEE = () => {
  const [value, setValue] = React.useState(0);
  const [departmentData , setDepartmentData] = useState([])
  const handleGeteeeDepartment = async () => {
    try {
      const res = await axios.get(
        resources.APPLICATION_URL + "getstudentdata"+"EEE"
      );
      if (res.data) {
       setDepartmentData(res.data)
       
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect (()=>{
    handleGeteeeDepartment();
  },[]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "35px" }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="About Department" {...a11yProps(0)} />
            <Tab label="Laboratories" {...a11yProps(1)} />
            <Tab label="Course Structure" {...a11yProps(2)} />
            <Tab label="Activities & Events" {...a11yProps(3)} />
            <Tab label="PEOs/POs/PSOs/COs" {...a11yProps(4)} />
           
            <Tab label="Faculty" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AboutDepartment />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Labs />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CourseStructure />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Events />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Obe />
        </CustomTabPanel>
        
        <CustomTabPanel value={value} index={5}>
          <Faculty />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default EEE;

import React from "react";
import "./AictefeedbackView.css"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import StudentFeedbackView from "./StudentFeedbackView";
import FacultyFeedbackView from "./FacultyFeedbackView";

const AicteFeedbackView = () => {
  const [value, setValue] = React.useState('student');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div class="aicte_main_container">
      <div className="aicte_heading_container">
      <h1 class="aicte_heading">AICTE FEEDBACK VIEW</h1>
      </div>
      <Box sx={{ width: '100%', display:'flex',justifyContent:'center'}}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="student" label="Student" />
        <Tab value="faculty" label="Faculty" />
      </Tabs>
    </Box>
    {value === 'student' && <StudentFeedbackView/>}
    {value === 'faculty' && <FacultyFeedbackView/>}
    </div>
  );
};

export default AicteFeedbackView;

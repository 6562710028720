import react, { useState, useEffect } from "react"
import "./BranchMenu.css"
import axios from "axios";
const ExamGrievanceInfo = () => {
    const [viewType, setViewType] = useState("")
    return (
        <div>
            <div class="container text-left">
                <p>The following mechanisms are in place for redressal of
                    examination related grievances of students and to ensure transparency.</p>
                <h1>RE-TOTALING, REVALUATION & REAPPEARANCE FOR BACKLOGS</h1>
            </div>
            <div class="container text-left">
                <ul>
                    <li>Re-totaling :
                        Re totaling of the theory answer scripts of the end-semester examinations is permitted on request, on payment of the
                        prescribed fee within seven days from the date of announcement of the
                        result.</li>
                    <li>Revaluation :
                        Revaluation of the theory answer scripts of the end-semester examinations is also permitted on request, on payment of the prescribed
                        fee within seven days from the date of announcement
                        of the results.</li>
                    <p>*For UG Programs **Only for UG Programs with Single Valuation</p>
                    <li>

                        <ul>
                            <li>Reappearance for Backlogs :</li>
                            <li>
                                A student who has secured �F� grade in a theory course shall have to reappear at the subsequent
                                Semester / Trimester - end examinations
                                held for that course to improve the grade.
                            </li>
                            <li>
                                A student who has secured �F� grade in a practical course shall have to attend special
                                Instruction classes held during summer to improve the grade.

                            </li>
                            <li>

                                A student who has secured �F� grade in Project work / Industrial Training
                                etc shall have to improve his / her report and reappear for Viva � voce at
                                the time of special examinations to be conducted in the summer vacation.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="container text-left">
                <h1>Answer Script Verification & Challenge Valuation</h1>
                <p>A provision for Answer Book Verification & Challenge
                    Valuation was given on the following conditions.</p>
            </div>
            <div class="container text-left">
                <ul>
                    <li>
                        UG Programs :
                        The verification is allowed only after announcement of revaluation results in case of UG programs.
                    </li>

                    <ul>
                        <li>
                            If the student is not satisfied with the marks awarded in revaluation, they can apply for Answer Book verification on payment of prescribed fee for each paper
                            (Course) within one week after announcement of Revaluation results.
                        </li>
                        <li>
                            If the student is not satisfied with the marks awarded after Answer Script Verification (i.e. Revaluation marks), they can apply for Challenge Valuation on payment of prescribed fee for each paper
                            (Course) within two weeks from the date of Answer Book Verification.

                        </li>
                    </ul>
                    <li>
                        PG Programs :

                    </li>
                    <p>The verification is allowed only after announcement of results in case of PG programs.</p>
                    <ul>
                        <li>

                            If the student is not satisfied after announcement of results, they can apply for Answer Book verification on payment of prescribed
                            fee for each paper (Course) within one week after announcement of results.
                        </li>
                        <li>

                            If the student is not satisfied with the marks awarded after verification of Answer Script (i.e. Announcement of results), they can apply for Challenge Valuation on payment of prescribed fee for each paper (Course)
                            within two weeks from the date of Answer Book Verification.
                        </li>
                    </ul>
                </ul>
                <p>
                    The students can apply online by paying the prescribed fee and submit the application (Hard copy) for each course to the Directorate of Evaluation.
                </p>
            </div>
        </div>
    )
}
export default ExamGrievanceInfo;
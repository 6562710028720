import axios from "axios"
import react, { useState } from "react"
import { resources } from "../Resourses/Resources"
import { EditRounded } from "@mui/icons-material"
import Cookies from "js-cookie"
import "./facultyScreens.css"
const FacultyUploadPressNotes = () => {

    const userDetailsCookie = Cookies.get("userDetails");

    const userDetails = JSON.parse(userDetailsCookie);
    const userName = userDetails.username
    const departMent = userDetails.department
    const userEmail = userDetails.email
    const [selectedFiles, setSelectedFiles] = useState([])
    const [details, setDetails] = useState({
        submitName: "",
        paperName: "",
        description: "",
        printedDate: "",
        departMent: departMent,
        facultyName: userName,
        facultyEmail: userEmail
    })
    const handleUploadImages = async (e) => {
        const files = e.target.files
        console.log("selected File===>", files)
        setSelectedFiles(files)
    }
    console.log("selected Files===>", selectedFiles)
    const handleSubmit = async () => {
        console.log("details===>", details)
        const formData = new FormData()
        formData.append("pressNotesDetails", new Blob([JSON.stringify(details)], { type: "application/json" }))
        for (let i = 0; i <= (selectedFiles.length) - 1; i++) {
            formData.append("pressNotesFiles", selectedFiles[i])
            console.log("triggered===>", i)
        }
        const detailsToSend = await axios.post(resources.APPLICATION_URL + "savePressNotesImages", formData)
        console.log("response after submission===>", detailsToSend)
        if (detailsToSend.status === 200) {
            setDetails({
                submitName: "",
                paperName: "",
                description: "",
                printedDate: "",
                departMent: departMent,
                facultyName: userName,
                facultyEmail: userEmail
            })
            setSelectedFiles([])
            document.getElementById("imagesFiles").value = ""

            alert("Successfully uploaded data")
        }
        else {
            alert("Something went wrong")
        }

    }
    return (
        <div className="uploadContainer">
            <p className="headerTagStyle">Press Notifications</p>
            <div className="uploadDivs">
                <div className="uploadLabelAndInput">
                    <label>Submit Name</label>
                    <input type="text" value={details.submitName} onChange={(e) => setDetails({ ...details, submitName: e.target.value })} />
                </div>
                <div className="uploadLabelAndInput">
                    <label>Paper Name</label>
                    <input type="text" value={details.paperName} onChange={(e) => setDetails({ ...details, paperName: e.target.value })} />
                </div>
                <div className="uploadLabelAndInput">
                    <label>Select Images</label>
                    <input type="file" id="imagesFiles" multiple onChange={(e) => handleUploadImages(e)} />
                </div>

                <div className="uploadLabelAndInput">
                    <label>Description</label>
                    <textarea value={details.description} onChange={(e) => setDetails({ ...details, description: e.target.value })} />
                </div>
                <div className="uploadLabelAndInput">
                    <label>Printed Date</label>
                    <input type="date" value={details.printedDate} onChange={(e) => setDetails({ ...details, printedDate: e.target.value })} />
                </div>
            </div>
            <div className="uploadDivButton">
                <button onClick={() => handleSubmit()}>Submit</button>
            </div>
        </div>
    )
}
export default FacultyUploadPressNotes;
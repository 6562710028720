import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { useHistory, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
const errorMessage = (message) => {
  toast.error(message, {
    //  position: toast.POSITION.TOP_CENTER,
    toastId: "error1",
    className: "error",
  });
};
const successMessage = (message) => {
  toast.success(message, {
    // position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
    className: "success",
  });
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleChangeLogin = (e) => {
    e.preventDefault();
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };
  const handleLogin = async (e) => {
    e.preventDefault();
   

    console.log("login ==>", login);
    try {
      const response = await axios.post(
        resources.AUTHORIZE_URL + "signin",
        login
      );
      console.log("response ==>", response.data.roles[0]);
      const details = response.data
      if (details.roles[0] === "ROLE_FACULTY" || details.roles[0] === "ROLE_HOD") {
        successMessage("Login Successful");
        Cookies.set("userDetails", JSON.stringify(response.data));
        navigate("/facultyUploads");
        
      }
      else if(details.roles[0] === "ROLE_ADMIN"){
        successMessage("Login Successful");
        Cookies.set("userDetails", JSON.stringify(response.data));
        navigate("/admin/DashboardView");
      }
      else if (details.roles[0] === "ROLE_EXAMBRANCH" || details.roles[0] === "ROLE_OFFICE") {
        successMessage("Login Successful");
        Cookies.set("userDetails", JSON.stringify(response.data));

        navigate("/gallery/General/notifications");
      } else if (details.roles[0] === "ROLE_STUDENT") {
        successMessage("Login Successful");
        Cookies.set("userDetails", JSON.stringify(response.data));

        navigate("/gallery/studentDashboard/view");
      }
      else if (details.roles[0] === "ROLE_COMMITTEE") {
        successMessage("Login Successful");
        Cookies.set("userDetails", JSON.stringify(response.data));

        navigate("/governance/committee");
      }
      else {
        successMessage("Login Successful");
        Cookies.set("userDetails", JSON.stringify(response.data));
        navigate("/");
      }
    } catch (error) {
      if (error.response || error.response.status === 400 || error.response.data) {
        // Handle validation errors here
        console.log("Validation errors:", error.response.data);
        errorMessage(error.response.data.message);
      } else {
        console.error("API request failed:", error);
        errorMessage(error.response.data.message);
      }
    }
  };

  return (
    <div className="container">
      <div className="login-root">
        <div
          className="box-root flex-flex flex-direction--column"
          style={{ minHeight: "fit-content", flexGrow: 1 }}
        >
          <div
            className="box-root padding-top--24 flex-flex flex-direction--column"
            style={{ flexGrow: 1, zIndex: 9 }}
          >
            <div className="box-root padding-top--48 padding-bottom--24 flex-flex flex-justifyContent--center">
              <h1>
                <a href="http://blog.stackfindover.com/" rel="dofollow">
                  LOGIN
                </a>
              </h1>
            </div>
            <div className="formbg-outer">
              <div className="formbg">
                <div className="formbg-inner padding-horizontal--48">
                  <span className="padding-bottom--15">
                    Sign in to your account
                  </span>
                  <form id="stripe-login">
                    <div className="field padding-bottom--24">
                      <label htmlFor="userName">User Name</label>
                      <input
                        type="text"
                        name="username"
                        value={login.username}
                        onChange={(e) => handleChangeLogin(e)}
                      />
                    </div>
                    <div className="field padding-bottom--24">
                      <div className="grid--50-50">
                        <label htmlFor="password">Password</label>
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={login.password}
                        onChange={(e) => handleChangeLogin(e)}
                      />
                    </div>
                    <div className="field field-checkbox padding-bottom--24 flex-flex align-center">
                      <label htmlFor="checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={togglePasswordVisibility}
                        />{" "}
                        Show Password
                      </label>
                    </div>
                    <div className="field padding-bottom--24">
                      <input
                        type="submit"
                        name="submit"
                        value="Login"
                        onClick={handleLogin}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

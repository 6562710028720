import React, { useEffect, useState } from 'react';
import "./ViewStudents.css";
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { resources } from '../Resourses/Resources';

const ViewStudents = () => {
    const [alumniData, setAlumniData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const handleAlumniStudentData = async () => {
        
        try {
            const res = await axios.get(resources.APPLICATION_URL + "getPersonalInfo");
            if (res.data) {
                setAlumniData(res.data);
                console.log(JSON.stringify(res.data));
            } else {
                alert("No Data Found");
            }
        } catch (e) {
            console.log("error==>", e);
        }
    };

    useEffect(() => {
        handleAlumniStudentData();
    }, []);

    const filteredAlumniData = alumniData.filter((alumni) =>
        Object.values(alumni).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <div>
            <h1 className='sudent_head'>ALUMNI STUDENTS</h1>
            <div className='search_container'>
                <input
                    type='search'
                    className='search'
                    placeholder='Search...'
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <SearchIcon />
            </div>
            <div className='student_table'>
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Gender</th>
                            <th>Address</th>
                            <th>Qualification</th>
                            <th>Prof Type</th>
                            <th>Prof Details</th>
                            <th>Achievements</th>
                            <th>H.Education</th>
                            <th>H.Education Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAlumniData.map((alumni, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{alumni.aluminiName}</td>
                                <td>{alumni.gender}</td>
                                <td>
                                    {alumni.personalCity},
                                    <br />
                                    {alumni.personalState},
                                    {alumni.countryName},
                                    <br />
                                    {alumni.personalEmail},
                                    {alumni.personalPhone}.
                                </td>
                                <td>
                                    {alumni.educationalDegree},
                                    {alumni.educationalBranch},
                                    <br />
                                    {alumni.passedout},
                                    <br />
                                    {alumni.htno}
                                </td>
                                <td>{alumni.employementType}</td>
                                <td>
                                    {alumni.presentempName},
                                    <br />
                                    {alumni.employementDesignation},
                                    {alumni.employementCity},
                                    <br />
                                    {alumni.employementState},
                                    {alumni.employementCountryName}
                                </td>
                                <td>{alumni.specialAchievements}</td>
                                <td>{alumni.presentStatus}</td>
                                <td>
                                    {alumni.university}, {alumni.higherCity}
                                    <br />
                                    {alumni.higherState}, {alumni.higerCountry}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ViewStudents;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { resources } from "../Resourses/Resources";
import "./committeeMembers.css"
const CommitteeMembersAdd = () => {
    const [details, setDetails] = useState({
        committeeName: "",
        cordinator: "",
        email: "",
        phoneNumber: "",

    })
    const [committeeDetails, setCommitteeDetails] = useState([])
    const [selectedFile, setSelectedFile] = useState([])
    const handleSelectFile = async (e) => {
        const file = e.target.files[0]
        setSelectedFile(file)
    }
    useEffect(() => {
        const fetchCommitteeDetails = async () => {
            try {
                const response = await axios.get(resources.APPLICATION_URL + "getAallCommitteeDetails")
                setCommitteeDetails(response.data)
            } catch (error) {
                console.log("error")
            }
        }
        fetchCommitteeDetails()
    }, [])
    const handleSubmit = async () => {
        console.log("details to submit===>", details)
        const formData = new FormData();
        try {
            formData.append("committeeDetails", new Blob([JSON.stringify(details)], { type: "application/json" }))

            // formData.append("committeeDetails", new Blob([JSON.stringify(details), { type: "application/json" }]))
            formData.append("committeeFile", selectedFile)
            const response = await axios.post(resources.APPLICATION_URL + "saveCommitteeDetails", formData)
            if (response.status === 200) {
                alert("Successfully uploaded details")
            } else {
                alert("Something went wrong")
            }
        } catch (error) {
            console.log("error")
        }
    }
    return (
        <div className="committeeDiv">
            <div className="committMainConatiner">
                <div className="inputContainer">
                    <div className="inputDiv">
                        <label>Committee name</label>
                        <input type="text" value={details.committeeName} onChange={(e) => setDetails({ ...details, committeeName: e.target.value })} />

                    </div>
                    <div className="inputDiv">
                        <label>Cordinator </label>
                        <input type="text" value={details.cordinator} onChange={(e) => setDetails({ ...details, cordinator: e.target.value })} />

                    </div>
                    <div className="inputDiv">
                        <label>Email </label>
                        <input type="text" value={details.email} onChange={(e) => setDetails({ ...details, email: e.target.value })} />

                    </div>
                    <div className="inputDiv"  >
                        <label>Phone Number </label>
                        <input type="text" value={details.phoneNumber} onChange={(e) => setDetails({ ...details, phoneNumber: e.target.value })} />

                    </div>
                    <div className="inputDiv">
                        <label>Select File</label>
                        <input type="file" onChange={(e) => handleSelectFile(e)} />

                    </div>
                    <div className="inputDiv">
                        <button onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </div>




            </div>
            <div className="committMainConatiner">
                <table className="tableStylings">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Committee Name</th>
                            <th>Cordinator</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {committeeDetails && committeeDetails.length > 0 && committeeDetails.map((items, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{items.committeeName}</td>
                                    <td>{items.cordinator}</td>
                                    <td>{items.email}</td>
                                    <td>{items.phoneNumber}</td>
                                    <td><a href={items.filePath}>Click</a></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CommitteeMembersAdd;

import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import carousel1 from "../../assets/images/SS-collageImage.jpg";
import "./Home.css";
import chairman from "../../assets/chairman1.jpg";
import secretary from "../../assets/Secretary.jpg";
import cse from "../../assets/cse .jpg";
import ece from "../../assets/ece.jpg";
import mech from "../../assets/mech.jpg";
import eee from "../../assets/eee.jpg";
import civil from "../../assets/civil.jpg";
import aidimg from "../../assets/aidc.jpg";
import ptechimg from "../../assets/aidimg.jpg";
import mba from "../../assets/course-1.jpg";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
import principal from "../../assets/Principal.jpg";
import mou1 from "../../assets/MOUs/MISSION10X.jpg";
import mou2 from "../../assets/MOUs/Cisco_logo.png";
import mou3 from "../../assets/MOUs/Oracle-Academy-Logo.jpg";
import mou4 from "../../assets/MOUs/TASK_LOGO.png";
import mou5 from "../../assets/MOUs/campus_connect_logo.png";
import mou6 from "../../assets/MOUs/eswecha_logo.png";
import mou7 from "../../assets/MOUs/microsoft-metro-tall.png";
import mou8 from "../../assets/MOUs/wipro_logo .png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { FluentHatGraduationSparkle28Filled } from "../../assets/icons/svgs";
import { MdiAccountGraduationOutline } from "../../assets/icons/svgs";
import PolicyIcon from "@mui/icons-material/Policy";
import "aos/dist/aos.css";
import AOS from "aos";
import Carousel from 'react-bootstrap/Carousel';

// carousal images

import carousal_img1 from "../../assets/carousal_images/women'sday.jpeg";
import carousal_img2 from "../../assets/carousal_images/InteractionwithChairman.jpeg";
import carousal_img3 from "../../assets/carousal_images/Alumni.JPG";
import carousal_img4 from "../../assets/carousal_images/Alumni2.JPG";
import carousal_img5 from "../../assets/carousal_images/lab.JPG";
// import carousal_img6 from "../../assets/carousal_images/library.JPG"
import carousal_img7 from "../../assets/carousal_images/Placement.JPG";
import carousal_img8 from "../../assets/carousal_images/Sankranthi.JPG";
import carousal_img9 from "../../assets/carousal_images/SaraswathiStatue.JPG";
import carousal_img10 from "../../assets/carousal_images/Temple.JPG";

// management images
import chairman_img from "../../assets/Management/chairman.jpg";
import principal_img from "../../assets/sai spurthi principal.jpg";

import secretary_img from "../../assets/Management/secretary.jpg";
import cse_img from "../../assets/Trustee-2.png";
import NotificationPannel from "../NotificationPannel/NotificationPannel";
import aiml from "../../assets/aimlimg.jpg";
import aid from "../../assets/images.jpg";
// import eceimgclassroom from "../../assets/ececlass.jpg";
import eeeclassroom from "../../assets/eeeclassroom.jpg";
import ececlass from "../../assets/ececlass.jpg";
import sh from "../../assets/s&himg.jpg";
import { useState } from 'react'
import { resources } from "../Resourses/Resources";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  let currentYear;
  const date = new Date();
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  if (month < 5) {
    currentYear = Number(year - 1) + "-" + "" + year
  }
  else {
    currentYear = year + "-" + "" + Number(year + 1)
  }
  const [courseDetails, setCourseDetails] = useState([])
  const [diplomaCourse, setDiplomaCourse] = useState([])
  const [graduateCourse, setGraduateCourse] = useState([])
  const [pgCourses, setPgCourse] = useState([])
  const [allImages, setAllImages] = useState([])

  // const currentYear = date.getFullYear()
  useEffect(() => {
    const fetchCourseDetails = async () => {
      if (currentYear != null && currentYear != "") {
        try {
          const response = await axios.get(resources.APPLICATION_URL + `getAllCourseDetailsByAcademicYear?academicYear=${currentYear}`)
          setCourseDetails(response.data)
          const details = response.data
          const diploa = details.filter((items) => items.level === "Diploma")
          if (details.length > 0) {
            const graduation = details.filter((items) => items.level === "Graduation")
            const pgGraduation = details.filter((items) => items.level === "Post Graduation")
            setDiplomaCourse(diploa)
            setGraduateCourse(graduation)
            setPgCourse(pgGraduation)

          }

        } catch (error) {
          if (error) {
            console.log("error===>", error)
          }
        }
      }
    }
    fetchCourseDetails()
  }, [currentYear])

  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 300, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 200, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 450, // offset (in px) from the original trigger point
      // delay: 100, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  let carousal_images = [
    {
      image: carousal_img9,
    },
    {
      image: carousal_img1,
    },
    {
      image: carousal_img2,
    },
    {
      image: carousal_img3,
    },
    {
      image: carousal_img4,
    },
    {
      image: carousal_img5,
    },
    // {
    //   image: carousal_img6,
    // },
    {
      image: carousal_img7,
    },
    {
      image: carousal_img8,
    },

    {
      image: carousal_img10,
    },
  ];
  let management_images = [
    {
      image: chairman_img,
      position: "Chairman",
      name: "Dr. B. Pardha Saradhi Reddy",
    },
    {
      image: principal_img,
      position: "Principal",
      name: "Dr. V.S.R Kumari",
    },
    {
      image: secretary_img,
      position: "Secretary",
      name: "Sri D. Prabhakar Reddy",
    },
    {
      image: cse_img,
      position: "Trustee",
      name: "Smt. Bandi Anvida",
    },
  ];
  useEffect(() => {
    if (currentYear !== null & currentYear !== "") {
      const fetchAllImages = async () => {
        try {
          const details = await axios.get(resources.APPLICATION_URL + `get/lab/imagesByYear?academicYear=${currentYear}&department=Management`)
          // setAllImages(details.data)
          // console.log("Carousel images===?>", JSON.stringify(details.data));
          const allImg = details?.data[0]?.filePath.split(",");
          setAllImages(allImg);
          console.log("Carousel images===?>", allImg);
        } catch (error) {
          console.log("error===>", error)
        }

      }
      fetchAllImages()
    }
  }, [currentYear])


  return (
    <div className="App">
      <div className="carousal_bg_cnt">
      <Slider {...settings}>
      {allImages?.map((img, id) => {
            return (
              <div key={id}>
                <div className="card-body">
                <img
                className="campous_imgs"
                src={`data:image/png;base64,${img}`}
                alt={`Image ${id + 1}`}
              />
                </div>
              </div>
            );
          })}
        </Slider>


      </div>

      <div className="container">
        <div className="card_cntr2">
          <div className="boxes col-lg-3 col-sm-6 fade-in-up">
            <div className="boxes1 service-item text-center pt-3">
              <div className="p-4">
                {/* <i className="fa fa-3x fa-graduation-cap text-primary mb-4"></i> */}
                <RemoveRedEyeIcon sx={{ fontSize: 80, color: "#0d6efd" }} />
                <h5 className="mb-3">Vision</h5>
                <p style={{ textAlign: "justify" }}>
                  To make the students achieve highest standards of quality
                  technical education adaptable to suit the present day
                  requirements in the fields of science and technology and to
                  mould them morally into persons of character and integrity.
                </p>
              </div>
            </div>
          </div>
          <div className="boxes col-lg-3 col-sm-6 " data-wow-delay="0.3s">
            <div className="boxes1 service-item text-center pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                <h5 className="mb-3">Mission</h5>
                <p style={{ textAlign: "justify" }}>
                  To awaken the students to the reality of identifying their
                  hidden potentials and talents through meticulous and
                  systematic grooming to gain the spirit and inventiveness to
                  build a career full of glorious prospects and eventful future.
                </p>
              </div>
            </div>
          </div>
          <div className="boxes col-lg-3 col-sm-6 " data-wow-delay="0.5s">
            <div className="boxes1 service-item text-center pt-3">
              <div className="p-4">
                {/* <i className="fa fa-3x fa-home text-primary mb-4"></i> */}
                <PolicyIcon sx={{ fontSize: 80, color: "#0d6efd" }} />
                <h5 className="mb-3">Quality Policy</h5>
                <p style={{ textAlign: "justify" }}>
                  To provide an industry need education through high quality
                  knowledge transfer with excellent skills at par with
                  international standards to get good placements.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1 className="course_offerd">COURSES OFFERED</h1>
        </div>

        <div className="card_cntr2">
          <div
            className="boxes col-lg-4 col-sm-6 wow animate__animated animate_fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="boxes1 service-item text-center pt-3">
              <div className="p-1">
                <FluentHatGraduationSparkle28Filled
                  sx={{ fontSize: 80, color: "#0d6efd" }}
                />
                {/* <i className="fa fa-3x fa-graduation-cap text-primary mb-4"></i> */}
                <h5 className="mb-2">Diploma Programs </h5>
                {diplomaCourse && diplomaCourse.length > 0 && diplomaCourse.map((items, index) => {
                  return (
                    <div key={index}>
                      <p>{items.branch}: {items.intakeCount}</p>

                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div
            className="boxes col-lg-4 col-sm-6 wow animate__animated animate_fadeInUp"
            data-wow-delay="0.3s"
          >
            <div className="boxes1 service-item text-center pt-3">
              <div className="p-1">
                {/* <i className="fa fa-3x fa-globe text-primary mb-4"></i> */}
                <FluentHatGraduationSparkle28Filled
                  sx={{ fontSize: 80, color: "#0d6efd" }}
                />
                <h5 className="mb-2">Under Graduation Programmes</h5>
                {graduateCourse && graduateCourse.length > 0 && graduateCourse.map((items, index) => {
                  return (
                    <div key={index}>
                      <p>{items.branch}: {items.intakeCount}</p>

                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div
            className="boxes col-lg-4 col-sm-6 wow animate__animated animate_fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="boxes1 service-item text-center pt-3">
              <div className="p-1">
                {/* <i className="fa fa-3x fa-home text-primary mb-4"></i> */}
                <MdiAccountGraduationOutline />
                <h5 className="mb-2">Post Graduation Programmes</h5>
                {pgCourses && pgCourses.length > 0 && pgCourses.map((items, index) => {
                  return (
                    <div key={index}>
                      <p>{items.branch}: {items.intakeCount}</p>

                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div>
        <h1 className="course_offerd">NOTIFICATIONS</h1>
      </div>
      <NotificationPannel />

      <div
        className="container-fluid py-5 mou_cnt_bg w-100"
        data-aos="zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1000"
      >
        <div className="container-fluid">
          <div className="row g-5">
            <div className="col-lg-12">
              <h6 className="text-start text-white pe-3">OUR MOUs</h6>
              <h1 className="mb-4 heading">Memorandum Of Understanding</h1>
              {/* <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Microsoft Windows
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    CISCO Networking Academy
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    TASK(Telangana Academy for Skill and Knowledge)
                  </p>
                </div>
                <br />
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Wipro
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    ESwetcha
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Campus Connect
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Oracle Academy
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Mission 10X
                  </p>
                </div>
              </div> */}
            </div>
            <div className="col-lg-12">
              <div className="position-relative h-100">
                <marquee behavior="scroll" direction="left" scrollamount="15">
                  <div className="d-flex flex-row align-items-center gap-5">
                    <div className="w-100 mou_img_cnt">
                      <img src={mou1} alt="mou1" className="mou_img" />
                    </div>
                    <div className="w-100 mou_img_cnt">
                      <img src={mou2} alt="mou1" className="mou_img" />
                    </div>
                    <div className="w-100 mou_img_cnt">
                      <img src={mou3} alt="mou1" className="mou_img" />
                    </div>
                    <div className="w-100 mou_img_cnt">
                      <img src={mou4} alt="mou1" className="mou_img" />
                    </div>
                    <div className="w-100 mou_img_cnt">
                      <img src={mou5} alt="mou1" className="mou_img" />
                    </div>
                    <div className="w-100 mou_img_cnt">
                      <img src={mou6} alt="mou1" className="mou_img" />
                    </div>
                    <div className="w-100 mou_img_cnt">
                      <img src={mou7} alt="mou1" className="mou_img" />
                    </div>
                    <div className="w-100 mou_img_cnt">
                      <img src={mou8} alt="mou1" className="mou_img" />
                    </div>
                  </div>
                </marquee>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5 category">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              some programs
            </h6>
            <h1 className="mb-5">Programs Offering</h1>
          </div>
          <div className="row g-3">
            <div className="col-lg-12 col-md-12">
              <div className="row g-3">
                <div
                  className="col-lg-4 col-md-12"
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-easing="ease-out"
                  data-aos-duration="800"
                >
                  <Link
                    className="position-relative d-block overflow-hidden"
                    to=""
                  >
                    <img className="img-fluid" src={cse} alt="cse-img" />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">CSE</h5>
                      <small className="text-primary">4 Courses</small>
                    </div>
                  </Link>
                </div>
                <div
                  className="col-lg-4 col-md-12"
                // data-aos="zoom-in"
                // data-aos-offset="200"
                // data-aos-easing="ease-in-sine"
                // data-aos-duration="500"
                >
                  <Link
                    className="position-relative d-block overflow-hidden"
                    to=""
                  >
                    <img
                      className="img-fluid w-100"
                      style={{ height: "280px" }}
                      src={aiml}
                      alt="civil-img"
                    />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">CSE(AIML)</h5>
                      {/* <small className="text-primary">49 Courses</small> */}
                    </div>
                  </Link>
                </div>
                <div
                  className="col-lg-4 col-md-12"
                  data-aos="fade-left"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="500"
                >
                  <Link
                    className="position-relative d-block overflow-hidden"
                    to=""
                  >
                    <img
                      className="img-fluid w-100"
                      style={{ height: "280px" }}
                      src={aidimg}
                      alt="civil-img"
                    />
                    {/* <img className="img-fluid" src={aidimg} alt="aid-img" /> */}
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">AID</h5>
                      {/* <small className="text-primary">49 Courses</small> */}
                    </div>
                  </Link>
                </div>
                <div
                  className="col-lg-4 col-md-12"
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="500"
                >
                  <Link
                    className="position-relative d-block overflow-hidden"
                    to=""
                  >
                    <img className="img-fluid" src={eee} alt="eee-img" />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">EEE</h5>
                      {/* <small className="text-primary">49 Courses</small> */}
                    </div>
                  </Link>
                </div>
                <div
                  className="col-lg-4 col-md-12"
                // data-aos="zoom-in"
                // data-aos-offset="200"
                // data-aos-easing="ease-in-sine"
                // data-aos-duration="500"
                >
                  <Link
                    className="position-relative d-block overflow-hidden"
                    to=""
                  >
                    <img className="img-fluid" src={ececlass} alt="ece-img" />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">ECE</h5>
                      {/* <small className="text-primary">49 Courses</small> */}
                    </div>
                  </Link>
                </div>
                <div
                  className="col-lg-4 col-md-12"
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="500"
                >
                  <Link
                    className="position-relative d-block overflow-hidden"
                    to=""
                  >
                    <img className="img-fluid" src={mech} alt="mba-img" />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">MECHANICAL</h5>
                      {/* <small className="text-primary">49 Courses</small> */}
                    </div>
                  </Link>
                </div>

                <div className="col-lg-6 col-md-12">
                  <Link
                    className="position-relative d-block overflow-hidden"
                    to=""
                  >
                    <img className="img-fluid" src={mba} alt="ece-img" />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">MBA</h5>
                      {/* <small className="text-primary">49 Courses</small> */}
                    </div>
                  </Link>
                </div>
                <div
                  className="col-lg-6 col-md-12"
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="500"
                >
                  <Link
                    className="position-relative d-block overflow-hidden"
                    to=""
                  >
                    <img
                      className="img-fluid"
                      src={ptechimg}
                      alt="mba-img"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "420px",
                      }}
                    />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">Polytechnic</h5>
                      {/* <small className="text-primary">49 Courses</small> */}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* <div
              className="col-lg-5 col-md-6 wow zoomIn"
              data-wow-delay="0.7s"
              style={{ minHeight: "350px" }}
            >
              <Link
                className="position-relative d-block h-100 overflow-hidden"
                to=""
              >
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src={cat4}
                  alt="cat-4-img"
                  style={{ objectFit: "cover" }}
                />
                <div
                  className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                  style={{ margin: "1px" }}
                >
                  <h5 className="m-0">Online Marketing</h5>
                  <small className="text-primary">49 Courses</small>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Instructors
            </h6>
            <h1 className="mb-5">Expert Instructors</h1>
          </div>
          <div className="row g-4">
            {management_images.map((item, index) => {
              return (
                <div
                  className="col-lg-3 col-md-6 "
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-delay={`${index}00`}
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="800"
                  key={index}
                >
                  <div className="team-item bg-light img_cnt">
                    <div className="overflow-hidden">
                      <img
                        className="img-fluid img"
                        src={item.image}
                        alt="management-img"
                      />
                    </div>
                    <div className="text-center p-4">
                      <h5 className="mb-0">{item.name}</h5>
                      <small>{item.position.toUpperCase()}</small>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-lg-3 col-md-6 wow">
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid img"
                    src={chairman}
                    alt="chairman-img"
                  />
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">NAME OF CHAIRMAN</h5>
                  <small>CHAIRMAN</small>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow">
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid img"
                    src={chairman}
                    alt="chairman2-img"
                  />
                </div>
                <div
                  className="position-relative d-flex justify-content-center"
                  style={{ marginTop: "-23px" }}
                ></div>
                <div className="text-center p-4">
                  <h5 className="mb-0">NAME OF CHAIRMAN</h5>
                  <small>CHAIRMAN</small>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow">
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid img"
                    src={principal}
                    alt="principal-img"
                  />
                </div>
                <div
                  className="position-relative d-flex justify-content-center"
                  style={{ marginTop: "-23px" }}
                ></div>
                <div className="text-center p-4">
                  <h5 className="mb-0">NAME OF PRINCIPAL</h5>
                  <small>PRINCIPAL</small>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow" data-wow-delay="0.7s">
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid img"
                    src={secretary}
                    alt="secretary-img"
                  />
                </div>

                <div className="text-center p-4">
                  <h5 className="mb-0">NAME OF SECRETARY</h5>
                  <small>SECRETARY</small>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

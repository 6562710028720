import React from "react";

import { useEffect, useState } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import RotatingSpinner from "../Resourses/RotatingSpinner";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HeaderAdmissionForm from "./HeaderAdmissionForm";
import "./AcadamicCalender.css";
const successMessage = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
    className: "success",
  });
};
const errorMessage = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "error1",
    className: "error",
  });
};

const AdmissionForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataFiles, setDataFiles] = useState({});
  const [dropDownValues, setDropDownValues] = useState([]);
  const [prefValue1, setPrefValue1] = useState("");
  const [prefValue2, setPrefValue2] = useState("");
  const [uploadValue, setUploadValue] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");

  const [dropDownValues2, setDropDownValues2] = useState([]);
  const [dropDownValues3, setDropDownValues3] = useState([]);
  const [dropDownValues4, setDropDownValues4] = useState([]);
  const [dropDownValues5, setDropDownValues5] = useState([]);
  const [dropDownValues6, setDropDownValues6] = useState([]);

  const [open, setOpen] = React.useState(false);

  const uploadDropDown = {
    migrationCertificateUpload: "Migration Certificate",
    aadharCardUpload: "Aadhar Card",
    jeeMainsHtUpoad: "JEE Mains Hallticket",
    jeeMainsRankUpload: "JEE Mains Rank Card",
    eamcetRankUpload: "EAMCET Rank Card",
    emcetHtUpload: "EAMCET Hallticket",
    casteUpload: "Caste Certificate",
    sixtwelveUpload: "6th-12th Study Conducts",
    tcUpload: "Tc (Transfer Certificate)",
    InterUpload: "Mark Sheet of Intermediate or Equivalent Certificate",
    sscUpload: "SSC or Equivalent Certificate",
    photoUpload: "Upload Photo",
  };

  console.log("prefValue1", prefValue1);
  console.log("dropDownValues", dropDownValues);
  console.log("prefValue2", prefValue2);
  console.log("dropDownValues", dropDownValues);

  const [admissionFormDetails, setAdmissionFormDetails] = useState({
    course: "",
    nameOfCandidate: "",
    nameOfFather: "",
    dateOfBirth: "",
    nationalityAndReligion: "",
    gender: "",
    category: "",
    subCategory: "",
    correspondingAddress: "",
    permanentAddress: "",
    phoneNumber: "",
    email: "",
    jeeHallTicketNumber: "",
    jeeRankNumber: "",
    eamcetHallTicketNumber: "",
    eamcetRankNumber: "",
    diploma: "",
    inter: "",
    yearOfPass: "",
    reference1: "",
    reference2: "",
    reference3: "",
    reference4: "",
    reference5: "",
    reference6: "",
    aadharNumber: "",
  });

  const branchesList = [
    "COMPUTER SCIENCE AND ENGINEERING",
    "COMPUTER SCIENCE AND MACHINE LEARNING",
    "ARTIFICIAL INTELLIGENCE & DATA SCIENCE",
    "ELECTRICAL AND ELECTRONIC ENGINEERING",
    "ELECTRICAL AND COMMUNICATION ENGINEERING",
    "MECHANICAL ENGINEERING",
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDropDownValues1 = (e) => {
    const remaingVal = branchesList.filter((each) => each !== e.target.value);
    setDropDownValues2(remaingVal);
    setDropDownValues3([]);
    setDropDownValues4([]);
    setDropDownValues5([]);
    setDropDownValues6([]);
  };
  const handleDropDownValues2 = (e) => {
    setDropDownValues3(
      dropDownValues2.filter((each) => each != e.target.value)
    );
    setDropDownValues4([]);
    setDropDownValues5([]);
    setDropDownValues6([]);
  };
  const handleDropDownValues3 = (e) => {
    setDropDownValues4(
      dropDownValues3.filter((each) => each != e.target.value)
    );
    setDropDownValues5([]);
    setDropDownValues6([]);
  };

  const handleDropDownValues4 = (e) => {
    setDropDownValues5(
      dropDownValues4.filter((each) => each != e.target.value)
    );
    setDropDownValues6([]);
  };

  const handleDropDownValues5 = (e) => {
    setDropDownValues6(
      dropDownValues5.filter((each) => each != e.target.value)
    );
  };

  // const handleDropDownValues6 = (e) => {
  //   setDropDownValues((prev) => [
  //     ...prev.filter((each) => each !== e.target.value),
  //   ]);
  // };

  function jsonBlob(obj) {
    return new Blob([JSON.stringify(obj)], {
      type: "application/json",
    });
  }

  const uppercaseRegex = /^[A-Z\s]+$/;

  const isValidate = (value) => {
    if (uppercaseRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  const handleAdmissionFormDetails = (e) => {
    const { value, name } = e.target;
    if (name === "nameOfCandidate" || name === "nameOfFather") {
      if (isValidate(value) === true) {
        setAdmissionFormDetails({ ...admissionFormDetails, [name]: value });
      } else {
        setAdmissionFormDetails({ ...admissionFormDetails, [name]: "" });
      }
    } else {
      setAdmissionFormDetails({ ...admissionFormDetails, [name]: value });
    }
  };

  const handleAdmissionFilesUpload = (e) => {
    // const { Uploadfile } = e.target.files[0];
    const { name } = e.target;
    // console.log("file===>", Uploadfile);
    console.log("file", e.target.files[0]);
    setDataFiles(e.target.files[0]);
  };

  const handlesubmitAdmissionForm = async (e) => {
    e.preventDefault();
    console.log(admissionFormDetails);
    // handleClickOpen();
    // course, emailid,aadhar not empty
    // const formData = new FormData(admissionFormDetails.aadharNumber);
    if (
      admissionFormDetails.length !== 0 &&
      admissionFormDetails.aadharNumber !== "" &&
      admissionFormDetails.course !== "" &&
      admissionFormDetails.email !== ""
    ) {
      // formData.append("file", jsonBlob(dataFiles));
      // formData.append("normalData", jsonBlob(admissionFormDetails));
    } else {
      alert("Please fill all inputs");
    }
    setIsLoading(true);
    try {
      const res = await axios.post(
        resources.APPLICATION_URL + `save/admissionform?`,
        admissionFormDetails
      );
      if (res.data) {
        toast.success("Data Uploaded Successfully");
        handleClickOpen();
        setAdmissionFormDetails({
          course: "",
          nameOfCandidate: "",
          nameOfFather: "",
          dateOfBirth: "",
          nationalityAndReligion: "",
          gender: "",
          category: "",
          subCategory: "",
          correspondingAddress: "",
          permanentAddress: "",
          phoneNumber: "",
          email: "",
          jeeHallTicketNumber: "",
          jeeRankNumber: "",
          eamcetHallTicketNumber: "",
          eamcetRankNumber: "",
          diploma: "",
          inter: "",
          yearOfPass: "",
          reference1: "",
          referance2: "",
          reference3: "",
          reference4: "",
          reference5: "",
          reference6: "",
          aadharNumber: "",
        });
      } else {
        toast.error("Something went wrong. Please try again later");
        alert("Something went wrong. Please try again later");
      }
    } catch (e) {
    
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      if (dataFiles.length !== 0) {
        formData.append("fileType", dataFiles);
        await axios
          .post(
            resources.APPLICATION_URL +
              `save/uploads?aadharNumber=${aadharNumber}&typeUpload=${uploadValue}`,
            formData
          )
          .then((res) => {
            console.log("res", res);
            alert("Uploaded Successfully");
          })
          .catch((err) => {
            console.log("err", err);
            alert("Something went wrong. Please try again later");
          });
      } else {
        alert("Please select file");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <RotatingSpinner />
      ) : (
        <form
          className="admission_main"
          onSubmit={(e) => handlesubmitAdmissionForm(e)}
        >
          <HeaderAdmissionForm />
          <hr />
          <h1 className="admission_heading" style={{ alignText: "center" }}>
            Registration Form
          </h1>
          <hr />
          <div style={{ display: "flex" }}>
            <ol
              className="admission_form_container"
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Name Of The Course</label>
                  <select
                    name="course"
                    value={admissionFormDetails.course}
                    onChange={(e) => handleAdmissionFormDetails(e)}
                  >
                    <option value="">Please select</option>
                    <option value="Btech">Btech</option>
                    <option value="Mtech">Mtech</option>
                    <option value="Diploma">Diploma</option>
                  </select>
                </div>
              </li>
              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    Name of the Candidate
                    <br />
                    (As per SSC in Block Letters)
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.nameOfCandidate}
                    name="nameOfCandidate"
                    id="nameOfCandidate"
                    placeholder="Name of the Candidate"
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    Name of the father/guardian
                    <br />
                    (As per SSC in Block Letters)
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.nameOfFather.toUpperCase()}
                    name="nameOfFather"
                    id="nameOfFather"
                    placeholder="Name of the Father"
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>
              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="dateOfBirth" className="admission_label">
                    Date of birth
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.dateOfBirth}
                    name="dateOfBirth"
                    id="dateOfBirth"
                    type="date"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Gender</label>
                  <div style={{ display: "flex" }}>
                    <span className="span_eleGender">Male</span>
                    <input
                      onChange={(e) => handleAdmissionFormDetails(e)}
                      value="Male"
                      id="gender"
                      type="radio"
                      name="gender"
                      style={{ width: "15px" }}
                    />
                    <span className="span_eleGender">Female</span>
                    <input
                      onChange={(e) => handleAdmissionFormDetails(e)}
                      value="Female"
                      id="gender"
                      type="radio"
                      name="gender"
                      style={{ width: "15px" }}
                    />
                    <span className="span_eleGender">others</span>
                    <input
                      onChange={(e) => handleAdmissionFormDetails(e)}
                      value="Others"
                      id="gender"
                      type="radio"
                      name="gender"
                      style={{ width: "15px" }}
                    />
                  </div>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="phone" className="admission_label">
                    {" "}
                    Phone Number
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    id="phone"
                    name="phoneNumber"
                    value={admissionFormDetails.phoneNumber}
                    type="number"
                    className="admission_inp"
                  />
                </div>
              </li>
              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="email" className="admission_label">
                    Email ID
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    id="email"
                    name="email"
                    type="email"
                    value={admissionFormDetails.email}
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="nationality" className="admission_label">
                    Nationality & Religion
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.nationalityAndReligion}
                    id="nationality"
                    name="nationalityAndReligion"
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Category</label>
                  <select
                    name="category"
                    value={admissionFormDetails.category}
                    onChange={(e) => handleAdmissionFormDetails(e)}
                  >
                    <option value="">Please select</option>
                    <option value="General">General</option>
                    <option value="OBC">OBC</option>
                    <option value="SC">SC</option>
                    <option value="ST">ST</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </li>
              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="subCategory" className="admission_label">
                    Sub Category
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.subCategory}
                    id="subCategory"
                    name="subCategory"
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="permanent" className="admission_label">
                    {" "}
                    Address for Correspondence
                  </label>
                  <textarea
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.addressForCorrespondence}
                    name="correspondingAddress"
                    cols={30}
                    rows={2}
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label htmlFor="permanent" className="admission_label">
                    Permanent address
                  </label>
                  <textarea
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    value={admissionFormDetails.permanentAddress}
                    name="permanentAddress"
                    cols={30}
                    rows={2}
                    type="text"
                    className="admission_inp"
                  />
                </div>
              </li>
            </ol>

            <ol
              className="admission_form_container"
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    JEE-Mains rank(All India Rank)-2022
                  </label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          padding: "10px",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        H.T.No
                      </span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        value={admissionFormDetails.jeeHallTicketNumber}
                        name="jeeHallTicketNumber"
                        type="text"
                        style={{
                          width: "220px",
                          height: "30px",
                          maxWidth: "220px",
                          maxHeight: "30px",
                          margin: "0px",
                        }}
                      />
                    </div>
                    <hr style={{ margin: "5px" }} />
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          padding: "10px",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        Rank
                      </span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        value={admissionFormDetails.jeeRankNumber}
                        name="jeeRankNumber"
                        type="number"
                        style={{
                          width: "220px",
                          height: "30px",
                          maxWidth: "220px",
                          maxHeight: "30px",
                        }}
                      />
                    </div>
                    {/* <hr style={{ margin: "5px" }} /> */}
                  </div>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">EAMCET rank-2023</label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          padding: "10px",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        H.T.No
                      </span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        name="eamcetHallTicketNumber"
                        value={admissionFormDetails.eamcetHallTicketNumber}
                        type="text"
                        style={{
                          width: "220px",
                          height: "30px",
                          maxWidth: "220px",
                          maxHeight: "30px",
                        }}
                      />
                    </div>
                    <hr style={{ margin: "5px" }} />
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          padding: "10px",
                          fontSize: "15px",
                            fontWeight: "600",
                          minWidth:"70px"
                        }}
                      >
                        Rank
                      </span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        name="eamcetRankNumber"
                        value={admissionFormDetails.eamcetRankNumber}
                        type="number"
                        style={{
                          width: "220px",
                          height: "30px",
                          maxWidth: "220px",
                          maxHeight: "30px",
                        }}
                      />
                    </div>
                    {/* <hr style={{ margin: "5px" }} /> */}
                  </div>
                </div>
              </li>

             
              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    Month & Year of Passing of Qualifying Exam
                  </label>
                  <input
                    onChange={(e) => handleAdmissionFormDetails(e)}
                    name="yearOfPass"
                    value={admissionFormDetails.yearOfPass}
                    type="date"
                    className="admission_inp"
                  />
                </div>
              </li>
              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Aadhar Number</label>
                  <input
                    type="Number"
                    name="aadharNumber"
                    value={admissionFormDetails.aadharNumber}
                    onChange={(e) => {
                      handleAdmissionFormDetails(e);
                      setAadharNumber(e.target.value);
                    }}
                  />
                </div>
                </li>
                
                 <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">
                    Percentage of Marks in intermediate/qualifying exam
                  </label>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          padding: "5px",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        Diploma %
                      </span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        name="diploma"
                        value={admissionFormDetails.diploma}
                        type="text"
                        style={{
                          width: "220px",
                          height: "30px",
                          maxWidth: "220px",
                          maxHeight: "30px",
                        }}
                      />
                    </div>
                    <hr style={{ margin: "5px" }} />
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          padding: "10px",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        Inter marks
                      </span>
                      <input
                        onChange={(e) => handleAdmissionFormDetails(e)}
                        name="inter"
                        value={admissionFormDetails.inter}
                        type="Number"
                        style={{
                          width: "220px",
                          height: "30px",
                          maxWidth: "220px",
                          maxHeight: "30px",
                        }}
                      />
                    </div>
                  
                  </div>
                </div>
              </li>

            </ol>
          </div>
          <hr />
          <h1 className="admission_heading">
            Select the branch according to your preference
          </h1>
          <hr />
          <div style={{ display: "flex" }}>
            <ol
              className="admission_form_container"
              style={{ paddingTop: "0px", paddingBottom: "0px", width: "49%" }}
            >
              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Preference 1</label>
                  <select
                    name="reference1"
                    value={admissionFormDetails.reference1}
                    onChange={(e) => {
                      handleAdmissionFormDetails(e);
                      handleDropDownValues1(e);
                    }}
                  >
                    <option>Please Select...</option>
                    {branchesList.map((branch) => (
                      <option value={branch}>{branch}</option>
                    ))}
                  </select>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Preference 2</label>
                  <select
                    name="reference2"
                    value={admissionFormDetails.reference2}
                    onChange={(e) => {
                      handleAdmissionFormDetails(e);
                      handleDropDownValues2(e);
                    }}
                  >
                    <option>Please Select...</option>
                    {dropDownValues2.map((branch) => (
                      <option value={branch}>{branch}</option>
                    ))}
                  </select>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Preference 3</label>
                  <select
                    name="reference3"
                    value={admissionFormDetails.reference3}
                    onChange={(e) => {
                      handleAdmissionFormDetails(e);
                      handleDropDownValues3(e);
                    }}
                  >
                    <option>Please Select...</option>
                    {dropDownValues3.map((branch) => (
                      <option value={branch}>{branch}</option>
                    ))}
                  </select>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Preference 4</label>
                  <select
                    name="reference4"
                    value={admissionFormDetails.reference4}
                    onChange={(e) => {
                      handleAdmissionFormDetails(e);
                      handleDropDownValues4(e);
                    }}
                  >
                    <option>Please Select...</option>
                    {dropDownValues4.map((branch) => (
                      <option value={branch}>{branch}</option>
                    ))}
                  </select>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Preference 5</label>
                  <select
                    value={admissionFormDetails.reference5}
                    name="reference5"
                    onChange={(e) => {
                      handleAdmissionFormDetails(e);
                      handleDropDownValues5(e);
                    }}
                  >
                    <option>Please Select...</option>
                    {dropDownValues5.map((branch) => (
                      <option value={branch}>{branch}</option>
                    ))}
                  </select>
                </div>
              </li>

              <li>
                <div className="admission_form_inp_container">
                  <label className="admission_label">Preference 6</label>
                  <select
                    name="reference6"
                    value={admissionFormDetails.reference6}
                    onChange={(e) => {
                      handleAdmissionFormDetails(e);
                    }}
                  >
                    <option>Please Select...</option>
                    {dropDownValues6.map((branch) => (
                      <option value={branch}>{branch}</option>
                    ))}
                  </select>
                </div>
              </li>
            </ol>
          </div>
          <button
            className="submit_btn"
            type="submit"
            style={{ marginLeft: "20px" }}
          >
            Submit
          </button>
          <React.Fragment>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Please Upload all files before closing"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div style={{ display: "flex" }}>
                    <div className="admission_form_inp_container">
                      <select
                        className="admission_inp"
                        name="uploadDropDown"
                        value={uploadValue}
                        onChange={(e) => {
                          setUploadValue(e.target.value);
                        }}
                      >
                        <option value="">Please Select</option>
                        {/* {Object.keys(uploadDropDown).map((key) => (
                          <option key={key} value={key}>
                            {uploadDropDown[key]}
                          </option>
                        ))} */}

                        {Object.keys(uploadDropDown).map((each) => (
                          <option value={each}>{uploadDropDown[each]}</option>
                        ))}
                      </select>
                    </div>
                    <div className="admission_form_inp_container">
                      <input
                        onChange={(e) => handleAdmissionFilesUpload(e)}
                        id="UploadFile"
                        type="file"
                        className="admission_inp"
                        name="Uploadfile"
                      />
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ display: "flex" }}>
                <Button onClick={handleClose} sx={{ color: "red" }}>
                  Close
                </Button>
                {/* <Button onClick={() => handleUpload()}>Send</Button> */}
                <Button onClick={() => handleUpload()}>Send</Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </form>
      )}
    </>
  );
};

export default AdmissionForm;

import { useEffect, useState } from "react"
import * as React from 'react';

import "./facultyScreens.css"
import axios from "axios"
import Cookies from "js-cookie"
import { resources } from "../Resourses/Resources"
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
import PaginationReUsable from "../ReuseableComponent/ReusePagination";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ViewPressNotes = () => {
    const userDetailsCookie = Cookies.get("userDetails");
    const [pressNotesDetails, setPressnotesDetails] = useState([])
    const [selectedFile, setSelectedFile] = useState("")
    const [departMent, setDepartment] = useState("")
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = useState("");
    const [paginationData, setPaginationData] = useState([])
    const [allData, setAllData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [serialNo, setSerialNo] = useState("")
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (userDetailsCookie != null && userDetailsCookie != "") {
            const userDetails = JSON.parse(userDetailsCookie);
            const details = userDetails.department
            setDepartment(details)
        }
    }, [userDetailsCookie])
    console.log("department in view===>", departMent)
    const fetchPressNotes = async () => {
        try {
            const response = await axios.get(resources.APPLICATION_URL + `getPressNotesByDepartment?department=${departMent}`)
            console.log("response from bacend===>", response.data)
            setPressnotesDetails(response.data)
            setAllData(response.data)
            setFilteredData(response.data)
            setPaginationData(response.data)
        } catch (error) {
            console.log("error")
        }
    }

    useEffect(() => {
        if (departMent != null && departMent != "") {
            fetchPressNotes();
        }
    }, [departMent])
    const handleViewFile = async (filePath) => {
        setSelectedFile(filePath)
        handleClickOpen()
    };
    console.log("filePath===>", selectedFile);

    //deleteThePressNote
    const handleDelete = async (items) => {
        console.log("deletable record===>", items)
        const response = await axios.delete(resources.APPLICATION_URL + `deleteThePressNote?sno=${items.sno}`)
        console.log("response after deletig===>", response)
        if (response.status === 200) {
            alert("Sucessfully deleted the record")
            fetchPressNotes()
        } else {
            alert("Unable to delete the record")
        }
    }
    const handleSearch = (e) => {
        let { value } = e.target
        console.log("value", search)
        let list = allData.filter(each => each.submitName.toLowerCase().includes(value.toLowerCase()))
        console.log("list==>", list)
        setFilteredData(list)
        setSearch(value)
    }
    const handleGetSpliceData = (receivedList, startSNo) => {
        setPaginationData(receivedList)
        setSerialNo(startSNo);
    }
    const handleSearchByDate = async () => {
        console.log("from date===>", fromDate)
        console.log("toDate date===>", toDate)
        const details = allData.filter((items) => {
            const newDate = items.printedDate;
            if(newDate >= fromDate && newDate <= toDate){
                return items;
            }      
        })
        setFilteredData(details)
        console.log("details===>", details)
    }
    return (
        <div className="vewContainer">
            <div className="searchDiv">
                <div className="fromDateToDateDiv">
                    <div className="fromDateToDateInput">
                        <label>From Date</label>
                        <input type="date" placeholder="Issued by..." value={fromDate} onChange={(e) => setFromDate(e.target.value)} />

                    </div>
                    <div className="fromDateToDateInput">
                        <label>To Date</label>
                        <input type="date" placeholder="Issued by..." value={toDate} onChange={(e) => setToDate(e.target.value)} />
                        <button onClick={() => handleSearchByDate()}> View</button>
                    </div>
                </div>
                <input type="text" placeholder="Submitted by..." value={search} onChange={(e) => handleSearch(e)} />
            </div>

            <div className="tableDiv">
                <table className="tableStylings">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Submited By</th>
                            <th>Description</th>
                            <th>Printed Date</th>

                            <th>File View</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginationData && paginationData.length > 0 && paginationData.map((items, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{items.submitName}</td>
                                    <td>{items.description}</td>
                                    <td>{items.printedDate}</td>

                                    <td ><button onClick={() => handleViewFile(items.filePath)} className="viewButtons">View</button></td>
                                    <td><DeleteIcon onClick={() => handleDelete(items)} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <React.Fragment>
                    <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        PaperProps={{
                            style: {
                                width: '100%',
                                height: '100%',
                                margin: 0,
                                padding: 0,
                                maxHeight: '100vh',
                            },
                        }}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>

                            </Toolbar>
                        </AppBar>
                        {selectedFile && (
                            <iframe
                                src={`data:application/pdf;base64,${selectedFile}`}
                                width="100%"
                                height="100%"
                            />
                        )}
                    </Dialog>
                </React.Fragment>
                <PaginationReUsable paginationData={filteredData} handleGetSpliceData={handleGetSpliceData} />

            </div>

        </div>
    )
}
export default ViewPressNotes;
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo2r.png";
import "./Header.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import UGC from "../../assets/UGC.jpg";
import AICTE from "../../assets/AICTE.png";
import ISO from "../../assets/ISO.png";
import { resources } from "../Resourses/Resources";
import axios from "axios";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import BannerNew from '../../assets/Banner/BannerSaiSpurthiNew.png'
import MobileBanner from '../../assets/Banner/Untitled design (3).png'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Header = () => {
  const userDetailsCookie = Cookies.get("userDetails");
  const [departMent, setDepartment] = useState("")
  const navigate = useNavigate()
  const [viewFile, setViewFile] = useState(false)
  const [lastInserTedRecord, setLastInsertedRecord] = useState({})
  const [typeOfUload, setTypeOfUpload] = useState("")
  const [role, setRole] = useState("")

  const handleClickOpen = () => {
    setViewFile(true);
  };

  const handleClose = () => {
    setViewFile(false);
  };
  useEffect(() => {
    if (userDetailsCookie != null && userDetailsCookie != "") {
      const userDetails = JSON.parse(userDetailsCookie);
      const details = userDetails.roles[0]
      setDepartment(details)
      setRole(details)
      if (details === "ROLE_ADMIN") {
        setTypeOfUpload("General")
      } else if (details === "ROLE_EXAMBRANCH") {
        setTypeOfUpload("ExamBranch")

      }
    }
  }, [userDetailsCookie])
  console.log("department===>", departMent)
  const date = new Date;
  let currentMonth;
  let currentDate;
  const toDaysDate = date.getDate();
  const month = date.getMonth() + 1
  const currentYear = date.getFullYear()

  if (month < 10) {
    currentMonth = "0" + month
  } else {
    currentMonth = month
  }
  if (toDaysDate < 10) {
    currentDate = "0" + toDaysDate
  } else {
    currentDate = toDaysDate
  }
  const newDate = currentYear + "-" + currentMonth + "-" + currentDate


  const handleShowSelfDeclarationPdf = async (param) => {
    try {
      const response = await axios.get(
        resources.APPLICATION_URL + "filenames?filename=" + param + "&foldername=ssr"
      );
      console.log("response", response.data);
      const base64 = response.data;

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (e) {
      console.log("error", e);

    }
  };
  console.log("typeOfUload===>", typeOfUload)
  const location = useLocation();
  useEffect(() => {
    const fetchLasstRecord = async () => {
      try {
        if (typeOfUload !== null && typeOfUload !== "") {
          const response = await axios.get(resources.APPLICATION_URL + `getLastInsertedRecord?typeOfUpload=${typeOfUload}`)
          const details = response.data
          if (details.endDate >= newDate) {
            console.log("triggered")
            setLastInsertedRecord(response.data)

          } else {
            console.log("triggered")
          }
        }

      } catch (error) {
        console.log("error")
      }
    }
    fetchLasstRecord();
  }, [typeOfUload])
  const handleLogout = async () => {
    console.log("logout triggered")
    await Cookies.remove("userDetails")
    navigate("/")
    window.location.reload();
  }
  return (
    <div style={{ width: "100vw", zIndex: 100000 }}>
      <div className="col-sm-12 notification_banner_cnt">
        <marquee behavior="scroll" direction="left" scrollamount="10">
          <div className="d-flex align-items-center gap-3 notification_bg_cnt">
            <NewReleasesIcon
              className="heartbeat"
              sx={{ fontSize: 28, color: "yellow" }}
            />
            {lastInserTedRecord.endDate != null && lastInserTedRecord.endDate != "" ? <p className="notification_prg" style={{ cursor: "pointer" }} onClick={() => setViewFile(true)}>
              {lastInserTedRecord.description}
            </p> : <p>Welcome to Saispurthi New Notification Board</p>}
          </div>
        </marquee>
      </div>
      <div className="hm_header">
        <img src={BannerNew} alt="Banner Sai Spurthi" className="sai_banner_img" />
      </div>
      <div>
        <nav className="manu_bar menubar navbar navbar-expand-md navbar-light  p-0 navbar">
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="menu_inner navbar-nav p-4 p-lg-0">
              <Link
                to="/"
                className={
                  location.pathname === "/"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Home
              </Link>
              <Link
                to="/about"
                className={
                  location.pathname === "/about"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                About
              </Link>

              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/Administration")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Administration
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  {/* <Link to="/Administration/about" className="dropdown-item">
                  ABOUTUS
                </Link> */}
                  <Link
                    to="/Administration/management"
                    className={
                      location.pathname === "/Administration/management"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    MANAGEMENT
                  </Link>
                  <Link
                    to="/Administration/society"
                    className={
                      location.pathname === "/Administration/society"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    SOCIETY
                  </Link>
                  <Link
                    to="/Administration/vision"
                    className={
                      location.pathname === "/Administration/vision"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    VISSION & MISSION
                  </Link>
                  <Link
                    to="/Administration/quality"
                    className={
                      location.pathname === "/Administration/quality"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    QUALITY POLICY
                  </Link>
                  <button style={{ borderWidth: '0px', backgroundColor: 'transparent' }}>
                    <Link
                      className="dropdown-item" style={{ paddingLeft: "10px" }}

                      onClick={() => {
                        handleShowSelfDeclarationPdf("ogram.pdf");
                      }}
                    >
                      ORGANOGRAM
                    </Link>
                  </button>
                  <Link
                    to="/Administration/governing"
                    className={
                      location.pathname === "/Administration/governing"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    GOVERNING BODY
                  </Link>
                  <Link
                    to="/Administration/principal"
                    className={
                      location.pathname === "/Administration/principal"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    PRINCIPAL
                  </Link>
                  <Link
                    to="/Administration/council"
                    className={
                      location.pathname === "/Administration/council"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ACADAMIC COUNCIL
                  </Link>
                  <Link
                    to="/Administration/mandatory"
                    className={
                      location.pathname === "/Administration/mandatory"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    MANDATORY DISCLOSURE
                  </Link>
                  <Link
                    to="/Administration/strength"
                    className={
                      location.pathname === "/Administration/strength"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    STRENGTHS
                  </Link>
                </div>
              </div>

              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/acadamics")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Academics
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <Link
                    to="/acadamics/acadamicRegulations"
                    className={
                      location.pathname === "/acadamics/acadamicRegulations"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ACADEMIC REGULATIONS
                  </Link>
                  <Link
                    to="/acadamics/acadamicCalender"
                    className={
                      location.pathname === "/acadamics/acadamicCalender"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ACADEMIC CALENDER
                  </Link>
                  <Link
                    to="/acadamics/incentives"
                    className={
                      location.pathname === "/acadamics/incentives"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    INCENTIVES
                  </Link>
                  <Link
                    to="/acadamics/socialService"
                    className={
                      location.pathname === "/acadamics/socialService"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    SOCIAL SERVICE
                  </Link>
                </div>
              </div>

              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/departments")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Departments
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <Link
                    to="/departments/cse"
                    className={
                      location.pathname === "/departments/cse"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    CSE
                  </Link>

                  <Link
                    to="/departments/ece"
                    className={
                      location.pathname === "/departments/ece"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ECE
                  </Link>
                  <Link
                    to="/departments/eee"
                    className={
                      location.pathname === "/departments/eee"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    EEE
                  </Link>
                  <Link
                    to="/departments/ai&ds"
                    className={
                      location.pathname === "/departments/ai&ds"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    AI&DS
                  </Link>
                  <Link
                    to="/departments/aiml"
                    className={
                      location.pathname === "/departments/aiml"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    AIML
                  </Link>
                  <Link
                    to="/departments/mech"
                    className={
                      location.pathname === "/departments/mech"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    MECH
                  </Link>
                  <Link
                    to="/departments/s&h"
                    className={
                      location.pathname === "/departments/s&h"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    S&H
                  </Link>
                  <Link
                    to="/departments/mba"
                    className={
                      location.pathname === "/departments/mba"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    MBA
                  </Link>
                  <Link
                    to="/departments/polytechnic"
                    className={
                      location.pathname === "/departments/polytechnic"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    POLYTECHNIC
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/admissions")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Admissions
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <Link
                    to="/admissions/procedure"
                    className={
                      location.pathname === "/admissions/procedure"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ADMISSION PROCEDURE
                  </Link>
                  <Link
                    to="/admissions/fee"
                    className={
                      location.pathname === "/admissions/fee"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    FEE STRUCTURE
                  </Link>
                  <Link
                    to="/admissions/form"
                    className={
                      location.pathname === "/admissions/form"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ADMISSION FORM
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/governance")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Governance
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <Link
                    to="/governance/counselling"
                    className={
                      location.pathname === "/governance/counselling"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    COUNSELLING
                  </Link>
                  <Link
                    to="/governance/examination"
                    className={
                      location.pathname === "/governance/examination"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    EXAMINATION BRANCH
                  </Link>
                  <Link
                    to="/governance/office"
                    className={
                      location.pathname === "/governance/office"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ADMINISTRATIVE OFFICE
                  </Link>
                  <Link
                    to="/governance/policy"
                    className={
                      location.pathname === "/governance/policy"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    POLICIES
                  </Link>
                  <Link
                    to="/governance/uploadPhotosCommittee"
                    className={
                      location.pathname === "/governance/uploadPhotosCommittee"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    UPLOAD PHOTOS COMMITIES
                  </Link>
                  <Link
                    to="/governance/committee"
                    className={
                      location.pathname === "/governance/committee"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    COMMITTEES
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/amenities")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Amenities
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <Link
                    to="/amenities/library"
                    className={
                      location.pathname === "/amenities/library"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    LIBRARY
                  </Link>
                  <Link
                    to="/amenities/digitalLibrary"
                    className={
                      location.pathname === "/amenities/digitalLibrary"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    DIGITAL LIBRARY
                  </Link>
                  <Link
                    to="/amenities/internet"
                    className={
                      location.pathname === "/amenities/internet"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    INTERNET
                  </Link>
                  <Link
                    to="/amenities/englishLabs"
                    className={
                      location.pathname === "/amenities/englishLabs"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ENGLISH LABS
                  </Link>
                  <Link
                    to="/amenities/ictFecilities"
                    className={
                      location.pathname === "/amenities/ictFecilities"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ICT FECILITIES
                  </Link>
                  <Link
                    to="/amenities/seminarHalls"
                    className={
                      location.pathname === "/amenities/seminarHalls"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    SEMINAR HALLS
                  </Link>
                  <Link
                    to="/amenities/sports"
                    className={
                      location.pathname === "/amenities/sports"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    SPORTS & GAMES
                  </Link>
                  <Link
                    to="/amenities/cafeteria"
                    className={
                      location.pathname === "/amenities/cafeteria"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    CAFETERIA
                  </Link>
                  <Link
                    to="/amenities/hostels"
                    className={
                      location.pathname === "/amenities/hostels"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    HOSTELS
                  </Link>
                  <Link
                    to="/amenities/transport"
                    className={
                      location.pathname === "/amenities/transport"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    TRANSPORTATION
                  </Link>
                  <Link
                    to="/amenities/e-classRooms"
                    className={
                      location.pathname === "/amenities/e-classRooms"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    E-CLASS ROOMS
                  </Link>
                  <Link
                    to="/amenities/staffQuarters"
                    className={
                      location.pathname === "/amenities/staffQuarters"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    STAFF QUARTERS
                  </Link>
                  <Link
                    to="/amenities/temple"
                    className={
                      location.pathname === "/amenities/temple"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    TEMPLE IN COLLEGE
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/placements")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  placements
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <Link
                    to="/placements/placementRecords"
                    className="dropdown-item"
                  >
                    PLACEMENT RECORDS
                  </Link>
                  <Link
                    to="/placements/companies"
                    className={
                      location.pathname === "/placements/companies"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    COMPANIES
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <nav className="manu_bar menubar navbar navbar-expand-md navbar-light shadow p-0 ">
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="menu_inner navbar-nav p-4 p-lg-0">
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/naac")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  NAAC
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <Link
                    to="/naac/naacSsr"
                    className={
                      location.pathname === "/naac/naacSsr"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    NAAC SSR
                  </Link>
                  <Link
                    to="/naac/naacDvv"
                    className={
                      location.pathname === "/naac/naacDvv"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    NAAC DVV
                  </Link>
                  <Link
                    to="/naac/iqac"
                    className={
                      location.pathname === "/naac/iqac"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    IQAC
                  </Link>
                  <button
                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                    onClick={() => {
                      handleShowSelfDeclarationPdf("selfdeclaration");
                    }}
                  >
                    <Link style={{ paddingLeft: "10px" }} className="dropdown-item">SELF DECLARATION</Link>
                  </button>
                  <button
                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                    onClick={() => {
                      handleShowSelfDeclarationPdf("undertaking.pdf");
                    }}
                  >
                    <Link style={{ paddingLeft: "10px" }} className="dropdown-item">UNDERTAKING</Link>
                  </button>
                  <button
                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                    onClick={() => {
                      handleShowSelfDeclarationPdf("iiqareport.pdf");
                    }}
                  >
                    <Link className="dropdown-item" style={{ paddingLeft: "10px" }}>IIQA REPORT</Link>
                  </button>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/nirf")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  NIRF
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <button style={{ borderWidth: '0px', backgroundColor: 'transparent' }}>
                    <Link
                      className="dropdown-item" style={{ paddingLeft: "10px" }}

                      onClick={() => {
                        handleShowSelfDeclarationPdf("overall.pdf");
                      }}
                    >
                      NIRF-OVERALL
                    </Link>
                  </button>
                  <button style={{ borderWidth: '0px', backgroundColor: 'transparent' }}>
                    <Link
                      className="dropdown-item" style={{ paddingLeft: "10px" }}

                      onClick={() => {
                        handleShowSelfDeclarationPdf("engineering.pdf");
                      }}
                    >
                      NIRF-ENGINEERING
                    </Link>
                  </button>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/alumini")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Alumini
                </Link>
                <div className="dropdown-menu fade-down m-0">

                  <button style={{ borderWidth: '0px', backgroundColor: 'transparent' }}>
                    <Link
                      className="dropdown-item" style={{ paddingLeft: "10px" }}

                      onClick={() => {
                        handleShowSelfDeclarationPdf("inv_21_22.pdf");
                      }}
                    >
                      INVITATION -AY:21-22
                    </Link>
                  </button>
                  <Link
                    to="/alumini/registration"
                    className={
                      location.pathname === "/alumini/registration"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    REGISTRATION
                  </Link>
                  <Link
                    to="/alumini/view"
                    className={
                      location.pathname === "/alumini/view"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    VIEW STUDENTS
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/student")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Student Corner
                </Link>
                <div className="dropdown-menu fade-down m-0">

                  <button style={{ borderWidth: '0px', backgroundColor: 'transparent' }}>
                    <Link
                      className="dropdown-item" style={{ paddingLeft: "10px" }}

                      onClick={() => {
                        handleShowSelfDeclarationPdf("inv_23_24.pdf");
                      }}
                    >
                      STUDENT SCHOLARSHIP NOTOFICATION-AY:23-24
                    </Link>
                  </button>
                  <Link
                    to="/student/scholarshipRegistration"
                    className={
                      location.pathname === "/student/scholarshipRegistration"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    STUDENT SCHOLARSHIP REGISTRATION
                  </Link>
                  <Link
                    to="/student/socialService"
                    className={
                      location.pathname === "/student/socialService"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    SOCIAL SERVICE
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/feedback")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Greivences
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <Link
                    to="/feedback/enqiryform"
                    className={
                      location.pathname === "/feedback/enqiryform"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ENQUIRY FORM
                  </Link>
                  <Link
                    to="/feedback/greivences"
                    className={
                      location.pathname === "/feedback/greivences"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    GREVIENCES FORM
                  </Link>
                  <Link
                    to="/feedback/ic-committee"
                    className={
                      location.pathname === "/feedback/ic-committee"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    IC COMMITTEE
                  </Link>
                  <Link
                    to="/feedback/aicteFeedback"
                    className={
                      location.pathname === "/feedback/aicteFeedback"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    AICTE FEEDBACK
                  </Link>
                  <Link
                    to="/feedback/aicteFeedbackView"
                    className={
                      location.pathname === "/feedback/aicteFeedbackView"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    AICTE FEEDBACK VIEW
                  </Link>
                  <Link
                    to="/feedback/teacherFeedback"
                    className={
                      location.pathname === "/feedback/teacherFeedback"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    TEACHER FEEDBACK
                  </Link>
                  <Link
                    to="/feedback/studentFeedback"
                    className={
                      location.pathname === "/feedback/studentFeedback"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    STUDENT FEEDBACK
                  </Link>
                  <Link
                    to="/feedback/employerFeedback"
                    className={
                      location.pathname === "/feedback/employerFeedback"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    EMPLOYER FEEDBACK
                  </Link>
                  <Link
                    to="/feedback/aluminiFeedback"
                    className={
                      location.pathname === "/feedback/aluminiFeedback"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    ALUMINI FEEDBACK
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname.includes("/gallery")
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                >
                  Gallery
                </Link>
                <div className="dropdown-menu fade-down m-0">
                  <Link
                    to="/gallery/campusPhotos"
                    className={
                      location.pathname === "/gallery/campusPhotos"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    CAMPUS PHOTOS
                  </Link>
                  {/* <Link
                    to="/gallery/notifications"
                    className={
                      location.pathname === "/gallery/notifications"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    NOTIFICATIONS
                  </Link> */}

                  {/*<Link
                    to="/gallery/login"
                    className={
                      location.pathname === "/gallery/login"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    LOGIN
                  </Link>*/}



{role === "ROLE_ADMIN" && (
  <Link
                    to="/gallery/photosUpload"
                    className={
                      location.pathname === "/gallery/photosUpload"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    Photos Upload
                  </Link>)}
                  {/* <Link
                    to="/gallery/galleryView"
                    className={
                      location.pathname === "/gallery/galleryView"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    GALLERY VIEW
                  </Link>
                  <Link
                    to="/notificationupload"
                    className={
                      location.pathname === "/notificationupload"
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    Notification Upload
                  </Link> */}
                </div>
              </div>
              <Link
                to="/contactus"
                className={
                  location.pathname === "/contactus"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Contact
              </Link>
              {(departMent !== "ROLE_STUDENT" && departMent !== "") &&
                <div className="nav-item dropdown">
                  <Link
                    to="#"
                    className={
                      location.pathname.includes("/general")
                        ? "nav-link dropdown-toggle active"
                        : "nav-link dropdown-toggle"
                    }
                    data-bs-toggle="dropdown"
                  >
                    General
                  </Link>
                  <div className="dropdown-menu fade-down m-0">
                    {departMent === "ROLE_ADMIN" || departMent === "" ? <Link
                      to="/gallery/General/notifications"
                      className={
                        location.pathname === "/gallery/General/notifications"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      General Notifications
                    </Link> : <Link
                      to="/facultyUploads"
                      className={
                        location.pathname === "/facultyUploads"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      NOTIFICATIONS
                    </Link>}
                    <Link
                      to="/gallery/View/General/EnquiryForm"
                      className={
                        location.pathname === "/gallery/View/General/EnquiryForm"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      Enquiry Form
                    </Link>
                    <Link
                      to="/admin/feeStructure"
                      className={
                        location.pathname === "/admin/feeStructure"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      Fee Stucture Add
                    </Link>
                    <Link
                      to="/gallery/View/General/view/enquiryForm"
                      className={
                        location.pathname === "/gallery/View/General/view/enquiryForm"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      View Enquiry Form
                    </Link>
                    <Link
                      to="/gallery/View/General/courseUpload"
                      className={
                        location.pathname === "/gallery/View/General/courseUpload"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      Course Upload
                    </Link>
                    <Link
                      to="/gallery/View/General/carousel/photosUpload"
                      className={
                        location.pathname === "/gallery/View/General/carousel/photosUpload"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      Carousel Upload
                    </Link>
                    <Link
                      to="/gallery/View/General/uploadExcelFiles"
                      className={
                        location.pathname === "/gallery/View/General/uploadExcelFiles"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      Upload Excel Files
                    </Link>
                    <Link
                      to="/gallery/View/General/notifications"
                      className={
                        location.pathname === "/gallery/View/General/notifications"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      View General Notifications
                    </Link>
                    {departMent === "ROLE_ADMIN" &&
                      <Link
                        to="/gallery/Register"
                        className={
                          location.pathname === "/gallery/Register"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                      >
                        Register
                      </Link>
                    }
                     {departMent === "ROLE_ADMIN" &&
                      <Link
                        to="/admin/DashboardView"
                        className={
                          location.pathname === "/admin/DashboardView"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                      >
                        Dashboard View
                      </Link>
                    }
                    {/* <Link
                      to="/gallery/enquiryForm/view"
                      className={
                        location.pathname === "/gallery/enquiryForm/view"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      View EnquiryForm
                    </Link> */}
                  </div>

                </div>}
                
              {role === "ROLE_ADMIN" ? (<Link
                onClick={() => handleLogout()}
                className={
                  location.pathname === "/contactus"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Logout
              </Link>):
              (<Link
                to="/gallery/login"
                className={
                  location.pathname === "/gallery/Register"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >Login</Link>
              )}
              {role === "ROLE_ADMIN" && (
                <Link
                  to="/gallery/Register"
                  className={
                    location.pathname === "/gallery/Register"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                >
                  Register
                </Link>
              )}
            </div>
          </div>
        </nav>
      </div>
      <React.Fragment>
        <Dialog
          fullScreen
          open={viewFile}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
              maxHeight: '100vh',
            },
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

            </Toolbar>
          </AppBar>
          {lastInserTedRecord && (
            <iframe
              src={`data:application/pdf;base64,${lastInserTedRecord.filePath}`}
              width="100%"
              height="100%"
            />
          )}
        </Dialog>
      </React.Fragment>
    </div>
  );
};

export default Header;

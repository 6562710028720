import React from "react";
import mous from "../../assets/images/ss-mous.jpg";
import { Link } from "react-router-dom";

const VisionMision = () => {
  return (
    <div>
      <div className="card_cntr1">
        <div
          className="boxes col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-4">
              <i className="fa fa-3x fa-graduation-cap text-primary mb-4"></i>
              <h5 className="mb-3">Vision</h5>
              <p style={{textAlign:"justify"}}>
                To make the students achieve highest standards of quality
                technical education adaptable to suit the present day
                requirements in the fields of science and technology and to
                mould them morally into persons of character and integrity.
              </p>
            </div>
          </div>
        </div>
        <div
          className="boxes col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-4">
              <i className="fa fa-3x fa-globe text-primary mb-4"></i>
              <h5 className="mb-3">Mission</h5>
              <p style={{textAlign:"justify"}}>
                To awaken the students to the reality of identifying their
                hidden potentials and talents through meticulous and systematic
                grooming to gain the spirit and inventiveness to build a career
                full of glorious prospects and eventful future.
              </p>
            </div>
          </div>
        </div>
        <div
          className="boxes col-lg-3 col-sm-6 wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-4">
              <i className="fa fa-3x fa-home text-primary mb-4"></i>
              <h5 className="mb-3">Quality Policy</h5>
              <p style={{textAlign:"justify"}}>
                To provide an industry need education through high quality
                knowledge transfer with excellent skills at par with
                international standards to get good placements.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-130"
                  src={mous}
                  alt="about-img"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                OUR MOUs
              </h6>
              <h1 className="mb-4">Memorandum Of Understanding</h1>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Microsoft Windows
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    CISCO Networking Academy
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    TASK(Telangana Academy for Skill and Knowledge)
                  </p>
                </div>
                <br />
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Wipro
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    ESwetcha
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Campus Connect
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Oracle Academy
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Mission 10X
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default VisionMision;

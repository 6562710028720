import React from "react";
import { Link } from "react-router-dom";
import about from "../../assets/images/about-img.jpg";
import "./About.css";

const About = () => {
  return (
    <div>
      <div>
        {/* <h1 className="about_head">
          ABOUT US
        </h1> */}
        <div className="about_main">
          <div className="about_img_cntr">
            <img className="about_img" src={about} />
            <h1 className="cnt_head">ABOUT SSIT</h1>
          </div>
          <div className="about_contant">
            <p>
              The Sai Spurthi Institute of Technology(SSIT) was established in
              2001 by the Vipassana Educational Trust, Hyderabad. SSIT is
              located in Sathupalli , Khammam district , Telangana , India. It
              is located beside state highway (Khammam  Rajamundry), at
              distance of 7km (4.3mi) from Sathupalli towards Rajamundry. The
              college was affiliated to Jawaharlal Nehru Technological
              University, Hyderabadand Approved by AICTE, Delhi. The chairman of
              the college is Dr. B. Partha Sarathi Reddy, the CMD of Hetero
              Drugs, and the secretary is Sri D. Prabhakar Reddy. The motive
              behind establishing the college was to impart quality technical
              education to the rural people and to uplift the talent of
              economically backward students to greater levels.
            </p>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default About;

import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import "animate.css";

// Lab Images

import lab3 from "../../../assets/lab/cselab3-3.jpg";
import lab2 from "../../../assets/lab/cselab2-2.jpg";
import lab4 from "../../../assets/lab/cselab1-2.jpg";
import lab1 from "../../../assets/lab/cselab1-1.jpg";
import lab5 from "../../../assets/lab/cselab1-3.jpg";
import lab6 from "../../../assets/lab/cselab2-1.jpg";
import lab7 from "../../../assets/lab/cselab2-3.jpg";
import lab8 from "../../../assets/lab/cselab3-1.jpg";
import lab9 from "../../../assets/lab/cselab3-2.jpg";
import lab10 from "../../../assets/lab/cselab4-1.jpg";
import lab11 from "../../../assets/lab/cselab4-2.jpg";
import lab12 from "../../../assets/lab/cselab4-3.jpg";

import mech1 from "../../../assets/lab/Mech/MachineTools/mechlab4-1.jpg";
import mech2 from "../../../assets/lab/Mech/MachineTools/mechlab4-2.jpg";
import mech3 from "../../../assets/lab/Mech/MachineTools/mechlab4-3.jpg";

import cad1 from "../../../assets/lab/Mech/Cad/mechlab1-1.jpg";
import cad2 from "../../../assets/lab/Mech/Cad/mechlab1-2.jpg";
import cad3 from "../../../assets/lab/Mech/Cad/mechlab1-3.jpg";

import heat1 from "../../../assets/lab/Mech/HeatTransfer/mechlab2-1.jpg";
import heat2 from "../../../assets/lab/Mech/HeatTransfer/mechlab2-2.jpg";
import heat3 from "../../../assets/lab/Mech/HeatTransfer/mechlab2-3.jpg";

import therm1 from "../../../assets/lab/Mech/Thermal/mechlab3-1.jpg";
import therm2 from "../../../assets/lab/Mech/Thermal/mechlab3-2.jpg";
import therm3 from "../../../assets/lab/Mech/Thermal/mechlab3-3.jpg";
import ViewLabImages from "../ViewLabPhotos/ViewLabPhotos";

const Labs = () => {
  const [key, setKey] = useState("All");
  const currentPath = window.location.pathname;
    const path = currentPath.split("/").pop();
    const labType=path.toUpperCase()
    console.log("path===>",path.toUpperCase())
  return (
    <div className="container">
      <PageHeader name="Laboratories" />
      <Card style={{ width: "100%", margin: "20px 0px" }}>
        <Card.Header>MECH Laboratories</Card.Header>
        <Card.Body>
          <Card.Title>We have Fully Equipped Laboratories</Card.Title>
          <Card.Text>
            we have the several Computer Labs equipped with latest software's
            and workstations. It is connected to internet through a lease line
            to facilitate high speed internet access. All the computers are
            connected through a structured network. Printer and copier
            facilities are also available in the lab. Our dedicated faculty and
            staff are proud of their impressive credentials, which are
            comparable to those of the best career instructors.
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="content">
      <ViewLabImages labType={labType}/>

        {/* <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="All" title="All">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mech1}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>CAD/CAM LAB</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mech2}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>CAD/CAM LAB</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mech3}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>CAD/CAM LAB</p>
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={cad1}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>HEAT TRANSFER LAB</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={cad2}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>HEAT TRANSFER LAB</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={cad3}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>HEAT TRANSFER LAB</p>
                  <br />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={heat1}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>THERMAL ENGINEERING LAB</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={heat2}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>THERMAL ENGINEERING LAB</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={heat3}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>THERMAL ENGINEERING LAB</p>
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={therm1}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>MACHINE TOOLS LAB</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={therm2}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>MACHINE TOOLS LAB</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={therm3}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>MACHINE TOOLS LAB</p>
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="MACHINE TOOLS LAB" title="MACHINE TOOLS LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mech1}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>MACHINE TOOLS LAB</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mech2}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>MACHINE TOOLS LAB</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mech3}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>MACHINE TOOLS LAB</p>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="CAD/CAM LAB" title="CAD/CAM LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={cad1}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>CAD/CAM LAB</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={cad2}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>CAD/CAM LAB</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={cad3}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>CAD/CAM LAB</p>
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="HEAT TRANSFER LAB" title="HEAT TRANSFER LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={heat1}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>HEAT TRANSFER LAB</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={heat2}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>HEAT TRANSFER LAB</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={heat3}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>HEAT TRANSFER LAB</p>
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="THERMAL ENGINEERING LAB"
            title="THERMAL ENGINEERING LAB"
          >
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={therm1}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>THERMAL ENGINEERING LAB</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={therm2}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>THERMAL ENGINEERING LAB</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={therm3}
                    alt="lab-img"
                  />
                  <p style={{textAlign:"center"}}>THERMAL ENGINEERING LAB</p>
                  <br />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs> */}
      </div>
    </div>
  );
};

export default Labs;

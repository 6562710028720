import React, { useState ,useEffect} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import axios from "axios";
import { resources } from "../../Resourses/Resources";
import "animate.css";

// communication lab

import comm1 from "../../../assets/lab/ECE/CommunicationLab/communication.jpg";
import comm2 from "../../../assets/lab/ECE/CommunicationLab/ecelab4-2.jpg";
import comm3 from "../../../assets/lab/ECE/CommunicationLab/ecelab4-3.jpg";

// Simulation Lab

import sim1 from "../../../assets/lab/ECE/SimulationLab/ecelab1-1.jpg";
import sim2 from "../../../assets/lab/ECE/SimulationLab/ecelab1-2.jpg";
import sim3 from "../../../assets/lab/ECE/SimulationLab/ecelab1-3.jpg";

// Edc Lab

import edc1 from "../../../assets/lab/ECE/EdcLab/ecelab2-2.jpg";
import edc2 from "../../../assets/lab/ECE/EdcLab/ecelab2-2 (1).jpg";
import edc3 from "../../../assets/lab/ECE/EdcLab/ecelab2-2 (2).jpg";

// Icdsd Lab

import icd1 from "../../../assets/lab/ECE/IcdsdLab/ecelab3-1.jpg";
import icd2 from "../../../assets/lab/ECE/IcdsdLab/ecelab3-2.jpg";
import icd3 from "../../../assets/lab/ECE/IcdsdLab/ecelab3-3.jpg";

// Mpmc Lab

import mpmc1 from "../../../assets/lab/ECE/MpmcLab/ecelab2-1.jpg";
import mpmc2 from "../../../assets/lab/ECE/MpmcLab/ecelab2-2.jpg";
import mpmc3 from "../../../assets/lab/ECE/MpmcLab/ecelab2-3.jpg";
import ViewLabImages from "../ViewLabPhotos/ViewLabPhotos";

const Labs = () => {
  const [key, setKey] = useState("All");
  const [commonData, setCommonInterData] = useState([])
  const [computerData1, setComputer1Data] = useState([])
  const [compueter2Data, setComputer2Data] = useState([])
  const [computer3Data, setComputer3Data] = useState([])
  const date = new Date();
  const currentYear = date.getFullYear()
  const currentPath = window.location.pathname;
    const path = currentPath.split("/").pop();
    const labType=path.toUpperCase()
    console.log("path===>",path.toUpperCase())
  useEffect(() => {

    const fetchAllImages = async () => {
      try {
        const details = await axios.get(resources.APPLICATION_URL + `get/lab/imagesByYear?academicYear=${currentYear}&department=CSE`)
        // setAllImages(details.data)
        const allPhotos = details.data

        const labTypesCSE = ["Common Internet Center", "Computer Lab1", "Computer Lab2", "Computer Lab3"]

        const commonData = allPhotos.filter((items) => items.labName === "Common Internet Center")
        const electricMachines = allPhotos.filter((items) => items.labName === "Computer Lab1")
        const electricSimulation = allPhotos.filter((items) => items.labName === "Computer Lab2")
        const powerSystemLab = allPhotos.filter((items) => items.labName === "Computer Lab3")


        const commonDetails = commonData[0].filePath.split(",")
        const computer1 = electricMachines[0].filePath.split(",")
        const computer2 = electricSimulation[0].filePath.split(",")
        const computer3 = powerSystemLab[0].filePath.split(",")
        setCommonInterData(commonDetails)
        setComputer1Data(computer1)

        setComputer2Data(computer2)
        setComputer3Data(computer3)

      } catch (error) {
        console.log("error in fetch images===>", error)
      }
    }
    fetchAllImages()
  }, [currentYear])

  return (
    <div className="container">
      <PageHeader name="Laboratories" />
      <Card style={{ width: "100%", margin: "20px 0px" }}>
        <Card.Header>ECE Laboratories</Card.Header>
        <Card.Body>
          <Card.Title>We have Fully Equipped Laboratories</Card.Title>
          <Card.Text>
            we have the several Computer Labs equipped with latest software's
            and workstations. It is connected to internet through a lease line
            to facilitate high speed internet access. All the computers are
            connected through a structured network. Printer and copier
            facilities are also available in the lab. Our dedicated faculty and
            staff are proud of their impressive credentials, which are
            comparable to those of the best career instructors.
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="content">
      <ViewLabImages labType={labType}/>

        {/* <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="All" title="All">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm1}
                    alt="lab-img"
                  />
                <p style={{textAlign:"center"}}>Simulation Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Simulation Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Simulation Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>MP&MC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>MP&MC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>MP&MC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                  <br />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>IC/DSD Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>IC/DSD Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>IC/DSD Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Communication Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Communication Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Communication Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                  <br />
                </div>
              </div>
            </div>

            <br />
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>EDC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>EDC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>EDC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMMUNICATION LAB" title="COMMUNICATION LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Communication Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Communication Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Communication Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="SIMULATION LAB" title="SIMULATION LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Simulation Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Simulation Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>Simulation Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="MP&MC LAB" title="MP&MC LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>MP&MC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>MP&MC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>MP&MC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>

                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="IC/DSD LAB " title="IC/DSD LAB ">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>IC/DSD Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>IC/DSD Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>IC/DSD Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="EDC LAB" title="EDC LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc1}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>EDC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc2}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>EDC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc3}
                    alt="lab-img"
                  />
                   <p style={{textAlign:"center"}}>EDC Lab<br/>
Nullam id dolor id nibh ultricies vehicula.</p>
                  <br />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs> */}
      </div>
    </div>
  );
};

export default Labs;

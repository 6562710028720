import { useEffect } from "react"
import { useState } from "react";
import * as React from 'react';
import "./GeneralNotifications.css"
import axios from "axios";
import { resources } from "../Resourses/Resources";
import Cookies from "js-cookie"
import PaginationReUsable from "../ReuseableComponent/ReusePagination"

import ViewGeneralNotifications from "./ViewGeneralNotifications";
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GeneralNotifications = () => {


    const date = new Date();
    let currentMonth;
    let currentDate;
    const toDaysDate = date.getDate();
    const currentYear = date.getFullYear();
    const month = date.getMonth() + 1;
   currentDate = date.getDate();
    if (month < 10) {
      currentMonth = "0" + month
    } else {
      currentMonth = month
    }
    if (toDaysDate < 10) {
      currentDate = "0" + toDaysDate
    } else {
      currentDate = toDaysDate
    }

    const [typesUpload, setTypesUpload] = useState("")
    const [lastRecord, setLastRecord] = useState({})
    const [viewFile, setViewFile] = useState(false)

    const [userName, setUserName] = useState("")
    const userDetailsCookie = Cookies.get("userDetails");
    const handleClickOpen = () => {
        setViewFile(true);
      };
    
      const handleClose = () => {
        setViewFile(false);
      };

 
    if (month < 10) {
        currentMonth = "0" + month
      } else {
        currentMonth = month
      }
      if (toDaysDate < 10) {
        currentDate = "0" + toDaysDate
      } else {
        currentDate = toDaysDate
      }
      const newDate = currentYear + "-" + currentMonth + "-" + currentDate
    const todayDate = `${currentYear}-${currentMonth}-${currentDate}`;

    const uploadTypes = ["General", "ExamBranch", "Office"]

    const [notificationDetails, setNotificationDetails] = useState({
        uploadBy: userName,
        description: "",
        startDate: todayDate,
        endDate: "",
        typeOfUpload: ""


    })
    useEffect(() => {
        if (todayDate != null && todayDate != "") {
            setNotificationDetails({ ...notificationDetails, date: todayDate })
        }
        console.log("notifications===>", notificationDetails)

    }, [todayDate])


    useEffect(() => {
        if (userDetailsCookie != null && userDetailsCookie != "") {
            const userDetails = JSON.parse(userDetailsCookie);
            const details = userDetails.roles[0];
            const user = userDetails.username;
            setUserName(user);
            setNotificationDetails({ ...notificationDetails, uploadBy: user });

            if (details === "ROLE_ADMIN") {
                const type = "General";
                setTypesUpload("General");
                setNotificationDetails({ ...notificationDetails, typeOfUpload: type });
            }
            else if (details === "ROLE_EXAMBRANCH") {

                const type = "ExamBranch";
                setTypesUpload("ExamBranch");
                setNotificationDetails({ ...notificationDetails, typeOfUpload: type });
            }
            else if (details === "ROLE_OFFICE") {

                const type = "Office";
                setTypesUpload("Office");
                setNotificationDetails({ ...notificationDetails, typeOfUpload: type });
            }
        }
        console.log("typeOfUpload===>", notificationDetails.typeOfUpload)

    }, [userDetailsCookie]);




    const [selectdFile, setSelectedFile] = useState([])
    const handleSelectFile = async (e) => {
        const files = e.target.files[0]
        setSelectedFile(files);
    }
    const handleSubmit = async () => {
        console.log("notification date===>", notificationDetails)

        try {
            const formData = new FormData();
            formData.append("generalInfo", new Blob([JSON.stringify(notificationDetails)], { type: "application/json" }));
            formData.append("generalInfoFile", selectdFile);
            const response = await axios.post(resources.APPLICATION_URL + "saveGeneralNotifications", formData);
            console.log("Response from server123:", response);
            if (response.status === 200) {
                document.getElementById("generalFile").value = ""
                setNotificationDetails({
                    uploadBy: userName,
                    description: "",
                    startDate: todayDate,
                    endDate: "",
                    typeOfUpload: typesUpload
                })
                fetchLasstRecord();
                alert("uploaded Successfully");
            } else {
                alert("Something went wrong")
            }
        } catch (error) {
            console.log("error", error)
        }
    }
    const fetchLasstRecord = async () => {
        try {
            if (typesUpload !== null && typesUpload !== "") {
                const response = await axios.get(resources.APPLICATION_URL + `getLastInsertedRecord?typeOfUpload=ExamBranch`)
                const details = response.data
                console.log("newDate===>",newDate)
                console.log("details.endDate===>",details.endDate)
                if (details.endDate >= newDate) {
                    setLastRecord(response.data)
                } else {
                    console.log("triggered")
                }
            }
        } catch (error) {
            console.log("error===>",error)
        }
    }
    useEffect(() => {

        fetchLasstRecord();
    }, [typesUpload])

console.log("lastRecord===>",lastRecord)
    return (
        <div className="uploadContainer">
            <div>
                <p className="headerTagStyle">General Notifications</p>
            </div>
            <marquee behavior="scroll" direction="left" scrollamount="10" className="marQueeTag">
       
        
            {lastRecord.endDate != null && lastRecord.endDate != "" ? <p className="notification_prg" style={{ cursor: "pointer" }} onClick={() => setViewFile(true)}>
              {lastRecord.description}
            </p> : <p className="notification_prg">Please add Notifications</p>}
   
        </marquee>
            <div className="generalNotificationsDiv">
                <div className="uploadDivs">
                    <div className="uploadLabelAndInput">
                        <label>Start Date</label>
                        <input type="date" value={notificationDetails.startDate} onChange={(e) => { setNotificationDetails({ ...notificationDetails, startDate: e.target.value }) }} />
                    </div>
                    <div className="uploadLabelAndInput">
                        <label>End Date</label>
                        <input type="date" value={notificationDetails.endDate} onChange={(e) => { setNotificationDetails({ ...notificationDetails, endDate: e.target.value }) }} />
                    </div>
                    <div className="uploadLabelAndInput">
                        <label>Type Of Upload</label>
                        <input type="text"  disabled="true" value={notificationDetails.typeOfUpload} onChange={(e) => { setNotificationDetails({ ...notificationDetails, typeOfUpload: e.target.value }) }}/>
                        {/* <select value={notificationDetails.typeOfUpload} onChange={(e) => { setNotificationDetails({ ...notificationDetails, typeOfUpload: e.target.value }) }}>
                            <option>Please Select</option>
                            {uploadTypes.map((items, index) => {
                                return (
                                    <option key={index}>{items}</option>
                                )
                            })}
                        </select> */}
                    </div>
                    <div className="uploadLabelAndInput">
                        <label>Description</label>
                        <textarea value={notificationDetails.description} onChange={(e) => { setNotificationDetails({ ...notificationDetails, description: e.target.value }) }} />
                    </div>
                    <div className="uploadLabelAndInput">
                        <label>Select File</label>
                        <input type="file" id="generalFile" onChange={(e) => handleSelectFile(e)} />
                    </div>
                </div>
                <div className="secndContainer" >

                    <ViewGeneralNotifications />

                </div>
            </div>
            <div className="uploadDivButton">
                <button onClick={() => handleSubmit()}>Submit</button>
            </div>
            <React.Fragment>
        <Dialog
          fullScreen
          open={viewFile}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
              maxHeight: '100vh',
            },
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

            </Toolbar>
          </AppBar>
          {lastRecord && (
            <iframe
              src={`data:application/pdf;base64,${lastRecord.filePath}`}
              width="100%"
              height="100%"
            />
          )}
        </Dialog>
      </React.Fragment>
        </div>
    )
}
export default GeneralNotifications;
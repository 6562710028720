import React, { useState, useEffect } from "react";
import { FluentHatGraduationSparkle28Filled } from "../../assets/icons/svgs";
import { MdiAccountGraduationOutline } from "../../assets/icons/svgs";
import Card1 from "./Card1";
import Table from "./Table";
import axios from "axios";
import { resources } from "../Resourses/Resources";


import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const Acadamicregulation = () => {
  let currentYear;
  const date = new Date();
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  if (month < 5) {
    currentYear = year - 1 + "-" + "" + year
  }
  else {
    currentYear = year + "-" + "" + year + 1
  }
  const [courseDetails, setCourseDetails] = useState([])
  const [diplomaCourse, setDiplomaCourse] = useState([])
  const [graduateCourse, setGraduateCourse] = useState([])
  const [pgCourses, setPgCourse] = useState([])
  useEffect(() => {
    const fetchCourseDetails = async () => {
      if (currentYear != null && currentYear != "") {
        try {
          const response = await axios.get(resources.APPLICATION_URL + `getAllCourseDetailsByAcademicYear?academicYear=${currentYear}`)
          setCourseDetails(response.data)
          const details = response.data
          const diploa = details.filter((items) => items.level === "Diploma")
          if (details.length > 0) {
            const graduation = details.filter((items) => items.level === "Graduation")
            const pgGraduation = details.filter((items) => items.level === "Post Graduation")
            setDiplomaCourse(diploa)
            setGraduateCourse(graduation)
            setPgCourse(pgGraduation)

          }

        } catch (error) {
          console.log("error===>", error)
        }
      }
    }
    fetchCourseDetails()
  }, [currentYear])
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            ACADAMIC REGULATIONS
          </a>
        </div>
      </div>
      <br />

      <div class="container text-left">
        <p>
          The college is affiliated to JNTUH, Hyderabad. The academic
          regulations in respect of the academic program of study are prepared
          and approved by the Academic Council, the highest academic body in the
          college.
        </p>

        <div class="content content-expand-lg ">
          <h3>UG Programmes</h3>
          <div class="w-100 d-flex ">
            <p>R22 Academic Rules & Regulations for B.Tech Degree Programs </p>{" "}
            <a href="https://drive.google.com/file/d/1YSCJBoRQ9GPJ7BWYy5CSYnBCgCS3BXZO/view" target="blank">
              <PictureAsPdfIcon
                sx={{ fontSize: "50px", marginLeft: "150px", marginTop: "-10px" }}
              /></a>
          </div><br />
          <div class="w-100 d-flex ">
            <p>R18 Academic Rules & Regulations for B.Tech Degree Programs </p>{" "}
            <a href="https://drive.google.com/file/d/1e_fT1kJ3Cd1RWZU61llZcQPN8jLcTS6M/view" target="blank">
              <PictureAsPdfIcon
                sx={{ fontSize: "50px", marginLeft: "150px", marginTop: "-10px" }}
              /></a>
          </div>
          <br />
          <h4>PG Programmes</h4>
          <div class="w-100 d-flex ">
            <p>R22 Academic Regulations for MBA (MBA22) w.e.f 2022-23</p>{" "}
            <a href="https://drive.google.com/file/d/1eHE-H_CKE40SU1tHw4blemiCTe3ufR7K/view" target="blank">
              <PictureAsPdfIcon
                sx={{ fontSize: "50px", marginLeft: "150px", marginTop: "-10px" }}
              /></a>
          </div>
          <br />
          <div class="w-100 d-flex ">
            <p>R19 Academic Regulations for MBA (MBA19) w.e.f 2019-20 </p>{" "}
            <a href="https://drive.google.com/file/d/1Ox8jM2w4-HWNSsyAwHrNZrzwQJ4Dx8ke/view" target="blank">
              <PictureAsPdfIcon
                sx={{ fontSize: "50px", marginLeft: "150px", marginTop: "-10px" }}
              /></a>
          </div>
        </div>
      </div>
      <br />
      <div class="container">
        <a class="navbar-brand" href="#">
          <h4> COURSES OFFERED BY SAI SPURTHI</h4>
        </a>
      </div>

      <div className="card_cntr2">
        <div
          className="boxes col-lg-4 col-sm-6 wow animate__animated animate_fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-1">
              <FluentHatGraduationSparkle28Filled
                sx={{ fontSize: 80, color: "#0d6efd" }}
              />
              {/* <i className="fa fa-3x fa-graduation-cap text-primary mb-4"></i> */}
              <h5 className="mb-2">Diploma Programs </h5>
              {diplomaCourse && diplomaCourse.length > 0 && diplomaCourse.map((items, index) => {
                return (
                  <div key={index}>
                    <p>{items.branch}: {items.intakeCount}</p>

                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div
          className="boxes col-lg-4 col-sm-6 wow animate__animated animate_fadeInUp"
          data-wow-delay="0.3s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-1">
              {/* <i className="fa fa-3x fa-globe text-primary mb-4"></i> */}
              <FluentHatGraduationSparkle28Filled
                sx={{ fontSize: 80, color: "#0d6efd" }}
              />
              <h5 className="mb-2">Under Graduation Programmes</h5>
              {graduateCourse && graduateCourse.length > 0 && graduateCourse.map((items, index) => {
                return (
                  <div key={index}>
                    <p>{items.branch}: {items.intakeCount}</p>

                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div
          className="boxes col-lg-4 col-sm-6 wow animate__animated animate_fadeInUp"
          data-wow-delay="0.5s"
        >
          <div className="boxes1 service-item text-center pt-3">
            <div className="p-1">
              {/* <i className="fa fa-3x fa-home text-primary mb-4"></i> */}
              <MdiAccountGraduationOutline />
              <h5 className="mb-2">Post Graduation Programmes</h5>
              {pgCourses && pgCourses.length > 0 && pgCourses.map((items, index) => {
                return (
                  <div key={index}>
                    <p>{items.branch}: {items.intakeCount}</p>

                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div class="container text-left">
        <h3>ADMISSION</h3>
        <p style={{ textAlign: "justify" }}>
          <p>
            A candidate should possess the eligibility of 10+2 qualification from
            Board of Intermediate, Government of Andhra Pradesh with Mathematics,
            Physics and Chemistry as his/her optional subjects, or any other
            equivalent examination recognized as equivalent thereto. A student to
            step in four-year degree course in Engineering except NRI quota must
            qualify in "Engineering, Agricultural Sciences And Medical Common
            Entrance Test" (EAMCET), a State-Level Entrance Test conducted by the
            Govt. Of Andhra Pradesh. Students who qualify in EAMCET entrance test
            will be admitted strictly on merit basis.
          </p>
          <p>
            The Convener of EAMCET admits on merit secured in Intermediate or an
            equivalent examination and the rank secured in the EAMCET. The
            Management admits candidates for the Management and NRI quota based on
            merit and should possess first class in optional subjects:
            Mathematics, Physics, and Chemistry.
          </p>
          <p>
            The Convener of ECET admits 10% of the candidates from the stream of
            Diploma Holders should pass in a diploma from Andhra Pradesh State
            Government or an equivalent examination.
          </p>
        </p>
      </div>

      <div class="container text-left">
        <h3>FEE STRUCTURE:</h3>

        <Card1 />
      </div>
      <br />

      <div class="container">
        <a class="navbar-brand" href="#">
          <h4> ELIGIBILITY</h4>
        </a>
        <Table />
      </div>
    </div>
  );
};

export default Acadamicregulation;

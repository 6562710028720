import React from "react";
import labpic from "../../assets/englab1.jpg";
import labpic1 from "../../assets/englabc.jpg";

const Englishlab = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            ENGLISH LABS
          </a>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className=" w-100">
                <img
                  className="img rounded  w-100 "
                  src={labpic}
                  alt="labpic-img"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                ELCS LAB
              </h6>
              <div className="row gy-2 gx-4 mb-4">
                <p style={{textAlign:"justify"}}>
                  We are proud to say we have enriched the IST year B.Tech
                  Students in communication skills and spoken language. We are
                  teaching the students through the multimedia computers which
                  were established in 2001 with 6 7 students Consoles stand and
                  a masters console with high quality of head sets. We are using
                  p-IV 1.8 GHZ Processors, 40GB HD AND 250 MB-RAM. Operating
                  system windows 2000XP is used to all the system. The software
                  which we purchase was Centro nix in 2001 to be strengthened
                  for the students accent in English along with we have virtual
                  C.DS software such as Cambridge Advanced Learner's Dictionary,
                  Oxford talking Dictionary, talk to me English Grammar in use,
                  Telephoning skills etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 gy-2 gx-4 mt-5">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Advanced English Communication Skills Lab
              </h6>
              <p style={{textAlign:"justify"}}>
                Dept of English and Communications play a major role in
                strengthening the abilities of students and building their
                careers. This lab contains 39 systems with LCD monitors, LAN and
                Globarena Latest software. The lab has been equipped with P.A
                system also. The Lab is very useful to all B.Tech and MBA
                students.
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <div
                className="col-lg-6 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ minHeight: "100px" }}
              >
                <div className="w-100">
                  <img
                    className="rounded w-100"
                    src={labpic1}
                    alt="labpic1-img"
                    style={{  height:'15%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Englishlab;

import React from "react";
import "./AictefeedbackView.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import PaginationReUsable from "../../Components/ReuseableComponent/ReusePagination";


const FacultyFeedbackView = () => {
  const [facultyData, setfacultyData] = useState([]);

   // Pagination
   const [allData, setAllData] = useState([]);
   const [filteredData, setFilteredData] = useState([]);
   console.log("filteredData==>", filteredData);
   const [search, setSearch] = useState("");
   const [serialNo, setSerialNo] = useState("");
 
   const [paginationData, setPaginationData] = useState([]);
   useEffect(() => {
     setFilteredData(facultyData);
     setAllData(facultyData);
   }, [facultyData]);
   const handleGetSpliceData = (receivedList, startSNo) => {
     setPaginationData(receivedList);
 
     setSerialNo(startSNo);
   };
   const handleSearch = (e) => {
     let { value } = e.target;
     console.log("value", value);
 
     let list = allData.filter(
       (each) =>
         (each.facultyId && each.facultyId.includes(value)) ||
         (each.facultyName && each.facultyName.toLowerCase().includes(value.toLowerCase()))
     );
 
     console.log("list==>", list);
     setFilteredData(list);
     setSearch(value);
   };
 
  const handleView = async () => {
    try {
      await axios
        .get(resources.APPLICATION_URL + "getAllFacultyFeeedbacks")
        .then((response) => {
          console.log("response===>", response.data);
          if (response.data.length > 0) {
            setfacultyData(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleView();
  }, []);

  return (
    <>
      <div className="main_container_aicte">
      <div className="saarchDiv">
        <input
          type="text"
          value={search}
          placeholder="Search By Name..."
          onChange={(e) => handleSearch(e)}
          />
          </div>
      <table className="Aicte_table_container">
        <thead>
          <tr>
            <th>Faculty Id</th>
            <th>Faculty Name</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Aadhar No</th>
            <th>Mobile No</th>
            <th>Qualification</th>
            <th>Type Of Employement</th>
            <th>Courses per week</th>
            <th>AICTE Id</th>
            <th>Institute Name</th>
            <th>Institute Address</th>
          </tr>
        </thead>
        <tbody>
          {paginationData.length > 0 && paginationData.map((data, i) => {
                          const SNo = serialNo + i;
            return (
              <tr>
                <td>{data.facultyId}</td>
                <td>{data.facultyName}</td>
                <td>{data.facultyGender}</td>
                <td>{data.facultyEmail}</td>
                <td>{data.facultyAdhar}</td>
                <td>{data.mobileNumber}</td>
                <td>{data.facultyQualification}</td>
                <td>{data.typeOfEmployement}</td>
                <td>{data.coursesHandledperWeek}</td>
                <td>{data.aictePermanentId}</td>
                <td>{data.facultyinstituteName}</td>
                <td>{data.facultyInstituteAddress}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <PaginationReUsable
          paginationData={filteredData}
          handleGetSpliceData={handleGetSpliceData}
        />
      </div>
      </>
  );
};

export default FacultyFeedbackView;

import React from "react";
import { resources } from "../Resourses/Resources";
import axios from "axios";
import { useState } from "react";
const CommiteeUpload = () => {
  const [selectedFile, setSelectedFile] = useState([]);
  const handleSelectFile = async (e) => {
    const files = e.target.files[0];
    setSelectedFile(files);
  };
  const handleReset = async () => {
    document.getElementById("committeeExcel").value = "";
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      console.log("fileto send===>", selectedFile);
      const response = await axios.post(
        resources.APPLICATION_URL + `committeeExcel/Upload`,
        formData
      );
      console.log("response===>", response);
      if (response.status === 200) {
        alert("Uploaded successfully");
        document.getElementById("committeeExcel").value = "";
      }
    } catch (error) {
      alert("please try again");
      console.log("error==>", error);
    }
  };
  return (
    <div className="uploadExcelContainer">
      <div>
        <label>Committe Details Excel File</label>
      </div>
      <div className="fileAndButtonDiv">
        <div className="fileDiv">
          <input
            type="file"
            id="committeeExcel"
            onChange={(e) => handleSelectFile(e)}
          />
        </div>
        <div className="fileButtons">
          <button className="resetButton" onClick={() => handleReset()}>
            Reset
          </button>
          <button className="uploadButton" onClick={() => handleSubmit()}>
            Upload
          </button>
        </div>
      </div>
      <div>
        <a
          className="anchorTagDownload"
          href={resources.APPLICATION_URL + "CommiteeExcel/TemplateDownload"}
        >
          Download Template
        </a>
      </div>
    </div>
  );
};

export default CommiteeUpload;

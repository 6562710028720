import axios from "axios"
import * as React from 'react';

import { useEffect, useState } from "react"
import { resources } from "../Resourses/Resources"
import EditOutlined from "@mui/icons-material/EditOutlined"
import { DeleteOutlineSharp } from "@mui/icons-material"
import PaginationReUsable from "../ReuseableComponent/ReusePagination"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const StudentDashboardView = () => {
    const [studentDetails, setStudentDetails] = useState([])
    const [allData, setAllData] = useState([])
    const [paginationData, setPaginationData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState("")
    const [serialNo, setSerialNo] = useState("")
    const [modifyedRecord, setModifyedRecord] = useState({})
    const [edit, setEdit] = useState(false)
    const [lastRecord,setLastRecord]=useState({})
    const [viewFile, setViewFile] = useState(false)


    const handleClickOpen = () => {
        setEdit(true);
    };

    const handleClose = () => {
        setEdit(false);
    };
    const fetchStudentDetails = async () => {
        try {
            const response = await axios.get(resources.APPLICATION_URL + "getAllActiveStudents")
            if (response.data.length > 0) {
                setStudentDetails(response.data)
                setPaginationData(response.data)
                setAllData(response.data)
                setFilteredData(response.data)
            }
        } catch (error) {
            console.log("error===>", error)
        }
    }
    useEffect(() => {

            fetchStudentDetails()
        },[])

    const handleSearch = (e) => {
        let { value } = e.target;
        console.log("value", value);

        let list = allData.filter(
            (each) =>
                (each.branch && each.branch.toLowerCase().includes(value.toLowerCase())) ||
                (each.rollNumber && each.rollNumber.toLowerCase().includes(value.toLowerCase()))
        );
        console.log("list==>", list);
        setFilteredData(list);
        setSearch(value);
    };
    const handleGetSpliceData = (receivedList, startSNo) => {
        setPaginationData(receivedList);
        setSerialNo(startSNo);
    };
    const handleModify = async (e) => {
        try {
            const response = await axios.post(resources.APPLICATION_URL + "update/studentDetails", modifyedRecord)
            console.log("response===>", response)
            if(response.data==="Successfully Modifyed"){
                alert("Successfully modifyed record")
                fetchStudentDetails()
                handleClose()
            }
        } catch (error) {
            alert("Something went wrong")
            console.log("error===>", error)
        }
    }
    const handleDeleteTheRecord=async(items)=>{
        try {
            const response = await axios.post(resources.APPLICATION_URL + "delete/studentDetails", items)
            console.log("response===>", response)
            if(response.data==="Successfully deleted"){
                alert("Successfully deleted record")
                fetchStudentDetails()
             
            }
        } catch (error) {
            alert("Something went wrong")
            console.log("error===>", error)
        }
    }

    return (
        <div>
            <div className="searchDiv">
                    <input type="text" placeholder="Roll Number or Branch..." value={search} onChange={(e) => handleSearch(e)} />
                </div>
            <div>
                <table className="tableStylings">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>RollNumber</th>
                            <th>Name</th>
                            <th>Level</th>
                            <th>degree</th>
                            <th>Batch</th>
                            <th>Branch</th>
                            <th>Semester</th>

                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginationData && paginationData.length > 0 && paginationData.map((items,index)=>{
                            return(
                                <tr key={index}>
                                    <td>{serialNo+index}</td>
                                    <td style={{textTransform:"uppercase"}}>{items.rollNumber}</td>
                                    <td style={{textTransform:"capitalize"}}>{items.fullName}</td>
                                    <td style={{textTransform:"uppercase"}}>{items.level}</td>
                                    <td style={{textTransform:"uppercase"}}>{items.degree}</td>
                                    <td>{items.batch}</td>
                                    <td style={{textTransform:"uppercase"}}>{items.branch}</td>
                                    <td style={{textTransform:"uppercase"}}>{items.semester}</td>

                                    <td  ><EditOutlinedIcon onClick={() => { handleClickOpen(); setModifyedRecord(items) }}/><DeleteOutlinedIcon onClick={()=>handleDeleteTheRecord(items)}/> </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <React.Fragment>
                    <Dialog
                        fullScreen
                        open={edit}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        PaperProps={{
                            style: {
                                width: '100%',
                                height: '100%',
                                margin: 0,
                                padding: 0,
                                maxHeight: '100vh',
                            },
                        }}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>

                            </Toolbar>
                        </AppBar>


                        <div className="containerOne1">
                            <div className="inputAndLabelDiv">
                                <label htmlFor="userName">Student Name</label>
                                <input type="text" name="userName" value={modifyedRecord.fullName} onChange={(e) => setModifyedRecord({ ...modifyedRecord, fullName: e.target.value })} />
                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="emailId">Student RollNumber</label>
                                <input type="email" name="emailId" value={modifyedRecord.rollNumber} onChange={(e) => setModifyedRecord({ ...modifyedRecord, rollNumber: e.target.value })} />
                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="departMent">Semester</label>
                                <input type="email" name="emailId" value={modifyedRecord.semester} onChange={(e) => setModifyedRecord({ ...modifyedRecord, semester: e.target.value })} />

                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="departMent">Email</label>
                                <input type="email" name="emailId" value={modifyedRecord.emailId} onChange={(e) => setModifyedRecord({ ...modifyedRecord, emailId: e.target.value })} />

                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="departMent">Status</label>
                                <select value={modifyedRecord.status} onChange={(e) => setModifyedRecord({ ...modifyedRecord, status: e.target.value })}  >
                                    <option>Please Select</option>
                                    <option value="Active">Active</option>
                                    <option value="InActive">Inactive</option>
                                </select>

                            </div>

                            <div className="registerDiv">
                                <button className="" onClick={handleModify}>Submit</button>
                            </div>
                        </div>




                    </Dialog>
                </React.Fragment>
            </div>
 
            <PaginationReUsable
                paginationData={filteredData}
                handleGetSpliceData={handleGetSpliceData}
            />
        </div>
    )
}
export default StudentDashboardView;
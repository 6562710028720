import React from "react";
import "./Mandatorydiscloser.css"
import { resources } from "../Resourses/Resources";
import axios from "axios";

const Mandatorydiscloser = () => {
  const Aicte = [
    {
      name: "Extension of approval for the academic year 2022-23",
      link: "EOAReport2022-23.pdf"
    },
    {
      name: "Extension of approval for the academic year 2021-22",
      link: "EOAReport2021-22.pdf"
    },
    {
      name: "Extension of approval for the academic year 2020-21",
      link: "EOAReport2020-21.pdf"
    },
    {
      name: "Extension of approval for the academic year 2019-20",
      link: "EOAReport2019-20.pdf"
    },
    {
      name: "Extension of approval for the academic year 2018-19",
      link: "EOAReport2018-19.pdf"
    },
    {
      name: "Extension of approval for the academic year 2017-18",
      link: "EOAReport2017-18.pdf"
    },
    {
      name: "Extension of approval for the academic year 2016-17",
      link: "EOAReport2016-17.pdf"
    },
    {
      name: "Extension of approval for the academic year 2015-16",
      link: "EOAReport2015-16.pdf"
    },
    {
      name: "Extension of approval for the academic year 2014-15",
      link: "EOAReport2014-15.pdf"
    },
    {
      name: "Extension of approval for the academic year 2013-14",
      link: "EOAReport2013-14.pdf"
    },
    {
      name: "AICTE approvalletters from Inception of the college upto academic year 2012-13",
      link: "EOAReportfrom2001-2012.pdf"
    },

  ]
  const handleShowSelfDeclarationPdf = async (param) => {
    try {
      const response = await axios.get(
        resources.APPLICATION_URL + "filenames?filename=" + param + "&foldername=ssr"
      );
      console.log("response", response.data);
      const base64 = response.data;

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (e) {
      console.log("error", e);
  
    }
  };

  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            AICTE APPROVAL LETTERS
          </a>
        </div>
      </div>
      <div className="aicte_new_btn_cntr">
      <button className="aicte_new_btn" type="button" onClick={() => {
          handleShowSelfDeclarationPdf("EOAReport2023-24.pdf");
        }}><span></span>AICTE Extension of Approval for the academic year 2023-24 New</button>

</div>
      <br />
      <div className="aicte_btn_container">
        {Aicte.map((each) => <button className="aicte_btn" type="button" onClick={() => {
          handleShowSelfDeclarationPdf(each.link);
        }}><span></span>{each.name}</button>)}
        

      </div>



    </div>
  );
};

export default Mandatorydiscloser;

import react, { useEffect, useState } from "react";
import "./ExcelUpload.css";

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import StudentExcelUpload from "./StudentExcelUpload";
import RegularFeeExcelUpload from "./RegularFeeExcelUpload";
import SupplyFeeExcelUpload from "./SupplyFeeExcelUpload";
import FAcultyExcelUpload from "./FacultyExcelUpload";
// import CommiteeExcelupload from "./CommiteeExcelupload";
import CommiteeMemberUpload from "./CommiteeMemberExcelupload";
import CommiteeUpload from "./CommiteeUpload";
import RegulationsAndSyllabus from "./RegulationsSyllabusUpload";
const ExcelUploads = () => {
  const [uploadType, setUploadType] = useState("student");
  const navigate = useNavigate();

  console.log("upload type===>", uploadType);
  return (
    <div className="facultyUploadContainer">
      <div className="excelUploadContainer">
        <label>Excel Type</label>
        <select
          onChange={(e) => {
            setUploadType(e.target.value);
          }}
          id="student"
        >
          <option>Please Select</option>
          <option value="student">Student</option>
          <option value="RegularFee">Regular Fee</option>
          {/* <option value="supplyFee">Supply Fee</option> */}
          <option value="Faculty">Faculty Upload</option>
          <option value="Commitee">Commitee Upload</option>
          <option value="CommiteeMember">Commitee Member</option>
          <option value="regulation">Regulation Uploads</option>
        </select>
      </div>
      <div className="excelContainers">
        {uploadType === "student" && <StudentExcelUpload />}
        {uploadType === "RegularFee" && <RegularFeeExcelUpload />}
        {uploadType === "supplyFee" && <SupplyFeeExcelUpload />}
        {uploadType === "Faculty" && <FAcultyExcelUpload />}
        {uploadType === "Commitee" && <CommiteeUpload />}
        {uploadType === "CommiteeMember" && <CommiteeMemberUpload />}
        {uploadType === "regulation" && <RegulationsAndSyllabus />}
      </div>
    </div>
  );
};
export default ExcelUploads;

import axios from "axios";
import react, { useState, useEffect } from "react"
import { resources } from "../Resourses/Resources";

const RegulationsAndSyllabus = () => {
    const [selectedFile, setSelectedFile] = useState([])
    const date = new Date()
    let academicYear1;
    const currentYear = date.getFullYear()
    const month = date.getMonth()
    if (month < 5) {
        academicYear1 = (currentYear - 1) + "-" + currentYear
    } else {
        academicYear1 = currentYear + "-" + (currentYear + 1)
    }

    const [details, setDetails] = useState({
        academicYear: "",
        regulation: "",
        branch: "",
        batch: "",
        level: "",
        degree: "",


    })
    useState(() => {
        setDetails({ ...details, academicYear: academicYear1 })
    }, [academicYear1])
    const handleSelectFile = async (e) => {
        const files = e.target.files[0]
        setSelectedFile(files)
    }
    const handleReset = async () => {
        document.getElementById("feeDetailsExcel").value = ""
    }

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append("regulation", new Blob([JSON.stringify(details)], { type: "application/json" }));
    
            formData.append("file", selectedFile);
    
            console.log("fileto send===>", selectedFile);
            const response = await axios.post(resources.APPLICATION_URL + `upload/regulationDetails`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Set content type explicitly
                }
            });
            if(response.data==="Successfully"){
                alert("Successfully added Regulation")
                document.getElementById("regulationExcel").value=""
                setDetails({
                    ...details,
                    regulation: "",
                    branch: "",
                    batch: "",
                    level: "",
                    degree: "",
                })
            }
            console.log("response===>", response.data);
        } catch (error) {
            console.log("error==>", error);
        }
    }
    
    return (
        <div className="regulationContainer">
            <div className="rgulationInputDiv">
                <div className="regulationInputAndLbelDiv">
                    <label>AcademicYear</label>
                    <input type="text" value={details.academicYear} disabled="true" onChange={(e) => setDetails({ ...details, academicYear: e.target.value })} />
                </div>
                <div className="regulationInputAndLbelDiv">
                    <label>Level</label>
                    <input type="text" value={details.level} onChange={(e) => setDetails({ ...details, level: e.target.value })} />
                </div>
                <div className="regulationInputAndLbelDiv">
                    <label>Regulation</label>
                    <input type="text" lvalue={details.regulation} onChange={(e) => setDetails({ ...details, regulation: e.target.value })} evel />
                </div>
                <div className="regulationInputAndLbelDiv">
                    <label>Degree</label>
                    <input type="text" value={details.degree} onChange={(e) => setDetails({ ...details, degree: e.target.value })} />
                </div>
                <div className="regulationInputAndLbelDiv">
                    <label>Branch</label>
                    <input type="text" value={details.branch} onChange={(e) => setDetails({ ...details, branch: e.target.value })} />
                </div>
                <div className="regulationInputAndLbelDiv">
                    <label>Batch</label>
                    <input type="text" value={details.batch} onChange={(e) => setDetails({ ...details, batch: e.target.value })} />
                </div>
            </div>


            <div className="fileAndButtonDivRegulation">
                <div className="fileDiv">
                    <input type="file" id="regulationExcel" onChange={(e) => handleSelectFile(e)} />
                </div>
                <div className="fileButtons">
                    <button className="resetButton" onClick={() => handleReset()}>Reset</button>
                    <button className="uploadButton" onClick={() => handleSubmit()}>Upload</button>
                </div>

            </div>

        </div>
    )
}
export default RegulationsAndSyllabus;
import React from 'react'
import './Library.css'
import LibraryImg from "./../../assets/images/library.jpg"
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import staffImg from "../../assets/carousal_images/InteractionwithChairman.jpeg"

const Library = () => {
    const facilities = [
        {
            title: "JST",
            course: "J- GATE SCIENCE AND TECHNOLOGY"
        },
        {
            title: "DELNET",
            course: "E-journals",
        },
        {
            title: "NDL",
            course: "NDL India"
        },
        {
            title: "SHODHA SINDHU",
            course: "ONLINE E- RESOURCE REUISITION SYSTEMS"
        }
    ]
    const staff = [
        {
            name:"G.UPENDRA RAO",
            qualification:"MA.B.Ed.,MLISc,PGDCA	",
            designation:"LIBRARIAN",
        },
        {
            name:"V.VENKATESWER RAO",
            qualification:"BA,B.LISc.",
            designation:"Library Asst",
        },
        {
            name:"O.VENKATA KRISHNA",
            qualification:"BSc COMPUTERS.",
            designation:"BOOK KEEPER",
        },
        {
            name:"M.MAHA LAKSHMI",
            qualification:"SSC",
            designation:"ATTENDER",
        },
    ]
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&::before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));


    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <div className='library_about'>
                <img src={LibraryImg} alt='LibraryImg' />
                <div>
                    <h3>ABOUT LIBRARY</h3>
                    <p>
                        The SSIT Library System consists of a Central Library with 6 departmental sections (Libraries), which collectively support the teaching, research and extension programmers of the Institute. All students, faculty members and employees of the Institute are entitled to make use of the Library facilities on taking library membership. The Library, besides having 42734 volumes and digital library with huge collection of e-books for engineering, science, Management and humanities offers library services through its various divisions.
                    </p>
                </div>
            </div>
            <div className='library_services_container'>
                <div className='library_services'>
                    <h3>
                        SERVICES
                    </h3>
                    <p>
                        <MenuBookIcon sx={{ color: "orangered" }} /> Providing News Papers for every Room in Both MEN's and WOMEN's Hostels<br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Open Access Syste<br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Circulation (Issues & Returns)<br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Reference<br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Reading<br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Online Public Access Catalogue (OPAC) <br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Book Lending Service <br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Photo Coping Services<br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Reservation Facility<br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Department Libraries <br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Current Awareness Services<br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Project Reports & News Papers<br />
                        <MenuBookIcon sx={{ color: "orangered" }} /> Back Volumes

                    </p>
                </div>
                <div className='library_vision'>
                    <div>
                        <h3>VISION</h3>
                        <p>
                            We impart futuristic technical education and instill high patterns of discipline through our dedicated management and staff set to global standards, making our students technologically superior and ethically strong who in turn shall improve the quality and disciplined Life of the human race.
                        </p>
                    </div>
                    <div>
                        <h3>TIMINGS</h3>
                        <p>
                            <AccessAlarmsIcon sx={{ color: "orangered" }} /> Library Working Hours : 8.00 A.M to 8.00 P.M<br />
                            <AccessAlarmsIcon sx={{ color: "orangered" }} /> Holidays : 9.00 A.M to 1.00 P.M<br />
                            <AccessAlarmsIcon sx={{ color: "orangered" }} /> Book Circulation Timings : 9.00 A.M. to 5.00 P.M<br />
                            <AccessAlarmsIcon sx={{ color: "orangered" }} /> Reference Section : 8.00 A.M. to 8.00 P.M
                        </p>
                    </div>
                </div>
            </div>
            
            <h3 className='staff_title'>STAFF</h3>
            <div className='library_staff'>
                <div></div>
            <table className='library_staff_table'>
                
                        <thead>
                            <tr>
                                <th>S.NO</th>
                                <th>Name</th>
                                <th>Qualification</th>
                                <th>Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {staff.map((each, index) =>
                                <tr>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>
                                        {each.name}
                                    </td>
                                    <td>
                                        {each.qualification}
                                    </td>
                                    <td>
                                        {each.designation}
                                    </td>
                                </tr>)}
                        </tbody>
                    </table><div/>
                <img src={staffImg} alt='staffImg' />
            </div>
            <div className='library_threebox_container'>
                <div className='library_threebox'>
                    <h3>Ficilities</h3><br />
                    <p>
                        Digital LibraryDigital libraries provide users with online access to a wide range of resources. They are often used by students for research or by professionals seeking to stay current on the latest developments in their field.
                    </p><br />
                    <table className='library_facilities_table'>
                        <thead>
                            <tr>
                                <th>S.NO</th>
                                <th>TITLE</th>
                                <th>LIBRARY</th>
                            </tr>
                        </thead>
                        <tbody>
                            {facilities.map((each, index) =>
                                <tr>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>
                                        {each.title}
                                    </td>
                                    <td>
                                        {each.course}
                                    </td>
                                </tr>)}
                        </tbody>
                    </table><br/>
                    <p>Library AutomationThe Central Library has computerized all its activities including Acquisition, Cataloguing, Circulation and stock verification using clarity 2000 software. The Central Library using barcode technology for computerized circulation system and stock verification. The Central Library has developed clarity 2000 software facilities for bar coding of books and ID cards.</p>
                    <p>News Papers We are providing English Newspapers to each student of boys and girls hostel. We keep the following English and Telugu Newspapers in the library.</p>
                    <p>DECCAN CHRONICLE, HANS INDIA, EENADU(Telugu), ANDHRA JYOTHI(Telugu), SAKSHI(Telugu), NAMASTE TELANGANA(Telugu), EMPLOYMENT NEWS(Weekly), ANDHRA PRABHA(Telugu).</p>
                    <p>Print Journals&Magazines(National / International) More than 100 Journals (National and International) related to various disciplines area available in the central library.</p>
                    <p>Member ship All Students, Faculty, Administrative staff and supporting staff of the college are entitled to become members of the central library. The membership application forms can be obtaining from circulation counter and should be submitted through proper channel.</p>
                    <p>Area & Capacity Plinth Area : 435 sq.m Seating Capacity : 200</p>
                </div>
                <div>
                    
                </div>
                <div className='library_threebox'>
                    <h3>Collection</h3><br />
                    <Accordion className='library_accordion'  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Electronics and Communication Engineering</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                                1930
                                            </td>
                                            <td>
                                                8600
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}>  Computer Science Engineering</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            1610
                                            </td>
                                            <td>
                                            7582
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Electrical And Electronics Engineering</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            1260
                                            </td>
                                            <td>
                                            6514
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Mechanical Engineering</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            1370
                                            </td>
                                            <td>
                                            7745
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Computer Science and Engineering (AI  & ML)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            350
                                            </td>
                                            <td>
                                            1000
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Artificial Intelligence and Data Science </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            350
                                            </td>
                                            <td>
                                            1000
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}>  Diploma in Electronics and Communication Engineering</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            580
                                            </td>
                                            <td>
                                            3300
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}>  Diploma in Electrical And Electronics Engineering</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            580
                                            </td>
                                            <td>
                                            3064
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Diploma in Computer Science and Engineering </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            460
                                            </td>
                                            <td>
                                            2455
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Master of Business Administration </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            1000
                                            </td>
                                            <td>
                                            7868
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> SC/ST Students Book Bank</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            650
                                            </td>
                                            <td>
                                            2889
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Reference Books</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            
                                            </td>
                                            <td>
                                            7145
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Circulation Books</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            
                                            </td>
                                            <td>
                                            35589
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}> Total Titles & Volumes</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            9498
                                            </td>
                                            <td>
                                            48789
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}>  NPTEL CD-ROMs</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            
                                            </td>
                                            <td>
                                            2657
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    <Accordion className='library_accordion'  expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ fontSize: '13px',fontWeight:"bold",color:"orangered", }}>  Project Reports
</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <table className='library_facilities_table'>
                                    <thead>
                                        <tr>
                                            <th>Titiles</th>
                                            <th>Volumes</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                            
                                            </td>
                                            <td>
                                            950
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Typography>
                        </AccordionDetails>
                    </Accordion><br/>
                    

                </div>
                </div>
                <div>

            </div>
        </div>
    )
}

export default Library

import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./Naac.css";
import { PageHeader } from "../Header/PageHeader";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { useState } from "react";
import RotatingSpinner from "../Resourses/RotatingSpinner";


const Naacdvv = () => {
  const [isLoading, setIsLoading] = useState(false);


  const handleBase64Data = async (param) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        resources.APPLICATION_URL + "filenames?filename=" + param + "&foldername=dvv"
      );
      const base64 = response.data;

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (e) {
      console.log("error", e);

    } finally {
      setIsLoading(false);
    }

  }


  return (
    <>
    {isLoading ? <RotatingSpinner/> : (
      <div className="container mt-5">
      <PageHeader name={"NAAC DVV REPORT"} />
      <div className="crt_main">
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Extended Profile</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> 1.1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.1(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.1(1) Admission approvals for the last five years{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.1(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.1(2) List of students for the last five years
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>2.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1(1)List of full-time teachers for the last five
                            years
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1(2)Appointment letters of full-time teachers in
                            the A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1(3)Appointment letters of full-time teachers in
                            the A.Y.2020-21{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1(4) Appointment letters of full-time teachers in
                            the A.Y.2019-20{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1(5) Appointment letters of full-time teachers in
                            the A.Y.2018-17{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1(6).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1(6)Appointment letters of full-time teachers in
                            the A.Y.2017-18{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>2.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.2.pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.2List of full-time teachers for the last five
                            years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>3.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.1.pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.1CA certified audited statement by highlighting
                            the expenditure excluding salary component for the
                            last five years
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 1</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> 1.2.1 </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.2.1(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2.1(1)Circular, Course Structure, List of enrolled
                          students and Sample Certificates for offered Add-on
                          programs during the A.Y.2021-22{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.2.1(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2.1(2) Circular, Course Structure, List of enrolled
                          students and Sample Certificates for offered Add-on
                          programs during the A.Y.2020-21
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.2.1(3).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2.1(3) Circular, Course Structure, List of enrolled
                          students and Sample Certificates for offered Add-on
                          programs during the A.Y.2019-20{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.2.1(4).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2.1(4) Circular, Course Structure, List of enrolled
                          students and Sample Certificates for offered Add-on
                          programs during the A.Y.2018-19{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("1.2.1(5).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2.1(5) Circular, Course Structure, List of enrolled
                          students and Sample Certificates for offered Add-on
                          programs during the A.Y.2017-18{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>1.2.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.2.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.2.2(1) List of Students, Attendance Sheet and
                            Model certificates of offered Add-on programs during
                            the A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.2.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.2.2(2) List of Students, Attendance Sheet and
                            Model certificates of offered Add-on programs during
                            the A.Y.2020-21
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.2.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.2.2(3) List of Students, Attendance Sheet and
                            Model certificates of offered Add-on programs during
                            the A.Y.2019-20{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.2.2(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.2.2(4) List of Students, Attendance Sheet and
                            Model certificates of offered Add-on programs during
                            the A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.2.2(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.2.2(5) List of Students, Attendance Sheet and
                            Model certificates of offered Add-on programs during
                            the A.Y.2017-18{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>1.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.3.2(1) List of Students along with the details of
                            title, place of work, duration of Project
                            works/Internships during the A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.3.2(2)e-copies of Internship completion
                            certificates
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.3.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.3.2(3)e-copies of Project work completion
                            certificates{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.3.2(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.3.2(4)Documents related to fieldwork{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 1.4.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.4.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.4.1(1) Sample filled feedback forms
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.4.1(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.4.1(2)Stakeholder Feedback analysis report
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.1.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.4.1(3) Action taken report on feedback
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("1.4.1(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            1.4.1(4) Communication with affiliating University
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> 2.1.1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("2.1.1(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.1.1(1)Documents related to sanction of intake from
                          AICTE for the last five years
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("2.1.1(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.1.1(2) Approved program-wise admission list for the
                          last five years
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 2.1.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1.2(1) Copy of letter issued by state Government
                            indicating reserved categories
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1.2(2)")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1.2(2) Certified letter indicating number of seats
                            year-wise earmarked for reserved categories{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.1.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.1.2(3)Certified admission extract for the last
                            five years
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>2.2.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.2.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.2.1(1) List of full-time teachers in the
                            A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.2.1(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.2.1(2) List of full time teachers A.Y: 2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>2.4.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.4.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.4.1(1) Sanction letters for the last five years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.4.1(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.4.1(2) List of full-time teachers for the last
                            five years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 2.4.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.4.2.pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.4.2 List of faculty with e-copies of
                            NET/SLET/SET/Ph.D certificates for the last five
                            years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 2.6.3 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.6.3(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.6.3(1)Result sheet published by the affiliating
                            university along with the certified report from the
                            Head of the Institution for the A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.6.3(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.6.3(2) Result sheet published by the affiliating
                            university along with the certified report from the
                            Head of the Institution for the A.Y.2020-21
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.6.3(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.6.3(3) Result sheet published by the affiliating
                            university along with the certified report from the
                            Head of the Institution for the A.Y.2019-20
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.6.3(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.6.3(4) Result sheet published by the affiliating
                            university along with the certified report from the
                            Head of the Institution for the A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("2.6.3(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.6.3(5) Result sheet published by the affiliating
                            university along with the certified report from the
                            Head of the Institution for the A.Y.2017-18{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 3</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>3.2.2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("3.2.2(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.2.2(1)Circular, Borchure and Programme report for
                          the conducted seminars during the A.Y: 2021-22{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("3.2.2(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.2.2(2) Circular, Borchure and Programme report for
                          the conducted seminars during the A.Y.2020-21{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("3.2.2(3).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.2.2(3) Circular, Borchure and Programme report for
                          the conducted seminars during the A.Y.2019-20{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("3.2.2(4).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.2.2(4) Circular, Borchure and Programme report for
                          the conducted seminars during the A.Y.2018-19
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("3.2.2(5).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.2.2(5) Circular, Borchure and Programme report for
                          the conducted seminars during the A.Y.2017-18{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>3.3.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.3.1.pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.3.1 List of research papers published along with
                            the link to the journal website and research paper
                            for the last five years
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 3.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.3.2.pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.3.2 Cover page, content page and first page of
                            books, book chapters and conference proceedings
                            during the last five years
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>3.4.3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.4.3(0).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.4.3(0) Extension and Outreach Programs
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.4.3(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.4.3(1) Documents related to extension activities
                            offered during the A.Y.2021-22
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.4.3(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.4.3(2) Documents related to extension activities
                            offered during the A.Y.2020-21{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.4.3(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.4.3(3) Documents related to extension activities
                            offered during the A.Y.2019-20{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.4.3(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.4.3(4) Documents related to extension activities
                            offered during the A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.4.3(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.4.3(5) Documents related to extension activities
                            offered during the A.Y.2017-18
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>3.5.1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.5.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.5.1(1) List of activities conducted, duration of
                            the MoU along with e-copies of MoUs for the
                            A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.5.1(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.5.1(2) List of activities conducted, duration of
                            the MoU along with e-copies of MoUs for the
                            A.Y.2020-21{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.5.1(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.5.1(3) List of activities conducted, duration of
                            the MoU along with e-copies of MoUs for the
                            A.Y.2019-20{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.5.1(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.5.1(4) List of activities conducted, duration of
                            the MoU along with e-copies of MoUs for the
                            A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("3.5.1(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.5.1(5) List of activities conducted, duration of
                            the MoU along with e-copies of MoUs for the
                            A.Y.2017-18{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 4</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>4.1.2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("4.1.2.pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          4.1.2 CA Certified Audited statement by highlighting
                          expenditure towards infrastructure augmentation for
                          the last five years{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>4.3.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(1) Stock register entries of computers
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("4.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            4.3.2(2) Invoices of the puchase of computers by
                            highlighting the quantity of computers{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 5</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> 5.1.1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("4.3.2(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.1.1(1) Sanction letters of scholarships provided by
                          Government during the last five years{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("5.1.1(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.1.1(2) List of students benefitted by Government
                          scholarships during the last five years
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("5.1.1(3).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.1.1(3)Policy document for the award of scholarships
                          and freeships by the Institution{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.1.2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.2(1) e-copies of circulars{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography> 5.1.2(2) Programme reports</Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography> 5.1.2(3) Photographs</Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.1.3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.3(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.3(1) Circulars, Report of the programme with
                            Photographs along with the details of resource
                            persons for the offered guidance for competitive
                            examinations and career counselling by the
                            Institution for the last five years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.3(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.3(2) Certified list of benefitted students for
                            the last five years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.1.4 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.4(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.4(1) Minutes of Meetings of GRC{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.4(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.4(2) Minutes of Meetings of Anti-Ragging
                            Committee{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.4(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.4(3) Minutes of Meetings of WPC&ICC
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.4(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography> 5.1.4(4)Policy documents</Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.1.4(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.1.4(5)Weblink for online student grievances{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.2.1 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.1(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.1(1) List of students placed along with their
                            placement details for the last five years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.1(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.1(2) Offer letters of the placed students in the
                            A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.1(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.1(3) Offer letters of the placed students in the
                            A.Y.2020-21{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.1(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.1(4) Offer letters of the placed students in the
                            A.Y.2019-20{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.1(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.1(5) Offer letters of the placed students in the
                            A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.1(6).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.1(6) Offer letters of the placed students in the
                            A.Y.2017-18{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.1(7).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.1(7) e-copies of admission letters for students
                            progressed to higher education{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.2.2 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.2(1) List of qualified students under each
                            category for the last five years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.2.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.2.2(2) e-copies of qualifying certificates of the
                            students{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.3.1 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.1.pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.1 e-copies of award letters for outstanding
                            performance in Sports/Cultural during the last five
                            years{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 5.3.2 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(1) Documents related to the organised
                            sports/cultural programs along with list of
                            participants for the A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(2) e-copies of students certificates
                            participated in other Institutions in the
                            A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(3) Documents related to the organised
                            sports/cultural programs along with list of
                            participants for the A.Y.2020-21{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(4) e-copies of students certificates
                            participated in other Institutions in the
                            A.Y.2020-21{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(5) Documents related to the organised
                            sports/cultural programs along with list of
                            participants for the A.Y.2019-20
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(6).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(6) e-copies of students certificates
                            participated in other Institutions in the
                            A.Y.2019-20{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(7).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(7) Documents related to the organised
                            sports/cultural programs along with list of
                            participants for the A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(8).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(8) e-copies of students certificates
                            participated in other Institutions in the
                            A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(9).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(9) Documents related to the organised
                            sports/cultural programs along with list of
                            participants for the A.Y.2017-18{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("5.3.2(10).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.3.2(10) e-copies of students certificates
                            participated in other Institutions in the
                            A.Y.2017-18
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 6</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>6.2.2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.2.2(1) Institutional expenditure statements
                          reflecting the implementation of e-governance
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.2.2(2) ERP document and Screenshots of User
                          interface{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(3).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.2.2(3) Annual e-governance report{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("6.2.2(4).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography> 6.2.2(4) Policy Document </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 6.3.2 </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(1)List of teachers receiving financial support
                            along with e-copies of sanction letters for the
                            A.Y.2021-22
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(2) List of teachers receiving financial
                            support along with e-copies of sanction letters for
                            the A.Y.2020-21
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(3) List of teachers receiving financial
                            support along with e-copies of sanction letters for
                            the A.Y.2019-20{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(4) List of teachers receiving financial
                            support along with e-copies of sanction letters for
                            the A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.2(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.2(5) List of teachers receiving financial
                            support along with e-copies of sanction letters for
                            the A.Y.2017-18{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>6.3.3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(1) e-copies of certificates of teachers
                            attended Professional Development Programs in the
                            A.Y.2021-22
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(2) e-copies of participation certificates of
                            non-teaching staff in Administrative Training
                            Programs offered during the A.Y.2021-22{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(3) e-copies of certificates of teachers
                            attended Professional Development Programs in the
                            A.Y.2020-21
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(4) e-copies of participation certificates of
                            non-teaching staff in Administrative Training
                            Programs offered during the A.Y.2020-21{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(5) e-copies of certificates of teachers
                            attended Professional Development Programs in the
                            A.Y.2019-20
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(6).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(6) e-copies of participation certificates of
                            non-teaching staff in Administrative Training
                            Programs offered during the A.Y.2019-20{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(7).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(7) e-copies of certificates of teachers
                            attended Professional Development Programs in the
                            A.Y.2018-19
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(8).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(8) e-copies of participation certificates of
                            non-teaching staff in Administrative Training
                            Programs offered during the A.Y.2018-19{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(9).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(9) e-copies of certificates of teachers
                            attended Professional Development Programs in the
                            A.Y.2017-18{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.3.3(10).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.3.3(10) e-copies of participation certificates of
                            non-teaching staff in Administrative Training
                            Programs offered during the A.Y.2017-18
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>6.5.3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.5.2(1) Proceedings of Meetings of IQAC
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.5.2(2) Collected feedback, analysis and Action
                            taken report{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.5.2(3) Academic and Administrative Audit{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.5.2(4) List of collaborative quality initiatives
                            with other Institutions along with e-copies of
                            collaboration letters{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("6.5.2(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography> 6.5.2(5) ISO Certificate</Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="naac_ssr_criterias">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Criterion 7</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Accordion className="naac_ssr_inside">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> 7.1.2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("7.1.2(1).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography> 7.1.2(1)Policy Documents </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("7.1.2(2).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          7.1.2(2) Geo-tagged Photographs and Videos.{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("7.1.2(3).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          7.1.2(3) Bills for the purchased equipments{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("7.1.2(4).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          7.1.2(4)Report on provided facilities{" "}
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                    <AccordionDetails>
                      <AccordionSummary
                        expandIcon={
                          <button
                            style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                            onClick={() => handleBase64Data("7.1.2(5).pdf")}
                          >
                            <VisibilityIcon />
                          </button>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          7.1.2(5) Documents related to Green campus initiatives
                        </Typography>
                      </AccordionSummary>
                    </AccordionDetails>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Typography>
            <Typography>
              <Typography>
                <Accordion className="naac_ssr_inside">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography> 7.1.3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(1).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography> 7.1.3(1) Policy Documents</Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(2).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            7.1.3(2) Certified Audited Reports
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(3).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            7.1.3(3) Report on Green campus initiatives
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(4).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            7.1.3(4) Geo-tagged Photographs
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                      <AccordionDetails>
                        <AccordionSummary
                          expandIcon={
                            <button
                              style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                              onClick={() => handleBase64Data("7.1.3(5).pdf")}
                            >
                              <VisibilityIcon />
                            </button>
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            7.1.3(5) Documents related to Beyond campus
                            environment promotional activities{" "}
                          </Typography>
                        </AccordionSummary>
                      </AccordionDetails>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
    )}
    </>
  );
};

export default Naacdvv;

import react, { useState } from "react"
import { resources } from "../Resourses/Resources"
const SupplyFeeExcelUpload=()=>{
    const [selectedFile,setSelectedFile]=useState([])
    const handleSelectFile=async(e)=>{
const files=e.target.files[0]
setSelectedFile(files)
    }
    const handleReset=async()=>{
        document.getElementById("studentExcel").value=""
    }
    const handleSubmit=async()=>{
        const formData=new FormData()
        formData.append("studentExcelFile",selectedFile)
    }
    return(
        <div className="uploadExcelContainer">
            <div>
               <label>Supply Fee Details Excel File</label>
            </div>
         <div className="fileAndButtonDiv">
         <div className="fileDiv">
                <input type="file" id="studentExcel" onChange={(e)=>handleSelectFile(e)}/>
            </div>
            <div className="fileButtons">
                <button className="resetButton" onClick={()=>handleReset()}>Reset</button>
                <button className="uploadButton" onClick={()=>handleSubmit()}>Upload</button>
            </div>
         </div>
         <div>
            <a className="anchorTagDownload" href={resources.APPLICATION_URL+"getStudentExcelUploadTemplate"}>Download Template</a>
        </div>
        </div>
    )
}
export default SupplyFeeExcelUpload;
import { useEffect, useState } from "react";
import "./NotificationPannel.css";
import * as React from 'react';

import axios from "axios";
import { resources } from "../Resourses/Resources";
import Dialog from '@mui/material/Dialog';

import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
import PaginationReUsable from "../ReuseableComponent/ReusePagination";
import ImageViewer from "../Login/GalleryView";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const latestUpdates = [
  {
    notification:
      "Student Development Programme on Business Analytics – Power BI and R Software 6-11 January, 2024",
    date: "01-01-2024",
  },
  {
    notification: "Sai Spurthi Notification 2",
    date: "02-01-2024",
  },
  {
    notification: "Sai Spurthi Notification 3",
    date: "03-01-2024",
  },
];

const NotificationPannel = () => {
  const [scroolAmount, setScroolAmount] = useState(0);
  const [officeNtifications, setOfficeNotifications] = useState([])
  const [examBranchNotifications, setExamBranchNotifications] = useState([])
  const [generalNotifications, setGeneralNotifications] = useState([])
  const [viewFile, setViewFile] = useState(false)
  const [fileToOpen, setFileToOpen] = useState("")
  const handleClickOpen = () => {
    setViewFile(true);
  };
  const handleClose = () => {
    setViewFile(false);
  };
  function stopMarquee() {
    const marquee = document.querySelector(
      ".notification_pannel_inner marquee"
    );

    if (marquee) {
      marquee.stop();
    }
  }

  function startMarquee() {
    const marquee = document.querySelector(
      ".notification_pannel_inner marquee"
    );
    if (marquee) {
      marquee.start();
    }
  }
  const fetchOfficeRecords = async () => {
    try {
    const details = await axios.get(resources.APPLICATION_URL + `getLastFiveRecords?typeOfUpload=Office`)
    console.log("office details====>", details.data)
    setOfficeNotifications(details?.data)
  } catch (error) {
    console.log("error==>", error)
  }
  }

  const fetchGeneralNotifictions = async () => {
    try {
      const details = await axios.get(resources.APPLICATION_URL + `getLastFiveRecords?typeOfUpload=General`)
      console.log(" general details====>", details.data)
      setGeneralNotifications(details?.data)
    } catch (error) {
      console.log("error==>", error)
    }
  }

  const fetchExamBranchNotifications = async () => {
    try {
      const details = await axios.get(resources.APPLICATION_URL + `getLastFiveRecords?typeOfUpload=ExamBranch`)
      console.log(" exam branch details====>", details.data)
      if(details?.data?.length > 0){
        setExamBranchNotifications(details?.data)
      }
     
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {

    fetchGeneralNotifictions()
    fetchExamBranchNotifications()
    fetchOfficeRecords()


  }, [])
  const handleViewFile = async (filedata) => {
    setViewFile(true)
    setFileToOpen(filedata)
  }
  return (
    <>
      <div className="notification_cnt">
        <div className="notification_button_cnt">
          <button>Office Notifications</button>
          <button>General Notifications</button>
          <button>Examination News</button>
        </div>
        <div className="notification_pannel">
          <div className="notification_pannel_inner">
            <div className="marquee-wrapper">
              <div className="marquee-content">
                {officeNtifications && officeNtifications.length > 0 && officeNtifications?.map((each, index) => (
                  <div key={index} className="notification_content" style={{ cursor: "pointer" }} onClick={() => { handleViewFile(each.filePath) }}>
                    <p className="notification_date">
                      <a href="#"> {each.startDate} - {each.endDate}</a></p>
                    <p className="notification_text">
                      <a href="#">{each.description}</a>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="notification_pannel_inner">
            {/* <marquee behavior="scroll" direction="up" scrollamount="5"> */}
            <div className="marquee-wrapper">
              <div className="marquee-content">
                {generalNotifications && generalNotifications.length > 0 && generalNotifications?.map((each) => {
                  return (
                    <div className="notification_content" style={{ cursor: "pointer" }} onClick={() => { handleViewFile(each.filePath) }}>
                      <p className="notification_date">
                        <a href="#" style={{ color: "#fff" }}>{each.startDate} - {each.endDate}</a></p>
                      <p className="notification_text">
                        <a href="#"> {each.description}</a>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* </marquee> */}
          </div>
          <div
            className="notification_pannel_inner"
            onMouseEnter={() => stopMarquee()}
            onMouseLeave={() => startMarquee()}
            style={{ overflow: "hidden" }}
          >
            <marquee behavior="scroll" direction="up" scrollamount="8">
              {examBranchNotifications && examBranchNotifications?.length > 0 && examBranchNotifications?.map((each, index) => (
                <div key={index} className="notification_content" id={index} style={{ cursor: "pointer" }} onClick={() => { handleViewFile(each.filePath) }}>
                  <p className="notification_date">
                    <a href="#" style={{ color: "#fff" }}> {each.startDate} - {each.endDate}</a>
                  </p>
                  <p className="notification_text">
                    <a href="#">{each.description}</a>
                  </p>
                </div>
              ))}
            </marquee>
          </div>
        </div>
        <React.Fragment>
          <Dialog
            fullScreen
            open={viewFile}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                width: '100%',
                height: '100%',
                margin: 0,
                padding: 0,
                maxHeight: '100vh',
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

              </Toolbar>
            </AppBar>
            {fileToOpen && (
              <iframe
                src={`data:application/pdf;base64,${fileToOpen}`}
                width="100%"
                height="100%"
              />
            )}
          </Dialog>
        </React.Fragment>
      </div>


    </>
  );
};

export default NotificationPannel;

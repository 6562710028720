import React from 'react'
import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const NotificationUpload = () => {
    const [imgList, setImgList] = useState([]);
    const [fileName, setFileNames] = useState([])

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileNames([...fileName, file.name])
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImgList([...imgList, reader.result]);
                console.log(reader.result);

            }
        }
    }

    return (
        <>
            <div className='photo_upload_main_container'>
                <div className='file_upload_container'>
                    <p className='upload_heading'>Notification Upload</p>
                    <input multiple type="file" onChange={(e) => handleFileUpload(e)} />
                </div>

            </div>


            <TableContainer className="adminstration_table" component={Paper} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Table sx={{ maxWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className="adminstration_table_cell">
                                S No
                            </StyledTableCell>
                            <StyledTableCell className="adminstration_table_cell">
                                File Name
                            </StyledTableCell>
                            <StyledTableCell className="adminstration_table_cell">
                                File
                            </StyledTableCell>
                            <StyledTableCell className="adminstration_table_cell">
                                Action
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {imgList.map((row, index) => (
                            <StyledTableRow
                                className="adminstration_table_row"
                                key={row.name}
                            >
                                <StyledTableCell
                                    className="adminstration_table_cell"
                                    component="th"
                                    scope="row"
                                >
                                    {index + 1}
                                </StyledTableCell>
                                <StyledTableCell className="adminstration_table_cell">
                                    {fileName[index]}
                                </StyledTableCell>
                                <StyledTableCell className="adminstration_table_cell">
                                    <img src={row} width="100px" height="100px" />
                                </StyledTableCell>

                                <StyledTableCell className="adminstration_table_cell">
                                    <button onClick={() => setImgList(imgList.filter((_, i) => i !== index))}><DeleteIcon /></button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default NotificationUpload

import React from 'react'
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
const HeaderAdmissionForm = () => {
  return (
    <div className="admission_header">
            <h1>APPLICATION FORM FOR ADMISSION TO 1ST YEAR OF B.TECH</h1>
            <lable>FOR THE ACADEMIC YEAR 2024</lable>
            <div className="admission_btn_container">
              <Navbar bg="transparent" data-bs-theme="light">
                <Container>
                  <Nav className="me-auto">
                    <Nav.Link style={{ color: "#fff" }} href="/admissions/form">
                      Registration Form
                    </Nav.Link>
                    <Nav.Link style={{ color: "#fff" }} href="/ViewLogin">
                      View The Records
                    </Nav.Link>
                    <Nav.Link style={{ color: "#fff" }} href="/gallery/login">
                      Login
                    </Nav.Link>
                  </Nav>
                </Container>
              </Navbar>
            </div>
          </div>
  )
}

export default HeaderAdmissionForm
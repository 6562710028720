import React from "react";
import { Link } from "react-router-dom";

import chairman from "../../assets/chairman1.jpg";
import secretary from "../../assets/mangimg.jpg";
import trustee from "../../assets/Trustee-2.png";
import principal from "../../assets/sai spurthi principal.jpg";



// import secretary from "../../assets/Secretary1.jpg";

import logo from "../../assets/spurthi logo.png";
import "../Management/Management.css";

const Management = () => {
  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              MANAGEMENT
            </h6>
            <h1 className="mb-5">
              CHAIRMAN / SECRATARY & CORRESPONDENT /TRUSTEE /PRINCIPAL
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-3 col-md-3 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow">
                  <img
                    className="img rounded img "
                    src={chairman}
                    style={{objectFit:"contain"}}
                    alt="chairman-img"
                  />
                </div>
                <div
                  className="position-relative d-flex justify-content-center"
                  style={{ marginTop: "-13px" }}
                >
                  {/* <div className="bg-light d-flex justify-content-center pt-2 px-1">
                    <Link className="btn btn-sm-square btn-primary mx-1" to="">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link className="btn btn-sm-square btn-primary mx-1" to="">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link className="btn btn-sm-square btn-primary mx-1" to="">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </div> */}
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Dr.B.PardhaSaradhiReddy.</h5>
                  <small>CHAIRMAN</small>
                  <br />
                  <br />
                  <div className="container_text">
                    <small>
                      Sri Dr. B.Pardha Saradhi Reddy is a noted industrialist,
                      the founder of HETERO Pharmaceuticals, Hyd, a member of
                      Parliament, and a resident of Kandukuru, Khammam District,
                      TS, established the institute with a philanthropic motto
                      to impart a quality technical education that can lift the
                      poorer’ aspirations of the rural surroundings in a serene
                      and enthralling academic and natural ambience The motive
                      of establishing the college here is to impart quality
                      technical education to the rural people and to uplift the
                      economically backward students talent to greater levels.
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-3 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow">
                  <img
                    className="img rounded  "
                    style={{ objectFit: "contain" }}
                    src={secretary}
                    alt="secretary-img"
                  />
                </div>
                <div
                  className="position-relative d-flex justify-content-center"
                  style={{ marginTop: "13px" }}
                >
                  {/* <div className="bg-light d-flex justify-content-center pt-2 px-1">
                    <Link className="btn btn-sm-square btn-primary mx-1" to="">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link className="btn btn-sm-square btn-primary mx-1" to="">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link className="btn btn-sm-square btn-primary mx-1" to="">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </div> */}
                </div>
                <div className="text-center p-6">
                  <h5 className="mb-0">Sri D. Prabhakar Reddy.</h5>
                  <small>Secretary & Correspondent</small>
                  <br />
                  <br />
                  <div className="container_text">
                    <small>
                      Sri D. Prabhakar Reddy, a renowned academician in the
                      vicinity, has been promoting an institute for the noble
                      cause of promoting quality technical education through the
                      VIPASSANA Educational Society, thanks to the enthused idea
                      of Dr. B. Pardha Saradhi Reddy garu, who established the
                      institute. It has been nurturing the many young poor rural
                      lots towards all-round progression, which is possible only
                      through value-added education. Sri D. Prabhakar Reddy is a
                      visionary to witness the revolution of converting a near
                      average student in intermediate into a disciplined and
                      sincere engineer of a future India. He constantly advises
                      the staff to put this concept in practice. Sri D.
                      Prabhakar Reddy takes active interest in the day-to-day
                      smooth running of the college through watchful monitoring
                      of the college. He periodically visits the college to
                      consolidate the functioning as per the plan that has been
                      drawn earlier. He is a constant source of inspiration for
                      the students and staff of the college.
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-3 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid img"
                    src={trustee}
                    alt="trustee-img"
                  />
                </div>
                <div
                  className="position-relative d-flex justify-content-center"
                  style={{ marginTop: "-13px" }}
                ></div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Smt BANDI ANVIDA</h5>
                  <small> Vipassana Educational Trustee</small>
                  <br />
                  <br />
                  <div className="container_text">
                    <small>
                      Smt. Bandi Anvida serves as the trustee of VIPASSANA
                      EDUCATIONAL SOCIETY, demonstrating unwavering dedication
                      and expertise in guiding our institution. With a profound
                      commitment to advancing education and fostering community
                      development, Smt. Anvida plays a pivotal role in shaping
                      the strategic direction and policies of our trust. Her
                      visionary leadership and steadfast support are
                      instrumental in driving our mission forward, ensuring the
                      success and empowerment of our students and stakeholders.
                      I am committed to fostering an inclusive environment where
                      every student has access to quality education and
                      opportunities for personal growth. My vision for the
                      college is centered around academic excellence, ethical
                      values, and community engagement. I believe in providing
                      students with a well-rounded education that equips them
                      with the skills and knowledge to succeed in an
                      ever-changing world. Together, let us work towards
                      realizing this vision and creating a brighter future for
                      our students and communities.
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-3 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item bg-light img_cnt">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid img"
                    src={principal}
                    alt="principal-img"
                  />
                </div>
                <div
                  className="position-relative d-flex justify-content-center"
                  style={{ marginTop: "-13px" }}
                ></div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Dr. V.S.R Kumari</h5>
                  <small> PRINCIPAL</small>
                  <br />
                  <br />
                  <div className="container_text">
                    <small>
                    Dr. V.S.R Kumari, a leading Principal It is an extreme honour to be a part of achieving academic
                  excellence with the institute and I am much obliged to
                  felicitate the infrastructure of global technical and industry
                  orientation while safeguarding the values and esteem of the
                  institute and taking them to the next level by an integrating
                  my research, academic and technical exposure and also in
                  collaboration with good many value nurturing institutions.
                  with process
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default Management;

import react, { useEffect, useState } from "react"
import Cookies from "js-cookie"
import axios from "axios"
import { resources } from "../Resourses/Resources"

const ViewStudentEnquiryForm = () => {
    const useData = Cookies.get("userDetails")
    const userDetails = JSON.parse(useData)
    const [departMent, setDepartment] = useState("")
    const [userName, setUserName] = useState("")
    const [enquiryFormDetails, setEnquiryFormDetails] = useState([])
    useEffect(() => {
        const roll = userDetails.username
        const branchdata=userDetails.program
        setUserName(userDetails.username)
        setDepartment(userDetails.program)
    }, [])
    useEffect(() => {
        console.log("rollNumber===>",userName)
        if (userName !== null && userName !== "") {
            const fetchEnquiryForm = async () => {
                try {
                    const response = await axios.get(resources.APPLICATION_URL + `getAllEnquiryFormsByRollNumber?userName=${userName}`)
                    if (response.data) {
                        setEnquiryFormDetails(response.data)
                    }
                } catch (error) {
                    console.log("error===>", error)
                }
            }
            fetchEnquiryForm()
        }
    }, [userName])
    console.log("enquiryFormDetails===>",enquiryFormDetails)
    return (
        <div>
            <div>
              <table className="tableStylings">
                <thead >
                    <tr>
                        <th>S.No</th>
                        <th>Subject</th>
                        <th>Message</th>
                        <th>Feed back</th>
                    </tr>
                </thead>
                <tbody>
                    {enquiryFormDetails && enquiryFormDetails.length > 0 && enquiryFormDetails.map((items,index)=>{
                        return(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{items.subject}</td>
                                <td>{items.message}</td>
                                <td>{items.feedBack}</td>
                            </tr>
                        )
                    })}
                </tbody>
              </table>
            </div>
        </div>
    )
}
export default ViewStudentEnquiryForm;
import React from "react";
import StarIcon from "@mui/icons-material/Star";

const Cafeteria = () => {
  return (
    <div>
      <div className="container text-left">
        <h4 className="text text-center">Cafeteria</h4><br/>
        <p>
          <StarIcon />
          &nbsp; Our Institute has a spacious canteen with hygienic environment
          which provides quality food and prompts service and caters to needs of
          all the students and the staff.
        </p>
        <p>
          <StarIcon />
          &nbsp; The canteen is open from 6:30 a.m. to 7:00 p.m. There is a wide
          variety of North-Indian and South-Indian cuisine and the students
          enjoy the pleasure of eating during the breaks.<br/>  &nbsp; &nbsp; &nbsp;  &nbsp;R.O purified water for
          drinking is available.
        </p>
      </div>
    </div>
  );
};

export default Cafeteria;





import React, { useState } from "react";
import "./Register.css";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

import { resources } from "../Resourses/Resources";

const Register = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("")
    const navigate=useNavigate()
    const [login, setLogin] = useState({
        userName: "",
        password: "",
        role: "",
        emailId: "",
        departMent: ""
    })
    const branches=["CSE(AI&ML)","CSE(AI&DL)","S&H","CSE","ECE","EEE","MECH","MBA","MCA","Management"]
    const userRoles = ["admin","examBranch","office","student", "faculty", "hod", "principal", "committeeMember"]
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleChangeLogin = (e) => {
        console.log("e.target.value===>", e.target.value)
        e.preventDefault();
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        })
    }

    function encryptFun(Password) {
        const data = Password;
        const key = CryptoJS.enc.Latin1.parse("1234567812345678");
        const iv = CryptoJS.enc.Latin1.parse("1234567812345678");
        const encrypted = CryptoJS.AES.encrypt(data, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding,
        });
        console.log("encrypted: " + encrypted);
        // var decrypted = CryptoJS.AES.decrypt(encrypted,key,{iv:iv,padding:CryptoJS.pad.ZeroPadding});
        // console.log('decrypted: '+decrypted.toString(CryptoJS.enc.Utf8));
        return encrypted.toString();
      }
    const handleRegister = async (e) => {
        e.preventDefault();
        const signupRequest = {
            username: login.userName,
            password: encryptFun(login.password),
            role: [login.role],
            email: login.emailId,
            department: login.departMent,
        };
        if (login.password !== confirmPassword) {
            alert("Password and confirm password should be same")
        } else {
            try {

                console.log("signupRequest:", signupRequest);
                const response = await axios.post(
                    resources.AUTHORIZE_URL + "signup",signupRequest
                
                );
                if(response.status===200){
                    setLogin({
                        userName: "",
                        password: "",
                        role: "",
                        emailId: "",
                        departMent: ""
                    })
                    setConfirmPassword("")
              
                    alert("User registered successfully")
                    navigate("/gallery/login")
                }else{
                    alert("Something went wrong")
                }
                console.log("response===>",response)
            } catch (e) {
                console.log(e)
            }
        }


    }

    return (

        <div className="registerContainer">
            <div className="registerMainContainer">
                <div className="centerTheHTag">
                    <h1>Register</h1>
                </div>
                <form id="" className="registerFrmContainer" >
                    <div className="mainContainer">
                        <div className="containerOne">
                            <div className="inputAndLabelDiv">
                                <label htmlFor="userName">User Name</label>
                                <input type="text" name="userName" value={login.userName} onChange={(e) => handleChangeLogin(e)} />
                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="emailId">Email Id</label>
                                <input type="email" name="emailId" value={login.emailId} onChange={(e) => handleChangeLogin(e)} />
                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="departMent">DepartMent</label>
                                {/* <input type="text" name="departMent" value={login.departMent} onChange={(e) => handleChangeLogin(e)} /> */}

                                <select name="departMent" value={login.departMent} onChange={(e) => handleChangeLogin(e)}>
                                    <option>Please Select</option>
                                    {branches.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="checkbox">
                                    <input
                                        type="checkbox"
                                        name="checkbox"
                                        onChange={togglePasswordVisibility}
                                    />{" "}
                                    Show Password
                                </label>
                            </div>
                        </div>
                        <div className="containerOne">
                            <div className="inputAndLabelDiv">
                                <label htmlFor="role">Role</label>
                                <select name="role" value={login.role} onChange={(e) => handleChangeLogin(e)}>
                                    <option>Please Select</option>
                                    {userRoles.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="password">Password</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={login.password}
                                    onChange={(e) => handleChangeLogin(e)}
                                />
                            </div>


                            <div className="inputAndLabelDiv">
                                <label htmlFor="confirmpassword">Confirm Password</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>



                        </div>
                    </div>
                    <div className="registerDiv">
                        <button className="" onClick={handleRegister}>Register</button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default Register;

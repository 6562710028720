import react from "react"
import "./BranchMenu.css"

const SyllabusMenu = () => {
    return (
        <div>
            <div class="container text-left">
                <h1>Curriculum and Syllabus</h1>
                <p>The Boards of Studies of the respective program prepare the program curriculum and course syllabus and approved by the Academic Council. Program Outcomes, Knowledge, skills and attitude, and Program Educational Objectives have been established for each program and the curriculum and course syllabus have been structured in such a way that each of the course contributes to the one or more of these outcomes.
                    The curriculum and course syllabus varies depending on the year of admission.</p>
            </div>
            <div class="container text-left">
                <a href="">First Year B.Tech R18 Syllabus</a>
            </div>
            <div class="container text-left">
                <h1>B.TECH PROGRAMMES CURRICULUM</h1>
                <table className="tableStylings">
                    <thead>
                        <tr>
                            <th>UG Programme</th>
                            <th>Program Outcome</th>
                            <th>R16(Effective from 2017-18)</th>
                            <th>R13(Effective from 2014-15)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Computer Science and Engineering</td>
                            <td><a href="#">Program Outcomes</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                        </tr>
                        <tr>
                            <td> Electronics and Communication Engineering</td>
                            <td><a href="#">Program Outcomes</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                        </tr>
                        <tr>
                            <td>Electrical & Electronics Engineering</td>
                            <td><a href="#">Program Outcomes</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                        </tr>
                        <tr>
                            <td>Mechanical Engineering</td>
                            <td><a href="#">Program Outcomes</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="container text-left">
                <h1>MBA CURRICULUM</h1>
                <table className="tableStylings">
                    <thead>
                        <tr>
                            <th>PG Programme</th>
                            <th>Program Outcome</th>
                            <th>MBA15(Effective from 2015-16)</th>
                            <th>(2015-16)MBA19/ MCA 19(Effective from 2019-20)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Masters in Business Administration	</td>
                            <td><a href="#">Program Outcomes</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                            <td><a href="#">Download Syllabus</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default SyllabusMenu;
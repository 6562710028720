import react, { useState } from "react"
import { resources } from "../Resourses/Resources"
import axios from "axios"

const FAcultyExcelUpload = () => {
    const [selectedFile, setSelectedFile] = useState([])
    const handleSelectFile = async (e) => {
        const files = e.target.files[0]
        setSelectedFile(files)
    }
    const handleReset = async () => {
        document.getElementById("feeDetailsExcel").value = ""
    }

    const handleSubmit = async () => {
        try {
            const formData = new FormData()
            formData.append("file", selectedFile)
            console.log("fileto send===>", selectedFile)
            const response = await axios.post(resources.APPLICATION_URL + `upload/faculty/details`, formData)
            console.log("response===>", response.data)
            if(response?.data.length > 0){
                document.getElementById("feeDetailsExcel").value = ""
                alert("Successfully added faculty details")
                
            }
        } catch (error) {
            console.log("error==>", error)
        }
    }
    return (
        <div className="uploadExcelContainer">
            <div>
                <label>Faculty Details Excel File</label>
            </div>
            <div className="fileAndButtonDivRegulation">
                <div className="fileDiv">
                    <input type="file" id="feeDetailsExcel" onChange={(e) => handleSelectFile(e)} />
                </div>
                <div className="fileButtons">
                    <button className="resetButton" onClick={() => handleReset()}>Reset</button>
                    <button className="uploadButton" onClick={() => handleSubmit()}>Upload</button>
                </div>

            </div>
            <div>
                <a className="anchorTagDownload" href={resources.APPLICATION_URL + "faculty/upload/template"}>Download Template</a>

            </div>
        </div>
    )
}
export default FAcultyExcelUpload;
import React from "react";
import "./Policies.css";
import PolicyIcon from "@mui/icons-material/Policy";
import PaymentIcon from "@mui/icons-material/Payment";
import { BxlInternetExplorer } from "../../assets/icons/svgs";
import { FluentMdl2RecruitmentManagement } from "../../assets/icons/svgs";
import { EpPromotion } from "../../assets/icons/svgs";
import { FlatColorIconsRules } from "../../assets/icons/svgs";
import { OcticonCodeOfConduct16 } from "../../assets/icons/svgs";
const Policies = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            POLICIES
          </a>
        </div>
      </div>
      <div className="container text-center w-100 d-flex justify-content-center" style={{backgroundColor: "rgb(241, 230, 241)"}}>
        <table className="policies_table">
          <thead>
            <tr>
              <th className="pacifico-regular-polacy">logo</th>
              <th className="pacifico-regular-polacy">Policies</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <PolicyIcon sx={ { height: "1.5em", width: "1.5em"}} />
              </td>
              <td>
                {" "}
                <a
                  href="http://saispurthi.ac.in/docs/policy/research_policy.pdf"
                  target="blank"
                  Research
                  className="links_policies"
                >
                  Research Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <PaymentIcon sx={ { height: "1.5em", width: "1.5em"}} />
              </td>
              <td>
                {" "}
                <a
                  href="http://saispurthi.ac.in/docs/policy/IncentivePolicy.pdf"
                  target="blank"
                  className="links_policies"
                >
                  Incentive Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <BxlInternetExplorer />
              </td>
              <td>
                {" "}
                <a
                  href="http://saispurthi.ac.in/docs/policy/EGovernancepolicy.pdf"
                  target="blank"
                  className="links_policies"
                >
                  E-governance Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <FluentMdl2RecruitmentManagement />
              </td>
              <td>
                <a
                  href="http://saispurthi.ac.in/docs/policy/RecruitmentPolicy.pdf"
                  target="blank"
                  className="links_policies"
                >
                  Recruitment Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <EpPromotion />
              </td>
              <td>
                {" "}
                <a
                  href="http://saispurthi.ac.in/docs/policy/PromotionPolicy.pdf"
                  target="blank"
                  className="links_policies"
                >
                  Promotion Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <FlatColorIconsRules />
              </td>
              <td>
                {" "}
                <a
                  href="http://saispurthi.ac.in/docs/policy/ServiceRules.pdf"
                  target="blank"
                  className="links_policies"
                  
                >
                  Service Rules
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <OcticonCodeOfConduct16 />
              </td>
              <td>
                {" "}
                <a
                  href="http://saispurthi.ac.in/docs/policy/codeandconductpolicy.pdf"
                  target="blank"
                  className="links_policies"
                >
                  Code of Conduct
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Policies;

import react from "react"
const AcademicCalendar=()=>{
    return(
        <div>
          <div class="container text-left">
            <h1>ACADEMIC CALENDAR</h1>
            <p>The academic calendar gives the academic 
                schedules of the program which includes 
                starting of session, continuous assessment 
                dates, closing session, starting date of examinations
                 and expected results declaration date.</p>
          </div>
        </div>
    )
}
export default AcademicCalendar;
import React, { useEffect, useState } from 'react'
import './AdminFeeStructure.css'
import axios from 'axios';
import { resources } from '../Resourses/Resources';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const AdminFeeStructure = () => {
    const [data, setData] = useState({})
    const [Edit, setEdit] = useState(false)
    const [editIndex, setEditIndex] = useState(false)

    const date = new Date();
    let currentMonth = date.getMonth() + 1;
    let currentYear = date.getFullYear();
    let acy = "";
    if (currentMonth >= 6) {
        acy = currentYear + "-" + (currentYear + 1);
    } else {
        acy = (currentYear - 1) + "-" + currentYear
    }
    let acyList = [];
    acyList.push(acy);
    let ac = acy.split("-")[0];
    for (let i = 0; i < 5; i++) {
        let a = (ac - i - 1) + "-" + (ac - i);
        acyList.push(a);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        const postData = {
            feeStructurePrimaryKey: {
                academicYear: data.academicYear,
                level: data.level
            },
            amount: data.amount
        }
        const response = await axios.post(

            resources.APPLICATION_URL + "save/feestructure", postData
        )
        // window.location.reload();
        console.log("res" + response)
        if (response.status === 200) {
            alert("Sucessfully Added")
        }
    }

    const level = ["DIPLOMA", "UG", "PG"]

    const [feeStructureTable, setFeeStructureTable] = useState([])

    useEffect(() => {
       try { axios.get(
            resources.APPLICATION_URL + "getFeeStructureData"
        ).then((response) => {
            setFeeStructureTable(response.data)
            console.log("rrrr", response.data)
        })} catch (error) {
            console.log(error)
        }
    }, [])

    const handleEdit = (item, i) => {
        console.log("item", item)
        setEdit(true);
        setEditIndex(i)
    }
    const handleSave = (item, i) => {
        console.log("item", item)
        setEdit(false);
        // setEditIndex(i)
    }


    return (
        <div>
            <h2 style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>Fee Structure</h2>
            <div className="all_dropdown_container">
                <div className="all_dropdowns">
                    <label>Level</label>
                    <select onChange={(e) => setData({ ...data, level: e.target.value })}>
                        <option>Select Level</option>
                        {level.map((item) => <option>{item}</option>)}
                    </select>
                </div>

                <div className="all_dropdowns">
                    <label>Academic Year</label>
                    <select onChange={(e) => setData({ ...data, academicYear: e.target.value })}>
                        <option >Select Academic Year</option>
                        {acyList && acyList.map((item) => <option>{item}</option>)}
                    </select>
                </div>

                <div className="all_dropdowns">
                    <label>Fee /Year</label>
                    <input onChange={(e) => setData({ ...data, amount: e.target.value })} type="number" placeholder="Fee /Year" />
                </div>
                <div className="all_dropdowns">
                    <button onClick={(e) => handleSubmit(e)}>Save</button>
                </div>
            </div>
            <div className="fee_structure_table_container">
                <table className="fee_structure_table">
                    <thead style={{ position: "sticky" }}>
                        <tr style={{ position: "sticky", top: "0px" }}>
                            <th>S.NO</th>
                            <th>Level</th>
                            <th>Academic Year</th>
                            <th>Fee /Year</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {feeStructureTable?.map((item, i) => <tr>
                            <td>{i + 1}</td>
                            <td>{item?.feeStructurePrimaryKey.level}</td>
                            <td>{item?.feeStructurePrimaryKey.academicYear}</td>
                            {(Edit && editIndex === i) ? <td> <input type='number' value={item.amount} /></td> : <td>{item.amount}</td>}
                            <td>
                                {(Edit && editIndex === i) ? (
                                    <><IconButton aria-label="save" onClick={() => handleSave(item, i)}>
                                        <SaveIcon sx={{ color: "green", }} />
                                    </IconButton></>) :
                                    (
                                        <>
                                            <IconButton aria-label="edit" onClick={() => handleEdit(item, i)}>
                                                <EditIcon sx={{ color: "green", }} />
                                            </IconButton>
                                            <IconButton aria-label="delete">
                                                <DeleteIcon sx={{ color: "red" }} />
                                            </IconButton></>)}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AdminFeeStructure

import React from "react";
import { useState ,useEffect} from "react";
import axios from "axios";
import Resources, { resources } from "../Resourses/Resources";
import Cookies from "js-cookie"

const StudentEnquiryForm = () => {
    const [departMent, setDepartment] = useState("")
    const [rollNumber, setRollNumber] = useState("")
    const useData = Cookies.get("userDetails")
    const userDetails = JSON.parse(useData)
    const [enquiryData, setEnquiryData] = useState({
        rollNumber:"",
        name: "",
        subject: "",
        mail: "",
        contact: "",
        message: "",
        departMent:"",
        status:"Pending"
    });
    useEffect(() => {
        const roll = userDetails.username
        const branchdata=userDetails.program
        setRollNumber(userDetails.username)
        setDepartment(userDetails.program)
        setEnquiryData({ ...enquiryData, rollNumber: roll,departMent:branchdata})
    }, [])
    const handleSaveDataEnquiryData = async () => {
        try {
            const res = await axios.post(
                resources.APPLICATION_URL + "enquiryForm/save",
                enquiryData
            );
            if (res.data) {
                alert("Enquiry Added Successfully");
                setEnquiryData({
                    name: "",
                    subject: "",
                    mail: "",
                    contact: "",
                    message: "",
                });
            }
        } catch (e) {
        
            console.log(e);
        }
    };

    return (
        <div className="enQuiryFormConatainer">
            <h3 className="headersTag">ENQUIRY FORM</h3>

            <div className="enquirySubdiv">
                <div className="enquirySubdivOne">
                <div className="enQuiryInputDiv">
                        <label htmlFor="Name">Name</label>
                        <input
                            value={enquiryData.rollNumber}
                            onChange={(e) => setEnquiryData({ ...enquiryData, rollNumber: e.target.value })}
                            type="text"
                        disabled="true"
                            id="Name"
                            name="name"

                            required
                        />
                    </div>
                    <div className="enQuiryInputDiv">
                        <label htmlFor="Name">Name</label>
                        <input
                            value={enquiryData.name}
                            onChange={(e) => setEnquiryData({ ...enquiryData, name: e.target.value })}
                            type="text"
                            placeholder="Enter Name"
                            id="Name"
                            name="name"

                            required
                        />
                    </div>
                    <div className="enQuiryInputDiv">
                        <label htmlFor="Subject">Subject</label>
                        <input
                            value={enquiryData.subject}
                            onChange={(e) => setEnquiryData({ ...enquiryData, subject: e.target.value })}
                            type="text"
                            placeholder="Subject"
                            id="Subject"
                            name="subject"
                            required
                        />
                    </div>
                    <div className="enQuiryInputDiv">
                        <label htmlFor="Email">Email</label>
                        <input
                            value={enquiryData.mail}
                            onChange={(e) => setEnquiryData({ ...enquiryData, mail: e.target.value })}
                            type="email"
                            placeholder="Email"
                            id="Email"
                            name="mail"
                            required
                        />
                    </div>
                    <div className="enQuiryInputDiv">
                        <label htmlFor="Contact">Contact</label>
                        <input
                            value={enquiryData.contact}
                            onChange={(e) => setEnquiryData({ ...enquiryData, contact: e.target.value })}
                            type="number"
                            placeholder="Contact"
                            id="Contact"
                            name="contact"
                            required
                        />
                    </div>

                </div>
                <div className="enquirySubdivTwo">
                    <label htmlFor="Message">Message</label>
                    <textarea
                        value={enquiryData.message}
                        onChange={(e) => setEnquiryData({ ...enquiryData, message: e.target.value })}
                        name="message"
                        type="text"
                        cols={30}
                        rows={5}
                        placeholder="Message"
                        id="Message"
                        required
                    />
                </div>
            </div>
            <div className="enquiryButtonDiv">
                <button className="" type="button" onClick={() => handleSaveDataEnquiryData()}>
                    Submit
                </button>
            </div>

        </div>
    );
};

export default StudentEnquiryForm;

import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./Naac.css";
import { PageHeader } from "../Header/PageHeader";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { useState } from "react";
import RotatingSpinner from "../Resourses/RotatingSpinner";

const Naac = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleBase64Data = async (param) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        resources.APPLICATION_URL + "filenames?filename=" + param + "&foldername=ssr"
      );
      const base64 = response.data;

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }

  }


  return (
    <>
      {isLoading ? <RotatingSpinner /> : (
        <div className="container mt-5">
          <PageHeader name="NAAC SELF STUDY REPORT" />
          <div className="crt_main">
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        1.1.1 The Institution ensures effective curriculum planning
                        and delivery through a well-planned and documented process
                        including Academic calendar and conduct of continuous
                        internal Assessment
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("1.1.1(1).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              1.1.1(1) Effective Curriculum Planning and Delivery
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("1.1.1(2).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography> 1.1.1(2) Academic Calendars</Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("1.1.1(3).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              1.1.1(3) Work Load & Time Table
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2.1 Number of Certificate/Value added courses offered
                          and online courses of MOOCs, SWAYAM, NPTEL etc. (where the
                          students of the institution have enrolled and successfully
                          completed during the last five years)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.1(1) Add on Value Added Programs for A.Y:
                                2021-22{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.1(2) Add on Value Added Programs for A.Y:
                                2020-21{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.1(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.1(3) Add on Value Added Programs for A.Y:
                                2019-20{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.1(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.1(4) Add on Value Added Programs for A.Y:
                                2018-19{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.1(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.1(5) Add on Value Added Programs for A.Y:
                                2017-18{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.2.2 Percentage of students enrolled in Certificate/
                          Value added courses and also completed online courses of
                          MOOCs, SWAYAM, NPTEL etc. as against the total number of
                          students during the last five years
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.2(1) Number of Add on/Certificate programs
                                offered during A.Y: 2021-22{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.2(2) Number of Add on/Certificate programs
                                offered during A.Y: 2020-21{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.2(3) Number of Add on/Certificate programs
                                offered during A.Y: 2019-20{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.2(4) Number of Add on/Certificate programs
                                offered during A.Y: 2018-19{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.2.2(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.2.2(5) Number of Add on/Certificate programs
                                offered during A.Y: 2017-18{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.3.1 : Institution integrates crosscutting issues
                          relevant to Professional Ethics, Gender, Human Values,
                          Environment and Sustainability in transacting the
                          Curriculum
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.3.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.3.1(1) Qualitative Description
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.3.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.3.1(2) Subjects with Syllabus
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.3.1(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.3.1(3) Gender related Documentation{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.3.1(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.3.1(4) Human Values related Documentation{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.3.2 Percentage of students undertaking project
                          work/field work/ internships (Data for the latest
                          completed academic year)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.3.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 1.3.2(1) Project Work </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.3.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.3.2(2) Industrial visit during A.Y: 2021-22{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.3.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.3.2(3) Internships during A.Y: 2021-22{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          1.4.1 Institution obtains feedback on the academic
                          performance and ambience of the institution from various
                          stakeholders, such as Students, Teachers, Employers,
                          Alumni etc. and action taken report on the feedback is
                          made available on institutional website(Yes or No)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.4.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.4.1(1) Feedback forms of Students
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.4.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.4.1(2) Feedback forms of Teachers
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.4.1(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.4.1(3) Feedback forms of Employers{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.4.1(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.4.1(4) Feedback forms of Alumni{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("1.4.1(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                1.4.1(5) Communicating with Affiliating Universities
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 2</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography> 2.1.1 Enrolment percentage </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("2.1.1(1).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography> 2.1.1(1) Approvals</Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("2.1.1(2).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              2.1.1(2) Admitted list during last five years
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.1.2 Percentage of seats filled against seats reserved
                          for various categories (SC, ST, OBC, Divyangjan, etc. as
                          per applicable reservation policy during the last five
                          years (Exclusive of supernumerary seats){" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.1.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 2.1.2(A) Government Order</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.1.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.1.2(B) List of students enrolled against Reserved
                                Category during last five years{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.1.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 2.1.2(C) Admission Extract </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.2.1 Student – Full time Teacher Ratio(Data for the
                          latest completed academic year){" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.2.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.2.1(1) List of Students Data A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.2.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.2.1(2) List of full time teachers A.Y: 2021-22{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.3.1 Student centric methods, such as experiential
                          learning, participative learning and problem solving
                          methodologies are used for enhancing learning experiences
                          using ICT tools
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.3.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.3.1 Student Centric Methods
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.4.1 Percentage of full-time teachers against sanctioned
                          posts during the last five years
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.1(1) List of full time teachers during the A.Y:
                                2021-22{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.1(2) List of full time teachers during the A.Y:
                                2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.1(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.1(3) List of full time teachers during the A.Y:
                                2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.1(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.1(4) List of full time teachers during the A.Y:
                                2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.1(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.1(5) List of full time teachers during the A.Y:
                                2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.1(6).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.1(6) Staff sanctioned letters{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.4.2 Percentage of full time teachers with NET/SET/SLET/
                          Ph. D. / D.M. / M.Ch. / D.N.B Superspeciality / D.Sc. /
                          D.Litt. during the last five years (consider only highest
                          degree for count){" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.2(1) Faculty with Ph.D for the A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.2(2) Faculty with Ph.D for the A.Y: 2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.2(3) Faculty with Ph.D for the A.Y: 2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.2(4) Faculty with Ph.D for the A.Y: 2018-19{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.4.2(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.4.2(5) Faculty with Ph.D for the A.Y: 2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.5.1 Mechanism of internal/ external assessment is
                          transparent and the grievance redressal system is time-
                          bound and efficient{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.5.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.5.1(1) Mechnism of Examination for B.Tech
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data(" 2.5.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.5.1(2) Mechnism of Examination for M.B.A
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          2.6.1 Programme Outcomes (POs) and Course Outcomes (COs)
                          for all Programmes offered by the institution are stated
                          and displayed on website and attainment of POs and COs are
                          evaluated{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.6.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.6.1(1) Methodology for CO-PO attainment for all
                                programmes
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.6.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.6.1(2) Evaluation of CO-PO attainment of B.Tech
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("2.6.1(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                2.6.1(3) Evaluation of CO-PO attainemnt of M.B.A
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Typography>
                      <Accordion className="naac_ssr_inside">
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            2.6.3 Pass percentage of Students during last five years
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    button
                                    onClick={() => handleBase64Data("2.6.3(1).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  2.6.3(1) Result Analysis for the A.Y: 2021-22
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("2.6.3(2).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  2.6.3(2) Result Analysis for the A.Y: 2020-21
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("2.6.3(3).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  2.6.3(3) Result Analysis for the A.Y: 2019-20
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("2.6.3(4).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  2.6.3(4) Result Analysis for the A.Y: 2018-19
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("2.6.3(5).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  2.6.3(5) Result Analysis for the A.Y: 2017-18
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("2.6.3(6).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography> 2.6.3 Annual Report </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Typography>
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 3</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        3.1.1 Grants received from Government and non-governmental
                        agencies for research projects / endowments in the
                        institution during the last five years (INR in Lakhs){" "}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("3.1.1(1).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.1.1(1) Research Grants A.Y: 2021-22
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("3.1.1(2).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.1.1(2) Research Grants A.Y: 2020-21
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("3.1.1(3).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.1.1(3) Research Grants A.Y: 2019-20
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("3.1.1(4).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              3.1.1(4) Research Grants A.Y: 2018-19
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("3.1.1(5).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography> 3.1.1(5) Audited Statements</Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.2.1 Institution has created an ecosystem for innovations
                          and has initiatives for creation and transfer of knowledge
                          (patents filed, published, incubation center facilities in
                          the HEI to be considered)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.2.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.2.1(1) Events and Certificates of Innovation
                                activities
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.2.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.2.1(2) Proofs of Events and Certificates of
                                Innovation activities{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.2.2 Number of workshops/seminars/conferences including
                          on Research Methodology, Intellectual Property Rights
                          (IPR) and entrepreneurship conducted during the last five
                          years{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.2.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.2.2(1) Workshops/seminars conducted on Research
                                Methodology, IPR and entrepreneurship for the A.Y:
                                2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.2.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.2.2(2) Workshops/seminars conducted on Research
                                Methodology, IPR and entrepreneurship for the A.Y:
                                2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.2.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.2.2(3) Workshops/seminars conducted on Research
                                Methodology, IPR and entrepreneurship for the A.Y:
                                2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.2.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.2.2(4) Workshops/seminars conducted on Research
                                Methodology, IPR and entrepreneurship for the A.Y:
                                2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.2.2(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.2.2(5) Workshops/seminars conducted on Research
                                Methodology, IPR and entrepreneurship for the A.Y:
                                2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.3.1 Number of research papers published per teacher in
                          the Journals notified on UGC care list during the last
                          five years
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.3.1(1) Research Papers A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.3.1(2) Research Papers A.Y: 2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.1(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.3.1(3) Research Papers A.Y: 2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.1(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.3.1(4) Research Papers A.Y: 2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.1(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.3.1(5) Research Papers A.Y: 2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.1(6).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.3.1(6) UGC OLD Care List </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.3.2 Number of books and chapters in edited volumes/books
                          published and papers published in national/ international
                          conference proceedings per teacher during last five years
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.3.2(1) Books and Chapters and Conference
                                Proceedings A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.3.2(2) Books and Chapters and Conference
                                Proceedings A.Y: 2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.3.2(4) Books and Chapters and Conference
                                Proceedings A.Y: 2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.3.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.3.2(5) Books and Chapters and Conference
                                Proceedings A.Y: 2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.4.1 Extension activities are carried out in the
                          neighborhood community, sensitizing students to social
                          issues, for their holistic development, and impact thereof
                          during the last five years.{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.1(1) Documents for Extension Activities during
                                A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.1(2) Documents for Extension Activities during
                                A.Y: 2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.1(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.1(3) Documents for Extension Activities during
                                A.Y: 2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.1(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.1(4) Documents for Extension Activities during
                                A.Y: 2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.1(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.1(5) Documents for Extension Activities during
                                A.Y: 2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.1(m1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.1(m1) Documents for Extension Activities during
                                Last Five Years
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.1(m2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.1(m2) Program Reports for Extension Activities
                                during Last Five Years
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.4.2 Awards and recognitions received for extension
                          activities from government / government recognised bodies{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.2(0).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.2(0) Awards and Recognitions
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.2(1) Support Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.2(2) Support Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.2(3) Support Document </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.2(4) Support Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.2(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.2(5) Support Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.2(m).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.2(m) Awards and Recognitions during Last Five
                                Years{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          3.4.3 Number of extension and outreach programs conducted
                          by the institution through NSS/NCC/Red cross/YRC etc.,
                          (including the programmes such as Swachh Bharat, AIDS
                          awareness, Gender issues etc. and/or thoseorganised in
                          collaboration with industry, community and NGOs) during
                          the last five years{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.3(0).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                3.4.3(0) Extension and Outreach Programs
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.3(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.3(1) Support Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.3(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.3(2) Support Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data(" 3.4.3(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.3(3) Support Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.3(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.3(4) Support Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("3.4.3(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 3.4.3(5) Support Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Typography>
                      <Accordion className="naac_ssr_inside">
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            3.5.1 The number of MoUs, collaborations/linkages for
                            Faculty exchange, Student exchange, Internship, Field
                            trip, On-the- job training, research and other academic
                            activities during the last five years
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("3.5.1(1).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  3.5.1(1) Documents for Functional MOU'S &
                                  Collaborative Activities during A.Y: 2021-22{" "}
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("3.5.1(2).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  3.5.1(2) Documents for Functional MOU'S &
                                  Collaborative Activities during A.Y: 2020-21{" "}
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("3.5.1(3).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  3.5.1(3) Documents for Functional MOU'S &
                                  Collaborative Activities during A.Y: 2019-20{" "}
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("3.5.1(4).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  3.5.1(4) Documents for Functional MOU'S &
                                  Collaborative Activities during A.Y: 2018-19{" "}
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("3.5.1(5).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  3.5.1(5) Documents for Functional MOU'S &
                                  Collaborative Activities during A.Y: 2017-18{" "}
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Typography>
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 4</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        4.1.1 The Institution has adequate infrastructure and other
                        facilities for, teaching – learning, viz., classrooms,
                        laboratories, computing equipment etc ICT – enabled
                        facilities such as smart class, LMS etc. Facilities for
                        Cultural and sports activities, yoga centre, games (indoor
                        and outdoor), Gymnasium, auditorium etc{" "}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("4.1.1.pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              4.1.1 Infrastructure and Physical Facilities for
                              Teaching - Learning
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          4.1.2 Percentage of expenditure, excluding salary for
                          infrastructure augmentation during last five years (INR in
                          Lakhs){" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("4.1.2.pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                4.1.2 INFRASTRUCTURE MAITENANCE{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          4.2.1 Library is automated using Integrated Library
                          Management System (ILMS), subscription to e-resources,
                          amount spent on purchase of books, journals and per day
                          usage of library{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("4.2.1.pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                4.2.1 Link for Additional information{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          4.3.1 Institution frequently updates its IT facilities and
                          provides sufficient bandwidth for internet connection
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("4.3.1.pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                4.3.1 Details of IT Facilities
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          4.3.2 Student – Computer ratio (Data for the latest
                          completed academic year)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("4.3.2.pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                4.3.2 Computer Invoices and Stock Registers
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          4.4.1 Percentage of expenditure incurred on maintenance of
                          infrastructure (physical and academic support facilities)
                          excluding salary component during the last five years(INR
                          in Lakhs).
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("4.4.1.pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                4.4.1 ACADEMICS AND PHYSICAL FACILITIES
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 5</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        5.1.1 Percentage of students benefited by scholarships and
                        freeships provided by the Government and Non-Government
                        agencies during last five years
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1.pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1 Scholarships and freeships provided by the
                              Government and non government consolidated statement{" "}
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(1).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(1) Scholarships and freeships provided by the
                              Government for the A.Y: 2021-22{" "}
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(2).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(2) Scholarships and freeships provided by the
                              Government for the A.Y: 2020-21
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(3).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(3) Scholarships and freeships provided by the
                              Government for the A.Y: 2019-20
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(4).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(4) Scholarships and freeships provided by the
                              Government for the A.Y: 2018-19{" "}
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(5).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(5) Scholarships and freeships provided by the
                              Government for the A.Y: 2017-18{" "}
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(6).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(6) Merit Scholarships provided by the
                              Institution for the A.Y: 2021-22
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(7).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(7) Merit Scholarships provided by the
                              Institution for the A.Y: 2020-21
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(8).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              5.1.1(8) Merit Scholarships provided by the
                              Institution for the A.Y: 2019-20
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(9).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(9) Merit Scholarships provided by the
                              Institution for the A.Y: 2018-19
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(10).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(10) Merit Scholarships provided by the
                              Institution for the A.Y: 2017-18
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(11).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(11) AICTE PRAGATHI Scholarships for the A.Y:
                              2021-22
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(12).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(12) AICTE PRAGATHI Scholarships for the A.Y:
                              2020-21
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(13).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(13) AICTE PRAGATHI Scholarships for the A.Y:
                              2019-20
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(14).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(14) AICTE PRAGATHI Scholarships for the A.Y:
                              2018-19
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("5.1.1(15).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              5.1.1(15) AICTE PRAGATHI Scholarships for the A.Y:
                              2017-18
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.1.2 Capacitybuildingandskills enhancementinitiatives
                          takenbythe institutionincludethefollowing 1. Softskills 2.
                          Languageandcommunicationskills 3.
                          Lifeskills(Yoga,physicalfitness,healthandhygiene) 4.
                          ICT/computing skills{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 5.1.2(1) SOFT SKILLS </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.1.2(2) Languages and Communication Skills
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 5.1.2(3) Life Skills</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.1.2(4) ICT/Computing Skills
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.1.3 Percentage of students benefitted by guidance for
                          competitive examinations and career counseling offered by
                          the Institution during the last five years
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.3(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.1.3(1) Career Counseling Last Five Years{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.3(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.1.3(2) Competitive Examinations Last Five Years{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.1.4 The Institution has a transparent mechanism for
                          timely redressal of student grievances including sexual
                          harassment and ragging cases
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.4(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.1.4(1) Internal Compliance Committee
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.4(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.1.4(2) Anti Ragging Committee
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.4(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.1.4(3) Grievance & Redressal Committee
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.1.4(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.1.4(4) Women Protection Committee{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.2.1 Percentage of placement of outgoing students and
                          students progressing to higher education during the last
                          five years
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.1(1) Placements and Higehr Education Details of
                                outgoing Students during the A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.1(2) Placements and Higehr Education Details of
                                outgoing Students during the A.Y: 2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.1(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.1(3) Placements and Higehr Education Details of
                                outgoing Students during the A.Y: 2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.1(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.1(4) Placements and Higehr Education Details of
                                outgoing Students during the A.Y: 2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.1(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.1(5) Placements and Higehr Education Details of
                                outgoing Students during the A.Y: 2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.2.2 Percentage of students qualifying in state/national/
                          international level examinations during the last five
                          years (eg: JAM/CLAT/GATE/ GMAT/CAT/GRE/ TOEFL/ Civil
                          Services/State government examinations)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.2(1) List of Students Qualified the Competitive
                                Exams for the A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.2(2) List of Students Qualified the Competitive
                                Exams for the A.Y: 2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.2(3) List of Students Qualified the Competitive
                                Exams for the A.Y: 2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.2(4) List of Students Qualified the Competitive
                                Exams for the A.Y: 2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.2.2(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.2.2(5) List of Students Qualified the Competitive
                                Exams for the A.Y: 2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.3.1 Number of awards/medals for outstanding performance
                          in sports/ cultural activities at University / state/
                          national / international level (award for a team event
                          should be counted as one) during the last five years
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.3.1.pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.3.1 SPORTS & CULTURAL during Last Five Years
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          5.3.2 Average number of sports and cultural programs in
                          which students of the Institution participated during last
                          five years (organised by the institution/other
                          institutions)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.3.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.3.2(1) SPORTS & CULTURAL PARTICIPATION
                                CERTIFICATES A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.3.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.3.2(2) SPORTS & CULTURAL PARTICIPATION
                                CERTIFICATES A.Y: 2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.3.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.3.2(3) SPORTS & CULTURAL PARTICIPATION
                                CERTIFICATES A.Y: 2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.3.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.3.2(4) SPORTS & CULTURAL PARTICIPATION
                                CERTIFICATES A.Y: 2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("5.3.2(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                5.3.2(5) SPORTS & CULTURAL PARTICIPATION
                                CERTIFICATES A.Y: 2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Typography>
                      <Accordion className="naac_ssr_inside">
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            5.4.1 There is a registered Alumni Association that
                            contributes significantly to the development of the
                            institution through financial and/or other support
                            services
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("5.4.1(1).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  5.4.1(1) Alumni Registration Certificate
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("5.4.1(2).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  5.4.1(2) Alumni Annual Reports
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Typography>
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 6</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        6.1.1 The governance and leadership is in accordance with
                        vision and mission of the institution and it is visible in
                        various institutional practices such as decentralization and
                        participation in the institutional governance
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("6.1.1(1).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              6.1.1(1) Governance of Institution
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.2.1 The functioning of the institutional bodies is
                          effective and efficient as visible from policies,
                          administrative setup, appointment and service rules,
                          procedures, deployment of institutional Strategic/
                          perspective/development planetc
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.2.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.2.1(1) Functioning of Institutional Bodies
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.2.2 Implementation of e-governance in areas of operation
                          1.Administration 2.Finance and Accounts 3.Student
                          Admission and Support 4.Examination
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.2.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.2.2(1) E-GOVERNANCE POLICY DOCUMENT
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.2.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.2.2(2) SCREENSHOTS OF E-GOVERNANCE
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.2.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 6.2.2(3) INVOICES</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.2.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 6.2.2(4) ERP DOCUMENT</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.2.2(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.2.2(5) REPORT ON IMPLEMENTATION OF E-GOVERNANCE IN
                                AREAS OF OPERATIONS
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.3.1 The institution has effective welfare measures and
                          Performance Appraisal System for teaching and non-teaching
                          staff
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.1(1) Welfare Measures and Performance Appraisal
                                Systems
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.3.2 Percentage of teachers provided with financial
                          support to attend conferences/workshops and towards
                          membership fee of professional bodies during the last five
                          years
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 6.3.2(1) Policy Document</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.2(2) Teachers provided with financial support
                                for the A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.2(3) Teachers provided with financial support
                                for the A.Y: 2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.2(4) Teachers provided with financial support
                                for the A.Y: 2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.2(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.2(5) Teachers provided with financial support
                                for the A.Y: 2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.2(6).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.2(6) Teachers provided with financial support
                                for the A.Y: 2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.3.3 Percentage of teaching and non-teaching staff
                          participating in Faculty development Programmes (FDP),
                          professional development /administrative training programs
                          during the last five years
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.3(0).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.3(0) Documents List to support the claim{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.3(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.3(1) Documents for FDPs organized during the
                                A.Y: 2021-22
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.3(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.3(2) Documents for FDPs organized during the
                                A.Y: 2020-21
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.3(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.3(3) Documents for FDPs organized during the
                                A.Y: 2019-20
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data(" 6.3.3(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.3(4) Documents for FDPs organized during the
                                A.Y: 2018-19
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.3.3(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.3.3(5) Documents for FDPs organized during the
                                A.Y: 2017-18
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.4.1 Institution has strategies for mobilization and
                          optimal utilization of resources and funds from various
                          sources (government/ non-government organizations) and it
                          conducts financial audits regularly(internal and external)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.4.1.pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.4.1 strategies for mobilization and optimal
                                utilization of resources
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          6.5.1 Internal Quality Assurance Cell (IQAC) has
                          contributed significantly for institutionalizing the
                          quality assurance strategies and processes. It reviews
                          teaching learning process, structures & methodologies of
                          operations and learning outcomes at periodic intervals and
                          records the incremental improvement in various activities
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("6.5.1.pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                6.5.1 Document for IQAC Activities for Quality
                                Improvements
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Typography>
                      <Accordion className="naac_ssr_inside">
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <Typography>
                            {" "}
                            6.5.2 Quality assurance initiatives of the institution
                            include: 1. Regular meeting of Internal Quality
                            Assurance Cell (IQAC); Feedback collected, analysed and
                            used for improvements 2. Collaborative quality
                            initiatives with other institution(s)/ membership of
                            international networks 3. Participation in NIRF 4. any
                            other quality audit/accreditation recognized by state,
                            national or international agencies such as NAAC, NBA,
                            ISO Certification etc.
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("6.5.2(1).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  6.5.2(1) IQAC MINUTES & FEEDBACK during Last Five
                                  Years
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("6.5.2(2).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  6.5.2(2) ACTIVITES ON QUALITY INITIATIVES
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("6.5.2(3).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  6.5.2(3) COLLABORATION WITH OTHER INSTITUTIONS
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("6.5.2(4).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography> 6.5.2(4) NIRF</Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                            <AccordionDetails>
                              <AccordionSummary
                                expandIcon={
                                  <button
                                    style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                    onClick={() => handleBase64Data("6.5.2(5).pdf")}
                                  >
                                    <VisibilityIcon />
                                  </button>
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  {" "}
                                  6.5.2(5) CERTIFICATES FOR QUALITY AUDITS
                                </Typography>
                              </AccordionSummary>
                            </AccordionDetails>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Typography>
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="naac_ssr_criterias">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Criterion 7</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordion className="naac_ssr_inside">
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        {" "}
                        7.1.1: Institution has initiated the Gender Audit and
                        measures for the promotion of gender equity during the last
                        five years. Describe the gender equity & sensitization in
                        curricular and co-curricular activities, facilities for
                        women on campus etc., within 500 words{" "}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <AccordionDetails>
                          <AccordionSummary
                            expandIcon={
                              <button
                                style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                onClick={() => handleBase64Data("7.1.1(1).pdf")}
                              >
                                <VisibilityIcon />
                              </button>
                            }
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography>
                              {" "}
                              7.1.1(1) Document for Gender Equity
                            </Typography>
                          </AccordionSummary>
                        </AccordionDetails>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
                <Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          7.1.2 The Institution has facilities and initiatives for
                          1.Alternate sources of energy and energy conservation
                          measures 2.Management of the various types of degradable
                          and non-degradable waste 3.Water conservation 4.Green
                          campus initiatives 5.Disabled-friendly, barrier free
                          environment.{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.2(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.1.2(1) The Institution has facilities and
                                initiatives{" "}
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.2(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.1.2(2) Policy document on the green campus/plastic
                                free campus.
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.2(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 7.1.2(3) Geo-tagged Photos</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.2(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.1.2(4) Circulars and report of activities
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.2(5).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.1.2(5) Bills for the purchase
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          7.1.3 Quality audits on environment and energy regularly
                          undertaken by the Institution. The institutional
                          environment and energyinitiatives are confirmed through
                          the following 1.Green audit / Environment audit 2.Energy
                          audit 3.Clean and green campus initiatives 4.Beyond the
                          campus environmental promotion activities.{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.3(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.1.3(1) Report on Environmental Promotional
                                activities
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.3(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.1.3(2) Links for any other relevant document
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.3(3).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.1.3(3) Policy document on environment and energy
                                usage Certificate
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.3(4).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.1.3(4) Green audit/environmental audit report from
                                recognized bodies
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          7.1.4 Describe the Institutional efforts/initiatives in
                          providing an inclusive environment i.e., tolerance and
                          harmony towards cultural, regional, linguistic, communal
                          socioeconomic and Sensitization of students and employees
                          to the constitutional obligations: values, rights, duties
                          and responsibilities of citizens (Within 500 words)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.1.4.pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography> 7.1.4 Inclusive Environment</Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          7.2.1 Describe two best practices successfully implemented
                          by the Institution as per NAAC format provided in the
                          Manual
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.2.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.2.1(1) Document for Best Practices-I
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.2.1(2).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.2.1(2) Document for Best Practices-II
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                  <Typography>
                    <Accordion className="naac_ssr_inside">
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>
                          {" "}
                          7.3.1 Portray the performance of the Institution in one
                          area distinctive to its priority and thrustwithin 1000
                          words{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <AccordionDetails>
                            <AccordionSummary
                              expandIcon={
                                <button
                                  style={{ borderWidth: '0px', backgroundColor: 'transparent' }}
                                  onClick={() => handleBase64Data("7.3.1(1).pdf")}
                                >
                                  <VisibilityIcon />
                                </button>
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                {" "}
                                7.3.1(1) Document for Institutional Distinctiveness
                              </Typography>
                            </AccordionSummary>
                          </AccordionDetails>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};

export default Naac;

import React from "react";
import pic1 from '../../assets/e_class1.jpg';
import pic2 from '../../assets/e_class2.jpg';

const Eclassroom = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            E-CLASS ROOMS
          </a>
        </div>
      </div><br/>

      <div className="container">
        <h4>E-Class Rooms</h4>
        <p>
          A well furnished E-class room is provided to students for extra
          circular activities well equipped lab for communication development
          and technical skill development was provided 18 hours internet also
          available for all students.
        </p>
       
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
            //   style={{ minHeight: "100px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded  "
                  src={pic1}
                  alt="pic1-img"
               
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
             
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded  "
                  src={pic2}
                  alt="pic2-img"
               
                />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default Eclassroom;

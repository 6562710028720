import React from "react";
import g_hostel from "../../assets/girls.jpg";
import b_hostel from "../../assets/boys.jpg";
import StarIcon from "@mui/icons-material/Star";

const Hostels = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            HOSTELS
          </a>
        </div>
      </div>
      <br />

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded  "
                  src={g_hostel}
                  alt="g_hostel-img"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s h-100">
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded  "
                  src={b_hostel}
                  alt="g_hostel1-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <p>
              <StarIcon />&nbsp;
              Internet Facility in Hostels.
            </p>
            <p>
              <StarIcon />&nbsp;
              News papers for every Room.
            </p>
            <p>
              <StarIcon />&nbsp;
              Study Hours (8.30pm-10.30pm).
            </p>
            <p>
              <StarIcon />&nbsp;
              Generator Facility to provide Uninterrupted Power Supply.
            </p>
            <p>
              <StarIcon />&nbsp;
              Providing Mineral Water.
            </p>
            <p>
              <StarIcon />
              General Stores for their Regular needs.
            </p>
            <p>
              <StarIcon />&nbsp;
              Games for Physical Fitness(4.00pm-6.00pm).
            </p>
           
            
          </div>

          <div className="col-lg-6">
          <p>
              <StarIcon />&nbsp;
              General Stores for their Regular needs.
            </p>
            <p>
              <StarIcon />&nbsp;
              Gym Room.
            </p>
            <p>
              <StarIcon />&nbsp;
              TV Room.
            </p>
            <p>
              <StarIcon />&nbsp;
              Bakery.
            </p>
            <p>
              <StarIcon />&nbsp;
              Hot Water Facility.
            </p>
            <p>
              <StarIcon />&nbsp;
              Phone Facility to Talk with their Parents.
            </p>
            <p>
            And college also provide Cot, Chair, and Table for each and every student.
            </p>
            <p>
            <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon /> &nbsp; &nbsp; Every Student is being monitored by Hostel Wardens.</p>
          </div>
        </div>
      </div>

      {/* <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="position-relative h-100">
               
                <p className="mb-0" style={{ marginTop: "40%" }}>
                  <h5>For Students</h5>
                  <p>
                  <StarIcon />
                  Total tution fee(Rs.70,000/-) will be provided to the students
                  as scholarship who secured 950 marks and above in 10+2 and
                  below 12,000 rank in EAMCET while seeking the admission into
                  SSIT and scholarship will be continued for entire four years,
                  when they got 80% in subsequent years.
                  </p>
                 
                  
                </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="position-relative h-100">
                <div className="position-relative h-100">
                  
                  <p className="mb-0" style={{ marginTop: "40%" }}>
                    <h5>For Faculty</h5>
                    <p>
                      <StarIcon />
                      Registration fee, TA & DA for attending seminars/workshops
                      /conferences to present technical papers anywhere in the
                      country/abroad to encourage the research activity.
                    </p>
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default Hostels;

import React from "react";
import "./AcadamicCalender.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AdministrationMembers from "./AdminstrationMembers";
import AdministrationMinutes from "./AdministartionMinutes";

const AdminstrationGovernigBody = () => {
  const [valueTab, setValueTab] = React.useState("members");
  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };
  return (
    <div style={{ padding: "30px" }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor:"orange", marginBottom:"20px" }}>
        <Tabs
          value={valueTab}
          onChange={handleChangeTabs}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab sx={{color:"black", fontWeight:"bold"}} value="members" label="GOVERNING BODY MEMBERS" />
          <Tab sx={{color:"black", fontWeight:"bold"}} value="minutes" label="GOVERNING BODY MINUTES" />
        </Tabs>
      </Box>
      {valueTab === "members" && <AdministrationMembers />}

      {valueTab === "minutes" && <AdministrationMinutes />}
    </div>
  );
};

export default AdminstrationGovernigBody;

import react, { useState } from "react"
import { resources } from "../Resourses/Resources"
import axios from "axios"
const StudentExcelUpload = () => {
    const [selectedFile, setSelectedFile] = useState([])
    const handleSelectFile = async (e) => {
        const files = e.target.files[0]
        setSelectedFile(files)
    }
    const handleReset = async () => {
        document.getElementById("studentExcel").value = ""
    }
    const handleSubmit = async () => {
        try {
            const formData = new FormData()
            formData.append("file", selectedFile)
            const response = await axios.post(resources.APPLICATION_URL + `studentmaster/exceupload`, formData)
            console.log("response===>", response.data)
            if(response.data.length > 0){
                document.getElementById("studentExcel").value=""
                alert("Successfully added student details")
            }
        } catch (error) {
            console.log("error===>", error)
        }
    }
    return (
        <div className="uploadExcelContainer">
            <div>
                <label>Upload Student Excel File</label>
            </div>
            <div className="fileAndButtonDiv">
                <div className="fileDiv">
                    <input type="file" id="studentExcel" onChange={(e) => handleSelectFile(e)} />
                </div>
                <div className="fileButtons">
                    <button className="resetButton" onClick={() => handleReset()}>Reset</button>
                    <button className="uploadButton" onClick={() => handleSubmit()}>Upload</button>
                </div>
            </div>
            <div>
                <a className="anchorTagDownload" href={resources.APPLICATION_URL + "getStudentExcelUploadTemplate"}>Download Template</a>
            </div>
        </div>
    )
}
export default StudentExcelUpload;
/* eslint-disable */
import React, { useState } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import { toast } from "react-toastify";
import RotatingSpinner from "../Resourses/RotatingSpinner";

const successMessage = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success1",
    className: "success",
  });
};
const errorMessage = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "error1",
    className: "error",
  });
};

const AlluminiFeedback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [aluminiFeedback, setAluminiFeedback] = useState([
    {
      regno: "",
      courteousness: "",
      induction: "",
      significance: "",
      friendly: "",
      workshops: "",
      competitiveexams: "",
      supportfaculty: "",
      orientation: "",
      professional: "",
      contribution: "",
      suggestions: "",
      academicYear: "",
      regulation: "",
      dept: "",
      nameOfTheAlumni: "",
      yearofStudy: ""
    }
  ])
  const dept = ["ECE", "CSE", "EEE", "MECH", "CIVIL", "CSE"];
  const reg = ["R16", "R17", "R18", "R19", "R20"];
  const semesters = [
    "I SEMESTER",
    "II SEMESTER",
    "III SEMESTER",
    "IV SEMESTER",
    "V SEMESTER",
    "VI SEMESTER",
    "VII SEMESTER",
    "VIII SEMESTER",
  ];
  const academicYear = [];
  const year = new Date().getFullYear();
  for (let i = year - 5; i <= year; i++) {
    academicYear.push(i);
  }
  for (let i = year + 1; i <= year + 5; i++) {
    academicYear.push(i);
  }
  const hamdleChangeAF = (e) => {
    const { name, value } = e.target
    setAluminiFeedback({
      ...aluminiFeedback,
      [name]: value
    }
    )
  }
  const handleAFSubmit = () => {
    console.log(aluminiFeedback);
    setIsLoading(true);
    try {
      const response = axios.post(
        resources.APPLICATION_URL + "saveAlumuniFeedBack",
        aluminiFeedback
      );
      if (response.data) {
        successMessage("Thank you for your valuable feedback");
      } else {
        errorMessage("Something went wrong. Please try again later");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading ? (
        <RotatingSpinner />
      ) : (
        <div style={{ padding: "50px" }}>
          <div className="teacher_feedback_heading_container">
            <h1 className="teacher_form_heading">
              Alumni Feedback on Academic Performance and Institution Ambience
            </h1>
          </div>
          <form className="teacher_feedback_form_main_container">
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">1.Name of the Allumini</label>
              <input
                type="text"
                id="Alumni"
                name="nameOfTheAlumni"
                value={aluminiFeedback.nameOfTheAlumni}
                required
                placeholder="Enter Alumni Name"
                onChange={(e) => hamdleChangeAF(e)}
              />
            </div>
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">2.Regd.No</label>
              <input
                type="text"
                id="regdNo"
                name="regno"
                value={aluminiFeedback.regno}
                required
                placeholder="Enter Regd. No"
                onChange={(e) => hamdleChangeAF(e)}
              />
            </div>
            <div className="teacher_feedback_form_container">
              <label htmlFor="name">3.Department</label>
              <select name="dept"
                id="department"
                value={aluminiFeedback.dept}
                onChange={(e) => hamdleChangeAF(e)}
              >
                <option>Please Select...</option>
                {dept.filter((each) => each !== "").map((each) => (
                  <option key={each}>{each}</option>
                ))}
              </select>
            </div>
            <div className="teacher_feedback_form_container">
              <label>4.Academic Year</label>
              <select
                id="academicYear"
                name="academicYear"
                value={aluminiFeedback.academicYear}
                onChange={(e) => hamdleChangeAF(e)}
              >
                <option>Please select...</option>
                {academicYear
                  .filter((each) => each !== "")
                  .map((each) => (
                    <option key={each}>{each}</option>
                  ))}
              </select>
            </div>

            <div className="teacher_feedback_form_container">
              <label htmlFor="name">2.Year Of Study</label>
              <input
                type="text"
                id="year"
                name="yearofStudy"
                value={aluminiFeedback.yearofStudy}
                onChange={(e) => hamdleChangeAF(e)}
                required
                placeholder="Enter Year Of Study"

              />
            </div>

            <div className="teacher_feedback_form_container">
              <label>5.Regulation</label>
              <select
                id="regulation"
                name="regulation"
                value={aluminiFeedback.regulation}
                onChange={(e) => hamdleChangeAF(e)}
              >
                <option>Please select...</option>
                {reg
                  .filter((each) => each !== "")
                  .map((each) => (
                    <option key={each}>{each}</option>
                  ))}
              </select>
            </div>
            <div className="teacher_feedback_form_container">
              <label>
                6.Courteousness of the Institute towards Alumni
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="courteousness"
                  value="Excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.courteousness == "Excellent"}

                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="courteousness"
                  value="Very Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.courteousness == "Very Good"}
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="courteousness"
                  value="Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.courteousness == "Good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="courteousness"
                  value="Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.courteousness == "Average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="courteousness"
                  value="Bellow Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.courteousness == "Bellow Average"}
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                7.Induction of research zeal and future aspirations

              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="induction"
                  value="Excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.induction == "Excellent"}
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="induction"
                  value="veryGood"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.induction == "veryGood"}
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="induction"
                  value="Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.induction == "Good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="induction"
                  value="average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.induction == "average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="induction"
                  value="Bellow Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.induction == "Bellow Average"}
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                8.Significance of courses towards global employment

              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="significance"
                  value="Excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.significance == "Excellent"}
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="significance"
                  value="veryGood"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.significance == "veryGood"}
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="significance"
                  value="Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.significance == "Good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="significance"
                  value="Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.significance == "Average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="significance"
                  value="bellowAverage"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.significance == "bellowAverage"}
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>9.How well is the curriculum Industry friendly</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="friendly"
                  value="Excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.friendly == "Excellent"}
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="friendly"
                  value="veryGood"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.friendly == "veryGood"}
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="friendly"
                  value="Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.friendly == "Good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="friendly"
                  value="average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.friendly == "average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="friendly"
                  value="bellowAverage"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.friendly == "bellowAverage"}
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                10.To what extent workshops/internships offered are helpful to the career
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="workshops"
                  value="Excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.workshops == "Excellent"}
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="workshops"
                  value='veryGood'
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.workshops == "veryGood"}
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="workshops"
                  value="good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.workshops == "good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="workshops"
                  value='average'
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.workshops == "average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="workshops"
                  value="bellowAverage"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.workshops == "bellowAverage"}
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>11.Potentiality of courses offered in training students for the Competitive exams</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="competitiveexams"
                  value="excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.competitiveexams == "excellent"}
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="competitiveexams"
                  value="veryGood"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.competitiveexams == "veryGood"}

                />
                <span>good Very </span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="competitiveexams"
                  value="Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.competitiveexams == "Good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="competitiveexams"
                  value="Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.competitiveexams == "Average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="competitiveexams"
                  value="Bellow Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.competitiveexams == "Bellow Average"}
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>12.Support of the Faculty to the students</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="supportfaculty"
                  value="Excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.supportfaculty == "Excellent"}
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="supportfaculty"
                  value='Very Good'
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.supportfaculty == "Very Good"}
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="supportfaculty"
                  value="Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.supportfaculty == "Good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="supportfaculty"
                  value="Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.supportfaculty == "Average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="supportfaculty"
                  value="Bellow Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.supportfaculty == "Bellow Average"}
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>13.Implementation of career orientation training programs</label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="orientation"
                  value="excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.orientation == "excellent"}
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="orientation"
                  value="veryGood"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.orientation == "veryGood"}
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="orientation"
                  value="Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.orientation == "Good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="orientation"
                  value="Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.orientation == "Average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="orientation"
                  value="Bellow Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.orientation == "Bellow Average"}

                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                14.Adeptness of the programs conducted for professional requirements
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="professional"
                  value="Excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.professional == "Excellent"}
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="professional"
                  value='Very Good'
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.professional == "Very Good"}
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="professional"
                  value="Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.professional == "Good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="professional"
                  value="Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.professional == "Average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="professional"
                  value="Bellow Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.professional == "Bellow Average"}
                />
                <span>Bellow Average</span>
              </div>
            </div>

            <div className="teacher_feedback_form_container">
              <label>
                15.Contribution of the institute for your future endeavors
              </label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="contribution"
                  value="Excellent"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.contribution == "Excellent"}
                />
                <span>Excellent</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="contribution"
                  value="Very Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.contribution == "Very Good"}
                />
                <span>Very Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="contribution"
                  value="Good"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.contribution == "Good"}
                />
                <span>Good</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="contribution"
                  value="Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.contribution == "Average"}
                />
                <span>Average</span>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  style={{ width: "15px" }}
                  type="radio"
                  name="contribution"
                  value="Bellow Average"
                  onChange={(e) => hamdleChangeAF(e)}
                  checked={aluminiFeedback.contribution == "Bellow Average"}
                />
                <span>Bellow Average</span>
              </div>
            </div>
            <button className="submit_btn" type="button" onClick={handleAFSubmit}>
              Submit
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default AlluminiFeedback;

import React from "react";
import ofc from "../../assets/adminofc.jpg";

const Administrationofc = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            ADMINISTRATIVE OFFICE
          </a>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded position-absolute w-90 h-40"
                  src={ofc}
                  alt="principal-img"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                About
              </h6>
              <div className="row gy-2 gx-4 mb-4">
                <p style={{textAlign:"justify"}}>
                <p className="mb-0">
                  The Administration department looks after all the admissions
                  in the college, and monitors everything during their
                  completion of the course. The dept also look administration of
                  the college for its smooth functioning under the guidance of
                  the Principal and Management. The dept also maintain the
                  records of the students and staff.
                </p>

                <p className="mb-0">
                  In order to achieve the stated activities. the office has an
                  Administrative Officer assisted by an accountant and junior
                  assistants. Additionally the office has office Assistants to
                  work in the academic section and to assist the examination
                  related work.
                </p>

                <p className="mb-0">
                  We are using Tally ERP 9.0 software for Accounts and Windows
                  2008 Operating System and MS Office, Windows 2007 for all
                  maintaining records and files of staff, students, Material
                  procurement and accounts management.
                </p>
                <p className="mb-0">
                  The Office aims at an error free maintenance of all the
                  records in the college for smooth and efficient running of the
                  administration which is turn results effective functioning of
                  the college.
                </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Administrationofc;

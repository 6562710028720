import react, { useState,useEffect } from "react"
import Examinationbranch from "../ExaminationBranch/Examinationbranch";
import "./BranchMenu.css"
import StaffMenu from "./Staff";
import Acadamicregulation from "../AcadamicRegulation/Acadamicregulation";
import AcademicCalendar from "./AcademicCalendar";
import SyllabusMenu from "./SyllabusMenu";
import ExaminationGrievanceMenu from "./ExaminationGrievanceMenu";
const BranchMenu=()=>{
    const [selectedType,setSelectedType]=useState("examinationBranch")
    useEffect(() => {
        const activeStyle = {
            backgroundColor: "orange",
            color: "white",
        };
    
        const defaultStyle = {
            backgroundColor: "",
            color: "",
        };
    
        document.getElementById("examinationBranch").style.backgroundColor = selectedType === "examinationBranch" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("examinationBranch").style.color = selectedType === "examinationBranch" ? activeStyle.color : defaultStyle.color;
    
        document.getElementById("staff").style.backgroundColor = selectedType === "staff" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("staff").style.color = selectedType === "staff" ? activeStyle.color : defaultStyle.color;
    
        document.getElementById("academicRegulation").style.backgroundColor = selectedType === "academicRegulation" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("academicRegulation").style.color = selectedType === "academicRegulation" ? activeStyle.color : defaultStyle.color;

            
        document.getElementById("academicCalendar").style.backgroundColor = selectedType === "academicCalendar" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("academicCalendar").style.color = selectedType === "academicCalendar" ? activeStyle.color : defaultStyle.color;

            
        document.getElementById("syllabus").style.backgroundColor = selectedType === "syllabus" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("syllabus").style.color = selectedType === "syllabus" ? activeStyle.color : defaultStyle.color;

                    
        document.getElementById("examinationGrievance").style.backgroundColor = selectedType === "examinationGrievance" ? activeStyle.backgroundColor : defaultStyle.backgroundColor;
        document.getElementById("examinationGrievance").style.color = selectedType === "examinationGrievance" ? activeStyle.color : defaultStyle.color;
    }, [selectedType]);
    return(
        <div className="facultyUploadContainer">
           <div className="facultyuploadButtons">
              <button onClick={()=>setSelectedType("examinationBranch")}id="examinationBranch">EXAMINATION BRANCH</button>
              <button onClick={()=>setSelectedType("staff")} id="staff">STAFF</button>
              <button onClick={()=>setSelectedType("academicRegulation")} id="academicRegulation">ACADEMIC REGULATION</button>
              <button onClick={()=>setSelectedType("academicCalendar")} id="academicCalendar">ACADEMIC CALENDER</button>
              <button onClick={()=>setSelectedType("syllabus")} id="syllabus">SYLLABUS</button>
              <button onClick={()=>setSelectedType("examinationGrievance")} id="examinationGrievance">EXAMINATION GRIEVANCE</button>
           </div>
    <div className="branchDivSecond">
    {selectedType=== "examinationBranch" && <Examinationbranch/>}
           {selectedType=== "staff" && <StaffMenu/>}
           {selectedType=== "academicRegulation" && <Acadamicregulation/>}
           {selectedType=== "academicCalendar" && <AcademicCalendar/>}
           {selectedType=== "syllabus" && <SyllabusMenu/>}
           {selectedType=== "examinationGrievance" && <ExaminationGrievanceMenu/>}
    </div>
        </div>
    )
}
export default BranchMenu;
import React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import block1 from "../../assets/quarters1.jpg";
import block2 from "../../assets/b_quarters.jpg";
import block3 from "../../assets/c_quarters.jpg";

const Quarters = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            STAFF QUARTERS
          </a>
        </div>
      </div>
      <br />

      <div className="container">
        <h4>Staff Quarters</h4>
        <p style={{textAlign:"justify"}}>
        <p>
          For a better administrative control, Sai Spurthi Provides Quarters for
          the employees of Sai Spurthi. The Management provides sufficient
          number of staff quarters named A(Krishna), B(Godhavari) &
          C(Kinnerasani) Blocks. Every Block has ‘12’ staff quarters. Each and
          every staff quarter is of a two bed room flat with all provisions like
          ‘24’ hours supply of electricity & purified water.
        </p>
        <p>
          Every Block has been equipped with internet, telephone, cable TV
          connection & ‘24’ hours security arrangement.
        </p>
        <p>
          The quarters can accommodate ‘41’ families. There is transport
          facility for school going children. About ‘30’ children are getting
          the benefit of this facility. There are well designed play ground like
          shuttle cock court, volley ball, cricket grounds for play. There are
          two canteens one for gents & one for ladies.
        </p>
        </p>
      </div>
      <br />
      <div className="container_card text-center d-flex ">
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            width: "33%",
            margin: 5,
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
            },
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 100 }}>
            <img src={block1} alt="block1-img" />
          </AspectRatio>
          <CardContent ratio="1" sx={{ width: 300 }}>
            <Typography level="title-lg" id="card-description w-100">
              A-Block - Krishna
            </Typography>
            <Typography
              level="body-sm"
              aria-describedby="card-description"
              mb={1}
            >
              <Link
                overlay
                underline="none"
                href="#interactive-card"
                sx={{color: "text.tertiary",
                 
                }}
              >
                This Block will be accommodate principal, head of the department
                & professors.
              </Link>
            </Typography>
            {/* <Chip
          variant="outlined"
          color="primary"
          size="sm"
          sx={{ pointerEvents: 'none' }}
        >
          Cool weather all day long
        </Chip> */}
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            width: 380,
            margin: 5,
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
            },
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 100 }}>
            <img src={block2} alt="block2-img" />
          </AspectRatio>
          <CardContent>
            <Typography level="title-lg" id="card-description">
              B Block - Godhavari
            </Typography>
            <Typography
              level="body-sm"
              aria-describedby="card-description"
              mb={1}
            >
              <Link
                overlay
                underline="none"
                href="#interactive-card"
                sx={{ color: "text.tertiary" }}
              >
                This block will be accommodate for associative & assistant
                professors.
              </Link>
            </Typography>
            {/* <Chip
              variant="outlined"
              color="primary"
              size="sm"
              sx={{ pointerEvents: "none" }}
            >
              Cool weather all day long
            </Chip> */}
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            width: 380,
            margin: 5,
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
            },
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 100 ,height:100}}>
            <img src={block3} alt="block3-img" />
          </AspectRatio>
          <CardContent>
            <Typography level="title-lg" id="card-description">
              C Block - Kinnerasani
            </Typography>
            <Typography
              level="body-sm"
              aria-describedby="card-description"
              mb={1}
            >
              <Link
                overlay
                underline="none"
                href="#interactive-card"
                sx={{ color: "text.tertiary" }}
              >
                This Block will be accommodate for non-teaching staff.
              </Link>
            </Typography>
            {/* <Chip
              variant="outlined"
              color="primary"
              size="sm"
              sx={{ pointerEvents: "none" }}
            >
              Cool weather all day long
            </Chip> */}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Quarters;

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { resources } from ".././Resourses/Resources";
import { useNavigate } from "react-router-dom";
import "../Admissionprocedure/AcadamicCalender.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Button from "@mui/material/Button";
import DialogEdit from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import PaginationReUsable from "../ReuseableComponent/ReusePagination";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein, moredetails) {
  return { name, calories, fat, carbs, protein, moredetails };
}

export default function CustomizedTables() {
  const [isEnable, setIsEnable] = useState(false);
  const [activeCommitte, setActiveCommitee] = useState([]);
  const [allCommitteData, setAllCommitteData] = useState([]);
  const [imgPath, setImgPath] = useState("");
  const [committeeName, setCommitteName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [modify, setModify] = useState({});
  const [modifyDataMembers, setModifyDataMembers] = useState({});
  const [imagePath, setImagePath] = useState("");
  const [editableIndex, setEditableIndex] = useState("");
  const [search, setSearch] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);

  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openEditMembers, setOpenEditMembers] = useState(false);

  const handleClickForEdit = () => {
    setOpenEdit(true);
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = async () => {
    try {
      setOpenEdit(false);
      const res = await axios.post(
        resources.APPLICATION_URL + "edit/commitees",
        modify
      );
      if (res.status === 200) {
        console.log("edit", res);
        handlegetAllCommitteData();
        alert("Data edited successfully");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleCloseAndEdit = () => {
    setOpenEdit(false);
  };

  // comitte members edit
  const handleDeleteCommiteeMem = async (rid) => {
    try {
      const res = await axios.delete(
        resources.APPLICATION_URL +
          `delete/committe-members?cMemberId=${rid}`
      );
      if (res.data) {
        console.log("delete", res);
        handleClickForMoreDetails(committeeName, name, email);
      } else {
        alert("No data found");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleClickForEditCommiteeMember = () => {
    setOpenEditMembers(true);
  };
  const handleEditSubmitCommMemmers = async () => {
    try {
      setOpenEditMembers(false);
      const res = await axios.post(
        resources.APPLICATION_URL + "edit/committe/members",
        modifyDataMembers
      );
      if (res.status === 200) {
        console.log("edit", res);

        setEditableIndex("");
        setModifyDataMembers({});
        handlegetAllCommitteData();
        alert("Data edited successfully");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleEditCloseCommiteeMember = () => {
    setOpenEditMembers(false);
  };

  const handleClickForMoreDetails = async (param, nameCo, emailCo) => {
    console.log("params===>", param, nameCo, emailCo);
    console.log("param===>", param);
    setIsEnable(true);
    try {
      const res = await axios.get(
        resources.APPLICATION_URL +
          `getallcommittee/members/?committee=${param}`
      );
      console.log("res", res);
      if (res.data) {
        console.log("getallcommittee/members/", res);
        setActiveCommitee(res.data[0]);
        setFilteredData(res.data[0]);
        setPaginationData(res.data[0]);
        setAllData(res.data[0]);
        setImagePath(res.data[1]);

        setCommitteName(param);
        setName(nameCo);
        setEmail(emailCo);
        setIsEnable(true);
      } else {
        alert("No data found");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlegetAllCommitteData = async () => {
    try {
      const res = await axios.get(
        resources.APPLICATION_URL + "get/all/committees"
      );
      if (res.data) {
        console.log(JSON.stringify(res.data));
        setAllCommitteData(res.data);
        console.log("committee members details===>", res.data);
      } else {
        alert("No data found");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handlegetAllCommitteData();
  }, []);

  const handleClose = () => {
    setIsEnable(false);
  };
  const handleNavigate = () => {
    navigate("/feedback/greivences");
  };

  const handleDelete = async (commiteeName) => {
    try {
      const res = await axios.delete(
        resources.APPLICATION_URL +
          `delete/committe?commiteName=${commiteeName}`
      );
      if (res.data) {
        console.log("delete", res);
        handlegetAllCommitteData();
      } else {
        alert("No data found");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleSearch = (e) => {
    let { value } = e.target;
    console.log("value", value);

    let list = allData.filter(
      (each) =>
        (each.memberName &&
          each.memberName.toLowerCase().includes(value.toLowerCase())) ||
        (each.memberId &&
          each.memberId.toLowerCase().includes(value.toLowerCase()))
    );
    console.log("list==>", list);
    setFilteredData(list);
    setSearch(value);
  };
  const handleGetSpliceData = (receivedList, startSNo) => {
    setPaginationData(receivedList);
    setSerialNo(startSNo);
  };
  console.log("paginationData===>", paginationData);
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">S.No</StyledTableCell>
              <StyledTableCell align="center">Committee Name</StyledTableCell>
              <StyledTableCell align="center">
                Committee Co-Ordinator{" "}
              </StyledTableCell>
              <StyledTableCell align="center">E-Mail</StyledTableCell>
              <StyledTableCell align="center">Mobile Number</StyledTableCell>
              <StyledTableCell align="center">
                Click Here for More Information
              </StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allCommitteData &&
              allCommitteData.length !== 0 &&
              allCommitteData.map((row, index) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.committees}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.cordinator}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.cordinatorEmail}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.cordinatorMobileNumber}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {
                      <button
                        onClick={() =>
                          handleClickForMoreDetails(
                            row.committees,
                            row.cordinator,
                            row.cordinatorEmail
                          )
                        }
                      >
                        Click Here For More Details
                      </button>
                    }
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <EditOutlinedIcon
                      className="editIconCommitee"
                      onClick={() => {
                        handleClickForEdit();
                        setModify(row);
                      }}
                    ></EditOutlinedIcon>
                    <DeleteIcon
                      className="deleteIcon"
                      onClick={() => handleDelete(row.committees)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <React.Fragment>
        <Dialog fullScreen open={isEnable} onClose={handleClose}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className="committeemainContainer">
            <div className="photo_container">
              <div className="photo_name_container">
                <button
                  onClick={() => handleNavigate()}
                  className="grevience_btn"
                >
                  Greviences
                </button>

                <p className="function_style">Functions</p>
                <p className="function_style">Roles & Responsibilites</p>
                <p className="function_style">Members</p>
              </div>

              <div className="conver_container">
                <div className="conver_head_container">
                  <h1 className="conver_head">Convener</h1>
                </div>
                <img
                  id="displayedImage"
                  alt="Displayed Image"
                  src={`data:image/png;base64,${imagePath}`}
                  className="img_commitee"
                />

                <p className="function_style_name">{name}</p>
                <p className="function_style_name email">{email}</p>
              </div>
            </div>
            {/* <div className="data_container">
                {committeeName === "Anti Ragging Cell" ? (
                  <p>
                    The college has been taking all the precautions for
                    preventing ragging. The menace of ragging has totally
                    eliminated from the college with the sincere efforts of the
                    management, Principal, staff and senior students.
                    Anti-Ragging committees have been constituted with teaching
                    and non-teaching staff. Vigilance duties have been allotted
                    to the members of the said committees in a systematic manner
                    by developing a specific time table. Potential points of
                    student gatherings and trouble making are identified and the
                    members of faculty have been posted in those locations. The
                    college has been taking a declaration in writing from all
                    the students and parents stating that they would not indulge
                    in ragging and could not resort to any unfair means of
                    intimidating fear and inflicting pain or agony on anybody.
                    Since the college has been able to implement all the
                    measures said above there has not been any cases of ragging
                    reported during the last four years
                  </p>
                ) : (
                  <p></p>
                )}
                <div className="commitee_table_header">
                  <h1 className="popup_heading">{committeeName} Members</h1>
                </div>

              </div> */}

            <div className="committeeTableDiv">
              <div className="committeSearchDiv">
                <input
                  type="text"
                  placeholder="MemberName..."
                  value={search}
                  onChange={(e) => handleSearch(e)}
                />
              </div>
              <table className="tableStylings">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {paginationData &&
                    paginationData.length > 0 &&
                    paginationData.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          {editableIndex === index ? (
                            <td>
                              <input
                                type="text"
                                name="CommiteeName"
                                value={modifyDataMembers.name}
                                onChange={(e) => {
                                  setModifyDataMembers({
                                    ...modifyDataMembers,
                                    name: e.target.value,
                                  });
                                }}
                                className="cordinator_input"
                              />
                            </td>
                          ) : (
                            <td>{items.name}</td>
                          )}
                          {editableIndex === index ? (
                            <td>
                              {" "}
                              <input
                                type="text"
                                name="CordinatorName"
                                value={modifyDataMembers.position}
                                className="cordinator_input"
                                onChange={(e) => {
                                  setModifyDataMembers({
                                    ...modifyDataMembers,
                                    position: e.target.value,
                                  });
                                }}
                              />
                            </td>
                          ) : (
                            <td> {items.position} </td>
                          )}
                          {editableIndex === index ? (
                            <td>
                              {" "}
                              <input
                                type="text"
                                name="CordinatorName"
                                value={modifyDataMembers.type}
                                className="cordinator_input"
                                onChange={(e) => {
                                  setModifyDataMembers({
                                    ...modifyDataMembers,
                                    type: e.target.value,
                                  });
                                }}
                              />
                            </td>
                          ) : (
                            <td> {items.type} </td>
                          )}
                          {editableIndex === index ? (
                            <td>
                              <button
                                className="generalButton"
                                onClick={() => handleEditSubmitCommMemmers()}
                              >
                                Submit
                              </button>
                            </td>
                          ) : (
                            <td>
                              <EditOutlinedIcon
                                className="editIconCommitee"
                                onClick={() => {
                                  handleClickForEditCommiteeMember();
                                  setModifyDataMembers(items);
                                  setEditableIndex(index);
                                }}
                              ></EditOutlinedIcon>
                              <DeleteIcon
                                className="deleteIcon"
                                onClick={() =>
                                  handleDeleteCommiteeMem(items.rid)
                                }
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <PaginationReUsable
                paginationData={filteredData}
                handleGetSpliceData={handleGetSpliceData}
              />
            </div>
          </div>
        </Dialog>
      </React.Fragment>

      <React.Fragment>
        <Dialog
          open={openEdit}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseEdit}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Edit Committee"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="containerPop">
                <label className="labelCommitee">Committee Name</label>
                <input
                  type="text"
                  name="CommiteeName"
                  value={modify.committees}
                  disabled
                  className="cordinator_input"
                />
                <label className="labelCommitee">Committee Co-Ordinator</label>
                <input
                  type="text"
                  name="CordinatorName"
                  value={modify.cordinator}
                  className="cordinator_input"
                  onChange={(e) => {
                    setModify({ ...modify, cordinator: e.target.value });
                  }}
                />
                <label className="labelCommitee">E-Mail</label>
                <input
                  type="text"
                  name="CordinatorEmail"
                  value={modify.cordinatorEmail}
                  onChange={(e) => {
                    setModify({ ...modify, cordinatorEmail: e.target.value });
                  }}
                  className="cordinator_input"
                />
                <label className="labelCommitee">Mobile Number</label>
                <input
                  type="number"
                  name="CordinatorContact"
                  value={modify.cordinatorMobileNumber}
                  onChange={(e) => {
                    setModify({
                      ...modify,
                      cordinatorMobileNumber: e.target.value,
                    });
                  }}
                  className="cordinator_input"
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCloseEdit();
              }}
              sx={{ color: "green" }}
            >
              submit
            </Button>
            <Button
              onClick={() => {
                handleCloseAndEdit();
              }}
              sx={{ color: "red" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

      {/* <React.Fragment>
        <Dialog
          open={openEditMembers}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleEditCloseCommiteeMember}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Edit Committee Member"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="containerPop">
                <label className="labelCommitee">Member Name</label>
                <input
                  type="text"
                  name="CommiteeName"
                  value={modifyDataMembers.memberName}
                  onChange={(e) => {
                    setModifyDataMembers({ ...modifyDataMembers, memberName: e.target.value });
                  }}
                  className="cordinator_input"
                />
                <label className="labelCommitee">Position</label>
                <input
                  type="text"
                  name="CordinatorName"
                  value={modifyDataMembers.position}
                  className="cordinator_input"
                  onChange={(e) => {
                    setModifyDataMembers({ ...modifyDataMembers, position: e.target.value });
                  }}
                />


              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleEditSubmitCommMemmers();
              }}
              sx={{ color: "green" }}
            >
              submit
            </Button>
            <Button
              onClick={() => {
                handleEditCloseCommiteeMember();
              }}
              sx={{ color: "red" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment> */}
    </>
  );
}

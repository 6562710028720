import React from "react";
import bus1 from '../../assets/b1.jpg';
import bus2 from '../../assets/b2.jpg';

const Transportion = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            TRANSPORT FACILITY
          </a>
        </div>
      </div>
      <br />

      <div className="container">
        <h4>Transportation</h4>
        <p>
          The College has a fleet of 2 Buses. The routes for the buses are
          organized so that students from all parts of the town can easily
          approach the college. The facility is made available to the students
          at reasonable charges depending upon the routes opted by them. The
          main pickup points are Sathupally, Chintalapudi etc.
        </p>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              //   style={{ minHeight: "100px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded  "
                  src={bus1}
                  alt="bus1-img"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100">
                <img
                  className="img-fluid rounded  "
                  src={bus2}
                  alt="bus2-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transportion;

import * as React from "react";
import { useState } from "react";

function createData(
  name,
  branch1,
  branch2,
  branch3,
  branch4,
  branch5,
  branch6
) {
  return {
    name,
    branch1,
    branch2,
    branch3,
    branch4,
    branch5,
    branch5,
    branch6,
  };
}

const rows = [
  createData(
    "1st prefered Branch",
    "CSE",
    "CSE (AI&ML)",
    "AI&DS",
    "ECE",
    "ECE",
    "MECH"
  ),
  createData(
    "2st prefered Branch",
    "CSE",
    "CSE (AI&ML)",
    "AI&DS",
    "ECE",
    "ECE",
    "MECH"
  ),
  createData(
    "3st prefered Branch",
    "CSE",
    "CSE (AI&ML)",
    "AI&DS",
    "ECE",
    "ECE",
    "MECH"
  ),
  createData(
    "4st prefered Branch",
    "CSE",
    "CSE (AI&ML)",
    "AI&DS",
    "ECE",
    "ECE",
    "MECH"
  ),
  createData(
    "5st prefered Branch",
    "CSE",
    "CSE (AI&ML)",
    "AI&DS",
    "ECE",
    "ECE",
    "MECH"
  ),
  createData(
    "6st prefered Branch",
    "CSE",
    "CSE (AI&ML)",
    "AI&DS",
    "ECE",
    "ECE",
    "MECH"
  ),
];

export default function BasicTable({ setStudentRegistrationData }) {
  console.log("setStudentRegistrationData", setStudentRegistrationData);
  const [dropDownValues2, setDropDownValues2] = useState([]);
  const [dropDownValues3, setDropDownValues3] = useState([]);
  const [dropDownValues4, setDropDownValues4] = useState([]);
  const [dropDownValues5, setDropDownValues5] = useState([]);
  const [dropDownValues6, setDropDownValues6] = useState([]);
  const branchesList = ["CSE", "CSE (AI&ML)", "AI&DS", "ECE", "EEE", "MECH"];
  const handleDropDownValues1 = (e) => {
    const remaingVal = branchesList.filter((each) => each !== e.target.value);
    setDropDownValues2(remaingVal);
    setDropDownValues3([]);
    setDropDownValues4([]);
    setDropDownValues5([]);
    setDropDownValues6([]);
  };
  const handleDropDownValues2 = (e) => {
    setDropDownValues3(
      dropDownValues2.filter((each) => each != e.target.value)
    );
    setDropDownValues4([]);
    setDropDownValues5([]);
    setDropDownValues6([]);
  };
  const handleDropDownValues3 = (e) => {
    setDropDownValues4(
      dropDownValues3.filter((each) => each != e.target.value)
    );
    setDropDownValues5([]);
    setDropDownValues6([]);
  };

  const handleDropDownValues4 = (e) => {
    setDropDownValues5(
      dropDownValues4.filter((each) => each != e.target.value)
    );
    setDropDownValues6([]);
  };

  const handleDropDownValues5 = (e) => {
    setDropDownValues6(
      dropDownValues5.filter((each) => each != e.target.value)
    );
  };

  const handleAppendData = (e) => {
    const { name, value } = e.target;
    setStudentRegistrationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div style={{ display: "flex" }}>
      <ol
        className="admission_form_container"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <li>
          <div className="admission_form_inp_container">
            <label className="admission_label">Preference 1</label>
            <select
              name="firstPreferedBranch"
              value={setStudentRegistrationData.firstPreferedBranch}
              onChange={(e) => {
                handleAppendData(e);
                handleDropDownValues1(e);
              }}
            >
              <option>Please Select...</option>
              {branchesList.map((branch) => (
                <option value={branch}>{branch}</option>
              ))}
            </select>
          </div>
        </li>

        <li>
          <div className="admission_form_inp_container">
            <label className="admission_label">Preference 2</label>
            <select
              name="secundPreferedBranch"
              value={setStudentRegistrationData.secundPreferedBranch}
              onChange={(e) => {
                handleAppendData(e);
                handleDropDownValues2(e);
              }}
            >
              <option>Please Select...</option>
              {dropDownValues2.map((branch) => (
                <option value={branch}>{branch}</option>
              ))}
            </select>
          </div>
        </li>

        <li>
          <div className="admission_form_inp_container">
            <label className="admission_label">Preference 3</label>
            <select
              name="thirdPreferedBranch"
              value={setStudentRegistrationData.thirdPreferedBranch}
              onChange={(e) => {
                handleAppendData(e);
                handleDropDownValues3(e);
              }}
            >
              <option>Please Select...</option>
              {dropDownValues3.map((branch) => (
                <option value={branch}>{branch}</option>
              ))}
            </select>
          </div>
        </li>

        <li>
          <div className="admission_form_inp_container">
            <label className="admission_label">Preference 4</label>
            <select
              name="fourthPreferedBranch"
              value={setStudentRegistrationData.fourthPreferedBranch}
              onChange={(e) => {
                handleAppendData(e);
                handleDropDownValues4(e);
              }}
            >
              <option>Please Select...</option>
              {dropDownValues4.map((branch) => (
                <option value={branch}>{branch}</option>
              ))}
            </select>
          </div>
        </li>

        <li>
          <div className="admission_form_inp_container">
            <label className="admission_label">Preference 5</label>
            <select
              value={setStudentRegistrationData.fifthPreferedBranch}
              name="fifthPreferedBranch"
              onChange={(e) => {
                handleAppendData(e);
                handleDropDownValues5(e);
              }}
            >
              <option>Please Select...</option>
              {dropDownValues5.map((branch) => (
                <option value={branch}>{branch}</option>
              ))}
            </select>
          </div>
        </li>

        <li>
          <div className="admission_form_inp_container">
            <label className="admission_label">Preference 6</label>
            <select
              name="sixthPreferedBranch"
              value={setStudentRegistrationData.sixthPreferedBranch}
              onChange={(e) => {
                handleAppendData(e);
              }}
            >
              <option>Please Select...</option>
              {dropDownValues6.map((branch) => (
                <option value={branch}>{branch}</option>
              ))}
            </select>
          </div>
        </li>
      </ol>
    </div>
  );
}

import React from "react";
import grevienceImg from "../../assets/grievance.jpg";
import "./GrevienceForm.css";
import { useState } from "react";
import axios from "axios";
import Resources, { resources } from "../Resourses/Resources";

const GrevienceEnquiryForm = () => {
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    subject: "",
    mail: "",
    contact: "",
    message: "",
  });

  const handleAppendDataToEnquiryData = (e) => {
    const { name, value } = e.target;
    setEnquiryData({ ...enquiryData, [name]: value });
  };

  const handleSaveDataEnquiryData = async () => {
    try {
      const res = await axios.post(
        resources.APPLICATION_URL + "enquiryForm/save",
        enquiryData
      );
      if (res.data) {
        alert("Enquiry Added Successfully");
        setEnquiryData({
          name: "",
          subject: "",
          mail: "",
          contact: "",
          message: "",
        });
      }
    } catch (e) {
  
      console.log(e);
    }
  };

  return (
    <form className="grevience_form_main_container">
      <img
        src={grevienceImg}
        alt="grievance Enquiry form"
        style={{ width: "43%" }}
      />

      <div className="grevience_form_sub_container">
        <h1 className="grevience_form_heading">ENQUIRY FORM</h1>
        <div className="grevience_form_container">
          <label htmlFor="Name">Name</label>
          <input
            onChange={(e) => handleAppendDataToEnquiryData(e)}
            type="text"
            placeholder="Enter Name"
            id="Name"
            name="name"
            required
          />
        </div>
        <div className="grevience_form_container">
          <label htmlFor="Subject">Subject</label>
          <input
            onChange={(e) => handleAppendDataToEnquiryData(e)}
            type="text"
            placeholder="Subject"
            id="Subject"
            name="subject"
            required
          />
        </div>
        <div className="grevience_form_container">
          <label htmlFor="Email">Email</label>
          <input
            onChange={(e) => handleAppendDataToEnquiryData(e)}
            type="email"
            placeholder="Email"
            id="Email"
            name="mail"
            required
          />
        </div>
        <div className="grevience_form_container">
          <label htmlFor="Contact">Contact</label>
          <input
            onChange={(e) => handleAppendDataToEnquiryData(e)}
            type="number"
            placeholder="Contact"
            id="Contact"
            name="contact"
            required
          />
        </div>
        <div className="grevience_form_container">
          <label htmlFor="Message">Message</label>
          <textarea
            onChange={(e) => handleAppendDataToEnquiryData(e)}
            name="message"
            type="text"
            cols={30}
            rows={5}
            placeholder="Message"
            id="Message"
            required
          />
        </div>
        <div>
          <button className="submit_btn" type="button" onClick={() => handleSaveDataEnquiryData()}>
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default GrevienceEnquiryForm;

import React from "react";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const Admissionprocedure = () => {
  return (
    <div>
      <div class="content content-expand-lg content-dark bg-light">
        <div class="container text-center">
          <h4> Admission Criteria</h4>

          <p>
            The eligibility criteria for admission into UG & PG programmes are
            as per the norms approved by Government of Telangana from time to
            time. The sanctioned seats in each program in the college are
            classified into CATEGORY – A and CATEGORY – B at first year level
            and Lateral Entry at second year level.
          </p>
        </div>
        <div className="service text-center">
          <h4>
            <NotificationsActiveIcon />
            <br />
            Sai Spurthi Instituteof Technology – EAMCET Code: SAIS
          </h4>
          <p>
            Reservation Policy – Seats will be filled by the Govt. of Telangana
            as per the Govt. approved Policy.
          </p>
          <h5>CATEGORY A SEATS</h5>
          <p>
            70% of seats will be filled by the Government of Telangana as per
            the norms approved.
          </p>
          <h5>CATEGORY B SEATS</h5>
          <p>
            30% of seats will be filled by the College as per the norms approved
            by the Government of Telangana.
          </p>
          <h4>CATEGORY - LATERAL ENTRY SEATS</h4>
          <p>
            Lateral entry candidates shall be admitted into the Third semester
            directly as per the norms approved by Government of Telangana.
          </p>
          <p>
            The percentages of Category – A, Category – B and Lateral Entry
            Seats are decided time to time by the Government of Telangana.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Admissionprocedure;

import React, { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import acadamic_calendar from "../../assets/acadamic_calendar.jpg";
import "./AcadamicCalender.css";

const AcadamicCalender = () => {
  const [year, setYear] = useState("");
  const [course, setCourses] = useState("");

  return (
    <div className="acadamic_calendar_bg_cnt">
      <div className="page_header">
        <h1>Acadamic Calender</h1>
      </div>

      <div class="d-flex flex-column align-items-center justify-content-center year_select_cnt">
        <label>Download Acadamic Calender Here</label>
        <div className="all_dropdown_container">
          <div className="year_select_cnt">
            <label>Academic Year</label>
            <select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="select text-center"
            >
              <option disabled>Select Acadamic Year</option>
              <option value="">Please Select</option>
              <option value="2014-15">2014-15</option>
              <option value="2015-16">2015-16</option>
              <option value="2016-17">2016-17</option>
              <option value="2017-18">2017-18</option>
              <option value="2018-19">2018-19</option>
              <option value="2019-20">2019-20</option>
              <option value="2020-21">2020-21</option>
              <option value="2021-22">2021-22</option>
              <option value="2022-23">2022-23</option>
              <option value="2023-24">2023-24</option>
              <option value="2024-25">2024-25</option>
            </select>
          </div>
          <div className="year_select_cnt">
            <label>Courses</label>
            <select
              value={course}
              onChange={(e) => setCourses(e.target.value)}
              className="select text-center"
            >
              <option disabled>Select Course</option>
              <option value="">Please Select</option>
              <option value="Ug">Ug</option>
              <option value="Pg">Pg</option>
              <option value="Diploma">Diploma</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row-12 text-center download_cnt mt-5">
        <label className="">
          Download For Here {"   "}----{">"}
        </label>
        <br />
        {year ? (
          <a
            id="links_ug"
            target="blank"
            href={`http://saispurthi.ac.in/docs/acalender/ug_ac_${year}.pdf`}
            style={{
              textDecoration: "none",
              border: "1px solid orange",
              padding: "30px",
            }}
          >
            <FileDownloadIcon fontSize="large" sx={{ fontSize: 50 }} />
          </a>
        ) : (
          <a
            style={{
              textDecoration: "none",
              border: "3px solid orange",
              padding: "30px",
            }}
            id="links_ug"
            target="_self"
            href={`/acadamics/acadamicCalender`}
          >
            <FileDownloadIcon
              sx={{ fontSize: 50, color: "orange" }}
              fontSize="large"
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default AcadamicCalender;

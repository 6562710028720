import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Iqac.css";
import StarRateIcon from "@mui/icons-material/StarRate";
import initiatives from "../../assets/images/iqac-initives.png";
import axios from "axios";
import { resources } from "../Resourses/Resources";

const Iqac = () => {
  const [value, setValue] = React.useState("1");

  const handleShowbase64DataPdfView = async (param) => {
    try {
      const response = await axios.get(
        resources.APPLICATION_URL + "filenames?filename=" + param + "&foldername=ssr"
      );
      console.log("response", response.data);
      const base64 = response.data;

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (e) {

      console.log("error", e);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const members = [
    {
      memberName: "Dr.V.S.R Kumari",
      position: "	Chair Person",
    },
    {
      memberName: "Mr. K Rama Krishna Prasad",
      position: "Coordinator",
    },
    {
      memberName: "Mr. Ch.Balakrishna",
      position: "Co-Coordinator",
    },
    {
      memberName: "Mr. K. V. Jawahar",
      position: "Member",
    },
    {
      memberName: "Dr. K. Bhaskar Muthyalu	",
      position: "Member",
    },
    {
      memberName: "Dr. P. Sekhar Babu	",
      position: "Member",
    },
    {
      memberName: "Dr.Sk. Yakoob	",
      position: "Member",
    },
    {
      memberName: "Dr. Sk. Meera saheb	",
      position: "Member",
    },
    {
      memberName: "Dr. D.N.V.Krishna Reddy	",
      position: "Member",
    },
    {
      memberName: "Mr. G. Upendra",
      position: "Member",
    },
    {
      memberName: "Mr. G. Rajendar Reddy",
      position: "Member",
    },
  ];
  const meetings = [
    {
      meetingDate: "2021-2022",
      link: "2021-2022.pdf",
    },
    {
      meetingDate: "2020-2021",
      link: "2020-2021.pdf",
    },
    {
      meetingDate: "2019-2020",
      link: "2019-2020.pdf",
    },
    {
      meetingDate: "2018-2019",
      link: "2018-2019.pdf",
    },
    {
      meetingDate: "2017-2018",
      link: "2017-2018.pdf",
    },

    {
      meetingDate: "2016-2017",
      link: "2016-2017.pdf",
    },
  ];
  const aqar = [
    {
      year: "2021-22",
      link: "AQAR21-22.pdf",
    },
    {
      year: "2020-21",
      link: "AQAR20-21.pdf",
    },
    {
      year: "2019-20",
      link: "AQAR19-20.pdf",
    },
    {
      year: "2018-19",
      link: "AQAR18-19.pdf",
    },
    {
      year: "2017-18",
      link: "AQAR17-18.pdf",
    },
  ];
  return (
    <div>
      <h1 className="iqac_header">IQAC</h1>
      <Box sx={{ width: "100%", typography: "body1", textAlign: "center" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              centered
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                }}
                label="IQAC Home"
                value="1"
              />
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                }}
                label="IQAC Members"
                value="2"
              />
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                }}
                label="IQAC Meetings"
                value="3"
              />
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                }}
                label="Initiatives"
                value="4"
              />
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                }}
                label="Annual Reports of AQAR"
                value="5"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <h3>INTERNAL QUALITY ASSURANCE CELL (IQAC)</h3>

            <div className="iqac_cuntant_container">
              <div>
                <h6 style={{ fontWeight: "bold", fontSize: "18px" }}>
                  The college has established the Internal Quality Assurance
                  Cell(IQAC) as per the NAAC guidelines with the following
                  functions:
                </h6>
                <p>
                  <span className="iqac_sidehead">Functions :</span>
                  <br />
                  <span className="iqac_secondary">
                    {" "}
                    Some of the functions expected of the IQAC are:
                  </span>
                </p>
                <div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Development and application of quality
                      benchmarks/parameters for various academic and
                      administrative activities of the institution
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Facilitating the creation of a learner-centric environment
                      conducive to quality education and faculty maturation to
                      adopt the required
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      knowledge and technology for participatory teaching and
                      learning process
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      Arrangement for feedback response from students, parents
                      and other stakeholders on quality-related institutional
                      processes
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Dissemination of information on various quality parameters
                      of higher education
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Organization of inter and intra institutional workshops,
                      seminars on quality related themes and promotion of
                      quality circles
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Documentation of the various programmes/activities leading
                      to quality improvement
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Acting as a nodal agency of the Institution for
                      coordinating quality-related activities, including
                      adoption and dissemination of best practices
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Development and maintenance of institutional database
                      through MIS for the purpose of maintaining/enhancing the
                      institutional quality
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p> Development of Quality Culture in the institution</p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Preparation of the Annual Quality Assurance Report (AQAR)
                      as per guidelines and parameters of NAAC, to be submitted
                      to NAAC
                    </p>
                  </div>
                </div>
                <p>
                  <span className="iqac_sidehead">Strategies:</span>
                  <br />
                  <span className="iqac_secondary">
                    The IQAC shall evolve mechanisms and procedures for:
                  </span>
                </p>
                <div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Ensuring timely, efficient and progressive performance of
                      academic, administrative and financial tasks
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      The relevance and quality of academic and research
                      programmes
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      Equitable access to and affordability of academic
                      programmes for various sections of society
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      Optimization and integration of modern methods of teaching
                      and learning
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p> The credibility of evaluation procedures</p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      Ensuring the adequacy, maintenance and functioning of the
                      support structure and services
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      Research sharing and networking with other institutions in
                      India and abroad
                    </p>
                  </div>
                </div>
                <div className="iqac_links">
                  <button
                    onClick={() =>
                      handleShowbase64DataPdfView(
                        "iso.pdf"
                      )
                    }
                  >
                    ISO Certification Letter
                  </button>

                  <button
                    onClick={() =>
                      handleShowbase64DataPdfView(
                        "naac_cer.pdf"
                      )
                    }
                  >
                    NAAC Accreditation Letter
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <h3>INTERNAL QUALITY ASSURANCE CELL MEMBERS</h3>
            <div className="iqac_table_container">
              <table className="iqac_table">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>MEMBER NAME</th>
                    <th>POSITION</th>
                  </tr>
                </thead>
                {members.map((each, index) => (
                  <tbody className="iqac_table_body">
                    <tr>
                      <td>{index + 1}</td>
                      <td>{each.memberName}</td>
                      <td>{each.position}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <h3>INTERNAL QUALITY ASSURANCE CELL MEETINGS</h3>
            <div className="iqac_table_container">
              <table className="iqac_table">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>MEETING DATE / ACADEMIC YEAR</th>
                    <th>DOCUMENT LINK</th>
                  </tr>
                </thead>
                {meetings.map((each, index) => (
                  <tbody className="iqac_table_body">
                    <tr>
                      <td>{index + 1}</td>
                      <td>{each.meetingDate}</td>
                      <td>
                        <button style={{borderWidth:"0px",cursor:"pointer",background:"none",color:"blue",textDecoration:"underline"}} onClick={() => handleShowbase64DataPdfView(each.link)}>View Document</button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </TabPanel>
          <TabPanel value="4">
            <h3>INTERNAL QUALITY ASSURANCE CELL INITIATIVES</h3>
            <img className="iqac_image" src={initiatives} alt="img" />
          </TabPanel>
          <TabPanel value="5">
            <h3>Annual Reports (AQAR)</h3>
            <div className="aqar_container">
              <span className="aqar_2ndhead">
                The annual quality assurance report is a self – reviewed
                progress report on the following criteria:
              </span>
              <br />
              <div className="aqar_criterion">
                <p>
                  Criterion – I: Curricular Aspects
                  <br />
                  Criterion – II: Teaching, Learning and Evaluation
                  <br />
                  Criterion – III: Research, Consultancy and Extension
                  <br />
                  Criterion – IV: Infrastructure and Learning Resources
                  <br />
                  Criterion – V: Student Support and Progression
                  <br />
                  Criterion – VI: Governance, Leadership and Management
                  <br />
                  Criterion – VII: Innovations and Best Practices
                </p>
              </div>
              <div>
                <p className="aqar_3rdline">
                  The report is to detail the tangible results achieved in key
                  areas, specifically identified by the institutional IQAC at
                  the beginning of the academic year. The AQAR will detail the
                  results of the perspective plan worked out by the IQAC.
                </p>
              </div>
            </div>
            <div className="aqar_button_container">
              {aqar.map((each) => (
                <button onClick={() => handleShowbase64DataPdfView(each.link)}>
                  {each.year}
                </button>
              ))}
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default Iqac;

import axios from "axios";
import react, { useEffect, useState } from "react";
import { resources } from "../Resourses/Resources";
import { EditRounded } from "@mui/icons-material";
import "./facultyScreens.css";
import Cookies from "js-cookie";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import RotatingSpinner  from "../Resourses/RotatingSpinner";

const FcultyEvents = () => {
  const [conductedBy, setConductedBy] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [issuedBy, setIssuedBy] = useState("");
  const [circular, setCircular] = useState("");
  const [notificaioDesc, setNotificationDescp] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [isAdded, setIsAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userDetailsCookie = Cookies.get("userDetails");
  

  const userDetails = JSON.parse(userDetailsCookie);
  const userName = userDetails.username;
  const departMent = userDetails.department;
  const userEmail = userDetails.email;
  const date = new Date;
  let currentMonth;
  let currentDate;
  const toDaysDate = date.getDate();
  const month = date.getMonth() + 1
  const currentYear = date.getFullYear()

  if (month < 10) {
      currentMonth = "0" + month
  } else {
      currentMonth = month
  }
  if (toDaysDate < 10) {
      currentDate = "0" + toDaysDate
  } else {
      currentDate = toDaysDate
  }
  const newDate = currentYear + "-" + currentMonth + "-" + currentDate
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [studentData, setStudentData] = useState([
    {
      participantName: "",
      participantEmail: "",
      mobileNo: "",
    },
  ]);
  const [details, setDetails] = useState({
    conductedBy: "",
    description: "",
    eventName: "",
    date: "",
    department: departMent,
    facultyName: userName,
    facultyEmail: userEmail,
    chiefGuest1: "",
    ChiefGuest2: "",
    startDate: "",
    endDate: "",
  });
  useEffect(()=>{
    setDetails({...details,date:newDate})
  },[newDate])
console.log("details===>",details)
  const [studentDetails, setStudentDetails] = useState([]);
  const handleUploadImages = async (e) => {
    const files = e.target.files;
    setSelectedFiles(files);
  };
  const handleAdd = () => {
    const details = {
      participantName: "",
      participantEmail: "",
      mobileNo: "",
    };
    setStudentData([...studentData, details]);
    // setRowCount(rowCount + 1);
    // setIsAdded(true);
  };

  const addingDataRows = (i, value, name) => {
    const updatedStudentData = [...studentData];
    updatedStudentData[i] = {
      ...updatedStudentData[i],
      [name]: value,
    };
    setStudentData(updatedStudentData);
  };
  const handleDelete = (i) => {
    const updatedStudentData = [...studentData];
    updatedStudentData.splice(i, 1);
    setStudentData(updatedStudentData);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    const allData = { ...details, eventParticipantsModel: studentData };
    console.log("files to send ===>", selectedFiles);
    console.log("data==>", details);
    console.log("allData==>", allData);
    const formData = new FormData();
    formData.append(
      "eventObject",
      new Blob([JSON.stringify(allData)], { type: "application/json" })
    );
    for (let i = 0; i <= selectedFiles.length - 1; i++) {
      formData.append("eventFiles", selectedFiles[i]);
    }
    try {
      const detailsToSend = await axios.post(
        resources.APPLICATION_URL + "saveEventsAndImages",
        formData
      );
      console.log("events to send ===>", detailsToSend);
      if (detailsToSend.status === 200) {
        setDetails({
          ...details,
          conductedBy: "",
          description: "",
          eventName: "",
     
          chiefGuest1: "",
          chiefGuest2: "",
          startDate: "",
          endDate: "",
          eventParticipantsModel: [],
        });
        setIsLoading(false);
        setSelectedFiles([]);
        setStudentData([])
        document.getElementById("eventsImages").value = "";
        alert("Successfully uploaded data");
      } else {
        setIsLoading(false);

        alert("Something went wrong");
      }
    } catch (error) {
      console.log("error")
    }
  };

  return (
    <>
      {isLoading ? (
        <RotatingSpinner />
      ):(
        <div className="uploadContainer">
      <p className="headerTagStyle">Upload an Event</p>
      <div className="overallDivContainer">
        <div className="uploadDivsCon">
          {/* <div className="uploadLabelAndInput">
            <label>Current Date</label>
            <input
              type="date"
              value={details.date}
              onChange={(e) => setDetails({ ...details, date: e.target.value })}
            />
          </div> */}
          <div className="uploadLabelAndInput">
            <label>Conducted By</label>
            <input
              type="text"
              value={details.conductedBy}
              onChange={(e) =>
                setDetails({ ...details, conductedBy: e.target.value })
              }
            />
          </div>
          <div className="uploadLabelAndInput">
            <label>Event Name</label>
            <textarea
              type="text"
              value={details.eventName}
              onChange={(e) =>
                setDetails({ ...details, eventName: e.target.value })
              }
            />
          </div>
          <div className="uploadLabelAndInput">
            <label>Event Description</label>
            <textarea
              value={details.description}
              onChange={(e) =>
                setDetails({ ...details, description: e.target.value })
              }
            />
          </div>

          <div className="uploadLabelAndInput">
            <label>Chief Guest 1</label>
            <input
              type="text"
              value={details.chiefGuest1}
              onChange={(e) =>
                setDetails({ ...details, chiefGuest1: e.target.value })
              }
            />
          </div>
          <div className="uploadLabelAndInput">
            <label>Chief Guest 2</label>
            <input
              type="text"
              value={details.chiefGuest2}
              onChange={(e) =>
                setDetails({ ...details, chiefGuest2: e.target.value })
              }
            />
          </div>
          <div className="uploadLabelAndInput">
            <label>Start Date</label>
            <input
              type="date"
              value={details.startDate}
              onChange={(e) =>
                setDetails({ ...details, startDate: e.target.value })
              }
              name="startDate"
            />
          </div>
          <div className="uploadLabelAndInput">
            <label>End Date</label>
            <input
              type="date"
              value={details.endDate}
              onChange={(e) =>
                setDetails({ ...details, endDate: e.target.value })
              }
              name="endDate"
            />
          </div>
          <div className="uploadLabelAndInput">
            <label>Selecte Photos</label>
            <input
              type="file"
              multiple
              id="eventsImages"
              onChange={(e) => handleUploadImages(e)}
            />
          </div>
        </div>
        <div className="tableUploadContainer">
          <div className="btnContainer">
            <button className="addBtn" onClick={() => handleAdd()}>
              Add
            </button>
          </div>
          <div>
            <table className="studentDataTable">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name of the Participent</th>
                  <th>Email</th>
                  <th>Mobile No</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {studentData &&
                  studentData.map((data, rowCount) => {
                    return (
                      <tr key={rowCount}>
                        <td>{rowCount + 1}</td>
                        <td>
                          <input
                            type="text"
                            name="participantName"
                            value={data.participantName}
                            onChange={(e) =>
                              addingDataRows(
                                rowCount,
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="participantEmail"
                            value={data.participantEmail}
                            onChange={(e) =>
                              addingDataRows(
                                rowCount,
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            name="mobileNo"
                            value={data.mobileNo}
                            onChange={(e) =>
                              addingDataRows(
                                rowCount,
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </td>
                        <td>
                          <DeleteIcon
                            className="deleteIcon"
                            onClick={() => handleDelete(rowCount)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="uploadDivButton">
        <button onClick={() => handleSubmit()}>Submit</button>
      </div>
    </div>  
      )}
    </>
    
  );
};
export default FcultyEvents;

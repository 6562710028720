import react, { useEffect, useState } from "react";
import "./ViewLabPhotos.css";
import axios from "axios";
import { resources } from "../../Resourses/Resources";
const ViewLabImages = ({ labType }) => {
  const [allImages, setAllImages] = useState([]);
  const [labTtypesButton, setLabTypesButton] = useState([]);
  const [imagesToView, setImagesToView] = useState([]);
  const [viewLab, setViewLab] = useState("All");
  let currentYear;
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  if (month < 5) {
    currentYear = year - 1 + "-" + "" + year;
  } else {
    currentYear = year + "-" + "" + year + 1;
  }
  useEffect(() => {
    if ((currentYear !== null) & (currentYear !== "")) {
      const fetchAllImages = async () => {
        //    const details=await axios.get(resources.APPLICATION_URL+`/get/lab/imagesByYear?academicYear=${currentYear}`)
        try {
          const details = await axios.get(
            resources.APPLICATION_URL +
            `get/lab/imagesByYear?academicYear=${currentYear}&department=${labType}`
          );
          setAllImages(details.data);
          setLabTypesButton(details.data);
          setImagesToView(details.data);
          console.log("lab images are===>", details.data);
        } catch (error) {
          console.log("error===>", error)
        }
      };
      fetchAllImages();
    }
  }, [currentYear]);
  const handleViewLabType = async (lab) => {
    console.log("lab type===>", lab);
    const newPhotos = allImages.filter((items) => items.labName === lab);
    setImagesToView(newPhotos);
    setViewLab(lab);
  };
  const handleViewAll = async () => {
    setImagesToView(allImages);
    setViewLab("All");
  };
  console.log("viewLab type===>", viewLab);

  return (
    <div className="container">
      <div className="button-container">
        <button
          onClick={() => handleViewAll()}
          className="btnLabtype"
          style={{
            backgroundColor: viewLab === "All" ? "orange" : "",
          }}
        >
          All
        </button>

        {labTtypesButton &&
          labTtypesButton.length > 0 &&
          labTtypesButton.map((items, index) => (
            <button
              className="btnLabtype"
              key={index}
              onClick={() => handleViewLabType(items.labName)}
              style={{
                backgroundColor: viewLab === items.labName ? "orange" : "",
              }}
            >
              {items.labName}
            </button>
          ))}
      </div>
      <div className="image-container">
        {imagesToView &&
          imagesToView.length > 0 &&
          imagesToView.map((item, index) => {
            const images = item.filePath.split(",");
            console.log("images.length===>", images.length);
            return (
              <div key={index} className="lab-image-container">
                {images.map((each, idx) => (
                  <div key={idx} className="image-wrapper">
                    <img
                      className="lab-image"
                      src={`data:image/png;base64,${each}`}
                      alt={`Lab Image ${idx}`}
                    />
                    <p className="lab-name">{item.labName}</p>
                  </div>
                ))}
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default ViewLabImages;

import * as React from 'react';
import "./GeneralNotifications.css"
import { useEffect, useState } from "react"
import axios from "axios"
import Cookies from "js-cookie"
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { resources } from "../Resourses/Resources"
import PaginationReUsable from "../ReuseableComponent/ReusePagination"
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ViewGeneralNotifications = () => {
    const [open, setOpen] = React.useState(false);
    const [details, setDetails] = useState([])
    const [search, setSearch] = useState("");
    const [paginationData, setPaginationData] = useState([])
    const [allData, setAllData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [serialNo, setSerialNo] = useState("")
    const [viewFile, setViewFile] = useState("")
    const [lastRecord, setLastRecord] = useState({})
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const date = new Date;
    let currentMonth;
    let currentDate;
    const toDaysDate = date.getDate();
    const month = date.getMonth() + 1
    const currentYear = date.getFullYear()
  
    if (month < 10) {
      currentMonth = "0" + month
    } else {
      currentMonth = month
    }
    if (toDaysDate < 10) {
      currentDate = "0" + toDaysDate
    } else {
      currentDate = toDaysDate
    }
    const newDate = currentYear + "-" + currentMonth + "-" + currentDate
    const [typesUpload, setTypesUpload] = useState("")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const userDetailsCookie = Cookies.get("userDetails");
    useEffect(() => {
        if (userDetailsCookie != null && userDetailsCookie != "") {
            const userDetails = JSON.parse(userDetailsCookie);
            const details = userDetails.roles[0]
            const user = userDetails.username

            console.log("details===>", user)
            if (details === "ROLE_ADMIN") {
                setTypesUpload("General")
            }
            else if (details === "ROLE_EXAMBRANCH") {
                setTypesUpload("ExamBranch")
            }
            else if (details === "ROLE_OFFICE") {
                setTypesUpload("Office")
            }
        }
    }, [userDetailsCookie])


   console.log("typesUpload==>",typesUpload)
    useEffect(() => {
        const fetchDetails = async () => {
            try {
                if (typesUpload != null && typesUpload !== "") {
                    const response = await axios.get(resources.APPLICATION_URL + `getAllGeneralNotifications?typeOfUpload=${typesUpload}`)
                    setDetails(response.data);
                    setAllData(response.data);
                    setFilteredData(response.data);
                    setPaginationData(response.data);
                }
            } catch (error) {
                console.log('error===>', error)
            }
        }
        fetchDetails()
    }, [typesUpload])
    const handleSearch = (e) => {
        let { value } = e.target
        console.log("value", search)
        let list = allData.filter(each => each.date.toLowerCase().includes(value.toLowerCase()))
        console.log("list==>", list)
        setFilteredData(list)
        setSearch(value)
    }
    const handleGetSpliceData = (receivedList, startSNo) => {
        setPaginationData(receivedList)
        setSerialNo(startSNo);
    }
    const handleViewFile = async (fileName) => {
        let filePath;
        if (fileName != null && fileName != "") {
            filePath = fileName.replace(/\\/g, "/")
            console.log("filepath is===>", filePath)
            try {
                const response = await axios.get(resources.APPLICATION_URL + `getFilePathtoConvertBase64?filePath=${filePath}`)

                if (response.data != null && response.data != "") {
                    handleClickOpen()
                    setViewFile(response.data)
                }

            } catch (error) {

            }
        }
    }
    const handleSearchByDate = async () => {
        console.log("from date===>", fromDate)
        console.log("toDate date===>", toDate)
        const details = allData.filter((items) => {
            const newDate = items.startDate;
            if(newDate >= fromDate && newDate <= toDate){
                return items;
            }
             
        })
        setFilteredData(details)
        console.log("details===>", details)

    }
    return (
        <div style={{marginTop:"1cm"}}>
            <div>
              
                         
                <div className="searchDiv">
                <div className="fromDateToDateDiv">
                    <div className="fromDateToDateInput">
                        <label>From Date</label>
                        <input type="date" placeholder="Issued by..." value={fromDate} onChange={(e) => setFromDate(e.target.value)} />

                    </div>
                    <div className="fromDateToDateInput">
                        <label>To Date</label>
                        <input type="date" placeholder="Issued by..." value={toDate} onChange={(e) => setToDate(e.target.value)} />
                        <button onClick={() => handleSearchByDate()}> View</button>
                    </div>
                </div>
                {/* <input type="text" placeholder="conducted by..." value={search} onChange={(e) => handleSearch(e)} /> */}
            </div >
                <table className="tableStylings" style={{marginTop:"20px"}}>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>File Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginationData && paginationData.length > 0 && paginationData.map((items, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{items.startDate}</td>
                                    <td>{items.description}</td>
                                    <td>{items.fileName}</td>
                                    <td><RemoveRedEyeOutlinedIcon onClick={() => handleViewFile(items.filePath)}></RemoveRedEyeOutlinedIcon></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            width: '100%',
                            height: '100%',
                            margin: 0,
                            padding: 0,
                            maxHeight: '100vh',
                        },
                    }}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>

                        </Toolbar>
                    </AppBar>
                    {viewFile && (
                        <iframe
                            src={`data:application/pdf;base64,${viewFile}`}
                            width="100%"
                            height="100%"
                        />
                    )}
                </Dialog>
            </React.Fragment>
            <PaginationReUsable paginationData={filteredData} handleGetSpliceData={handleGetSpliceData} />

        </div>
    )
}
export default ViewGeneralNotifications;
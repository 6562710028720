import axios from "axios"
import react, { useState } from "react"
import { resources } from "../Resourses/Resources"
import { EditRounded } from "@mui/icons-material"
import "./facultyScreens.css";
const FacultyUploadHome = () => {
const [startDate,setStartDate]=useState("")
const [endDate,setEndDate]=useState("")
const [fromDate,setFromDate]=useState("")
const [issuedBy,setIssuedBy]=useState("")
const [circular,setCircular]=useState("")
const [notificaioDesc,setNotificationDescp]=useState("")
const handleSubmit=async()=>{
    const detailsToSend=axios.post(resources.APPLICATION_URL+"")

}
    return (
        <div className="uploadContainer">
            <div >
            <p className="headerTagStyle">Home</p>
            <div className="uploadDivs">
                <div className="uploadLabelAndInput">
                    <label>Issued By</label>
                    <input type="text" value={issuedBy} onChange={(e)=>setIssuedBy(e.target.value)}/>
                </div>
                <div className="uploadLabelAndInput">
                    <label>Notification Description</label>
                    <textarea type="text" value={notificaioDesc} onChange={(e)=>setNotificationDescp(e.target.value)}/>
                </div>
                <div className="uploadLabelAndInput">
                    <label>Circular</label>
                    <input type="text"  value={circular} onChange={(e)=>setCircular(e.target.value)} />
                </div>
                <div className="uploadLabelAndInput">
                    <label>Start Date</label>
                    <input type="text" value={startDate} onChange={(e)=>setStartDate(e.target.value)}/>
                </div>
                <div className="uploadLabelAndInput">
                    <label>End Date</label>
                    <input type="text" value={endDate} onChange={(e)=>setEndDate(e.target.value)} />
                </div>
            </div>
            <div className="uploadDivButton">
                <button onClick={()=>handleSubmit()}>Submit</button>
            </div>
            </div>
        </div>
    )
}
export default FacultyUploadHome;
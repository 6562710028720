import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import csefaculty from "../../../assets/HODs/CSEHOD.JPG";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Cookies from "js-cookie"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import Labs from "./Labs";
import Faculty from "./Faculty";
import axios from "axios";
import { resources } from "../../Resourses/Resources";
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourseStructure = () => {
  const userDetailsCookie = Cookies.get("userDetails");
  let userDetails;
  if(userDetailsCookie){
    userDetails = JSON.parse(userDetailsCookie);
  }
  const userName = userDetails?.username
  const departMent = userDetails?.department
  const userEmail = userDetails?.email
  const date = new Date()
  let academicYear1;
  const currentYear = date.getFullYear()
  const month = date.getMonth()
  if (month < 5) {
    academicYear1 = currentYear - 1 + "-" + currentYear
  } else {
    academicYear1 = currentYear + "-" + currentYear + 1

  }
  const [regulationDetails, setRegulationDetails] = useState([])
  const [fileView, setFileView] = useState(false)
  const [filepath, setFilepath] = useState("")
  const handleClickOpen = () => {
    setFileView(true);
  };

  const handleClose = () => {
    setFileView(false);
  };
  useEffect(() => {
    const fetchRegulationDetails = async () => {
      try {
        const response = await axios.get(resources.APPLICATION_URL + `getRegulationDetails?academicYear=${academicYear1}&branch=MBA`)
        console.log("response===>", response.data)
        setRegulationDetails(response.data)
      } catch (error) {
        console.log("error===>", error)
      }
    }
    fetchRegulationDetails()
  }, [])
  return (
    <div className="containe">
      <PageHeader name="MBA - COURSE STRUCTURE" />
      <div className="course_structure_cnt animate__animated animate__zoomIn">
        <div className="course text-center">
          <h4>Course Structure for all Years</h4>
          <p>
            Download the Course Structure and Syllabus of four years of MBA
            course
          </p>
        </div>

        <div className="w-100 text-center d-flex justify-content-center gap-5 ">
        <table className="studentDataTable" >
          <thead>
            <tr>
              <th>S.No</th>
              <th>Regulation</th>
              <th>Level</th>
              <th>Degree</th>
              <th>Batch</th>
              <th>Branch</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {regulationDetails && regulationDetails.length > 0 && regulationDetails.map((items, index) => {
              console.log("items===>", items)
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{items.regulation}</td>
                  <td>{items.level}</td>
                  <td>{items.degree}</td>
                  <td>{items.batch}</td>
                  <td>{items.branch}</td>
                  <td><RemoveRedEyeOutlinedIcon onClick={() => { setFileView(true); setFilepath(items.filePath) }}></RemoveRedEyeOutlinedIcon></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        </div>
        <React.Fragment>
        <Dialog
          fullScreen
          open={fileView}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
              maxHeight: '100vh',
            },
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

            </Toolbar>
          </AppBar>
          {filepath && (
            <iframe
              src={`data:application/pdf;base64,${filepath}`}
              width="100%"
              height="100%"
            />
          )}
        </Dialog>
      </React.Fragment>
      </div>
    </div>
  );
};
const Events = () => {
  return (
    <div className="container">
      <PageHeader name="ACTIVITIES & EVENTS" />

      <div className="course text-center animate__animated animate__fadeInRight">
        <h4>Student Development Activities</h4>
        <br />
        <p style={{textAlign: "justify"}}>
          The Orientation Day is conducted by the college Freshman orientation
          is a way for students to meet other students, become familiar with
          campus services. Every student attending college should add
          orientation to their to-do listin college.
        </p>
      </div>
    </div>
  );
};

const Obe = () => {
  return (
    <div className="container">
      <PageHeader name="PEOs/POs/PSOs/COs" />
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM EDUCATIONAL OBJECTIVES(PEO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PEO 1:</h2>
              <p>
                To achieve higher levels of managerial proficiency in functional
                areas of business required for a successful career in industry,
                business, and entrepreneurship.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 2:</h2>
              <p>
                To equip the students with necessary tools and techniques for
                handling complex business problems and uncertainties.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 3:</h2>
              <p>
                To impart relevant, current and cutting-edge knowledge from the
                advanced/emerging areas of business.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 4:</h2>
              <p>
                To ignite the passion for entrepreneurship in Management
                Graduates by promoting creativity and innovation.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 5:</h2>
              <p>
                To bring out Industry-ready Management Graduates having
                necessary individual and professional competencies to excel at
                national and international level.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 6:</h2>
              <p>
                To create ethical leaders committed to the welfare of community
                and society.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 7:</h2>
              <p>
                To impart relevant, current and cutting-edge knowledge from the
                advanced/emerging areas of business.
              </p>
            </div>
            <div class="peo">
              <h2>PEO 8:</h2>
              <p>
                To promote diversity and inclusiveness in active engagement with
                community and society..
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM OUTCOMES(PO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PO 1:</h2>
              <p>
                Analyze the Political, Economical, Social, Technological, Legal
                and Environmental context of business.
              </p>
            </div>
            <div class="peo">
              <h2>PO 2:</h2>
              <p>
                Appreciate individual ethical behavior and discharge community
                responsibilities in organization and society..
              </p>
            </div>
            <div class="peo">
              <h2>PO 3:</h2>
              <p>
                Create and deliver value to the customers by identifying their
                needs and wants.
              </p>
            </div>
            <div class="peo">
              <h2>PO 4:</h2>
              <p>
                Apply knowledge in new and unfamiliar circumstances through a
                conceptual understanding of relevant disciplines.
              </p>
            </div>
            <div class="peo">
              <h2>PO 5:</h2>
              <p>
                Adapt and find innovative methods for problem solving, to cope
                with unforeseen events, and to manage in unpredictable
                environments.
              </p>
            </div>
            <div class="peo">
              <h2>PO 6:</h2>
              <p>
                Manage contemporary societal and global issues resulting from
                diversity.
              </p>
            </div>
            <div class="peo">
              <h2>PO 7:</h2>
              <p>Demonstrate Leadership and Team work capabilities.</p>
            </div>
            <div class="peo">
              <h2>PO 8:</h2>
              <p>Improve the verbal and non-verbal communication skills.</p>
            </div>
            <div class="peo">
              <h2>PO 9:</h2>
              <p>Analyze financial statements to make investment decisions.</p>
            </div>
            <div class="peo">
              <h2>PO 10:</h2>
              <p>
                Demonstrate capabilities in new venture creation &
                entrepreneurship..
              </p>
            </div>
            <div class="peo">
              <h2>PO 11:</h2>
              <p>
                Achieve higher levels of proficiency and self-actualization
                through pursuing lifelong learning.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM SPECIFIC OUTCOMES (PSO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PSO 1 Practices of mathematical ideas:</h2>
              <p>
                By using mathematical techniques to solve problems using
                appropriate mathematical study, data structure and algorithms.
              </p>
            </div>
            <div class="peo">
              <h2>PSO 2 Practices Of Computing:</h2>
              <p>
                Apply modern tools, programming environments and strategies to
                develop cost effective computing solutions for complex
                engineering and real-time problems.
              </p>
            </div>
            <div class="peo">
              <h2>PSO 3 Practices of Software Development :</h2>
              <p>
                Make use of modern computer languages and environments to become
                successful software professional, an entrepreneur or zest for
                higher studies.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          COURSE STRUCTURE (CO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <ul class="course_outcomes_cnt">
              <li>
                <a
                  href="https://drive.google.com/file/d/1ToJ4qNnKjBFPUDfxwIzi2wy7dk84hiWh/view"
                  target="_blank"
                >
                  R19
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1PhZsGRg0F_t83BesEfmbsLqM4_oRHk8D/view"
                  target="_blank"
                >
                  R22
                </a>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

// const AboutDepartment = () => {
//   return (
//     <div>
//       <div className="container">
//         <PageHeader name="COMPUTER SCIENCE & ENGINEERING" />
//         <div className="container-xxl">
//           <div className="container-xxl py-5">
//             <div className="container">
//               <div className="row g-5">
//                 <div
//                   className="col-lg-6 wow fadeInUp"
//                   data-wow-delay="0.1s"
//                   style={{ minHeight: "100px" }}
//                 >
//                   <div className="position-relative h-100 animate__animated animate__zoomIn">
//                     <img
//                       className="img-fluid rounded w-100 h-100"
//                       src={csefaculty}
//                       alt="csefaculty-img"
//                       style={{ objectFit: "cover" }}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
//                   <h6 className="section-title bg-white text-start text-primary pe-3">
//                     Faculty
//                   </h6>
//                   <h1 className="mb-4">About</h1>

//                   <div className="row gy-2 gx-4 mb-4">
//                     <p className="mb-0">
//                       Studying for a Computer Science and Engineering degree is
//                       exciting enough as it will open the doors to very cool
//                       jobs. It is truly amazing to catch the trend with SSIT.
//                       Because SSIT takes care to maximize the learning
//                       opportunities by keeping up-to-date and continuous with
//                       highly qualified and richly experienced faculty to meet
//                       the present requirement at global scenario.
//                       <p>
//                         Department Offers an intake of 120 with total student
//                         strength is about 480.
//                       </p>
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="card_cntr1">
//             <div
//               className="boxes col-lg-3 col-sm-6 wow fadeInUp"
//               data-wow-delay="0.1s"
//             >
//               <div className="boxes1 service-item text-center pt-3">
//                 <div className="p-4">
//                   <h5 className="mb-1">Vision</h5>
//                   <p>
//                     To make the students achieve highest standards of quality
//                     technical education adaptable to suit the present day
//                     requirements in the fields of science and technology and to
//                     mould them morally into persons of character and integrity.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="boxes col-lg-3 col-sm-6 wow fadeInUp"
//               data-wow-delay="0.3s"
//             >
//               <div className="boxes1 service-item text-center pt-3">
//                 <div className="p-4">
//                   <h5 className="mb-3">Mission</h5>
//                   <p>
//                     To awaken the students to the reality of identifying their
//                     hidden potentials and talents through meticulous and
//                     systematic grooming to gain the spirit and inventiveness to
//                     build a career full of glorious prospects and eventful
//                     future.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="boxes col-lg-3 text-center col-sm-6 wow "
//               data-wow-delay="0.5s"
//             >
//               <div
//                 className="boxes text-center pt-3 pb-5"
//                 style={{ backgroundColor: "#f6f6f6", height: "fit-content" }}
//               >
//                 <div className="p-4 ">
//                   <h5 className="mb-3">STRENGTHS</h5>
//                   <div>
//                     <Accordion
//                       sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
//                     >
//                       <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel1-content"
//                         id="panel1-header"
//                         sx={{ backgroundColor: "#fff" }}
//                       >
//                         Infrastructure & Faculty
//                       </AccordionSummary>
//                       <AccordionDetails
//                         align="center"
//                         sx={{
//                           backgroundColor: "#fff5dc",
//                           color: "#000",
//                           fontSize: "12px",
//                           fontFamily: "Arial",
//                           marginBottom: "10px",
//                           letterSpacing: "1px",
//                         }}
//                       >
//                         Ultra Modern Labs with more than 430 Systems having
//                         multiple platforms like Linux, Windows and Ubuntu. 24/7
//                         InternetFacility with 300 MBPS throughtwo different
//                         ISPs. Well Experienced and University Ratified Faculty.
//                       </AccordionDetails>
//                     </Accordion>
//                     <Accordion
//                       sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
//                     >
//                       <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2-content"
//                         id="panel2-header"
//                       >
//                         Training & Placements
//                       </AccordionSummary>
//                       <AccordionDetails
//                         sx={{
//                           backgroundColor: "#fff5dc",
//                           color: "#000",
//                           fontSize: "12px",
//                           fontFamily: "Arial",
//                           marginBottom: "10px",
//                           letterSpacing: "1px",
//                         }}
//                       >
//                         Conducting Industry relevant Training Programmes and
//                         providing Placements in various MNCs through
//                         On-Campus/Off-Campus.
//                       </AccordionDetails>
//                     </Accordion>
//                     <Accordion>
//                       <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel3-content"
//                         id="panel3-header"
//                       >
//                         Research & Development
//                       </AccordionSummary>
//                       <AccordionDetails
//                         sx={{
//                           backgroundColor: "#fff5dc",
//                           color: "#000",
//                           fontSize: "12px",
//                           fontFamily: "Arial",
//                           marginBottom: "10px",
//                           letterSpacing: "1px",
//                         }}
//                       >
//                         More than 50% Faculty Pursuing Ph.Ds from Reputed
//                         Univeristies and publishing Their Research Papers in
//                         Various Good Impact Journals.
//                       </AccordionDetails>
//                     </Accordion>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

const MBA = () => {
  const [value, setValue] = React.useState(0);
  const [departmentData , setDepartmentData] = useState([])
  const handleGetMbaDepartment = async () => {
    try {
      const res = await axios.get(
        resources.APPLICATION_URL + "getstudentdata"+"MBA"
      );
      if (res.data) {
       setDepartmentData(res.data)
       
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(()=>{
    handleGetMbaDepartment();
  },[]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "35px" }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Faculty" {...a11yProps(0)} />
            <Tab label="Laboratories" {...a11yProps(1)} />
            <Tab label="Course Structure" {...a11yProps(2)} />
            <Tab label="Activities & Events" {...a11yProps(3)} />
            <Tab label="PEOs/POs/PSOs/COs" {...a11yProps(4)} />
            {/* <Tab label="About Department" {...a11yProps(5)} /> */}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Faculty />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Labs />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CourseStructure />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Events />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Obe />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          {/* <AboutDepartment /> */}
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default MBA;

import react, { useState, useEffect } from "react"
import Examinationbranch from "../ExaminationBranch/Examinationbranch";
import "./BranchMenu.css"
import axios from "axios";
import { resources } from "../Resourses/Resources";
const StaffMenu = () => {
    const [availableUsers, setAvailableUsers] = useState([])
    useEffect(() => {
        const fecthUsers = async () => {
            try {
                const usersData = await axios.get(resources.APPLICATION_URL + "getAllAvailableUsers")
                setAvailableUsers(usersData.data)
            } catch (error) {
                console.log("error===>", error)
            }
        }
        fecthUsers();
    }, [])
    return (
        <div className="facultyUploadContainer">
            <table className="tableStylings">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Department</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {availableUsers && availableUsers.length > 0 && availableUsers.map((items, index) => {
                        console.log("{items.roles[1]?.name}===>", items.roles[1])
                        const { username, email, departmentname, roles } = items
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{username}</td>
                                <td>{email}</td>
                                <td>{departmentname}</td>
                                <td>{roles.map((each, e) => {
                                    return (
                                        <>
                                            <span>{each.name}</span>
                                        </>
                                    )
                                })}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default StaffMenu;
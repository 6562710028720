import React from "react";
import temple from "../../assets/IMG_2002.JPG";

const Temple = () => {
  return (
    <div>
      <div class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="#">
            TEMPLE IN COLLEGE
          </a>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "100px" }}
            >
              <div className="position-relative">
                <img
                  className="img-fluid rounded position-absolute w-100 "
                  src={temple}
                  alt="temple-img"
                  style={{ objectFit: "cover",padding:'10px',
                height:'300px'}}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Sai Baba Temple
              </h6>
              <h1 className="mb-4">About</h1>

              <div className="row gy-2 gx-4 mb-4">
                <p style={{textAlign:"justify"}}>
                <p className="mb-0">
                  An extra Asset of College is having beautiful Sai temple in
                  The College Premises. In Order to develop Spirituality,
                  divinity and mental peace in the minds of students and Staff
                  and thus by healthy and peaceful environment, Our Chairman Dr.
                  B. Partha Sarathi Reddy who is a great devotee of SAI BABA
                  built "Ganapathi Dattatreya Sahitha Sai Mandir" on 30th May,
                  2005. 
                </p><br/>
                <p>
                Every Thursday Special Offerings, Puja for Sai Baba will
                  be done in the temple.
                  </p>
                  </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Temple;

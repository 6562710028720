import React from "react";
import { RotatingLines } from "react-loader-spinner";
import "./reuseStyles.css";
const RotatingSpinner = () => {
  return (
    <div className="loderBackground">
    <div className="loader-container">
      <RotatingLines
        visible={true}
        height="100"
        width="100"
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <p
        style={{
          color: "#fff",
          fontSize: "20px",
          fontWeight: 300,
          marginTop: "20px",
          fontFamily: "Arial",
          letterSpacing: "2px",
        }}
      >
        Please Wait
      </p>
    </div>
    </div>
  );
};

export default RotatingSpinner;

import React, { useState, useEffect } from "react";
import "./ReusePagination.css";
import { resources } from "../Resourses/Resources";
import axios from "axios";

const PaginationReUsable = ({ paginationData, handleGetSpliceData }) => {
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const itemsPerPageOptions = [5, 10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(paginationData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = paginationData.slice(indexOfFirstItem, indexOfLastItem);
  // if(currentItems.length == 0){
  //   currentItems = paginationData
  // }

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };
  const pageRange = 3;
  const startPage = Math.max(1, currentPage - pageRange);
  const endPage = Math.min(totalPages, currentPage + pageRange);
  const pagesToShow = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );
  let selectedStart = (currentPage - 1) * itemsPerPage + 1;

  console.log("current Items===>", currentItems);
  const handlePageChange = (page) => {
    let k = page;

    setCurrentPage(k);
    console.log("current items in props====>", currentItems);
  };
  useEffect(() => {
    if (currentItems.length == 0) {
      currentItems = paginationData;
      setCurrentPage(1);
      handleGetSpliceData(currentItems, 0);
    } else {
      handleGetSpliceData(currentItems, selectedStart);
    }
  }, [currentPage, itemsPerPage, paginationData]);
  useEffect(() => {
    handlePageChange(1);
  }, []);
  return (
    <div>
      <div className="paginationInAllColleges">
        <label>Total</label>
        <input
          className="selectPagination"
          type="text"
          value={paginationData.length ? paginationData.length : "0"}
          readOnly
        />
        <label>Show per page&nbsp; </label>
        <select
          className="selectPagination"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          style={{ width: "60px" }}
        >
          {itemsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        {/* {currentPage > 1 && ( */}
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage <= 1}
        >
          {"<"}
        </button>
        {/* )} */}
        {pagesToShow.map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={page === currentPage ? "active" : ""}
          >
            {page}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage >= totalPages}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
export default PaginationReUsable;

import { useEffect, useState } from "react"
import * as React from 'react';
import axios from "axios";
import { resources } from "../Resourses/Resources";
import Cookies from "js-cookie";
import PaginationReUsable from "../ReuseableComponent/ReusePagination";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const FacultyDashBoardView = () => {
    const [availableUsers, setAllAvailableUsers] = useState([])
    const [allData, setAllData] = useState([])
    const [paginationData, setPaginationData] = useState([])
    const [edit, setEdit] = useState(false)
    const [modifyedRecord, setModifyedRecord] = useState({})
    const branches = ["CSE", "ECE", "EEE", "MECH", "MBA", "MCA", "Management"]
    const userRoles = ["admin", "student", "faculty", "hod", "principal", "alumni", "parent", "exitstudent", "committeeMember"]
    const [filteredData, setFilteredData] = useState([]);
    const userDetailsCookie = Cookies.get("userDetails");
    console.log("userDetailsCookie", userDetailsCookie)
    const [departMent, setDepartment] = useState("")
    const [search, setSearch] = useState("")
    const [serialNo, setSerialNo] = useState("")
    const handleClickOpen = () => {
        setEdit(true);
    };

    const handleClose = () => {
        setEdit(false);
    };
    useEffect(() => {
        if (userDetailsCookie != null && userDetailsCookie != "") {
            const userDetails = JSON.parse(userDetailsCookie);
            const details = userDetails.department
            setDepartment(details)
        }
    }, [userDetailsCookie])
    const fetchFacultyDetail = async () => {
        try {
            const response = await axios.get(resources.APPLICATION_URL + `get/All/Active/FacultyDetails`)
            setAllAvailableUsers(response.data)
            setFilteredData(response.data)
            setAllData(response.data)

        } catch (e) {
            console.log("error from Backend faculty Details===>", e);
        }
    }
    useEffect(() => {

        fetchFacultyDetail()
    }, []);

    const handleSearch = (e) => {
    let { value } = e.target;
    console.log("value", value);

    let list = allData.filter(
      (each) =>
        (each.faculty_name &&
          each.faculty_name.toLowerCase().includes(value.toLowerCase())) ||
        (each.email && each.email.toLowerCase().includes(value.toLowerCase()))
    );
    console.log("list==>", list);
    setFilteredData(list);
    setSearch(value);
  };
    const handleGetSpliceData = (receivedList, startSNo) => {
        setPaginationData(receivedList);
        setSerialNo(startSNo);
    };

    const handleModify = async (e) => {
        try {
            const response = await axios.post(resources.APPLICATION_URL + "update/facultyDetails", modifyedRecord)
            console.log("response===>", response)
            if(response.data==="Successfully Modifyed"){
                alert("Successfully modifyed record")
                fetchFacultyDetail()
                handleClose()
            }
        } catch (error) {
            alert("Something went wrong")
            console.log("error===>", error)
        }
    }
    const handleDeleteTheRecord=async(items)=>{
        try {
            const response = await axios.post(resources.APPLICATION_URL + "delete/facultyDetails", items)
            console.log("response===>", response)
            if(response.data==="Successfully deleted"){
                alert("Successfully deleted record")
                fetchFacultyDetail()
             
            }
        } catch (error) {
            alert("Something went wrong")
            console.log("error===>", error)
        }
    }
    console.log("modifyedRecord===>", modifyedRecord)
    return (
        <div className="facultyViewContainer">

            <div >
                <div className="searchDiv">
                    <input type="text" placeholder="Name or Email..." value={search} onChange={(e) => handleSearch(e)} />
                </div>
                <table className="tableStylings">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Designation</th>
                            <th>Experience</th>
                            <th>Date of Join</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginationData && paginationData.length > 0 && paginationData.map((items, index) => {
                            return (
                                <tr key={index}>
                                    <td>{serialNo + index}</td>
                                    <td>{items.faculty_name}</td>
                                    <td>{items.email}</td>
                                    <td>{items.designation}</td>
                                    <td>{items.totalExperience}</td>
                                    <td>{items.doj}</td>
                                    <td  ><EditOutlinedIcon onClick={() => { handleClickOpen(); setModifyedRecord(items) }}></EditOutlinedIcon ><DeleteOutlinedIcon onClick={()=>handleDeleteTheRecord(items)}> </DeleteOutlinedIcon></td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <React.Fragment>
                    <Dialog
                        fullScreen
                        open={edit}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                        PaperProps={{
                            style: {
                                width: '100%',
                                height: '100%',
                                margin: 0,
                                padding: 0,
                                maxHeight: '100vh',
                            },
                        }}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>

                            </Toolbar>
                        </AppBar>


                        <div className="containerOne1">
                            <div className="inputAndLabelDiv">
                                <label htmlFor="userName">Faculty Name</label>
                                <input type="text" name="userName" value={modifyedRecord.faculty_name} onChange={(e) => setModifyedRecord({ ...modifyedRecord, faculty_name: e.target.value })} />
                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="emailId">Faculty Email</label>
                                <input type="email" name="emailId" value={modifyedRecord.email} onChange={(e) => setModifyedRecord({ ...modifyedRecord, email: e.target.value })} />
                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="departMent">Designation</label>
                                <input type="email" name="emailId" value={modifyedRecord.designation} onChange={(e) => setModifyedRecord({ ...modifyedRecord, designation: e.target.value })} />

                            </div>
                            <div className="inputAndLabelDiv">
                                <label htmlFor="departMent">Status</label>
                                <select value={modifyedRecord.status} onChange={(e) => setModifyedRecord({ ...modifyedRecord, status: e.target.value })}  >
                                    <option>Please Select</option>
                                    <option value="Active">Active</option>
                                    <option value="InActive">Inactive</option>
                                </select>

                            </div>

                            <div className="registerDiv">
                                <button className="" onClick={handleModify}>Submit</button>
                            </div>
                        </div>




                    </Dialog>
                </React.Fragment>
            </div>
            <PaginationReUsable
                paginationData={filteredData}
                handleGetSpliceData={handleGetSpliceData}
            />
        </div>
    )
}
export default FacultyDashBoardView;

import react, { useState, useEffect } from "react"
import axios from "axios";
import "./CourseUpload.css"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { resources } from "../../Resourses/Resources";
import PaginationReUsable from "../../ReuseableComponent/ReusePagination";
const CourseUpload = () => {
    let currentYear;
    const date = new Date();
    const year = date.getFullYear()
    console.log("year", year + 1)
    const previusYear = year - 3
    let academicYear = []
    
    const month = date.getMonth() + 1
    for (let i = previusYear; i < (year + 2); i++) {
        if (month < 5) {
            currentYear = (i - 1) + "-" + i;
            academicYear=[...academicYear,currentYear]
        }
        else {
            currentYear = i + "-" + (i + 1)
            academicYear=[...academicYear,currentYear]
        }
    }
    console.log("ac"+academicYear)


    // console.log("year",month)
    if (month < 5) {
        currentYear = (year - 1) + "-" + year
    }
    else {
        currentYear = year + "-" + (year + 1)
    }


    const [courselist, setCourseList] = useState([])
    const [viewCourseDetails, setViewCourseDetails] = useState([])
    const level = ["Diploma", "Graduation", "Post Graduation"]
    const degreelist = ["B.TECH", "M.TECH", "MBA", "MCA", "OTHERS"]
    const [search, setSearch] = useState("")
    const [serialNo, setSerialNo] = useState("")
    const [allData, setAllData] = useState([])
    const [paginationData, setPaginationData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [edit, setEdit] = useState(false)
    const [courseDetails, setCourseDetails] = useState({
        academicYear: currentYear,
        level: "",
        degree: "",
        branch: "",
        intakeCount: ""
    })
    useEffect(() => {
        const fetchCourseDetails = async () => {
            if (currentYear != null && currentYear != "") {
                try {
                    const response = await axios.get(resources.APPLICATION_URL + `getAllCourseDetailsByAcademicYear?academicYear=${currentYear}`)
                    const details = response.data
                    setViewCourseDetails(details)
                    setFilteredData(details)
                    setAllData(details)

                } catch (error) {
                    console.log("error===>", error)
                }
            }
        }
        fetchCourseDetails()
    }, [currentYear])
    const handleAdd = async () => {
        setCourseList([...courselist, courseDetails])
        setCourseDetails({
            ...courseDetails,
            branch: "",
            intakeCount: ""
        })
    }
    const handleSubmit = async () => {
        try {
            const response = await axios.post(resources.APPLICATION_URL + "saveCourseIntakeCount", courselist)
            if (response.data === "Successfully added intake") {
                setCourseList([])
                setCourseDetails({
                    academicYear: currentYear,
                    degree: "",
                    branch: "",
                    level: "",
                    intakeCount: ""
                })
                alert("Successfully added Intake count")
            }
        } catch (error) {
            alert("Something went wrong")
            console.log("error", error)
        }
    }
    const HandleDeleteRecord = (items) => {
        const newRecords = courselist.filter((each) => each !== items)
        setCourseList(newRecords)
    }
    console.log("modify record===>", courseDetails)
    const handleModifyRecord = async () => {
        const modifiedList = [...courselist];
        const index = modifiedList.findIndex(item => item.degree === courseDetails.degree && item.branch === courseDetails.branch);
        if (index !== -1) {
            modifiedList[index] = { ...courseDetails };
            setCourseList(modifiedList);
            setCourseDetails({
                academicYear: currentYear,
                degree: "",
                branch: "",
                level: "",
                intakeCount: ""
            });
            setEdit(false);
        }
    }
    const handleSearch = (e) => {
        let { value } = e.target;
        console.log("value", value);

        let list = allData.filter(
            (each) =>
                (each.branch && each.branch.toLowerCase().includes(value.toLowerCase()))

        );
        console.log("list==>", list);
        setFilteredData(list);
        setSearch(value);
    };
    const handleGetSpliceData = (receivedList, startSNo) => {
        setPaginationData(receivedList);
        setSerialNo(startSNo);
    };
    return (
        <div className="courseUploadMainContainer">
            <p className="headerTagStyle">Add Course Count</p>
            <div className="searchDiv">
                <input type="text" placeholder="Search By Degree..." value={search} onChange={(e) => handleSearch(e)} />
            </div>
            <div className="courseUploaContainer">
                <div className="courseUploadDivCon">
                    <div className="InputDiv">
                        <label>Academic Year</label>

                        <select type="text" onChange={(e) => setCourseDetails({ ...courseDetails, academicYear: e.target.value })} >
                            <option>{currentYear}</option>
                            {academicYear.length > 0 && academicYear.map((items, index) => {
                                return (
                                    <option key={index}>{items}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="InputDiv">
                        <label>level</label>
                        <select value={courseDetails.level} onChange={(e) => setCourseDetails({ ...courseDetails, level: e.target.value })}>
                            <option>Please Select</option>
                            {level.length > 0 && level.map((items, index) => {
                                return (
                                    <option key={index}>{items}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="InputDiv">
                        <label>Degree</label>
                        <select value={courseDetails.degree} onChange={(e) => setCourseDetails({ ...courseDetails, degree: e.target.value })}>
                            <option>Please Select</option>
                            {degreelist.length > 0 && degreelist.map((items, index) => {
                                return (
                                    <option key={index}>{items}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="InputDiv">
                        <label>Name Of the Course</label>
                        <input type="text" value={courseDetails.branch} onChange={(e) => setCourseDetails({ ...courseDetails, branch: e.target.value })} />
                    </div>
                    <div className="InputDiv">
                        <label>Intake</label>
                        <input type="text" value={courseDetails.intakeCount} onChange={(e) => setCourseDetails({ ...courseDetails, intakeCount: e.target.value })} />
                    </div>
                    <div className="InputDiv">
                        {edit === false ? <button onClick={() => handleAdd()}>Add</button> : <button onClick={() => handleModifyRecord()}>Submit</button>}
                    </div>
                </div>
                <div className="towtablesView">
                    <div className="tableStylesDiv">
                        <table className="tableStylings">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Academic Year</th>
                                    <th>Degree</th>
                                    <th>Branch</th>
                                    <th>Count</th>
                                    <th>Action</th>

                                </tr>
                            </thead>

                            <tbody>
                                {courselist && courselist.length > 0 && courselist.map((items, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{items.academicYear}</td>
                                            <td>{items.degree}</td>
                                            <td>{items.branch}</td>
                                            <td>{items.intakeCount}</td>
                                            <td>
                                                <EditOutlinedIcon onClick={() => { setCourseDetails(items); setEdit(true) }} />
                                                <DeleteOutlinedIcon onClick={() => HandleDeleteRecord(items)} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="submitButtonDiv">
                            <button onClick={() => handleSubmit()}>Submit</button>
                        </div>
                    </div>
                    <div className="tableStylesDiv">

                        <table className="tableStylings">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>AcademicYear</th>
                                    <th>Level</th>
                                    <th>Degree</th>
                                    <th>Branch</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginationData && paginationData.length > 0 && paginationData.map((items, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{serialNo + index}</td>
                                            <td>{items.academicYear}</td>
                                            <td>{items.level}</td>
                                            <td>{items.degree}</td>
                                            <td>{items.branch}</td>
                                            <td>{items.intakeCount}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
            <PaginationReUsable
                paginationData={filteredData}
                handleGetSpliceData={handleGetSpliceData}
            />
        </div>

    )
}
export default CourseUpload;
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import "./Login.css";
import "./GalleryView.css";
const ImageViewer = ({ eventItems }) => {
  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [images, setImages] = useState([]);
  console.log("images===>", images);
  const [description, setDescription] = useState("");
  const [studentDetails, setStudentDetails] = useState([]);
  const [nameOfTheEvent, setNameOfTheEvent] = useState("");
  const [chief1, setChief1] = useState("");
  const [chief2, setChief2] = useState("");
  const [conducted, setConducted] = useState("");
  useEffect(() => {
    const imagesdata = eventItems?.filePath.split(",");
    setImages(imagesdata);
    setDescription(eventItems?.description);
    setStudentDetails(eventItems?.eventParticipantsModel);
    setNameOfTheEvent(eventItems?.eventName);
    setChief1(eventItems?.chiefGuest1);
    setChief2(eventItems?.chiefGuest2);
    setConducted(eventItems?.conductedBy);
  }, [eventItems]);
  console.log("eventItems===>", eventItems);

  return (
    <div className="MainContainerImgs">
      <p className="TitleEvent">{nameOfTheEvent}</p>
      <div className="imagesAndStudents">
        <div className="discriptionCon">
          <h1 className="headEvent">
            Event has beeen conducted by <span>{conducted}</span>
          </h1>
          <h1 className="headEvent">
            Chief Guests are <span>{chief1}</span> and <span>{chief2}</span>
          </h1>

          <div className="parContainer">
          <p>{description}</p>
          </div>
        </div>
        <div className="secondTable">
          <table className="studentDataTable">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Contact</th>
              </tr>
            </thead>
            <tbody>
              {studentDetails &&
                studentDetails.length > 0 &&
                studentDetails.map((items, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{items.participantName}</td>
                      <td>{items.participantEmail}</td>
                      <td>{items.mobileNo}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="imageContainer">
        <div className="carousal_bg_cnt1">
          {images && images.length > 0 && 
          <Slider {...settings}>
            {images.map((item, index) => {
              return (
                <div key={index} style={{ width: "100%" }}>
                  <div className="card-body">
                    <img
                      className="img-fluid1 carousal_img1"
                      src={`data:application/pdf;base64,${item}`}
                      alt="carousel image"
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
}
        </div>
      </div>
    </div>
  );
};

export default ImageViewer;

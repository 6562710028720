import React from "react";
import Table from "@mui/joy/Table";
import { PageHeader } from "../../Header/PageHeader";
import { resources } from "../../Resourses/Resources";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "../styleFaculty/styleDep.css";
import PaginationReUsable from "../../ReuseableComponent/ReusePagination";
import "../../ReuseableComponent/ReusePagination.css";

const Faculty = () => {
  const [facultyData, setfacultyData] = useState([]);

  // Pagination
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  console.log("filteredData==>", filteredData);
  const [search, setSearch] = useState("");
  const [serialNo, setSerialNo] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  useEffect(() => {
    setFilteredData(facultyData);
    setAllData(facultyData);
  }, [facultyData]);
  const handleGetSpliceData = (receivedList, startSNo) => {
    setPaginationData(receivedList);

    setSerialNo(startSNo);
  };

  const handleSearch = (e) => {
    let { value } = e.target;
    console.log("value", value);

    let list = allData.filter(
      (each) =>
        (each.fid && each.fid.includes(value)) ||
        (each.fname && each.fname.toLowerCase().includes(value.toLowerCase()))
    );

    console.log("list==>", list);
    setFilteredData(list);
    setSearch(value);
  };

  const location = useLocation();
  console.log("locationComplete===>", location);
  console.log("location===>", location.pathname);
  const branchFaculty = location.pathname.split("/")[2].toUpperCase();
  console.log("branch===>", branchFaculty);

  useEffect(() => {
    try {
      axios
        .get(
          resources.APPLICATION_URL +
          `get/All/Active/FacultyDetailsByDepartment?department=${branchFaculty}`
        )
        .then((res) => {
          console.log(res.data);
          setfacultyData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log("error from Backend faculty Details===>", e);
    }
  }, []);
  
  return (
    <div className="container">
      <PageHeader name="FACULTY" />
      <h1 className="tableHeading">List of all S&H faculty</h1>
      <div className="saarchDiv">
        <input
          type="text"
          value={search}
          placeholder="Search By Name..."
          onChange={(e) => handleSearch(e)}
        />
      </div>
      <Table className="Examtool_allinone_table" id="faccse">
        <thead style={{ backgroundColor: "#b3078d" }}>
          <tr>
            <th>S.NO</th>
            <th>Faculty Name</th>
            <th>Designation</th>
            <th>Qualification</th>
            <th>Experience</th>
            <th>Faculty Id</th>
          </tr>
        </thead>
        <tbody>
          {paginationData.length > 0
            ? paginationData.map((each, i) => {
                const Sno = serialNo + i;
                console.log("facDtaa===>", paginationData);
                console.log("each==>", each.fname);
                const dateOfJoin = each.dateOfJoin;
                const currentDate = new Date();
                
                const [joinDay, joinMonth, joinYear] = dateOfJoin.split("-").map(Number);
                const joinDate = new Date(joinYear, joinMonth - 1, joinDay);
                const differenceMs = currentDate - joinDate;
                
                const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; 
                const experienceYears = Math.floor(differenceMs / millisecondsInYear);
                const remainingMs = differenceMs % millisecondsInYear;
                const millisecondsInMonth = (1000 * 60 * 60 * 24 * 365.25) / 12; 
                const experienceMonths = Math.floor(remainingMs / millisecondsInMonth);
                const experienceDays = Math.floor((remainingMs % millisecondsInMonth) / (1000 * 60 * 60 * 24));
                const newExperience=(Number(each.fexp)+experienceYears)+"Y"+"-"+experienceMonths+"M"
                console.log("Experience:", experienceYears, "years", experienceMonths, "months", experienceDays, "days");
                
                return (
                  <tr key={i}>
                    <td>{Sno}</td>
                    <td>{each.faculty_name}</td>
                    <td>{each.designation}</td>
                    <td>{each.qualifications}</td>
                    <td>{each.totalExperience}</td>
                    <td>{each.facultyId}</td>
                  </tr>
                );
              })
            : ""}
        </tbody>
      </Table>
      <PaginationReUsable
        paginationData={filteredData}
        handleGetSpliceData={handleGetSpliceData}
      />
    </div>
  );
};

export default Faculty;

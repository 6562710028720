import React from "react";
import "./AictefeedbackView.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import PaginationReUsable from "../../Components/ReuseableComponent/ReusePagination";
const StudentFeedbackView = () => {
  const [studentData, setstudentData] = useState([]);
  // Pagination
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  console.log("filteredData==>", filteredData);
  const [search, setSearch] = useState("");
  const [serialNo, setSerialNo] = useState("");

  const [paginationData, setPaginationData] = useState([]);
  useEffect(() => {
    setFilteredData(studentData);
    setAllData(studentData);
  }, [studentData]);
  const handleGetSpliceData = (receivedList, startSNo) => {
    setPaginationData(receivedList);

    setSerialNo(startSNo);
  };
  const handleSearch = (e) => {
    let { value } = e.target;
    console.log("value", value);

    let list = allData.filter(
      (each) =>
        (each.studentId && each.studentId.includes(value)) ||
        (each.nameOfTheStudent && each.nameOfTheStudent.toLowerCase().includes(value.toLowerCase()))
    );

    console.log("list==>", list);
    setFilteredData(list);
    setSearch(value);
  };

  const handleView = async () => {
    try {
      await axios
        .get(resources.APPLICATION_URL + "getAllStudentFds")
        .then((response) => {
          console.log("response===>", response.data);
          if (response.data.length > 0) {
            setstudentData(response.data);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleView();
  }, []);

  return (
    <>
      <div className="main_container_aicte">
        <div className="saarchDiv">
          <input
            type="text"
            value={search}
            placeholder="Search By Name..."
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <table className="Aicte_table_container">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Student Id</th>
              <th>Student Name</th>
              <th>Gender</th>
              <th>Aadhar No</th>
              <th>Contact</th>
              <th>Institute State</th>
              <th>AICTE Id</th>
              <th>Institute Name</th>
              <th>Institute Address</th>
            </tr>
          </thead>
          <tbody>
                      {paginationData.length > 0 && paginationData.map((data, i) => {
                          const SNo = serialNo + i;
                          return (
                              <tr key={i}>
                                  <td>{SNo}</td>
                                  <td>{data.studentId}</td>
                                  <td>{data.nameOfTheStudent}</td>
                                  <td>{data.studentGender}</td>
                                  <td>{data.studentAdharNumber}</td>
                                  <td>{data.sudentContact}</td>
                                  <td>{data.studentInstituteState}</td>
                                  <td>{data.studentAictePerminentId}</td>
                                  <td>{data.studentInstituteName}</td>
                                  <td>{data.studentInstituteAddress}</td>
                              </tr>
                          )
                      })}
          </tbody>
        </table>
        <PaginationReUsable
          paginationData={filteredData}
          handleGetSpliceData={handleGetSpliceData}
        />
      </div>
    </>
  );
};

export default StudentFeedbackView;
